import React, { useEffect, useState } from 'react'
import { Route, Routes as Switch } from 'react-router-dom'
import { Box, useBoolean } from '@chakra-ui/react'
import { Header, Footer } from './Components'
import Home from '../../pages/Home'
import { Loader } from '../../uikit'
import { useDispatch, useSelector } from 'react-redux'
import ClassView from '../../pages/ClassView'
import About from '../../pages/About'
import Contact from '../../pages/Contact'
import Terms from '../../pages/Terms'
import Privacy from '../../pages/Privacy'
import MockExam from '../../pages/MockExam'
import SubjectView from '../../pages/SubjectView'
import JoinClass from '../../pages/JoinClass'
import Papers from '../../pages/Papers'
import Ecat from '../../pages/Papers/Ecat'
// import { useDispatch, useSelector } from 'react-redux'
// import { Loader } from '../../ui-kit/Loader'
// import { subjectsAction } from '../../store/actions'
// import NotFound from '../../pages/errors/404'

export default function MainLayout() {

    const [height, setHeight] = useState(0)
    const [loading, setLoading] = useBoolean();
    const [mount, setMount] = useBoolean(false)
    const dispatch = useDispatch();
    // const { classes } = useSelector(state => state?.subjects)
    const classes = []

    // useEffect(() => {
    //     if (!mount) {
    //         setMount.on()
    //         setLoading.on();
    //         dispatch(subjectsAction.getAll())
    //             .then(() => {
    //                 setLoading.off();
    //             })
    //             .catch(() => {
    //                 setLoading.off();
    //             })
    //     }

    // }, [mount, setMount, dispatch, setLoading]);

    return (

        loading ? <Loader /> : (
            <>
                <Header setHeight={setHeight} classes={classes} />
                <Box>

                    <Switch>
                        <Route exact path="/" element={<Home />} />
                        <Route exact path="/class/:slug" element={<ClassView />} />
                        <Route exact path="/about" element={<About />} />
                        <Route exact path="/contact" element={<Contact />} />
                        <Route exact path="/terms-and-conditions" element={<Terms />} />
                        <Route exact path="/privacy-policy" element={<Privacy />} />
                        <Route exact path="/mdcat" element={<Papers />} />
                        <Route exact path="/ecat" element={<Ecat />} />
                        <Route exact path="/mock-exam" element={<MockExam />} />
                        <Route exact path="/subject/:slug" element={<SubjectView />} />
                        <Route exact path="/join-class/:class_id" element={<JoinClass />} />
                        {/* 
                        
                         />
                                                
                        <Route path="*" element={<NotFound />} /> */}
                    </Switch>

                </Box>              
                <Footer />
            </>
        )



    )
}


import React, { useState, useEffect} from "react"
import { Box , Spinner, HStack, useBoolean, Heading, Button, Text } from "@chakra-ui/react"
import { Helmet } from "react-helmet-async"
import { useLocation, useNavigate, useParams, Link } from "react-router-dom"
import { useApp } from "../../../hooks"
import { paperApi } from "../../../api"
import { Modal } from "../../../uikit"
import dayjs from "dayjs"

export default function CatResults(){

    const [ loading, setLoading ] = useBoolean(false)
    const location = useLocation()
    const [ quizzes, setQuizzes ] = useState([])
    const [ mount, setMount ] = useBoolean(false)
    const { setDashHeading, profile} = useApp()  
    const [ details, setDetails ] = useState(null)
    const exam_type = location?.pathname?.includes('ecat') ? 'ECAT' : location?.pathname?.includes('mdcat') ? "MDCAT" : ""

    useEffect(() => {
        setDashHeading(exam_type)
      return () => {
        setDashHeading('Dashboard')
      };
    }, [])

    useEffect(() => {
        if(exam_type && !mount){
            setMount.on()
            setLoading.on()
            paperApi._get_results(exam_type?.toUpperCase())
            .then((res) =>{
                setQuizzes(res?.quizzes)
                setLoading.off()
            })
            .catch((err) =>{
                setQuizzes([])
                setLoading.off()
            })    
        }
    }, [mount, setMount, exam_type, setLoading])



    return( 
        <>
            <Helmet>
                <title>Results - PCTB Online Academy</title>
                <meta name="description" content="PCTB Online Academy is FREE online learning platform for HSSC and SSC Student covering of Subjects, Assessment and other learning material to help our young genration for tapping in to the future." />
            </Helmet>
            {
                loading ?
                    <Box d="flex" h="100%" alignItems="center" justifyContent="center">
                        <Spinner />
                    </Box>
                :
                <Box px="2em" py="3">
                    <HStack py={4} justifyContent="space-between">
                        <Box>
                            <Heading size="lg" fontWeight="800">{`${'Mock Exam Results'}`}</Heading>
                        </Box>
                        <Button as={Link} to={'/account/'+exam_type}>Course Work</Button>
                    </HStack>

                    {
                        quizzes &&
                        <>
                            <Heading size="md" fontWeight="bold" fontFamily="Poppins">Exam Results</Heading>
                            <table className="results-table">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Subject</th>
                                        <th>Total Questions</th>
                                        <th>Correct Answers</th>
                                        <th>Marks Obtained</th>
                                        <th></th>

                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    quizzes?.map((quiz, q) =>                                        
                                            <tr key={q}>
                                                <td>{dayjs(quiz?.created_at).format('DD MMM YYYY hh:mm a')}</td>
                                                <td>{quiz?.title}</td>
                                                <td>{quiz?.result?.result?.total_questions}</td>
                                                <td>{quiz?.result?.result?.correct}</td>
                                                <td>{quiz?.result?.result?.percentage.toFixed(2)}%</td>
                                                <td><Button  onClick={() => setDetails(quiz?.result)} size="sm">View Details</Button></td>
                                            </tr>
                                        )
                                    
                                }
                                </tbody>
                            </table>
                        </>
                    }
                </Box>
            }

            <Modal 
                open={details !== null}
                onClose={() => setDetails(null)}
                title="Result Details"
                size="3xl"
                >
                <Box bg="white" rounded="lg">
                {
                    details?.details?.map((requ, b) =>                                         
                        <Box key={b} p="5" borderBottom="1px solid" borderColor="gray.200">
                            <Heading size="sm">Question No. {parseInt(b) + 1}</Heading>
                            <Box my="4" fontWeight="bold">                                
                                <div dangerouslySetInnerHTML={{__html: requ?.question}} />
                            </Box>
                            
                            <Box>
                            {
                                requ?.options?.map((anu, n) => {
                                    if(requ?.correct_answer === anu?.id ){
                                        return(
                                            <HStack p="2" rounded="md" key={n} my="2" bg='green.100'>
                                                <Text fontWeight="bold">{parseInt(n+1)}:</Text>                                                
                                                <div dangerouslySetInnerHTML={{__html: anu?.answer}} />
                                            </HStack>
                                        )
                                    }else if(requ?.answer === anu?.id && !requ?.correct ){
                                        return(
                                            <HStack p="2" rounded="md" key={n} my="2" bg='red.100'>
                                                <Text fontWeight="bold">{parseInt(n+1)}:</Text>
                                                <div dangerouslySetInnerHTML={{__html: anu?.answer}} />
                                            </HStack>
                                        )
                                    }else{
                                        return(
                                            <HStack p="2" rounded="md" key={n} my="2" bg='transparent'>
                                                <Text fontWeight="bold">{parseInt(n+1)}:</Text>
                                                <div dangerouslySetInnerHTML={{__html: anu?.answer}} />
                                            </HStack>
                                        )
                                    }
                                    
                                })
                            }
                            </Box>                                                
                        </Box>
                                                                    
                    )
                }
                </Box>
            </Modal>
        </>
    )
}
import React from "react"
import { Box, Text, Heading, HStack, IconButton,  Image, useBoolean, Button } from "@chakra-ui/react"
import { useDispatch } from "react-redux"
import { paperAction } from "../../../../redux/actions"

import { Link, useNavigate } from "react-router-dom"


export const  SubjectItem = ({data, onClick, handleEdit, ...rest}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()    
    const [ duplicating, setDuplicating ] = useBoolean(false)
    const [ removing, setRemoving ] = useBoolean(false)

    const onDuplicate = () => {
        setDuplicating.on()
        dispatch(paperAction.duplicate(data?.id))
            .then((res) => setDuplicating.off())
            .catch((e) => setDuplicating.off())
    }

    
    
    
//    
    return(
        <Box {...rest} onClick={onClick}>
            <Box p="15px">                
                <Box p="10px" bg="white" w="auto" shadow="custom" rounded="xl" cursor="pointer">                    
                    <Image src={process.env.REACT_APP_STORAGE_URL + data?.image} alt={data?.title} mx="auto" rounded="lg" />
                    <Box px="20px" py="5px" textAlign="center">
                        <Box my="5">
                            <Heading  fontSize="20px">{data?.title}</Heading>                                            
                            <HStack my={4} justifyContent="center">
                                <Text fontSize="sm">Total Chapters: {data?.topics_count || 0}</Text>
                                <Box w="5px" h="5px" rounded="full" bg="accent.500" />
                                <Text fontSize="sm">Total Lectures: {data?.lecture_count}</Text>
                            </HStack>
                        </Box>                                               
                        <Button isFullWidth onClick={onClick}  size="md" rounded="lg"  colorScheme="accent">Start Learning</Button>                       
                       
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
import fetchIntercept from 'fetch-intercept';
import { requestTimeout } from '../helpers';
import { authApi } from '.';
import Cookies from 'js-cookie';

const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME


const originalRequest = {url: null, config: null}

const interceptor = fetchIntercept.register({    
    request: function (url, config) {
        originalRequest.url = url
        originalRequest.config = config
        return [url, config];
    },
 
    requestError: function (error) {
        // Called when an error occured during another 'request' interceptor call        
        return Promise.reject(error);
    },
 
    response: async function (response) {
        let refresh_token = Cookies.get('insaf-auth-token')
        if(response?.status === 401)
        {            
            let {url, config} = originalRequest      
            if(url.includes('token') || !refresh_token){
                interceptor()
                return Promise.reject("Session expired");
            }
            else
            {   
                let data = await  authApi._refresh(refresh_token);
                config['headers']['Authorization'] = 'Bearer '+data.access_token
                return requestTimeout(apiMaxTime,fetch(url, config))
                        .then((data) => {    
                            return data;
                        });                                                
            }            
        }
        else
        {
            return response
        }        
    },
 
    responseError: function (error) {
        // Handle an fetch error
        return Promise.reject(error);
    }
});
import React, { useEffect, useState } from "react"
import { Box, Spinner, useBoolean, Heading, HStack, Text, Button } from "@chakra-ui/react"
import { useApp } from '../../../hooks'
import { useDispatch, useSelector } from "react-redux"
import { paperAction } from "../../../redux/actions"
import { Helmet } from "react-helmet-async"
import { PaperCard, SubjectItem } from "./Components"
import { useNavigate } from "react-router-dom"

export default function Ecat () {
    
    const { setDashHeading} = useApp()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [ loading, setLoading] = useBoolean(false)
    const { papers, subjects} = useSelector(state => state?.papers)
    

    useEffect(() => {
        setLoading.on()
        dispatch(paperAction.get('ECAT'))
        .then((res) =>{
            setLoading.off()
        })
        .catch((err) =>{
            setLoading.off()
        })    
    }, [])

    


    useEffect(() => {
        setDashHeading('ECAT')
      return () => {
        setDashHeading('Dashboard')
      };
    }, [])


    return(
        <>
            <Helmet>
                <title>ECAT - PCTB Online Academy</title>
                <meta name="description" content="PCTB Online Academy is FREE online learning platform for HSSC and SSC Student covering of Subjects, Assessment and other learning material to help our young genration for tapping in to the future." />
            </Helmet>
            {
                loading ?
                    <Box d="flex" h="100%" alignItems="center" justifyContent="center">
                        <Spinner />
                    </Box>
                :
                <>
                <Box px="2em" py="3">
                    <HStack mb={4} justifyContent="space-between" alignItems="center">
                        <Heading fontFamily="Poppins" fontWeight="bold">Subjects</Heading>
                        <Button onClick={() => navigate('/account/ecat/mock-exam')}>ECAT Mock Exam</Button>
                    </HStack>
                    <HStack flexWrap="wrap" spacing="0" mx="-15px" mb={5}>
                    {
                        subjects?.map((subject, s) => 
                        <SubjectItem onClick={() => navigate('/account/ecat/subject/'+subject?.id)}  data={subject} w={{ base: '100%', md: '50%', lg: '33.33%' }} key={s} />
                        )
                    }                        
                    </HStack>
                </Box>
                <Box px="2em" py="3">
                    <Heading fontFamily="Poppins" size="md" fontWeight="bold"></Heading>
                    <HStack flexWrap="wrap" spacing="0" mx="-15px">
                    {
                        papers?.map((paper, p) => 
                        <PaperCard
                            w={{ base: '50%', md: '50%', lg: '33.33%' }}
                            key={p}
                            subtitle={paper?.label || "..."}
                            heading={paper.title}                                    
                            to={'/account/ecat/'+paper?.url_title} 
                        />
                        )
                    }
                    </HStack>

                    {
                        (papers?.length === 0 && !loading) &&
                        <Box px={5} py={2} bg="yellow.100">
                            <Text>No ECAT Papers Found</Text>
                        </Box>
                    }

                </Box>
                </>
            }            
            
        </>
    )
}
import React, { useState, useEffect} from "react"
import { Box, Text, HStack, Image, Stack, Heading, Menu, Button, MenuItem, MenuButton, MenuList, AspectRatio, useBoolean } from "@chakra-ui/react"
import { Helmet } from "react-helmet-async"
import { BarChart, DonutChart, ProgressChart } from "./Charts"
import { subjectAction, authAction, gradeAction } from "../../../redux/actions"
import { useDispatch, useSelector } from 'react-redux';
import { useApp } from "../../../hooks"
import { Link } from "react-router-dom"
import ReactPlayer from 'react-player'
import { Icon } from "../../../uikit"

export default function Dashboard(){

    const dispatch = useDispatch();    
    const [ loading, setLoading ] = useBoolean(false)
    const {user, profile} = useApp()
    
    const { badges} = useSelector(state => state.subjects)
    const { grades} = useSelector(state => state?.grades)
    const [ userGrade, setUserGrade] = useState(grades[0])


    useEffect(() => {
        dispatch(gradeAction.get())
            .then((res) => {

            })
            .catch((err) => {
                console.log("Error", err)
            })
    }, [])

    useEffect(() => {
        if(grades?.length && profile){
            let myGrade = grades?.find(x => x?.id === profile?.grade_id);
            setUserGrade(myGrade)
        }
    }, [grades, profile])

    useEffect(() => {
        setLoading.on()
        dispatch(subjectAction.badges())
            .then((res) => setLoading.off())
            .catch((err) => setLoading.off())
    }, [])

    const changeClass = (id) => {
        
        let form_data ={
            first_name: user?.first_name,
            last_name: user?.last_name,
            gender: profile?.gender,
            country: profile?.country,
            province: profile?.province,
            city: profile?.city,
            grade_id: id,
            phone: profile?.phone,
            father_name: profile?.father_name,
            school: profile?.school
        }
        dispatch(authAction.update_user(form_data))            
    }


    let gold_badges = badges?.badges?.filter((x) => x?.badge === 'GOLD')?.length;
    let silver_badges = badges?.badges?.filter((x) => x?.badge === 'SILVER')?.length;
    let bronze_badges = badges?.badges?.filter((x) => x?.badge === 'BRONZE')?.length;

    return(
        <>
            <Helmet>
                <title>My Account - PCTB Online Academy</title>
                <meta name="description" content="PCTB Online Academy is FREE online learning platform for HSSC and SSC Student covering of Subjects, Assessment and other learning material to help our young genration for tapping in to the future." />
            </Helmet>
            <Box>
            <HStack flexDirection={{ base: "column", md: "row" }} px="2em" py="20px" spacing={{base: "10px", md: "40px" }} justifyContent="space-between" alignItems="flex-start">
                <Box flex={{base: 1, md:3}}>                    
                    <Box bg="accent.100" p="45px" rounded="xl" bgImage="/assets/dashboard-section-bg.png" bgSize="cover" >
                        <HStack position="relative" alignItems="flex-start" spacing="40px">
                            <Box w="70%">
                                <Heading mb="10px" fontWeight="800" size="lg" color="white">Good Job, {user?.first_name}, Keep Going</Heading>
                                <Text fontSize="18px" color="rgba(255,255,255,0.75)">"Develop a passion for learning. If you do, you will never cease to grow"</Text>
                                <Text textAlign="right" mt="2" fontWeight="bold" fontSize="18px" color="white"> Anthony J. D'Angelo</Text>
                            </Box>
                            <Box position="absolute" right="2" top="2">
                                
                                <Menu placement="bottom-end">                                
                                    <MenuButton as={Box}>
                                        <HStack>
                                            <Text fontWeight="bold" fontSize='sm' color="white">
                                                {userGrade?.title} {userGrade?.label}
                                            </Text>
                                            <Icon name="angle-down-solid" color="white" size="12px" />
                                        </HStack>
                                    </MenuButton>
                                    <MenuList>
                                        {
                                            grades?.map((grade, g) =>
                                            <MenuItem onClick={() => changeClass(grade?.id)}>{grade?.title} {grade?.label}</MenuItem>
                                            )
                                        }
                                        
                                    </MenuList>
                                </Menu>
                            </Box>
                        </HStack>
                    </Box>                    

                    <Stack direction={{ base: "column", md: "row" }} spacing={{ base: "10", md: "10" }} mt={"7"}  alignItems={{ base: "normal", md: "flex-start" }}>
                        <Box bg="white" flex="1" borderRadius={"10px"} py={5} px={'2'}>
                            <HStack flexDirection={['row', 'column']}>
                                <Box flex={1} w={{base: "100%", md: "100px"}} h={{base: "auto", md: '100px'}}>
                                    <Image
                                        src='/assets/goldmedal.svg'
                                        alt='goldmedal'
                                        objectFit={"cover"}
                                        w={"100%"}
                                        h={"100%"}
                                    />
                                </Box>
                                <Box ml={"5"} textAlign={{ base: "center", md: "left" }}>
                                    <Text color={"rgba(55, 69, 87, 1)"} fontWeight={"900"} fontSize={"18"}>
                                        {gold_badges}
                                    </Text>
                                    <Text color={"rgba(55, 69, 87, 1)"}> Gold Badges Earned</Text>
                                </Box>
                            </HStack>
                        </Box>

                        <Box bg="white" flex="1" borderRadius={"10px"} py={5} px={'2'}>
                            <HStack flexDirection={['row', 'column']}>
                                <Box flex={1} w={{base: "100%", md: "100px"}} h={{base: "auto", md: '100px'}}>
                                    <Image
                                        src='/assets/silvermedal.svg'
                                        alt='silvermedal'
                                        objectFit={"cover"}
                                        w={"100%"}
                                        h={"100%"}
                                    />

                                </Box>
                                <Box ml={"5"} textAlign={{ base: "center", md: "left" }}>
                                    <Text color={"rgba(55, 69, 87, 1)"} fontWeight={"900"} fontSize={"18"}>
                                        {silver_badges}
                                    </Text>
                                    <Text color={"rgba(55, 69, 87, 1)"}> Silver Badges Earned</Text>
                                </Box>
                            </HStack>
                        </Box>
                        
                        <Box bg="white" flex="1" borderRadius={"10px"} py={5} px={'2'}>
                            <HStack flexDirection={['row', 'column']}>
                                <Box flex={1} w={{base: "100%", md: "100px"}} h={{base: "auto", md: '100px'}}>
                                    <Image
                                        src='/assets/bronzemedal.svg'
                                        alt='bronzemedal'
                                        objectFit={"cover"}
                                        w={"100%"}
                                        h={"100%"}
                                    />
                                </Box>
                                <Box ml={"5"} textAlign={{ base: "center", md: "left" }}>
                                    <Text color={"rgba(55, 69, 87, 1)"} fontWeight={"900"} fontSize={"18"}>
                                        {bronze_badges}
                                    </Text>
                                    <Text color={"rgba(55, 69, 87, 1)"}> Bronze Badges Earned</Text>
                                </Box>
                            </HStack>
                        </Box>
                    </Stack>

                    <Box my={7} flex="1" bg="white" w="100%" rounded="xl" p="2" pt={4}>
                        <BarChart data={profile} height="450px" title="Subjects Progress (Videos & Quizzes)" />
                    </Box>


                   

                    {/* <Box flex="1" bg="white" rounded="xl" p="2" pt={4}>
                        <BarChart data={profile} height="330px" title="Subjects Progress (Videos & Quizzes)" />
                    </Box> */}

                    
                </Box>
                <Box flex={1} w="100%">
                    <Box  bg="white" mb="7" rounded="xl" p="2">
                        <DonutChart data={profile} title="My Subjects" />
                    </Box>   

                    <Box  bg="white" mb="7" rounded="xl" p="4">
                        <Button isFullWidth as={Link} to="/account/subjects">{profile?.progress?.length > 0  ? 'Continue Learning' : 'Start Learning'}</Button>
                    </Box>   

                    <Box  bg="white" mb="7" rounded="xl" p="4">
                        <Heading size="md">Recent Lectures</Heading>
                        {
                            profile?.recent?.length === 0 &&
                            <Box>
                                No recent lectures
                            </Box>
                        }
                        {
                            profile?.recent?.map((vid, v) =>{
                                if(v === 0){
                                    console.log("Vid", vid)
                                    return(
                                        <Box key={v}>
                                            <HStack my="4" key={v} as={Link} to={"/account/subjects/topic/" + vid?.topics?.id} alignItems="flex-start"  bg="gray.50" rounded="sm" spacing="4" _hover={{ bg: 'gray.100' }}>
                                                <Box flex="1" rounded="lg" overflow="hidden" position="relative">
                                                    <AspectRatio w="100%" ratio={16 / 9}>
                                                        <div className='player-wrapper'>
                                                            <ReactPlayer
                                                                className='react-player'
                                                                url={'https://www.youtube.com/watch?v=' + vid?.topics?.data}
                                                                width='100%'
                                                                // light={true}
                                                                height='100%'
                                                                autoPlay={false}
                                                            />
                                                        </div>
                                                    </AspectRatio>
                                                    <Box  position="absolute" left="0" top="0" w="100%" h="100%" bgGradient='linear(rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 50%)'>
                                                        <HStack position="absolute" bottom="0" left="0" w="100%" px="20px" py="10px"  alignItems="center">
                                                            <Image src="/assets/btn-play-icon.png" alt="Play" />                                                    
                                                            <Text noOfLines={1} color="white" fontSize="14px">{vid?.topics?.title}</Text>                                                    
                                                        </HStack>
                                                    </Box>
                                                </Box>                                                
                                            </HStack>
                                        </Box>
                                    )
                                }else if(v < 5) {
                                    return(
                                    <HStack as={Link} to={"/account/subjects/topic/" + vid?.topics?.id}  key={v} my="5" alignItems="flex-start">
                                        <Image mt={1} boxSize="16px" src="/assets/tick-icon.svg" alt="" />
                                        <Text fontSize="sm" color="gray.800">{vid?.topics?.title}</Text>
                                    </HStack>
                                    )
                                }else{
                                    return <></>
                                }
                            })
                        }
                        
                        
                    </Box>                    
                </Box>
                
            </HStack>
        </Box>
        </>
        
    )
}
import {handleResponse, requestTimeout} from '../helpers'
const apiUrl = process.env.REACT_APP_API_URL
const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME

export const gradeApi = {
    _get,    
    _with_subjects,
    _with_subjects2,
    _with_subjects_minimal,
    _details
};



async function _get() {    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'grades', requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _with_subjects() {    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'grades-subjects', requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _with_subjects2() {    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'grades-subjects2', requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _with_subjects_minimal() {    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'grades-subjects-minimal', requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _details(slug) {    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'grades/'+slug, requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}



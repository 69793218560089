import React, { Fragment, useEffect } from 'react';
import { Box, HStack, Image, Text, Heading, useBoolean, Table, Thead, Tr,  Td, Th, Tbody, IconButton } from '@chakra-ui/react';
import { useApp } from '../../../hooks';
import { Icon } from '../../../uikit';
import { useDispatch, useSelector  } from 'react-redux';
import { vClassAction } from '../../../redux/actions';
import dayjs from 'dayjs'

const LiveSessions = () => {
     
    const { setDashHeading } = useApp()
    const dispatch = useDispatch()
    const { user, profile } = useApp()
    const { sessions } = useSelector(state => state?.vclass)

    useEffect(() => {        
        dispatch(vClassAction.live(profile?.grade_id))
    }, [profile])


    useEffect(() => {
        setDashHeading("Live Sessions")
        return () => {
            setDashHeading("Dashboard")
          };
    }, [])

    
    console.log("Sessions", sessions)
    return (
        <Box p="2em">
            {
            (sessions?.length > 0) ?                    
            <Table bg="white" size="sm" shadow="sm" border="0"  rounded="md">
                <Thead>
                    <Tr>
                        
                        <Th py="3">Title</Th>                                
                        <Th py="3">URL</Th> 
                        <Th py="3">Date</Th>
                        <Th display={{ base: "none", md: "table-cell" }} py="3">Class</Th>
                        <Th display={{ base: "none", md: "table-cell" }} py="3">Subject</Th>
                        
                    </Tr>
                </Thead>
                <Tbody>
                    {sessions?.map((ss, s) => 
                    <Tr key={s} cursor="pointer" _hover={{ bg: 'gray.50' }}>
                        <Td py={6}>{ss?.title}</Td>
                        <Td py={6}>{ss?.class_url}</Td>                                
                        <Td py={6}>{dayjs(ss?.date).format('DD MMM YYYY')} {ss?.time} </Td>                            
                        <Td display={{ base: "none", md: "table-cell" }} py={6}>{ss?.grade?.title} {ss?.grade?.label}</Td>
                        <Td display={{ base: "none", md: "table-cell" }} py={6}>{ss?.subject}</Td>
                        
                                                            
                    </Tr>
                    )}
                </Tbody>
            </Table>
            :
            <Box my="5">
                <Box  bg="yellow.100" color="yellow.600" p="6">
                    <Text color="yellow.700">No live sessions has been added yet.</Text>
                </Box>
            </Box>
        }

        </Box>
    )
}

export default LiveSessions;
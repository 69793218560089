// import { datepicker } from "./datepicker"

export const custom = {
    'html, body': {
        color: 'gray.600',
        },
    '.dash-nav': {
            '&.active': {
                backgroundColor: 'accent.500',
                color: 'white',
                '&:hover': {                    
                    backgroundColor: 'accent.500',
                    color: 'white',
                }            
            },
            
    },
    ".player-wrapper":{
        position: 'relative',
        paddingTop: '56.25%'
    },
    ".react-player":{
        position: 'absolute',
        top: 0,
        left: 0,
    },
    '.hover-reveal':{
            '.hover-target':{
                visibility: 'hidden'
            },
            '&:hover':{
                '.hover-target':{
                    visibility: 'visible'
                }
            }
    },
    ".video-slider":{        
        ".slick-dots":{
            bottom: '20px',
        }
    },
    ".home-slider":{
        '.slick-dots':{
            listStyleType: 'none',
            margin: 0,
            padding: 0,
            textAlign: 'center',
            position: 'absolute',
            bottom: '30px',
            left: '50%',
            transform: 'translateX(-50%)',
            li:{            
                display: 'inline-block',
                mx: 1,
                my: 0,
                button:{
                    w: '8px',
                    h: '8px',
                    overflow: 'hidden',
                    textIndent: '-20px',
                    bg: 'whiteAlpha.500',
                    rounded: 'full'
                },
                '&.slick-active':{
                    button:{
                        bg: 'accent.500'
                    }
                }
            }
        }
    },
    '.slick-dots':{
        listStyleType: 'none',
        margin: 0,
        padding: 0,
        textAlign: 'center',
        position: 'absolute',
        bottom: '-30px',
        left: '50%',
        transform: 'translateX(-50%)',
        li:{            
            display: 'inline-block',
            mx: 1,
            my: 0,
            button:{
                w: '8px',
                h: '8px',
                overflow: 'hidden',
                textIndent: '-20px',
                bg: 'blackAlpha.500',
                rounded: 'full'
            },
            '&.slick-active':{
                button:{
                    bg: 'accent.500'
                }
            }
        }
    },
    ".student-slider":{
        ".slick-slide":{
            transition: "all 0.3s linear",
            transform: "scale(0.8)"
        },
        ".slick-current":{
            transform: "scale(1)"
        }
    },
    '.account-nav': {
        d: 'block',
        rounded: "xl",
        '&:hover':{
            // bg: 'gray.50',                
            color: 'whiteAlpha.900'
        },
        '&.active': {
            fontWeight: 'bold',                
            backgroundColor: 'accent.500',                
            color: 'white',
            '&:hover': {
                // borderColor: 'brand.500',                
                color: 'white',
            }            
        },
        
    },
    '::-webkit-scrollbar':{
            bg: '#f2f2f2',
            w: '4px',
            h: '4px'
        },            
    '::-webkit-scrollbar-thumb': {
            bg: '#C9C9C9',
            borderRadius: '5px'
        },            
    '::-webkit-scrollbar-thumb:hover, ::-webkit-scrollbar-thumb:active':{
            cursor: 'pointer'
        },
    '.jodit-container:not(.jodit_inline) .jodit-workplace':{
        bg: 'white'
    },
    ".results-table":{
        margin: "15px 0",
        rounded: "xl",
        shadow: "custom",
        overflow: 'hidden',
        width: '100%',
        backgroundColor: 'white',
        tbody:{
            tr:{
                cursor: 'pointer',
                "&:hover":{
                    bg: 'gray.50'
                }
            }
        },
        th:{
            textAlign: 'left',
            padding: '15px 20px',
            backgroundColor: '#f2f2f2'
        },
        td:{
            textAlign: 'left',
            padding: '25px 20px',
            borderBottom: '1px solid',
            borderColor: "gray.200"
        }
    },
    '.hover-reveal2':{
        '&:hover':{
            '.hover-target':{
                d: 'flex'
            }
        }
    },
    '.custom-table':{
        w: '100%',
        my: 5,

        tbody:{
            tr:{
                cursor: 'pointer',
                "&:hover":{
                    bg: 'gray.50'
                }
            }
        },
        th:{
            textAlign: 'left',
            padding: '5px 20px',
        },
        td:{
            textAlign: 'left',
            padding: '15px 20px',            
        }        
    }
    // ...datepicker
}   

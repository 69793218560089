import React, { useState, useEffect } from 'react'
import { Box, Slider, Text, Heading, Button, useToast,
    SliderTrack, SliderFilledTrack, SliderThumb, HStack, useBoolean 
} from '@chakra-ui/react'
import { Modal } from '../../../uikit'
import { useDispatch, useSelector } from 'react-redux'
import { gradeAction, virtualClassAction } from '../../../redux/actions'
import { Input, Select } from '../../../uikit'

export default function NewClass({open, onClose, ...rest}){

    const dispatch = useDispatch()
    const toast = useToast()
    const [state, setState ] = useState({total_students: 30, type: 'PUBLIC'})
    const [ errors, setErrors ] = useState(null)
    const [ subjects, setSubjects ] = useState([])
    const [ submitting, setSubmitting ] = useBoolean(false)
    const [ done, setDone ] = useBoolean(null)
    const { with_subjects } = useSelector(state => state?.grades)
    const handleInputChange = (name, value) => {
        setState({
            ...state,
            [name]: value
        })
    }

    useEffect(() => {
        dispatch(gradeAction.with_subjects())
    }, [])

    useEffect(() => {
      if(state?.type === 'PRIVATE' && state?.total_students > 50){
        setState({
            ...state,
            total_students: 50
        })
      }
    }, [state?.type])

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitting.on()
        dispatch(virtualClassAction.add(state))
            .then((res) => {
                setSubmitting.off()
                toast({
                    title: 'Virtual Class',
                    description: res?.message || 'Class created successfully',
                    position: 'top'
                })
                setState({total_students: 30, type: "PUBLIC"})
                alert(`${"URL for virutal class is <br/> <br /><code>" + process.env.REACT_APP_BASE_URL+"join-class/"+res?.virtual_class?.id+"</code>"}`, "Virtual Class Created" );
                onClose()
            })
            .catch((err) => {
                setErrors(err?.error)
                setSubmitting.off()
            })

    }

    useEffect(() => {
        if(state?.grade_id){
            let grade = with_subjects?.find((x) => x?.id === parseInt(state?.grade_id))
            setSubjects(grade?.subjects || [])
        }
    }, [state?.grade_id])

    
    
    return(
        <Modal
            open={open}
            onClose={onClose}
            title="Create Virtual Class"
            >                
            <Box p={6}>
                <form onSubmit={handleSubmit}>
                    <Box my="5">
                        <Text fontWeight="bold" fontSize="12px">Class Type</Text>
                        <HStack mt="3" justifyContent="space-between">
                            <Box cursor="pointer" py="3" flex="1" rounded="lg" bg={state?.type === 'PUBLIC' ? 'accent.500' : 'white'} color={state?.type === 'PUBLIC' ? 'white' : 'accent.500'}  textAlign="center" border="1px solid" borderColor="accent.500"  onClick={() => handleInputChange('type', 'PUBLIC')}>PUBLIC</Box>
                            <Box cursor="pointer"  py="3" flex="1" rounded="lg" bg={state?.type === 'PRIVATE' ? 'accent.500' : 'white'} color={state?.type === 'PRIVATE' ? 'white' : 'accent.500'}  textAlign="center" border="1px solid" borderColor="accent.500"   onClick={() => handleInputChange('type', 'PRIVATE')}>PRIVATE</Box>                            
                        </HStack>
                    </Box>
                    <Box my={3}>
                        <Heading size="sm">No. of Students (Max. {state?.type === 'PUBLIC' ? 100 : 50} for now)</Heading>                
                        <Box p={5} borderWidth="1px" borderColor="gray.200" rounded="md">
                            <Text fontSize="24px" fontWeight="bold" mb="4" textAlign="center">{state?.total_students}</Text>
                            <Slider aria-label='slider-ex-1' value={state?.total_students || 10} onChange={(val) => handleInputChange('total_students', val)} max={state?.type === 'PUBLIC' ? 100 : 50} min={5} colorScheme="accent" size="lg">
                                <SliderTrack h="10px" rounded="full">
                                    <SliderFilledTrack />
                                </SliderTrack>
                                <SliderThumb bg="brand.500" w="24px" h="24px" />
                            </Slider>                        
                        </Box>  
                    </Box>  
                    <Box my={3}>
                        <Input 
                            error={errors?.title}
                            variant="filled"
                            label="Title"
                            value={state?.title || ""}
                            onChange={(e) => handleInputChange('title', e.target.value)}
                        />
                    </Box>
                    <Box my={3}>
                        <Input 
                            textarea
                            error={errors?.description}
                            variant="filled"
                            label="Description"
                            value={state?.description || ""}
                            onChange={(e) => handleInputChange('description', e.target.value)}
                        />
                    </Box>

                    <HStack my={3}>
                        <Box flex={1}>
                            <Select
                                error={errors?.grade_id}
                                label="Class"
                                required
                                variant="filled"
                                value={state?.grade_id || ''}
                                onChange={(e) => handleInputChange('grade_id', e.target.value)}
                                >
                                <option value="">Select Class</option>
                                {with_subjects?.map((grade, g) =>
                                    <option key={g} value={grade?.id}>{grade?.title} {grade?.label || ''}</option>
                                )}
                            </Select>
                        </Box>
                        <Box flex={1}>
                            <Select
                                error={errors?.subject}
                                label="Subject"
                                required
                                variant="filled"
                                value={state?.subject || ''}
                                onChange={(e) => handleInputChange('subject', e.target.value)}
                                >
                                <option value="">Select Subject</option>
                                {subjects?.map((subject, s) =>
                                    <option key={s} value={subject?.title}>{subject?.title}</option>
                                )}
                            </Select>
                        </Box>
                    </HStack>

                    <Box mt={10}>
                        <Button isFullWidth type="submit">Create Virtual Class</Button>
                    </Box>
                </form>

            </Box>
        </Modal>
    )
}
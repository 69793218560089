import { combineReducers } from 'redux'
import { authReducer } from './auth.reducer';
import { gradeReducer } from './grades.reducer';
import { paperReducer } from './paper.reducer';
import { parentReducer } from './parent.reducer';
import { subjectReducer } from './subjects.reducer';
import { vClassReducer } from './vclass.reducer';
import { virtualClassReducer } from './virtualclass.reducer'

export default combineReducers({
    auth: authReducer,    
    grades: gradeReducer,
    subjects: subjectReducer,
    virtual: virtualClassReducer,
    vclass: vClassReducer,
    parent: parentReducer,
    papers: paperReducer
});
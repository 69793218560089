import React, { useState, useEffect} from 'react'
import { Box, HStack, Avatar, Heading, Menu, Button, MenuButton,MenuList, MenuItem } from '@chakra-ui/react'
import { Input, Popover, Icon } from '../../../uikit'
import { Link, useNavigate } from 'react-router-dom'
import { authAction } from '../../../redux/actions'
import { useApp } from '../../../hooks'
import { useDispatch } from 'react-redux'
import Cookies from 'js-cookie'

export const Header = () => {
    
    const navigate = useNavigate()
    const {user, dashHeading} = useApp()
    const dispatch = useDispatch()
    const [ query, setQuery ] = useState('') 
    
    
    const handleLogout = () => {
        localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN);
        Cookies.remove('insaf-auth-token', { domain: process.env.REACT_APP_BASE_DOMAIN })
        dispatch(authAction.clear())
        window.location.href="/"

    }

    const handleSearch = (e) => {
        e.preventDefault();
        if(query?.length <= 2){
            alert("Please enter at least 3 character keyword")
        }else{
            navigate('/account/search/'+query)
        }

    }

    return (
        <Box zIndex="98"  bg="white" position="fixed" pl={{ base: "60px", md:"300px"}} left="0" top="0" w="100%">
            <Box px="2em" py="15px">
                <HStack spacing="7" justifyContent="space-between">
                    <HStack flex={3} justifyContent="space-between" alignItems="center">
                        <Box flex={1}>
                            <Heading variant="dashboard">{dashHeading || "Dashboard"}</Heading>
                        </Box>
                        <Box flex={1} display={{ base: "none" , md: "block" }}>
                            <form onSubmit={handleSearch}>
                                <Input
                                    searchInput
                                    value={query}
                                    onChange={(e) => setQuery(e.target.value)}
                                    placeholder="Enter search keywords and press enter..." 
                                    // rightElement={<Icon name="search-solid" color="brand.500" />}
                                    rounded="lg"
                                />
                            </form>
                        </Box>
                    </HStack>
                    <Box flex={1}>
                        <HStack justifyContent="flex-end">
                            {/* <IconButton size="sm" rounded="full" icon={<Icon fontSize="18px" color="currentcolor" name="bell" />} variant="ghost" /> */}
                            <Box>
                                <Menu placement="bottom-end">                                
                                    <MenuButton as={Avatar} size='sm' name={user?.first_name + ' '+user?.last_name} src={user?.avatar ? process.env.REACT_APP_STORAGE_URL+user.avatar.url :  "/assets/avatar.svg"} />
                                    <MenuList>
                                        <MenuItem as={Link} to="/account/settings">My Profile</MenuItem>
                                        <MenuItem onClick={handleLogout}>Logout</MenuItem>                                        
                                    </MenuList>
                                </Menu>
                            </Box>

                        </HStack>
                    </Box>
                </HStack>
            </Box>
        </Box>
    )
}
import React from 'react'
import {Route, Routes as Switch } from 'react-router-dom'
import { Box, HStack } from '@chakra-ui/react'
import { Header, Sidebar } from './Components'
import { useApp } from '../../hooks'
import Dashboard from '../../pages/Teacher/Dashboard'
import Notifications from '../../pages/Teacher/Notifications'
import VirtualClasses from '../../pages/Teacher/Classes'
import Reports from '../../pages/Teacher/Reports'
import Settings from '../../pages/Teacher/Settings'
import ClassDetails from '../../pages/Teacher/Classes/ClassDetails'
import ClassStudents from '../../pages/Teacher/Classes/ClassStudents'
import StudentProgress from '../../pages/Teacher/Classes/StudentProgress'
import QuizResults from '../../pages/Teacher/Classes/QuizResults'
import BrowseSubjects from '../../pages/Teacher/BrowseSubjects'
import SubjectDetails from '../../pages/Teacher/SubjectDetails'
import Topic from '../../pages/Teacher/Topic'

export default function DashboardLayout() {
    
    
    const {profile, user} = useApp()
    
    if(!profile || window.is_empty(user)){
        window.location.href="/"
    }

    if(user?.group === 'STUDENT'){
        window.location.href="/account"

    }
    else if(user?.group === 'PARENT'){
        window.location.href="/parent"
    }
    else{        
        return (
            <>
                <HStack bg="white" h="100vh" overflowY="hidden" spacing="0" alignItems="flex-start">
                    <Sidebar />
                    <Box flex="1" h="100%" overflowY="auto" bg="#E8E6E6" pt="80px">                     
                        <Header />
                        <Switch>
                            <Route exact path="/" element={<Dashboard />} />                            
                            <Route exact path="/notifications" element={<Notifications />} />                            
                            <Route exact path="/virtual-classes" element={<VirtualClasses />} />
                            <Route exact path="/virtual-classes/:class_id" element={<ClassDetails />} />
                            <Route exact path="/virtual-classes/students/:class_id" element={<ClassStudents />} />
                            <Route exact path="/virtual-classes/student-progress/:student_id" element={<StudentProgress />} />
                            <Route exact path="/virtual-classes/results/:quiz_id" element={<QuizResults />} />
                            <Route exact path="/subjects" element={<BrowseSubjects />} />
                            <Route exact path="/subjects/:subject_id" element={<SubjectDetails />} />
                            <Route exact path="/subjects/topic/:topic_id" element={<Topic />} />
                            <Route exact path="/reports" element={<Reports />} />
                            <Route exact path="/settings" element={<Settings />} />                            
                            
                        </Switch>
                    </Box>
                </HStack>
            </>

        )
    }
}


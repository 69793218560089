import React, { useEffect, useState } from "react";
import { Box, Heading, HStack, useBoolean, Text, Button } from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'
import { Container } from "../../uikit";
import { SubjectCard } from "./Components";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { gradeAction } from "../../redux/actions";
import { usePrevious } from "../../hooks";
import { Link } from "react-router-dom";

export default function ClassView() {
    const { slug } = useParams();
    const [loading, setLoading] = useBoolean(false);    
    const prevSlug = usePrevious()
    const dispatch = useDispatch();
    const [grade, setGrade] = useState(null)
    const { with_subjects } = useSelector(state => state?.grades)

    
    useEffect(() => {
        setLoading.on()
        dispatch(gradeAction.with_subjects())
            .then((res) => setLoading.off())
            .catch((err) => setLoading.off())
    }, [])

    useEffect(() => {
                
        if(slug && with_subjects && prevSlug !== slug){
            let myGrade = with_subjects?.find(x => x?.id === parseInt(slug))
            setGrade(myGrade)
        }
    }, [slug, prevSlug, with_subjects])

    
    return (
        <>
            <Helmet>
                <title>Class {`${grade?.title}`} {`${grade?.label}`} - PCTB Online Academy</title>
                <meta name="description" content="PCTB Online Academy is FREE online learning platform for HSSC and SSC Student covering of Subjects, Assessment and other learning material to help our young genration for tapping in to the future." />
            </Helmet>
            <Box  pt="100px" bgBlendMode="overlay" bgImage="/assets/waves-bg.svg" bgRepeat="no-repeat" bgSize="cover" bgPosition="center center">
                <Box py="3em">
                    <Container>
                        <HStack flexDir={{ base: 'column', lg: 'row' }} alignItems={{base: 'flex-start', lg: "flex-end"}}>
                            <Box flex="1">
                                <Text variant="heading-title">Class {grade?.title} {grade?.label}</Text>
                                <Heading variant="insaf-heading" color="white">Content available for Science Subjects initially </Heading>
                                <Text variant="loud" color="whiteAlpha.800">Curriculum based on textbooks by PCTB as well as additional knowledge incorporated from world’s best syllabus</Text>
                            </Box>
                            <Box w="40%" textAlign={{base: 'left', lg: "right"}}>
                                <Button as={Link} to="/register" size="lg" mt={10} colorScheme="accent">Start Learning Today</Button>
                            </Box>
                        </HStack>
                    </Container>
                </Box>
            </Box>
            <Box py="4em">
                <Container>
                    <HStack my="5" spacing="0" alignItems="flex-top" flexWrap="wrap" mx="-15px">
                        {grade?.subjects?.map((subject, s) => (

                            <SubjectCard grade_slug={grade?.url_title} w={{ base: '100%', md: '50%', lg: '33.33%' }} subject={subject} key={s} />

                        ))}
                    </HStack>
                </Container>
            </Box>
        </>
    )
}
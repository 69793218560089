export const USER_LOGOUT = 'USER_LOGOUT'

export const AuthConstants = {
    LOGIN: 'AUTH_LOGIN',
    LOGOUT: 'AUTH_LOGOUT',
    FAILED: 'AUTH_FAILED',
    AUTH: 'CHECK_USER_AUTH',
    UPDATE_USER: 'USER_UPDATE',
    PROFILE: 'GET_USER_PROFILE',
    UPDATE: 'UPDATE_PROFILE',
    REFRESH_TOKEN: 'REFRESH_TOKEN',
    REFRESH_TOKEN_FAILED: 'REFRESH_TOKEN_FAILED',
    MESSAGES: 'GET_USER_MESSAGES',
    UPDATE_MESSAGE: 'UPDATE_USER_MESSAGES',
    CLEAR: 'CLEAR_AUTH_DATA'
}

export const GradeConstants = {
    GET: 'GET_GRADES',
    DETAILS: 'GET_CLASS_DETAILS',    
    FAILED: 'GRADE_FAILED',    
    CLEAR: 'CLEAR_GRADE',
    WITHSUBJECTS: 'GRADES_WITHSUBJECTS',
    WITHSUBJECTS2: 'GRADES_WITHSUBJECTS2',
    WITHSUBJECTSMINIMAL: 'GRADES_WITHSUBJECTS_MINIMAL'
}



export const ClassesConstants = {
    GET: 'GET_CLASSES',
    FAILED: 'CLASSES_FAILED',
}
export const SubjectConstants = {
    GET: 'GET_SUBJECTS',
    DETAILS: 'GET_SUBJECT_DETAILS',
    FAILED: 'SUBJECT_FAILED',
    CLEAR: 'CLEAR_SUBJECT',
    TOPIC: 'GET_TOPIC',
    QUIZ: 'GET_QUIZ',
    CLEAR_TOPIC: 'CLEAR_TOPIC',
    BADGES: 'GET_ALL_BADGES',
    RESULTS: 'GET_SUBJECT_RESULTS',
}

export const ProgressConstants = {
    POST_CHAPTER: 'POST_CHAPTER_PROGRESS',
    FAILED: 'PROGRESS_FAILED',
}

export const AssessmentConstants = {
    GET_ASSESSMENT: 'GET_ASSESSMENT',
    GET_DURATION: 'GET_DURATION',
    FAILED: 'ASSESSMENT_FAILED',
}

export const BadgesConstants = {
    ADD: "ADD_BADGE",
    GET: "GET_BADGE",
    FAILED: 'BADGE_FAILED'
}

export const VirtualClassConstants = {
    GET: "GET_TEACHER_VIRTUAL_CLASSES",
    ADD: "ADD_TEACHER_VIRTUAL_CLASSES",
    DETAILS: 'GET_TEACHER_VIRTUAL_CLASS_DETAILS',
    FAILED: 'FAILED_TEACHER_VIRTUAL_CLASSES',
    REMOVE: 'REMOVE_TEACHER_VIRTUAL_CLASS'
}

export const VClassConstants = {
    GET: "GET_STUDENT_VIRTUAL_CLASSES",
    ADD: "ADD_STUDENT_VIRTUAL_CLASSES",
    DETAILS: 'GET_STUDENT_VIRTUAL_CLASS_DETAILS',
    FAILED: 'FAILED_STUDENT_VIRTUAL_CLASSES',
    QUIZ: 'GET_STUDENT_VIRTUAL_QUIZ',
    LIVE: 'GET_LIVE_CLASSES'
}

export const ParentConstants = {
    GET: "GET_PARENT_DATA",
    ADD: "ADD_CHILD",
    FAILED: 'FAILED_CHILD',    
    PROGRESS: 'GET_CHILD_PROGRESS'
}


export const PaperConstants = {
    GET: "GET_PAPERS",
    DETAILS: 'GET_PAPER_DETAILS',
    SUBJECT: 'GET_PAPER_SUBJECT',
    LECTURE: 'GET_PAPER_LECTURE',
    FAILED: 'FAILED_PAPERS',
    PROGRESS: 'GET_PAPER_RESULT'
}
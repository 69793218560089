import React, { useEffect, useState} from 'react'
import { Box, useBoolean, Spinner, HStack, Heading, Image, Stack, Text, Progress, Button } from '@chakra-ui/react'
import { useParams, useLocation } from 'react-router-dom'
import { vClassAction } from '../../../redux/actions'
import { vClassApi } from '../../../api'
import { useDispatch, useSelector } from 'react-redux'
import { useApp, usePrevious } from '../../../hooks'
import useCountDown from 'react-countdown-hook';
import dayjs from "dayjs"
var duration = require('dayjs/plugin/duration')
dayjs.extend(duration)

export default function Assessment(){

    const { quiz_id } = useParams() 
    const dispatch = useDispatch()
    const location = useLocation()
    const prev_quiz = usePrevious(quiz_id)   
    const [qIndex, setqIndex] = useState(0) 
    const [ submitting, setSubmitting ] = useBoolean(false)
    const [ myResult, setMyResult ] = useState(null)

    const [ userAnswers, setUserAnswwers] = useState([])
    const { setDashHeading} = useApp()
    const [ loading, setLoading ] = useBoolean(false)
    const [step, setStep ] = useState(0)
    const { quiz } = useSelector(state => state?.vclass)
    const [timeLeft, { start, pause, resume, reset }] = useCountDown(0);

    useEffect(() => {        
        if(quiz_id && quiz_id !== prev_quiz){
            setLoading.on()
            setStep(0)            
            dispatch(vClassAction.quiz(quiz_id))
            .then((res) =>{                
                setLoading.off()
            })
            .catch((err) =>{
                setLoading.off()
            })  
        }  
    }, [quiz_id])

    useEffect(() => {
        setDashHeading('Assessment')
      return () => {
        setDashHeading('Dashboard')
      };
    }, [])

    const startQuiz = () => {
        setStep(1);
        start(parseInt(quiz?.settings?.duration) * 60 * 1000);

    }

    

    const handleAnswerChange = (qid, aid) => {        

        let all_answers = [...userAnswers]
        let index = all_answers.findIndex(x => x?.qid === qid)
        if(index > -1){
            all_answers[index] = {qid: qid, aid: aid} 
        }else{
            all_answers.push({qid: qid, aid: aid})
        }
        setUserAnswwers(all_answers)
    }

    const getValue = (qid) => {
        let all_answers = [...userAnswers]
        let quest = all_answers.find(x => x?.qid === qid)
        if(quest){
            return quest?.aid
        }
        return null
    }


    const handleSubmit = (e = null) => {
        if(e){
            e.preventDefault()
        }
        setSubmitting.on()
        let details = [];

        quiz?.questions?.map((qu) => {
            let my_answer = userAnswers?.find((x) => x?.qid === qu?.id);
            return details.push({
                        question: qu?.question,
                        options: qu?.answers,
                        answer: my_answer?.aid,
                        correct_answer: qu?.correct,
                        correct: my_answer?.aid === qu?.correct
                    })
        })

        let form_data = {
            virtual_quiz_id: quiz?.id,
            details: details,            
        }
        vClassApi._submit_quiz(form_data)
            .then((res) => {
                setMyResult(res?.result)
                setStep(3)
                setSubmitting.off()
            })
            .catch((err) => {
                console.log('Error', err)
                setSubmitting.off()
            })
    }


    console.log("Quiz", quiz)

    return(
        <Box>
            {
                loading ?
                <Box d="flex" h="100%" alignItems="center" justifyContent="center">
                    <Spinner />
                </Box>
                :
                
                <Box  px="2em" py="5">
                    {
                        step === 0 &&
                        <Stack direction={{ base: "column", md: "row" }} alignItems="flex-start" spacing={{base: "7", md: "7"}}>
                            <Box flex={2.5}>
                                <Heading size="lg" fontWeight="800" fontFamily="Poppins" mb="3">{location?.state || 'Quiz'}</Heading>
                                <Text color='gray.400'>Test your knowledge</Text>
                                
                                <Box my="5">
                                    <Box  bg="white" rounded="lg" shadow="md">
                                        <Stack direction={{ base: "column", md: "row" }} py="10" px="10" spacing={{base: "10px", md: 0}} mx="-15px" alignItems={{base: "normal", md:"center"}} justifyContent={{ base: "normal", md: "space-between"}}>
                                            <Box w={{base: "100%", md: "20%"}} px="15px" textAlign="center">
                                                <Heading size="xs">Duration</Heading>
                                                <Text>{quiz?.settings?.duration} min</Text>
                                            </Box>
                                            <Box w={{base: "100%", md: "20%"}} px="15px" textAlign="center">
                                                <Heading size="xs">Number of Questions</Heading>
                                                <Text>{quiz?.questions?.length || 0}</Text>
                                            </Box>

                                            <Box w={{base: "100%", md: "20%"}} px="15px" textAlign="center">
                                                <Heading size="xs">Passing Marks</Heading>
                                                <Text>{quiz?.settings?.passing || 50}%</Text>
                                            </Box>

                                            <Box  w={{base: "100%", md: "20%"}} my={{base: "10px", md: 0  }} px="15px" textAlign={{base: "center", md: "right"}}>
                                                <Heading size="sm"></Heading>
                                                <Button onClick={startQuiz} colorScheme="accent" size="sm" >{location?.state === 'Assessment' ? 'Start Mock Exam' : 'Start Quiz'}</Button>
                                            </Box>

                                        </Stack>
                                        <Box borderTop="1px solid" py="5" px="10" borderColor="gray.200">
                                            <Heading mb="3" fontSize="24px" color="accent.500" fontWeight="bold">GOOD LUCK!</Heading>
                                            <Text fontSize="sm">It is mandatory to answer all questions, you can not skip a question and move to the next one, at the end of the assessment you will be able to see result. Do not forget, its just a practice quiz!</Text>
                                        </Box>
                                    </Box>
                                </Box>                                    
                            </Box>                            
                            
                        </Stack>
                    }
                    {
                        step === 1 &&
                        <Box my="5">
                            <Heading size="lg" fontWeight="800" fontFamily="Poppins" mb="3">{location?.state || 'Quiz'}</Heading>
                            <Text>{quiz?.description}</Text>
                            <Box bg="white" w={{base: "100%", md: "70%"}} mx="auto" rounded="lg" my={"5"}>
                                <Box d="flex" p="5"  justifyContent={"space-between"} alignItems={"center"} roundedTop="lg" bg="brand.500">
                                    <Box w={{base: "50%", md:"40%"}}>                                            
                                        <Progress  rounded="full" colorScheme='accent'  size='md' value={parseInt((parseInt(qIndex+1)/parseInt(quiz?.questions?.length) * 100)) } />
                                        <HStack mt="2" justifyContent="space-between">
                                            <Heading size="sm" color="white">Quiz Progress</Heading>
                                            <Heading size="sm" color="white">{parseInt(qIndex + 1) }/ {quiz?.questions?.length}</Heading>
                                        </HStack>
                                        {/* <Text color="white">
                                            Please answer the question and click next
                                        </Text> */}
                                    </Box>
                                    <HStack alignSelf="flex-end" alignItems="flex-start" justifyContent="flex-end">
                                        <Box bg="accent.500" px="3" py="2" rounded="md">
                                            <Text fontWeight="bold" fontSize="sm" color="white">{dayjs.duration(timeLeft).format('HH:mm:ss')}</Text>
                                        </Box>
                                    </HStack>                                            
                                </Box>
                                {
                                    quiz?.questions?.map((question, q) => {
                                        if(q === qIndex){
                                            return(
                                                <Box key={q} p="5">
                                                    <Heading size="sm">Question No. {parseInt(q) + 1}</Heading>
                                                    <Box my="4" fontWeight="bold">{question?.question}</Box>
                                                    {/* <RadioGroup value={getValue(question?.id)} onChange={(e) => handleAnswerChange(question?.id, e)}> */}
                                                    {
                                                        question?.answers?.map((answer, a) => 
                                                            <HStack cursor="pointer" key={a} my="2" alignItems="flex-start" onClick={() => handleAnswerChange(question?.id, answer?.id)}>
                                                                {/* <Radio value={answer?.id}>{answer?.answer}</Radio> */}
                                                                {
                                                                    getValue(question?.id) !== answer?.id ?
                                                                        // <Icon mt="1" name="circle" />
                                                                        <Box w="13px" mt="6px" h="13px" rounded="full" border="1px solid" borderColor="gray.400" />
                                                                    :
                                                                        <Box w="13px" mt="6px" h="13px" rounded="full" bg="green.500" />
                                                                }
                                                                
                                                                <Box>{answer?.answer}</Box>
                                                            </HStack>
                                                        )
                                                    }
                                                    {/* </RadioGroup> */}

                                                    <HStack mt="10" justifyContent="space-between">
                                                        <Button rounded="lg" onClick={() => setqIndex(qIndex-1)} isDisabled={qIndex === 0} variant="outline">Previous</Button>
                                                        {
                                                            (qIndex < quiz?.questions?.length - 1) ?
                                                                <Button rounded="lg" isDisabled={getValue(question?.id) === null} onClick={() => setqIndex(qIndex+1)}>Next</Button>
                                                            :
                                                            <Button rounded="lg" isDisabled={getValue(question?.id) === null} onClick={() => setStep(2)}>Review</Button>
                                                        }
                                                        
                                                    </HStack>
                                                </Box>
                                            )
                                        }
                                    })
                                }
                            </Box>

                            
                        </Box>
                    }
                    {
                        step === 2 &&
                        <Box my="5">
                            <HStack justifyContent="space-between" alignItems="flex-start">
                                <Box>
                                    <Heading size="lg" fontWeight="800" fontFamily="Poppins" mb="3">{location?.state || 'Quiz'}</Heading>
                                    <Text>{quiz?.description}</Text>
                                </Box>
                                <Button onClick={handleSubmit}>Submit Now</Button>
                            </HStack>
                            <Box bg="white" w="100%" mx="auto" rounded="lg" my={"5"}>
                                <Box d="flex" p="5"  justifyContent={"space-between"} alignItems={"center"} roundedTop="lg" bg="brand.500">
                                    <Box w="50%">
                                        <Heading size="sm" color="white" mb="1">Quiz Details</Heading>
                                        <Text color="white">
                                            Please review your answers before submitting
                                        </Text>
                                    </Box>
                                    <HStack alignSelf="flex-end" alignItems="flex-start" justifyContent="flex-end">
                                        <Box bg="accent.500" px="3" py="2" rounded="md">
                                            <Text fontWeight="bold" fontSize="sm" color="white">{dayjs.duration(timeLeft).format('HH:mm:ss')}</Text>
                                        </Box>
                                    </HStack>                                            
                                </Box>
                                {
                                    quiz?.questions?.map((question, q) => 
                                        
                                        <Box key={q} p="5">
                                            <Heading size="sm">Question No. {parseInt(q) + 1}</Heading>
                                            <Box my="4" fontWeight="bold">{question?.question}</Box>
                                            {/* <RadioGroup value={getValue(question?.id)} onChange={(e) => handleAnswerChange(question?.id, e)}> */}
                                            {
                                                question?.answers?.map((answer, a) => 
                                                    <HStack cursor="pointer" key={a} my="2" alignItems="flex-start" onClick={() => handleAnswerChange(question?.id, answer?.id)}>
                                                        {/* <Radio value={answer?.id}>{answer?.answer}</Radio> */}
                                                        {
                                                            getValue(question?.id) !== answer?.id ?
                                                                // <Icon mt="1" name="circle" />
                                                                <Box w="13px" mt="6px" h="13px" rounded="full" border="1px solid" borderColor="gray.400" />
                                                            :
                                                                <Box w="13px" mt="6px" h="13px" rounded="full" bg="green.500" />
                                                        }
                                                        
                                                        <Box>{answer?.answer}</Box>
                                                    </HStack>
                                                )
                                            }
                                            {/* </RadioGroup>*/}
                                        </Box>
                                            
                                        
                                    )
                                }
                            </Box>
                        </Box>
                    }
                    {
                        step === 3 &&
                        <Box my="5">
                            <HStack justifyContent="space-between" alignItems="flex-start">
                                <Box>
                                    <Heading size="lg" fontWeight="800" fontFamily="Poppins" mb="3">{location?.state || 'Quiz'}</Heading>
                                    <Text>{quiz?.description}</Text>
                                </Box>                                    
                            </HStack>
                            <Box>
                                <Box bg="white" mt="20" rounded="lg" shadow="lg" w={{base: "100%", md: "70%"}} mx="auto">
                                    <Box p={{base: 5, md:"20"}}>
                                        {
                                            (parseFloat(myResult?.result?.percentage) < parseFloat(quiz?.settings?.passing)) ?
                                            <Image maxW="350px" h="auto" src="/assets/retake-quiz.png" alt="congrats" mx="auto" />
                                            :
                                            <Image maxW="350px" h="auto" src="/assets/congrats.png" alt="congrats" mx="auto" />
                                        }
                                        
                                        <Box my="10" textAlign="center">
                                            <Heading mb="2" fontWeight="800" color="accent.500" size="lg"> { (parseFloat(myResult?.result?.percentage) < parseFloat(quiz?.settings?.passing)) ?  'Please Try Again' :'Congratulations !'}</Heading>
                                            <Text>{ (parseFloat(myResult?.result?.percentage) < parseFloat(quiz?.settings?.passing)) ? 'Please try again': 'Amazing skills, you nailed it!'}</Text>
                                        </Box>

                                        <Box rounded="lg" my="10" p="10" w="80%" mx="auto" bg="accent.50" border="2px solid" borderColor="accent.500">
                                            <Stack direction={{ base: "column", md: "row" }} alignItems="center" justifyContent="space-between">
                                                <Box textAlign="center">
                                                    <Heading mb="2" size="sm">Total Questions</Heading>
                                                    <Text>{myResult?.result?.total_questions}</Text>
                                                </Box>
                                                <Box textAlign="center">
                                                    <Heading mb="2" size="sm">Correct Answers</Heading>
                                                    <Text>{myResult?.result?.correct}</Text>
                                                </Box>
                                                <Box textAlign="center">
                                                    <Heading mb="2" size="sm">Score</Heading>
                                                    <Text>{parseFloat(myResult?.result?.percentage).toFixed(2)}%</Text>
                                                </Box>
                                            </Stack>
                                        </Box>

                                        <HStack my="4" justifyContent="center" spacing="5">
                                            <Button onClick={() => setStep(4)} rounded="lg" variant="outline" colorScheme="accent">View Details</Button>                                            
                                        </HStack>
                                    </Box>                            
                                </Box>
                            </Box>
                        </Box>
                    }
                    {
                        step === 4 &&
                        <Box my="5">
                            <HStack justifyContent="space-between" alignItems="flex-start">
                                <Box>
                                    <Heading size="lg" fontWeight="800" fontFamily="Poppins" mb="3">{location?.state || 'Quiz'}</Heading>
                                    <Text>{quiz?.description}</Text>
                                </Box>   
                                <Button variant="outline" onClick={() => setStep(3)}>Back</Button>                                 
                            </HStack>
                            <Box bg="white" rounded="lg">
                            {
                                myResult?.details?.map((requ, b) =>                                         
                                    <Box key={b} p="5" borderBottom="1px solid" borderColor="gray.200">
                                        <Heading size="sm">Question No. {parseInt(b) + 1}</Heading>
                                        <Box my="4" fontWeight="bold">{requ?.question}</Box>
                                        <Box>
                                        {
                                            requ?.options?.map((anu, n) => {
                                                if(requ?.correct_answer === anu?.id ){
                                                    return(
                                                        <HStack p="2" rounded="md" key={n} my="2" bg='green.100'>
                                                            <Text fontWeight="bold">{parseInt(n+1)}:</Text>
                                                            <Text>{anu?.answer}</Text>
                                                        </HStack>
                                                    )
                                                }else if(requ?.answer === anu?.id && !requ?.correct ){
                                                    return(
                                                        <HStack p="2" rounded="md" key={n} my="2" bg='red.100'>
                                                            <Text fontWeight="bold">{parseInt(n+1)}:</Text>
                                                            <Text>{anu?.answer}</Text>
                                                        </HStack>
                                                    )
                                                }else{
                                                    return(
                                                        <HStack p="2" rounded="md" key={n} my="2" bg='transparent'>
                                                            <Text fontWeight="bold">{parseInt(n+1)}:</Text>
                                                            <Text>{anu?.answer}</Text>
                                                        </HStack>
                                                    )
                                                }
                                                
                                            })
                                        }
                                        </Box>                                                
                                    </Box>
                                                                                
                                )
                            }
                            </Box>

                        </Box>
                    }
                </Box>
            }
        </Box>
    )
}
import React from 'react'
import { 
    Modal as ChakraModal, ModalBody, ModalContent, 
    ModalOverlay, ModalCloseButton, ModalHeader, 
    ModalFooter 
} from '@chakra-ui/react'


const Modal = (props) => {

    const modalRef= React.createRef()
    let {open, bg,  size, onClose, transition, footer, center, title, scrollType} = props
    return(
        <ChakraModal 
            isOpen={open || false} 
            size={size || "xl"}  
            onClose={onClose} 
            motionPreset={transition || "slideInBottom"}
            scrollBehavior={scrollType || 'inside'}
            initialFocusRef={modalRef}
            isCentered={center || false}
            p={0}
            >
            <ModalOverlay />
            <ModalContent p={0}>
                {
                    title && <ModalHeader py={2} borderBottom="1px solid" borderColor="gray.200" fontSize="lg">{title}</ModalHeader>
                }
                
                <ModalCloseButton _focus={{ outline: "none" }} />
                <ModalBody  bg={bg || "white"} borderBottomRadius="md" p={0}>
                    {props.children}
                </ModalBody>
                {
                    footer &&
                    <ModalFooter>
                        {footer}
                    </ModalFooter>
                }
                
                <div ref={modalRef}/>
            </ModalContent>    
        </ChakraModal>
    )
}

export {Modal}
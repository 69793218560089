import React, { useEffect, useState} from "react"
import { Box, useBoolean, Spinner, Heading, Button, useToast, HStack, Image, Text, Avatar, Progress, AspectRatio } from "@chakra-ui/react"
import { useParams, useNavigate } from "react-router-dom"
import { Helmet } from "react-helmet-async"
import { useDispatch, useSelector } from "react-redux"
import { paperAction } from "../../../redux/actions"
import { usePrevious, useApp } from "../../../hooks"
import { authApi } from "../../../api"
import ReactPlayer from 'react-player'
import { Link } from "react-router-dom"
import { Input } from "../../../uikit"
import dayjs from 'dayjs'

var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

export default function Lecture({type}){

    const { topic_id } = useParams()
    const navigate = useNavigate()
    const toast = useToast()
    const { setDashHeading, profile } = useApp()
    const [ loading, setLoading ] = useBoolean(false)
    const [ commenting, setCommenting ] = useState(null)
    const [ downloadLink, setDownloadLink ] = useState(null)
    const prev_topic = usePrevious(topic_id)
    const [comments, setComments] = useState('')
    const [ reply, setReply ] = useState('')
    const [ replyForm, setReplyForm ] = useState(null)
    const [ tab, setTab] = useState(0)
    const dispatch = useDispatch()    
    const { lecture } = useSelector(state => state?.papers)

    useEffect(() => {
        if(topic_id && topic_id !== prev_topic){
            setLoading.on()
            dispatch(paperAction.lecture(topic_id))
            .then((res) =>{
                setLoading.off()
            })
            .catch((err) =>{
                setLoading.off()
            })  
        }  
    }, [topic_id])


    


    useEffect(() => {
        setDashHeading(lecture?.topic?.title || 'Topic')
      return () => {
        setDashHeading('Dashboard')
      };
    }, [])

    
   
    const handleSubmit = (e) => {
        e.preventDefault()
        setCommenting('q')
        if(comments?.length < 1 || false){
            window.alert("Please enter comments", "Discussion")
        }else{
            dispatch(paperAction.topic_comment({comments: comments, cat_content_id: topic_id}))
                .then((res) => {
                    setCommenting(null)
                    setComments('')
                    // toast({
                    //     title: 'Disccussion',
                    //     description: 'Comments will be published on admin approved.',
                    //     status: "success",
                    //     position: "top"
                    // })
                })
                .catch((err) => setCommenting(null))
            
        }
    }

    const handleReply = (id) => {
        setCommenting(id)
        if(reply?.length < 1 || false){
            window.alert("Please enter comments", "Discussion")
        }else{
            dispatch(paperAction.topic_comment({comments: reply, cat_content_id: topic_id, parent_id: id}))
                .then((res) => {
                    setCommenting(null)
                    setReply('')
                    toast({
                        title: 'Disccussion',
                        description: res?.message,
                        status: "success",
                        position: "top"
                    })
                })
                .catch((err) => setCommenting(null))
            
        }
    }

    useEffect(() => {
        if(lecture?.data){
            authApi._get_download_link(lecture?.data)
                .then((res) => {                    
                    setDownloadLink(res?.link_url || null)
                }).catch((error) => {
                    console.log("Error", error)
                })
        }
    }, [lecture?.data])

    
    return(
        <>
            <Helmet>
                <title>Topic - PCTB Online Academy</title>
                <meta name="description" content="PCTB Online Academy is FREE online learning platform for HSSC and SSC Student covering of Subjects, Assessment and other learning material to help our young genration for tapping in to the future." />
            </Helmet>
            {
                loading ?
                    <Box d="flex" h="100%" alignItems="center" justifyContent="center">
                        <Spinner />
                    </Box>
                :
                <Box px={{base: "1em", md:"2em"}} py="5">                    
                    <HStack flexDirection={{ base: "column", md: "row" }} spacing={{base: "10px", md: "40px" }} justifyContent="space-between" alignItems="flex-start">
                        <Box flex={2.5}>
                            <Heading mb="4" fontFamily="Poppins" fontSize="32px">{`${lecture?.title}`}</Heading>
                            <Box my="3">
                                <Button variant="link" onClick={() => navigate(-1)} colorScheme="gray">Back</Button>
                            </Box>
                            <Box rounded="lg" overflow="hidden">
                                {
                                    lecture?.data &&
                                    <div className='player-wrapper'>
                                        <ReactPlayer
                                            className='react-player'
                                            url={'https://www.youtube.com/watch?v=' + lecture?.data}
                                            width='100%'
                                            height='100%'
                                            light={false}
                                            controls={true}
                                            autoPlay={true}
                                            playing={true}
                                            // onStart={() => { handlePlay(topic?.contents?._id, topic?.chapter?._id) }}

                                        />
                                    </div>
                                }
                            </Box>
                            {
                                downloadLink && 
                                <Button my={3} as="a"  href={downloadLink} target="_blank" size="sm" download>Download Video</Button>
                            }

                            {/* <HStack my="25" justifyContent="flex-start" alignItems="flex-start" spacing="0"> */}
                                {/* <Box as={Link} px="10" py="5" to="#" onClick={() => setTab(0)} borderBottom="4px solid" borderColor={tab === 0 ? "accent.500" : "gray.300"}>
                                    <Text fontWeight="bold">About</Text>
                                </Box> */}
                                {/* <Box as={Link} px="10" py="5" to="#" onClick={() => setTab(1)} borderBottom="4px solid" borderColor={tab === 1 ? "accent.500" : "gray.300"}>
                                    <Text>Discussion Board</Text>
                                </Box> */}
                            {/* </HStack> */}
                            <Box py="5">
                            {/* {
                                tab === 0 &&
                                <Box>
                                    <Heading fontFamily="Poppins" mb="3" size="md">About Topic</Heading>
                                    <Text>{topic?.details}</Text>
                                </Box>
                            } */}
                            {
                                tab === 0 &&
                                <Box>
                                    <Box my="8">
                                        <HStack justifyContent="flex-start">
                                            <Heading alignSelf="flex-start" mb="5" py="2" borderBottom="2px solid" borderColor="brand.400" size="sm">Discussion Board</Heading>
                                        </HStack>

                                        <HStack my={5} alignItems="flex-start" spacing="4">
                                            <Avatar name='Kent Dodds' display={{ base: "none", md: "block" }} visibility="hidden" src='https://bit.ly/kent-c-dodds' />
                                            <Box flex={1}  p="5" bg='white' rounded="lg" my={5}>
                                                <Input 
                                                    placeholder="Enter your comments..."
                                                    variant="filled"
                                                    textarea 
                                                    value={comments || ''}
                                                    onChange={(e) => setComments(e.target.value)}
                                                />
                                                <Button isLoading={commenting === 'q'} onClick={handleSubmit} mt={3} size="sm">Submit</Button>
                                            </Box>
                                        </HStack>

                                        {
                                            lecture?.comments?.map((comment, c) =>
                                            <HStack key={c} alignItems="flex-start" spacing="4" mb="3">
                                                <Avatar size="md" name={comment?.user?.first_name + " " + comment?.user?.last_name} src={comment?.user?.avatar ? process.env.REACT_APP_STORAGE_URL+comment?.user.avatar.url :  "/assets/avatar.svg"} />
                                                <Box flex={1} p="5" bg="gray.50" rounded="lg">
                                                    <Text fontSize="12px" color="gray.600">{dayjs(comment?.created_at).fromNow()}</Text>
                                                    <HStack mb="3" alignItems="center">
                                                        <Text  fontSize="16px" fontWeight="bold">{comment?.user?.first_name + " " + comment?.user?.last_name}</Text>                                                        
                                                    </HStack>
                                                    <Text fontSize="13px">{comment?.comments}</Text>
                                                    <Button onClick={() => setReplyForm(comment?.id)} mt={3} size="sm" variant="link">Reply</Button>
                                                    <Box>
                                                        {
                                                            replyForm === comment?.id &&
                                                            <Box bg="white" rounded="lg" p="4" border="1px solid" borderColor="gray.200">
                                                                <Input 
                                                                        placeholder="Enter your reply..."
                                                                        variant="filled"
                                                                        textarea 
                                                                        value={reply || ''}
                                                                        onChange={(e) => setReply(e.target.value)}
                                                                    />
                                                                    <Button isLoading={commenting === comment?.id} onClick={() => handleReply(comment?.id)} mt={3} size="sm">Submit</Button>
                                                            </Box>
                                                        }
                                                    </Box>
                                                    {
                                                        comment?.replies?.length > 0 &&                                        
                                                        <Box my={2}  rounded="lg" p={3}>
                                                            {
                                                                comment?.replies?.map((rep, r) =>
                                                                <Box p={5} bg="gray.100" my={2} key={r} rounded="lg">
                                                                    <Text fontSize="12px" color="gray.600">{dayjs(rep?.created_at).fromNow()}</Text>
                                                                    <HStack mb="3" alignItems="center">
                                                                        <Text fontWeight="700">{rep?.user?.first_name} {rep?.user?.last_name}</Text>                                                                        
                                                                    </HStack>
                                                                    
                                                                    <Text fontSize="13px">{rep?.comments}</Text>
                                                                </Box>
                                                                )
                                                            }
                                                        </Box>
                                                    }
                                                </Box>
                                            </HStack>
                                            )
                                        }                                                                                
                                    </Box>
                                </Box>
                            }
                            </Box>


                        </Box>
                            <Box flex={1} w="100%">
                                {
                                    lecture?.related?.length > 1 && 
                                    
                                    <Box  bg="white" mb="7" rounded="xl" p="4">
                                        <HStack justifyContent="space-between">
                                            <Heading size="md">Videos</Heading>
                                            <Heading size="md">1/{lecture?.related?.length}</Heading>
                                        </HStack>
                                        {
                                            (lecture?.related?.length > 0) && lecture?.related?.map((vid, v) =>
                                                (vid.type === "VIDEO") && 
                                                <HStack my="4" key={v} as={Link} to={"/account/"+lecture?.topic?.type.toLowerCase()+"/lecture/" + vid?.id} alignItems="flex-start"  bg="gray.50" rounded="sm" spacing="4" _hover={{ bg: 'gray.100' }}>
                                                    <Box flex="1" rounded="lg" overflow="hidden" position="relative">
                                                        <AspectRatio w="100%" ratio={16 / 9}>
                                                            <div className='player-wrapper'>
                                                                <ReactPlayer
                                                                    className='react-player'
                                                                    url={'https://www.youtube.com/watch?v=' + vid?.data}
                                                                    width='100%'
                                                                    light={true}
                                                                    height='100%'
                                                                    autoPlay={false}
                                                                />
                                                            </div>
                                                        </AspectRatio>
                                                        <Box  position="absolute" left="0" top="0" w="100%" h="100%" bgGradient='linear(rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 50%)'>
                                                            <HStack position="absolute" bottom="0" left="0" w="100%" px="20px" py="10px"  alignItems="center">
                                                                <Image src="/assets/btn-play-icon.png" alt="Play" />                                                    
                                                                <Text noOfLines={1} color="white" fontSize="14px">{vid?.title}</Text>                                                    
                                                            </HStack>
                                                        </Box>
                                                    </Box>
                                                    {/* <Box flex="1">
                                                        <Text>{vid?.title} {topic?.chapter?.topics?.title}</Text>
                                                        <Text mt="2" noOfLines={2} fontSize="12px" dangerouslySetInnerHTML={{ __html: topic?.contents?.description }} />
                                                    </Box> */}
                                                </HStack>


                                            )
                                        }
                                    </Box>
                                }
                            
                        </Box>
                    </HStack>
                </Box>
            }
        </>
    )
}
import React from "react";
import { Box, Image, HStack, Text } from '@chakra-ui/react'
import { Icon } from "../../../uikit";
import { Link, NavLink } from "react-router-dom";
import { useMatch } from "react-router-dom";

export const Sidebar = () => {

    const match = useMatch('/parent');

    
    return(
        <Box px="20px" w="300px" position="relative" zIndex="99"  bg="brand.500" py="20px"  h="100%" overflowY="auto" borderRight="0px solid" borderColor="gray.100">
            <Box h="0px" />
            <Box as={Link} to="/dashboard">
                <Image mx="20px" src="/assets/pctb-academy-logo-alt.svg" h="43px" w="auto" />
            </Box>
            <Box h="30px" />
            
            <HStack rounded="xl" my="15px" className={ match ? "account-nav active" : "account-nav"} as={Link} to="/parent" spacing="10px" alignItems="center" mx="15px" px="15px" py="15px" color="whiteAlpha.800">
                {/* <Icon  fontSize="24px" name="home-solid" color="currentcolor" /> */}
                <Image src="/assets/icon-dashboard.svg" alt="Dashboard" w="auto" h="32px" />
                <Text color="currentcolor" fontWeight="bold" fontSize="18px">Dashboard</Text>
            </HStack>                                
            
            <HStack rounded="xl" my="15px" className="account-nav" as={NavLink} to="/parent/children" spacing="10px" alignItems="center" mx="15px" px="15px" py="15px" color="whiteAlpha.800">
                {/* <Icon  fontSize="24px" name="bell" color="currentcolor" /> */}
                <Image src="/assets/icon-notifications.svg" alt="Dashboard" w="auto" h="32px" />
                <Text color="currentcolor" fontSize="18px" fontWeight="600">Children</Text>
            </HStack>
            
            <HStack rounded="xl" my="15px" className="account-nav" as={NavLink} to="/parent/notifications" spacing="10px" alignItems="center" mx="15px" px="15px" py="15px" color="whiteAlpha.800">
                {/* <Icon  fontSize="24px" name="bell" color="currentcolor" /> */}
                <Image src="/assets/icon-notifications.svg" alt="Dashboard" w="auto" h="32px" />
                <Text color="currentcolor" fontSize="18px" fontWeight="600">Notifications</Text>
            </HStack>
            
            <HStack rounded="xl" my="15px" className="account-nav" as={NavLink} to="/parent/settings" spacing="10px" alignItems="center" mx="15px" px="15px" py="15px" color="whiteAlpha.800">
                {/* <Icon  fontSize="24px" name="cog-solid" color="currentcolor" /> */}
                <Image src="/assets/icon-settings.svg" alt="Settings" w="auto" h="32px" />
                <Text color="currentcolor" fontSize="18px" fontWeight="600">Settings</Text>
            </HStack>                    
        </Box>
    )
}
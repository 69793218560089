import { ParentConstants as Constants } from '../types'
import { parentApi as api } from '../../api'

export const parentAction = {
    get,    
    add,
    remove,
    get_progress
};

function get() {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._get()
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.GET, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}


function add(id) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._add(id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.GET, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}


function remove(id) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._remove(id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.GET, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function get_progress(id) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._get_progress(id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.PROGRESS, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}
import { VClassConstants as Constants } from '../types'
import { vClassApi as api } from '../../api'

export const vClassAction = {
    get,    
    add,
    details,
    comment,
    join,
    quiz,
    live
};

function get(filters, offset) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._get(filters, offset)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.GET, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}


function details(id) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._details(id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.DETAILS, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}


function add(form_data) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._add(form_data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.ADD, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function join(id) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._join(id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.DETAILS, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}



function comment(form_data) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._comment(form_data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.DETAILS, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}



function quiz(id) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._quiz(id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.QUIZ, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function live(grade_id) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._live(grade_id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.LIVE, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}
import React, {useEffect, useState} from "react"
import { Drawer, Input, Select, ToggleGroup  } from "../../../uikit"
import { Box, Heading, HStack, Button, useBoolean } from "@chakra-ui/react"
import { useApp, usePrevious} from '../../../hooks'
import { useDispatch } from "react-redux"
import { states } from "../../../helpers/states";
import { cities } from "../../../helpers/cities";
import { authAction } from "../../../redux/actions";

export default function EditProfile ({isOpen, grades, onClose,...rest}){

    const dispatch = useDispatch()
    const { user, profile } = useApp()
    const [ errors, setErrors] = useState(null)
    const [ state, setState ] = useState(null)
    const [ submitting, setSubmitting ] = useBoolean(false)
    const [ myCities, setMyCities ] = useState([])

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitting.on()
        let form_data ={
            first_name: state?.first_name,
            last_name: state?.last_name,
            gender: state?.gender,
            country: state?.country,
            province: state?.province,
            city: state?.city,
            grade_id: state?.grade_id,
            phone: state?.phone,
            father_name: state?.father_name,
            school: state?.school
        }
        dispatch(authAction.update_user(form_data))
            .then((res) => {
                setSubmitting.off()
                onClose()                
            })
            .catch((err) => {
                setErrors(err?.error)
            })

    }

    const handleInputChange = (name, value) => {
        setState({
            ...state,
            [name] : value
        })
    }

    useEffect(() => {
        if(user && profile){
            setState({
                ...user,
                ...profile
            })
        }
    }, [user, profile])


    const prevProvince = usePrevious(state?.province)
    useEffect(() => {
        if (state?.province !== prevProvince) {
            let filtered = cities.filter((x) => x.stateCode === state?.province)
            setMyCities(filtered)
        }
    }, [state?.province, prevProvince, myCities])

    return(
        <Drawer
            open={isOpen}
            onClose={onClose}
            footer={
                <HStack>
                    <Button isLoading={submitting} type="submit" form="profile-form"  variant="action">Update</Button>
                </HStack>
            }
            >
            <Box p={5}>
                <Heading size="md">Edit Profile</Heading>

                <form onSubmit={handleSubmit} id="profile-form">
                    <HStack my={5} justifyContent="space-between">
                        <Box flex={1}>
                            <Input 
                                error={errors?.first_name}
                                variant="filled"
                                label="First Name"
                                value={state?.first_name}
                                onChange={(e) => handleInputChange('first_name', e.target.value)}
                            />
                        </Box>
                        <Box flex={1}>
                            <Input 
                                error={errors?.last_name}
                                variant="filled"
                                label="Last Name"
                                value={state?.last_name}
                                onChange={(e) => handleInputChange('last_name', e.target.value)}
                            />
                        </Box>
                    </HStack>

                    <HStack my={5} justifyContent="space-between">
                        <Box flex={1}>
                            <Input 
                                error={errors?.email}
                                variant="filled"
                                label="Email Address"
                                value={state?.email}
                                isReadOnly
                                // isDisabled
                                // onChange={(e) => handleInputChange('email', e.target.value)}
                            />
                        </Box>
                        <Box flex={1}>
                            <Input 
                                error={errors?.phone}
                                variant="filled"
                                label="Phone Number"
                                value={state?.phone}
                                onChange={(e) => handleInputChange('phone', e.target.value)}
                            />
                        </Box>
                    </HStack>
                    
                    <HStack my={5} justifyContent="space-between">
                        <Box flex={1}>
                            <Input 
                                variant="filled"
                                label="CNIC/B-Form"
                                value={state?.cnic}
                                isReadOnly
                                // isDisabled
                                // onChange={(e) => handleInputChange('email', e.target.value)}
                            />
                        </Box>  
                        <Box flex={1}>
                            <Input 
                                error={errors?.father_name}
                                variant="filled"
                                label="Father Name"
                                value={state?.father_name}                                
                                onChange={(e) => handleInputChange('father_name', e.target.value)}
                            />
                        </Box>                        
                    </HStack>

                    <HStack my={5} justifyContent="space-between">
                        <Box flex={1}>
                            <Select
                                error={errors?.country}
                                label="Country"
                                name="country"
                                variant="filled"
                                value={state?.country || ''}
                                onChange={(e) => handleInputChange('country', e.target.value)}
                                >
                                <option value="Pakistan">Pakistan</option>
                                <option value="Overseas">Overseas</option>
                            </Select>
                        </Box>
                        <Box flex={1}>
                            <Select
                                error={errors?.province}
                                label="Province"
                                name="province"
                                variant="filled"
                                value={state?.province || ''}
                                onChange={(e) => handleInputChange('province', e.target.value)}
                                >
                                <option value="">Select Province</option>
                                {states?.map((st, s) =>
                                    <option key={s} value={st?.isoCode}>{st?.name}</option>
                                )}
                            </Select>
                        </Box>
                    </HStack>

                    <HStack my={5} justifyContent="space-between">
                       
                        <Box flex={1}>
                            <Select
                                error={errors?.city}
                                label="City"
                                name="city"
                                variant="filled"
                                value={state?.city || ''}
                                onChange={(e) => handleInputChange('city', e.target.value)}
                                >
                                <option value="">Select City</option>
                                {myCities?.map((city, c) =>
                                    <option key={c} value={city?.name}>{city?.name}</option>
                                )}
                            </Select>
                        </Box>
                        <Box flex={1}>
                            <Input 
                                variant="filled"
                                label="School Name"
                                value={state?.school}
                                error={errors?.school}
                                onChange={(e) => handleInputChange('school', e.target.value)}
                            />
                        </Box>
                    </HStack>

                    <HStack>
                        <Box flex={1}>
                        <Select
                                error={errors?.grade}
                                label="Class"
                                required
                                variant="filled"
                                value={state?.grade_id || ''}
                                onChange={(e) => handleInputChange('grade_id', e.target.value)}
                                >
                                <option value="">Select Class</option>
                                {grades?.map((grade, g) =>
                                    <option key={g} value={grade?.id}>{grade?.title} {grade?.label || ''}</option>
                                )}
                            </Select>
                        </Box>
                    </HStack>

                    <Box mt="4">
                        <ToggleGroup
                            required
                            value={state?.gender || 'MALE'}
                            onChange={(val) => handleInputChange('gender', val)}
                            options={[
                                {label: 'Male', value: 'MALE'},
                                {label: 'Female', value: 'FEMALE'},
                                {label: 'Other', value: 'OTHER'},
                            ]}
                            label="Gender"
                        />
                    </Box>
                    


                </form>
            </Box>
        </Drawer>
    )
}
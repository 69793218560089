import React, { useEffect } from "react";
import { Box, Heading, HStack, useBoolean, Text, Button, Spinner, 
    Accordion, AccordionItem, AccordionButton,
    AccordionPanel, AccordionIcon
 } from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'
import { Container } from "../../uikit";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { subjectAction } from "../../redux/actions";
import { usePrevious, useApp } from "../../hooks";
import { Link, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";

export default function SubjectView() {

    const navigate = useNavigate()
    const { slug } = useParams();
    const { user, profile } = useApp()
    const [loading, setLoading] = useBoolean(false);    
    const prevSlug = usePrevious()
    const dispatch = useDispatch();    
    const { subject } = useSelector(state => state?.subjects)

    
    useEffect(() => {
        if(slug && prevSlug !== slug){
            setLoading.on()
            dispatch(subjectAction.details(slug))
                .then((res) => setLoading.off())
                .catch((err) => setLoading.off())
        }
    }, [slug, prevSlug])

    
    

    const getTotalTopics = () => {
        let total = 0;
        subject?.chapters?.map( x => {
            return total+=x?.topics?.length
        })
        return total;
    }

    const getTotalLectures = () => {
        let total = 0;
        subject?.chapters?.map( x => {
            x?.topics?.map(y => {
                y?.contents?.map( z => {
                    if(z?.type === "VIDEO"){
                        total++;
                    }
                    return null;
                })
                return null;
            })
            return null;
        })
        return total;
    }

    const getTotalQuizzes = () => {
        let total = 0;
        subject?.chapters?.map( x => {
            if(x?.quiz){
                total++
            }
            x?.topics?.map(y => {
                if(y?.quiz){
                    total++
                }
                return null;
            })
            return null;
        })
        return total;
    }


    return (
        <>
            <Helmet>
                <title>Class {`${subject?.grade?.title}`} {`${subject?.title}`} - PCTB Online Academy</title>
                <meta name="description" content="PCTB Online Academy is FREE online learning platform for HSSC and SSC Student covering of Subjects, Assessment and other learning material to help our young genration for tapping in to the future." />
            </Helmet>
            <Box  pt="100px" bgBlendMode="overlay" bgImage="/assets/waves-bg.svg" bgRepeat="no-repeat" bgSize="cover" bgPosition="center center">
                <Box py="2em">
                    <Container>
                        <HStack flexDir={{ base: 'column', lg: 'row' }} alignItems={{base: 'flex-start', lg: "flex-end"}}>
                            <Box flex={1} mb="3">
                                <Text color="accent.500" fontWeight="bold" fontSize="20px">Class {subject?.grade?.title} {subject?.grade?.label}</Text>
                                <Heading variant="insaf-heading" size="lg" color="white">{subject?.title}</Heading>
                                {subject && subject?.chapters?.length > 0 &&
                                    <Text fontSize="18px" color="whiteAlpha.800">{subject?.description}</Text>}
                            </Box>
                            {
                                (user && profile) ? 
                                <Button as={Link} to={"/account/subjects/"+subject?.id} size="lg" colorScheme="accent">Start Learning</Button>
                                :
                                <Button as={Link} to="/register" size="lg" colorScheme="accent">Start Learning</Button>
                            }
                            
                        </HStack>
                    </Container>
                </Box>
            </Box>
            {
                loading ?
                    <Box d="flex" h="100%" alignItems="center" justifyContent="center">
                        <Spinner />
                    </Box>
                :
                    <Box py="3em" pb="6em">
                        {
                            subject?.chapters?.length > 0 &&                        
                            <Container>
                                <HStack  flexDir={{ base: 'column', lg: 'row' }} spacing={{base: 0, lg: "10"}} alignItems="stretch">
                                    <Box flex="1" rounded="lg" overflow="hidden" mb={{ base: '5', lg: 0 }}>
                                        <Box className='player-wrapper'>  
                                            <ReactPlayer className='react-player' width="100%" height='100%' url={'https://www.youtube.com/watch?v='+subject?.intro_video} controls={true} />
                                        </Box>
                                    </Box>
                                    <Box w={{base: '100%', lg: "30%"}} >
                                        <Box h="100%" shadow="custom" rounded="lg" bg="white" overflow="hidden">
                                            <Box bg="brand.500" px="20px" py="15px">
                                                <Heading color="white" size="md">Subject Overview</Heading>
                                            </Box>
                                            <Box p="20px">
                                                <HStack borderBottom="1px solid" borderColor="gray.200" py="15px" alignItems="flex-start" justifyContent="space-between"> 
                                                    <Text fontWeight="600">Total Chapters</Text>
                                                    <Text color="accent.500">{subject?.chapters?.length || 0}</Text>
                                                </HStack>
                                                <HStack borderBottom="1px solid" borderColor="gray.200" py="15px" alignItems="flex-start" justifyContent="space-between"> 
                                                    <Text fontWeight="600">Total Topics</Text>
                                                    <Text color="accent.500">{getTotalTopics()}</Text>
                                                </HStack>

                                                <HStack borderBottom="1px solid" borderColor="gray.200" py="15px" alignItems="flex-start" justifyContent="space-between"> 
                                                    <Text fontWeight="600">Total Lectures</Text>
                                                    <Text color="accent.500">{getTotalLectures()}</Text>
                                                </HStack>

                                                <HStack borderBottom="1px solid" borderColor="gray.200" py="15px" alignItems="flex-start" justifyContent="space-between"> 
                                                    <Text fontWeight="600">Total Quizzes</Text>
                                                    <Text color="accent.500">{getTotalQuizzes()}</Text>
                                                </HStack>
                                                <HStack borderBottom="1px solid" borderColor="gray.200" py="15px" alignItems="flex-start" justifyContent="space-between"> 
                                                    <Text fontWeight="600">Teacher Name</Text>
                                                    <Text color="accent.500">{subject?.teacher}</Text>
                                                </HStack>
                                                <Box mt="10" textAlign="center">
                                                {
                                                    (user && profile) ? 
                                                    <Button as={Link} to={"/account/subjects/"+subject?.id} size="lg" colorScheme="accent">Start Learning</Button>
                                                    :
                                                    <Button as={Link} to="/register" size="lg" colorScheme="accent">Start Learning</Button>
                                                }   
                                                </Box>

                                            </Box>
                                        </Box>
                                    </Box>
                                </HStack>

                                <Accordion my="10" allowMultiple>
                                {
                                        subject?.chapters?.map((chapter, index) =>
                                            <AccordionItem key={index} border="0" my="8" rounded="lg" shadow="custom" bg="white">
                                                <AccordionButton border="0" px="10" py="4" _hover={{ bg: 'transparent' }} _focus={{ shadow: 'none' }} borderBottom="1px solid" borderColor="gray.200">
                                                    <Box flex='1' textAlign="left" pb="5px">
                                                        <Text fontSize="15px" mt="4" fontWeight="500" color="accent.500">{chapter?.label}:</Text>
                                                        <Heading fontFamily="Poppins" fontSize={{base: '18px', md: '22px', lg: "30px"}}>{chapter.title} </Heading>
                                                    </Box>
                                                    <HStack spacing="5">                                                                                                        
                                                        <Text fontSize="12px" d={{ base: 'none', lg: 'block' }} color="accent.500">View Lectures</Text>
                                                        <AccordionIcon color="accent.500" />
                                                    </HStack>
                                                </AccordionButton>
                                                <AccordionPanel pb={4} px="10">
                                                    <Text>{chapter.details}</Text> 
                                                    <Heading my="5" fontSize="20px">Topics</Heading>
                                                    <Box>
                                                        {
                                                        chapter?.topics?.map((topic, idx) => 
                                                            <Box flex={1} key={idx} mb="5" p="15px">
                                                                <HStack alignItems="flex-start" spacing={4}>
                                                                    <Box w="32px" h="32px" rounded="full" bg="brand.500" d="flex" alignItems="center" justifyContent="center">
                                                                        <Text color="white" fontSize="sm" fontWeight="bold">{idx + 1}</Text>
                                                                    </Box>
                                                                    <Box flex={1}>
                                                                        <Text fontSize="18px" fontFamily="Poppins" fontWeight="400">{topic.title}</Text>                                                        
                                                                    </Box>
                                                                </HStack>
                                                                <HStack my="5" flexDir={{ base: 'column', md: 'row' }} alignItems="flex-start" flexWrap="wrap" spacing="0">
                                                                {
                                                                    topic.contents.map((content, c) => 
                                                                    <Box w={{base: '100%', lg:"50%"}} key={c} >
                                                                        <Box  py="10px" px="20px" cursor="pointer" onClick={() => (user && profile)  ?  navigate('/account/subjects/topic/'+content?.id) : navigate('/register')} >
                                                                            <HStack flex={1} alignItems="flex-start" flexDir={{ base: 'column', lg: 'row' }} spacing={{base: 0, lg:"6"}} bg="gray.100" p="20px" rounded="md">
                                                                                {
                                                                                    content?.type === 'VIDEO' &&
                                                                                    <Box minW={{base: '100%', lg:"30%"}} rounded="lg" overflow="hidden">
                                                                                        <Box className='player-wrapper'>  
                                                                                            <ReactPlayer className='react-player' width="100%" height='100%' url={'https://www.youtube.com/watch?v='+content?.data} light={true} />                                                            
                                                                                        </Box>
                                                                                    </Box>
                                                                                }
                                                                                <Box>                                                                                
                                                                                    <Box mt={{ base: '3', lg: 0 }}>
                                                                                        <Text my={2} fontWeight="bold">{content.title}</Text>
                                                                                        <Text fontSize="14px">{content?.description}</Text>
                                                                                    </Box>
                                                                                    
                                                                                </Box>
                                                                            </HStack>
                                                                        </Box>
                                                                    </Box>
                                                                )}
                                                                </HStack>
                                                                {                                                                    
                                                                    (user && profile && topic?.quiz) &&
                                                                    <Box pl={{base: 0, lg: "1em"}}>
                                                                        <Button onClick={() => navigate('/account/subjects/quiz/'+topic?.quiz?.id, {state: topic?.title })} size="md">Topic Quiz</Button>
                                                                    </Box>
                                                                }
                                                                
                                                            </Box>   
                                                        )}

                                                    </Box>
                                                </AccordionPanel>
                                            </AccordionItem>
                                        )
                                    }
                                </Accordion>

                            </Container>
                        }
                        {
                            (!loading && subject?.chapters?.length === 0) && 
                            <Container>
                                <Box p="10" bg="yellow.100" rounded="lg">
                                    <Text color="yellow.700" fontSize="20px">Coming Soon</Text>
                                </Box>
                            </Container>
                        }
                    </Box>
            }
        </>
    )
}
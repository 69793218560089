import React from 'react'
import { Box, FormLabel, FormControl, FormErrorMessage, Text, HStack, RadioGroup, Radio} from '@chakra-ui/react'

const ToggleGroup = ({error, label, value, onChange, required, options = [],  ...rest}) => {
    return(
        <Box>
            <FormControl isInvalid={error}>
                {label && <FormLabel fontSize="12px" mb="3" color="brand.600" fontWeight="600" textTransform="uppercase">{label} {required && <Text as={'span'} fontSize="xs" color="red">*</Text>}</FormLabel>}                
                <RadioGroup value={value} onChange={onChange} >
                    <HStack flexWrap="wrap" spacing="4">
                    {
                        options.map((opt, o) =>
                            <Radio  _focus={{ shadow: "none" }}  colorScheme={opt?.color || 'brand'} key={o} value={opt.value}>
                                <Text fontWeight="600" fontSize="sm" textTransform="capitalize">{opt.label}</Text>
                            </Radio>
                        )
                    }
                    </HStack>
                </RadioGroup>                
                <FormErrorMessage>{error}</FormErrorMessage>
            </FormControl>
        </Box>
    )
}

export { ToggleGroup }
import React from 'react'
import {Route, Routes as Switch } from 'react-router-dom'
import { Box, HStack } from '@chakra-ui/react'
import { Header, Sidebar } from './Components'
import { useApp } from '../../hooks'
import Dashboard from '../../pages/Parent/Dashboard'
import Notifications from '../../pages/Parent/Notifications'
import Settings from '../../pages/Parent/Settings'
import Kids from '../../pages/Parent/Kids'
import KidProgress from '../../pages/Parent/Kids/Progress'



export default function ParentLayout() {
    
    
    const {profile, user} = useApp()
    
    if(!profile || window.is_empty(user)){
        window.location.href="/"
    }

    if(user?.group === 'STUDENT'){
        window.location.href="/account"

    }
    else if(user?.group === 'TEACHER'){
        window.location.href="/dashboard"
    }
    else{        
        return (
            <>
                <HStack bg="white" h="100vh" overflowY="hidden" spacing="0" alignItems="flex-start">
                    <Sidebar />
                    <Box flex="1" h="100%" overflowY="auto" bg="#E8E6E6" pt="80px">                     
                        <Header />
                        <Switch>
                            <Route exact path="/" element={<Dashboard />} />                            
                            <Route exact path="/notifications" element={<Notifications />} />                            
                            <Route exact path="/settings" element={<Settings />} />                            
                            <Route exact path="/children" element={<Kids />} />
                            <Route exact path="/children/progress/:child_id" element={<KidProgress />} />
                            
                        </Switch>
                    </Box>
                </HStack>
            </>

        )
    }
}


import { VirtualClassConstants as Constants } from '../types'

import { virtualClassApi as api } from '../../api'

export const virtualClassAction = {
    get,    
    add,
    details,
    comment,
    quiz,
    remove,
    remove_student
};

function get() {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._get()
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.GET, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}


function details(id) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._details(id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.DETAILS, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}


function add(form_data) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._add(form_data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.ADD, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function comment(form_data) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._comment(form_data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.DETAILS, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function quiz(form_data) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._quiz(form_data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.DETAILS, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function remove(id) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._remove(id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.REMOVE, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function remove_student(student_id, class_id) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._remove_student(student_id, class_id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.DETAILS, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}




import { VirtualClassConstants as Constants } from '../types'

const initialState = {
    classes: [],   
    virtual_class: null
    
};

export function virtualClassReducer(state = initialState, action) {
    switch (action.type) {  
        
        case Constants.GET:
            return {
                ...state,
                classes: action.payload.virtual_classes,
            };

        case Constants.ADD:
            return {
                ...state,
                classes: [...state?.classes, action.payload.virtual_class],
            };

        case Constants.DETAILS:
            return {
                ...state,
                virtual_class: action.payload.virtual_class,
            };

        case Constants.REMOVE:
            return({
                ...state,
                classes: state.classes.filter((item) => parseInt(item.id) !== parseInt(action.payload.id))
            })
                   


        default:
            return {
                ...state
            }
    }
}
import React, { useEffect, useState} from 'react'
import { Box, Heading, useBoolean, HStack, Spinner, Text} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { useApp } from '../../../hooks'
import { Modal } from '../../../uikit'
import { virtualClassAction } from '../../../redux/actions'
import { virtualClassApi } from '../../../api'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)


export default function QuizResults({id, ...rest}){

    
    const { quiz_id } = useParams()
    
    const { setDashHeading } = useApp()
    const [state, setState ] = useState({})    
    const [ details, setDetails ] = useState(null)
    const [ loading, setLoading] = useBoolean(false)
    const [ quiz, setQuiz] = useState(null)
    

    

    const handleInputChange = (name, value) => {
        setState({
            ...state,
            [name]: value
        })
    }


    useEffect(() => {
        if(quiz_id){
            setLoading.on()
            virtualClassApi._quiz_results(quiz_id)
                .then((res) => {                    
                    setQuiz(res?.quiz)
                    setLoading.off()
                })
                .catch((err) => {
                    setLoading.off()
                })
        }
    }, [quiz_id])


    useEffect(() => {
        setDashHeading("Quiz Results")
        return () => {
            setDashHeading("Dashboard")
          };
    }, [])


    
    if(loading){
        return(
            <Box d="flex" h="100%" alignItems="center" justifyContent="center">
                <Spinner />
            </Box>
        )
    }

   
    return(        
        <Box>
            {
                quiz &&
                <Box p={8}>
                    <Heading size="md">{quiz?.title}</Heading>
                    <HStack my="6" p="4" justifyContent="space-between" bg="white" rounded="lg">
                        <Box textAlign="center">
                            <Text fontWeight="700">Total Questions: </Text>
                            <Text fontSize="24px">{quiz?.settings?.total}</Text>
                        </Box>
                        <Box textAlign="center">
                            <Text fontWeight="700">Passing </Text>
                            <Text fontSize="24px">{quiz?.settings?.passing}%</Text>
                        </Box>
                        <Box textAlign="center">
                            <Text fontWeight="700">Duration </Text>
                            <Text fontSize="24px">{quiz?.settings?.duration} min</Text>
                        </Box>
                    </HStack>

                    <Box mt="10" p="5" bg="white" rounded="lg">                        
                        <Heading px="5" py="5" size="md">Students</Heading>
                        <table className="custom-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Correct</th>                      
                                    <th>Incorrect</th>
                                    <th>Result</th>
                                    <th></th>
                                </tr>                                
                            </thead>
                            <tbody>
                                {
                                    quiz?.results?.map((result, r) =>
                                    <tr key={r} onClick={() => setDetails(result)}>
                                        <td>{result?.user?.first_name} {result?.user?.last_name}</td>
                                        <td>{result?.user?.email} </td>
                                        <td>{result?.result?.correct}</td>
                                        <td>{result?.result?.total_questions - result?.result?.correct}</td>
                                        <td>{parseInt(result?.result?.percentage)}%</td>
                                        <td>
                                            {
                                                result?.result?.percentage < quiz?.settings?.passing ?
                                                <Box px="3" py="2" rounded="lg" bg="red.50" textAlign="center" color="red.500">
                                                    FAIL
                                                </Box>
                                                :
                                                <Box px="3" py="2" rounded="lg" bg="green.50" textAlign="center" color="green.500">
                                                    PASS
                                                </Box>
                                            }
                                        </td>
                                    </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </Box>
                </Box>
            }
            
            <Modal 
                open={details !== null}
                onClose={() => setDetails(null)}
                title="Result Details"
                size="3xl"
                >
                <Box bg="white" rounded="lg">
                {
                    details?.details?.map((requ, b) =>                                         
                        <Box key={b} p="5" borderBottom="1px solid" borderColor="gray.200">
                            <Heading size="sm">Question No. {parseInt(b) + 1}</Heading>
                            <Box my="4" fontWeight="bold">{requ?.question}</Box>
                            <Box>
                            {
                                requ?.options?.map((anu, n) => {
                                    if(requ?.correct_answer === anu?.id ){
                                        return(
                                            <HStack p="2" rounded="md" key={n} my="2" bg='green.100'>
                                                <Text fontWeight="bold">{parseInt(n+1)}:</Text>
                                                <Text>{anu?.answer}</Text>
                                            </HStack>
                                        )
                                    }else if(requ?.answer === anu?.id && !requ?.correct ){
                                        return(
                                            <HStack p="2" rounded="md" key={n} my="2" bg='red.100'>
                                                <Text fontWeight="bold">{parseInt(n+1)}:</Text>
                                                <Text>{anu?.answer}</Text>
                                            </HStack>
                                        )
                                    }else{
                                        return(
                                            <HStack p="2" rounded="md" key={n} my="2" bg='transparent'>
                                                <Text fontWeight="bold">{parseInt(n+1)}:</Text>
                                                <Text>{anu?.answer}</Text>
                                            </HStack>
                                        )
                                    }
                                    
                                })
                            }
                            </Box>                                                
                        </Box>
                                                                    
                    )
                }
                </Box>
            </Modal>

        </Box>
    )
}
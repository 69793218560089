import { AuthConstants as Constants } from '../types'
import { authApi as api } from '../../api'

export const authAction = {
    login,
    signup,
    logout,
    profile,
    fetch_user,
    update_user,
    refresh_token, 
    clear,
    set_activity,
    avatar,
    get_messages,
    update_message
}

function login(form_data) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._login(form_data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.LOGIN, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function profile() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._profile()
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.PROFILE, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function signup(form_data) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._signup(form_data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.LOGIN, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function fetch_user() {        
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._fetch_user()
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )
        });
    }
    function success(payload) { return { type: Constants.AUTH, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}


function update_user(form_data) {        
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._update_user(form_data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )
        });
    }
    function success(payload) { return { type: Constants.UPDATE_USER, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}


function logout() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._logout()
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve()
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )
        });
    function success() { return { type: Constants.LOGOUT } }
    function failure() { return { type: Constants.FAILED } }
    }
}

function refresh_token(token) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._refresh(token)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.REFRESH_TOKEN, payload } }
    function failure(error) { return { type: Constants.REFRESH_TOKEN_FAILED, error } }
}


function set_activity(topic_id) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._set_activity(topic_id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.PROFILE, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}


function avatar(form_data) {        
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._avatar(form_data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )
        });
    }
    function success(payload) { return { type: Constants.AUTH, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function clear() {
    return dispatch => {   
        return new Promise((resolve, reject) => {     
            dispatch(success());
            resolve()
        })   
    }
    function success() { return { type: Constants.CLEAR } }
}



function get_messages() {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._get_messages()
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.MESSAGES, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function update_message(id) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._update_messages(id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.UPDATE_MESSAGE, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}
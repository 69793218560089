import React, { Fragment, useEffect, useState } from 'react';
import { Box, Heading, HStack, Image, Text, Stack, IconButton, Avatar, Button, useBoolean, useToast, Spinner } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { gradeAction, authAction } from '../../../redux/actions';
import { Input, Icon } from '../../../uikit';
import { useApp } from '../../../hooks';
import EditProfile from './EditProfile';
import { authApi } from '../../../api';
import Cookies from 'js-cookie';

const Settings = () => {
    const dispatch = useDispatch();    
    const toast = useToast()
    const imgRef = React.useRef();
    const { setDashHeading, user, profile } = useApp()
    const [ passwords, setPasswords] = useState(null)
    const [ formData, setFormData ] = useState(null)
    const [ changing, setChanging] = useBoolean(false)
    const [ isOpen, setIsOpen] = useBoolean()
    const [ profilePic, setProfilePic] = useState(null)
    const [removing, setRemoving ] = useBoolean(false)
    const [ submitting, setSubmitting ] = useBoolean()
    const { grades} = useSelector(state => state?.grades)

    useEffect(() => {
        setDashHeading("Settings")
        return () => {
            setDashHeading("Dashboard")
          };
    }, [])

    useEffect(() => {
        if(user && profile){
            setFormData({
                ...user,
                ...profile
            })
        }
    }, [user, profile])

    useEffect(() => {
        dispatch(gradeAction.get())
            .then((res) => {

            })
            .catch((err) => {
                console.log("Error", err)
            })
    }, [])

    const changeClass = (id) => {
        
        let form_data ={
            first_name: formData?.first_name,
            last_name: formData?.last_name,
            gender: formData?.gender,
            country: formData?.country,
            province: formData?.province,
            city: formData?.city,
            grade_id: id,
            phone: formData?.phone,
            father_name: formData?.father_name,
            school: formData?.school
        }
        dispatch(authAction.update_user(form_data))            
    }
    
    const changePassword = () => {
        setSubmitting.on()
        authApi._change_password(passwords)
            .then((res) => {
                setSubmitting.off()
                setPasswords(null)
                toast({
                    title: 'Change Password',
                    description: res?.message,
                    status: "success",
                    position: "top"

                })
            })
            .catch((err) => {
                setSubmitting.off()
                toast({
                    title: 'Error',
                    description: err?.error || err?.message,
                    status: "error",
                    position: "top"

                })
                
            })
    }

    const handleFile = (e) => {
        if(e.target.files[0])
        {
            if(e.target.files[0]?.type.includes('image/'))
            {
                let img = URL.createObjectURL(e.target.files[0])
                let formData = new FormData()
                formData.append('avatar', e.target.files[0])
                dispatch(authAction.avatar(formData))
                    .then((res) => {
                        setChanging.off()
                    })
                    .catch((err) => {
                        setChanging.off()
                    })
                // setProfilePic({
                //     thumb: img,
                //     avatar: e.target.files[0]
                // })                
            }
            else
            {
                alert("Please select image file")
            }
        }
    }

    const triggerSelect = () =>{
        var event = new MouseEvent('click', {
            'view': window, 
            'bubbles': true, 
            'cancelable': false
           });           
           imgRef.current.dispatchEvent(event);
    }

    const resetAvatar = () => {
        setProfilePic({
            thumb: null,
            avatar: null
        }) 
    }

    const removeAccount = async() => {
        let result = await window.confirm("Do you really want to remove your account?", "Delete Account")
        if(result){
            setRemoving.on()
            authApi._remove_account(user?.id)
                .then((res) => {
                    toast({
                        title: 'Account Removed',
                        description: "Your account has been removed",
                        status: "success",
                        position: "top"
    
                    })
                    
                    localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN);
                    Cookies.remove('insaf-auth-token', { domain: process.env.REACT_APP_BASE_DOMAIN })
                    
                    dispatch(authAction.clear())
                    setRemoving.off()
                    // window.location.href="/"

                })
                .catch((err) => {
                    setRemoving.off()
                })
        }
    }

    return (
        <Box px="2em" py="5">   
            
            <Box bg="white" rounded="lg" p="10">
                <HStack alignItems="center" justifyContent="space-between">
                    <Heading size="md">Personal Details</Heading>
                    <Button onClick={setIsOpen.on} rounded="lg" colorScheme="accent">Edit Info</Button>
                </HStack>
                <Stack direction={{ base: "column", md: "row" }} my="3" spacing={5} alignItems="center" justifyContent="space-between">
                    <Box w="180px">
                        {/* <Avatar
                            w={"120px"}
                            h={"120px"}
                            bg={"#1A3F6B"}
                            src={user?.image ? process.env.REACT_APP_ASSETS_PATH + "/" + user?.image : ""}
                        />

                        <Text fontSize={"14px"} color={"#475F7B"} mt={'10px'} cursor={"pointer"}>Edit Profile Picture</Text> */}
                        <Avatar  showBorder className="hover-reveal2" size="2xl" verticalAlign="middle" src={user?.avatar ? process.env.REACT_APP_STORAGE_URL+user.avatar?.url :  "/assets/avatar.svg"}>
                            <HStack className="hover-target" d="none" pos="absolute" left="50%" top="50%" transform="translate(-50%, -50%)">
                                <IconButton onClick={triggerSelect} colorScheme="gray" rounded="full" size="sm" icon={<Icon color="gray.600" fontSize="18px" name="image-solid" />}  />
                                {/* { user?.avatar && <IconButton onClick={resetAvatar} rounded="full" colorScheme="red" size="sm" icon={<Icon color="white" fontSize="18px" name="times-solid" />}  />}                             */}
                            </HStack>
                            {
                                changing && 
                                <Spinner  />
                            }
                        </Avatar>
                        <input  accept="image/*" style={{  display: "none" }} ref={imgRef} type="file" onChange={handleFile}/>
                        
                    </Box>
                    <Box flex={4}>
                        <Text my="1"><Text as="strong">Name</Text>: {user?.first_name} {user?.last_name} </Text>
                        <Text my="1"><Text as="strong">Email</Text>: {user?.email} </Text>
                        <Text my="1"><Text as="strong">School Name</Text>: {profile?.school} </Text>
                    </Box>
                    <Box flex={4}>
                        <Text my="1"><Text as="strong">Father Name</Text>: {profile?.father_name}</Text>
                        <Text my="1"><Text as="strong">CNIC</Text>: {user?.cnic} </Text>
                        <Text my="1"><Text as="strong">City</Text>: {profile?.city} </Text>
                    </Box>                            
                </Stack>                
            </Box>

            <Box bg="white" my="5" rounded="lg" p="10">
                <Heading size="md">Choose Your Class</Heading>
                <Stack direction={{ base: "column", md: "row" }} mt="3" spacing="3" flexWrap="wrap">
                    {
                        grades?.map((grade, g) =>
                            <Box w={{ base: "100%", md: "16.66%" }} key={g} bg="gray.100" onClick={() => changeClass(grade?.id)} cursor="pointer" flex="1" rounded="lg" border="2px solid" borderColor="transparent" _hover={{ borderColor: 'brand.500', shadow: "xl" }} {...(profile?.grade_id === grade?.id && {borderColor: 'brand.500', shadow: 'xl'})}>
                                <Box textAlign="center" py="10">
                                    <Image src="/assets/class-icon.svg" alt="class" mx="auto" />
                                    <Heading my="2" size="md">Class {grade?.title}</Heading>
                                    <Heading fontSize="14px">{grade?.label || '...'}</Heading>
                                </Box>
                            </Box>
                        )
                    }
         

                </Stack>
            </Box>

            <Box bg="white" my="5" rounded="lg" p="10">
                <Heading mb="4" size="md">Update Password</Heading>
                <Stack direction={{ base: "column", md: "row" }} justifyContent="space-between" alignItems={{ base: "normal", md:"center" }} spacing={{base: "4", md:"20"}}>
                    <Box flex="1">
                        <Input        
                            type="password"
                            label="New Password"
                            value={passwords?.password || ''}
                            onChange={(e) => setPasswords({...passwords, password: e.target.value})}
                        />
                    </Box>
                    <Box flex="1">
                        <Input 
                            value={passwords?.confirm_password || ''}
                            type="password"
                            label="Confirm Password"
                            onChange={(e) => setPasswords({...passwords, confirm_password: e.target.value})}
                        />
                    </Box>
                    <Box>
                        <Button isLoading={submitting} onClick={changePassword} rounded="lg" mt="4" colorScheme="accent">Change Password</Button>
                    </Box>
                </Stack>
            </Box>
            <EditProfile isOpen={isOpen} grades={grades} onClose={setIsOpen.off} />
            <Box mt={5}>
                <Button isLoading={removing} onClick={removeAccount} colorScheme="red">Delete Account</Button>
            </Box>
        </Box>
    )
}

export default Settings;
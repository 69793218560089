import React, { useEffect, useState } from "react"
import { Box, Spinner, useBoolean, Heading, HStack, Button, Text,
    Slider, Stack,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Checkbox,
 } from "@chakra-ui/react"
import { useApp } from '../../../hooks'
import { useDispatch, useSelector } from "react-redux"
import { paperAction } from "../../../redux/actions"
import { paperApi } from "../../../api"
import { Helmet } from "react-helmet-async"
import { useLocation, useNavigate, useParams, Link } from "react-router-dom"

export default function MockExams () {
    
    const dispatch = useDispatch()    
    const { setDashHeading, profile} = useApp()    
    const { subject_id } = useParams()
    const [ mount, setMount ] = useBoolean(false)
    const navigate = useNavigate()
    const [ errors, setErrors ] = useState(null)
    const [submitting, setSubmitting ] = useBoolean(false)
    const [ selectedTopics, setSelectedTopics ] = useState([])    
    const location = useLocation()
    const [ checked, setChecked ] = useState([])
    const [ topics, setTopics ] = useState([])
    const [state, setState] = useState({subjects: [], level: 'MEDIUM', passing:65, duration: 10, total: 25})
    const { papers, subjects} = useSelector(state => state?.papers)

    

    const [ loading, setLoading] = useBoolean(false)
    
    const exam_type = location?.pathname?.includes('ecat') ? 'ECAT' : location?.pathname?.includes('mdcat') ? "MDCAT" : ""

    const handleInputChange = (name, value) => {
        setState({
            ...state,
            [name]: value
        })
    }


    useEffect(() => {
        setDashHeading(exam_type)
      return () => {
        setDashHeading('Dashboard')
      };
    }, [])

    useEffect(() => {
        if(exam_type && !mount){
            setMount.on()
            setLoading.on()
            dispatch(paperAction.get(exam_type?.toUpperCase()))
            .then((res) =>{
                setLoading.off()
            })
            .catch((err) =>{
                setLoading.off()
            })    
        }
    }, [mount, setMount, dispatch, exam_type, setLoading])


    const createQuiz = () => {
        setErrors(null)
        setSubmitting.on()
        let form_data = {            
            topics: selectedTopics,
            duration: state?.duration,
            passing: state?.passing,
            total: state?.total,
            type: 'MOCK',
            cat_type: exam_type
        }
        paperApi._quiz(form_data)
        .then((res) => {
            navigate('/account/cat-mock-exams/'+res?.quiz?.id, {state: 'Mock Exam'})
            setSubmitting.off()
        })
        .catch((err) => {
            console.log("Err", err)
            setErrors(err?.error)
            setSubmitting.off()
        })
    }

    const handleSubjects = (id) => {
        let all_subjs = [...state?.subjects]
        let all_selected_topics = [...selectedTopics]
        let subj = all_subjs?.findIndex(x => x === id);
        if(subj > -1){
            all_subjs.splice(subj, 1)
            let new_topics = all_selected_topics?.filter((x) => parseInt(x?.subject_id) !== parseInt(id))
            setSelectedTopics(new_topics)
        }else{
            all_subjs.push(id)
        }

        setState({...state, subjects: all_subjs})
    }

    useEffect(() => {
        if(state?.subjects){
            let find_topics = subjects?.filter(x => state?.subjects?.includes(x?.id))
            setTopics(find_topics)
        }
    }, [state?.subjects, subjects])

    const handleSelectTopic = (e, data) => {
        let all_selected_topics = [...selectedTopics]        
        let index = all_selected_topics?.findIndex(x => parseInt(x?.id) === parseInt(data?.id) && parseInt(data?.cat_subject_id) === parseInt(x?.subject_id))
        if(e.target.checked){
            all_selected_topics.push({id: data?.id, subject_id: data?.cat_subject_id })
        }else{
            all_selected_topics.splice(index, 1)
        }        
        setSelectedTopics(all_selected_topics)
    }


    useEffect(() => {
        let all_selected_topics = [...selectedTopics]
        let all_checked = all_selected_topics.map((x) => x?.id)
        setChecked(all_checked)
    }, [selectedTopics])
    

    
    return(
        <>
            <Helmet>
                <title>Mock Exam - PCTB Online Academy</title>
                <meta name="description" content="PCTB Online Academy is FREE online learning platform for HSSC and SSC Student covering of Subjects, Assessment and other learning material to help our young genration for tapping in to the future." />
            </Helmet>
            {
                loading ?
                    <Box d="flex" h="100%" alignItems="center" justifyContent="center">
                        <Spinner />
                    </Box>
                :
                <Box px="2em" py="3">
                    <HStack py={4} justifyContent="space-between">
                        <Box>
                            <Heading size="lg" fontWeight="800">{`${exam_type + ' Mock Exam'}`}</Heading>
                            <Text>
                                <Text color="accent.500" as={Link} to={'/account/'+exam_type}>Click here</Text>  to get access to {exam_type?.toUpperCase()} Syllabus & Lectures</Text>
                        </Box>
                        <Button as={Link} to={'/account/'+exam_type}>Course Work</Button>
                    </HStack>
                    <Box my="4" rounded="lg" p="10" bg="white">
                        <Heading fontWeight="800" size="md" textAlign="center">Select Subject(s)  for Mock Exam</Heading>
                        <Stack direction={{ base: "column", md: "row" }} my="10" justifyContent="space-between" spacing={{base: "5", md: "5"}}>
                            {
                                subjects?.map((subject, s) => 
                                <Box key={s} py={{ base: "10", md: '5' }} h="180px" onClick={() => handleSubjects(subject?.id)} flex="1" as={Button} bg={state?.subjects?.includes(subject?.id) ? "accent.50" : "gray.100"} rounded="lg" border="2px solid" borderColor={state?.subjects?.includes(subject?.id) ? "accent.500" : "transparent"} _hover={{ bg: 'accent.50', borderColor: 'accent.500' }}>
                                    <Text fontSize="20px" fontWeight="800" color="gray.700">{subject?.title}</Text>                            
                                </Box>
                            )}
                            
                        </Stack>
                    </Box>

                    <Box my="4" rounded="lg" p="10" bg="white">
                        <Heading fontWeight="800" size="md" textAlign="center">Choose topic(s) for Mock Exam</Heading>
                        {
                            errors?.topics &&
                            <Text  textAlign="center" color="red.500">{errors?.topics}</Text>
                        }
                        <HStack flexWrap="wrap" alignItems="flex-start" spacing={0} mx="-10px">
                            {
                                topics?.map((subject, s) => 
                                <Box w="33%" p="10px" key={s}>
                                    <Text fontSize="20px" mb={3} fontWeight="800" color="gray.700">{subject?.title}</Text>                            
                                    <Box p={4} bg="gray.50" rounded="md">
                                        
                                        {
                                            (subject?.topics?.length > 0) && subject?.topics?.map((topic, t) => 
                                            <Box key={t}>
                                                <Checkbox isChecked={checked.includes(topic?.id)}  onChange={(e) => handleSelectTopic(e, topic)} value={topic?.id}>{topic?.title}</Checkbox>
                                            </Box>
                                            )
                                        }{
                                            subject?.topics?.length === 0 &&
                                            <Box bg="yellow.100" p={3}>No Topic/Chapter Found</Box>
                                        }
                                    </Box>
                                    {/* <Box key={s} py={{ base: "10", md: '5' }} h="180px" onClick={() => handleSubjects(subject?.id)} flex="1" as={Button} bg={state?.subjects?.includes(subject?.id) ? "accent.50" : "gray.100"} rounded="lg" border="2px solid" borderColor={state?.subjects?.includes(subject?.id) ? "accent.500" : "transparent"} _hover={{ bg: 'accent.50', borderColor: 'accent.500' }}> */}
                                        
                                    {/* </Box> */}
                                </Box>
                            )}
                        </HStack>
                        
                    </Box>

                    <Stack direction={{ base: "column", md: "row" }} justifyContent="space-between" spacing={{base: "7", md: "7"}} mt="25px"  alignItems="flex-start">
                        <Box flex="1" rounded="lg" p="10" bg="white">
                            <Heading fontWeight="800" size="md" textAlign="center">Choose # of Questions for Mock Exam</Heading>
                            <Heading my="10" textAlign="center" fontWeight="800" fontSize="54px">{state?.total}</Heading>
                            <Box  maxW="400px" mx="auto">
                                <Slider aria-label='slider-ex-1' value={state?.total || 25} onChange={(val) => handleInputChange('total', val)} max={200} min={25} colorScheme="accent" size="lg">
                                    <SliderTrack h="10px" rounded="full">
                                        <SliderFilledTrack />
                                    </SliderTrack>
                                    <SliderThumb bg="brand.500" w="24px" h="24px" />
                                </Slider>    
                                <Text mt="5" textAlign="center">
                                Choose between 25-200 questions to attempt {'\n'} Mock Exam within selected time frame
                                </Text>                
                            </Box>  
                        </Box>
                        <Box flex="1" rounded="lg" p="10" bg="white">
                            <Heading fontWeight="800" size="md" textAlign="center">Set Time for Mock Exam</Heading>
                            <Heading my="10" textAlign="center" fontWeight="800" fontSize="54px">{state?.duration}:00</Heading>
                            <Box  maxW="400px" mx="auto">
                                <Slider aria-label='slider-ex-1' value={state?.duration || 10} onChange={(val) => handleInputChange('duration', val)} max={25} min={1} colorScheme="accent" size="lg">
                                    <SliderTrack h="10px" rounded="full">
                                        <SliderFilledTrack />
                                    </SliderTrack>
                                    <SliderThumb bg="brand.500" w="24px" h="24px" />
                                </Slider>    
                                <Text mt="5" textAlign="center">
                                    Set time to attempt mock Exam {'\n'}
                                    i.e. between 1 - 25 minutes
                                </Text>                
                            </Box>    
                        </Box>
                        
                    </Stack>

                    <Box rounded="lg" my="10" p="10" bg="white">
                        <Stack direction={{ base: "column", md: "row" }} alignItems="center" justifyContent="space-between">
                            <Box textAlign="center">
                                <Heading mb="2" size="sm">Duration</Heading>
                                <Text>{state?.duration}:00 min</Text>
                            </Box>
                            <Box textAlign="center">
                                <Heading mb="2" size="sm">Number of Questions</Heading>
                                <Text>{state?.total}</Text>
                            </Box>
                            <Box textAlign="center">
                                <Heading mb="2" size="sm">Passing Marks</Heading>
                                <Text>65%</Text>
                            </Box>
                            <Box textAlign="center">
                                <Button  rounded="lg" onClick={createQuiz} isLoading={submitting} colorScheme="accent">Create Mock Exam</Button>
                            </Box>
                        </Stack>
                    </Box>
                </Box>
            }            
            
        </>
    )
}
import React, { useEffect, useState } from "react"
import { Box, Spinner, useBoolean, Heading, HStack, Text,
    Accordion, AccordionItem, AccordionButton,
    AccordionPanel, AccordionIcon, Button,
} from "@chakra-ui/react"
import { useApp, usePrevious } from '../../../hooks'
import { useDispatch, useSelector } from "react-redux"
import { useParams, useNavigate } from "react-router-dom"
import { subjectAction } from "../../../redux/actions"
import { Helmet } from "react-helmet-async"
import ReactPlayer from 'react-player'
import { Icon, Modal } from '../../../uikit'
import { Link } from "react-router-dom"

export default function SubjectDetails () {
    
    const { subject_id } = useParams()
    
    const  navigate  = useNavigate()
    const prev_subject = usePrevious(subject_id)
    const [ details, setDetails ] = useState(null)
    const { setDashHeading, profile, user} = useApp()
    const dispatch = useDispatch()
    const [count,setCount]=useState(0);
    const [ loading, setLoading] = useBoolean(false)
    const { subject } = useSelector(state => state?.subjects)
    console.log(localStorage.getItem('lastitem'))

    useEffect(() => {
      
        if(subject_id && subject_id !== prev_subject){
            setLoading.on()
            dispatch(subjectAction.details(subject_id))
            .then((res) =>{
                setLoading.off()
            })
            .catch((err) =>{
                setLoading.off()
            })  
        }  
    }, [subject_id])
    
    


    useEffect(() => {
      
        setDashHeading('Subjects')
      return () => {
        setDashHeading('Dashboard')
      };
    }, [])


    const isWatched = (id) => {
        return profile?.progress?.find((x) => x.content_id === id)
    }
    
    return(
        <>
            <Helmet>
                <title>Subjects - PCTB Online Academy</title>
                <meta name="description" content="PCTB Online Academy is FREE online learning platform for HSSC and SSC Student covering of Subjects, Assessment and other learning material to help our young genration for tapping in to the future." />
            </Helmet>
            {
                loading ?
                    <Box d="flex" h="100%" alignItems="center" justifyContent="center">
                        <Spinner />
                    </Box>
                :
                <Box px={{base: "1em", md:"2em"}} py="3">
                    <Heading fontFamily="Poppins" size="md">{`${subject?.grade?.title}`} {`${subject?.grade?.label || ''}`} {`${subject?.title}`}</Heading>

                    <Button mt="5" colorScheme="black" leftIcon={<Icon name="long-arrow-alt-left-solid" color="currentcolor" />} onClick={() => navigate('/account/subjects')} variant="link">Back</Button>
                    {
                        subject?.chapters?.length > 0 ?
                        <Accordion mb="10" defaultIndex={[Number(localStorage.getItem('lastitem'))]} allowMultiple>
                        {                        
                                subject?.chapters?.map((chapter, index) =>
                                  
                                    <AccordionItem key={index} border="0" my="8" rounded="lg" shadow="custom" onClick={console.log(index)} bg="white" >
                                        <AccordionButton flexDirection={{ base: "column", md: "row" }} border="0" px={{base: "4", md: "10"}} py="4" _hover={{ bg: 'transparent' }} _focus={{ shadow: 'none' }} borderBottom="1px solid" borderColor="gray.200" alignItems={{ base: "normal", md: "space-between" }}>
                                            <Box flex='1' textAlign="left" pb="5px">
                                                <Text fontSize="15px" mt="4" fontWeight="500" color="accent.500">{chapter?.label}</Text>
                                                <Heading fontFamily="Poppins" fontSize={{base: '18px', md: '24px', lg: "30px"}}>{chapter.title} </Heading>
                                            </Box>
                                            <HStack spacing="5">
                                                {/* <Button isDisabled colorScheme="accent">Chapter Quiz</Button> */}
                                                {
                                                    chapter?.quiz &&
                                                    <Box as={Link} to={'/account/subjects/quiz/'+chapter?.quiz?.id} state={chapter?.title} bg="accent.500" color="white" py="2" rounded="md" px="5" >Chapter Quiz</Box>
                                                }
                                                
                                                <Text display={{ base: "none", md: "block" }} fontSize="12px" color="accent.500" onClick ={()=>localStorage.setItem('lastitem',index)} >Click To Expand</Text>
                                                <AccordionIcon color="accent.500" />
                                            </HStack>
                                        </AccordionButton>
                                        <AccordionPanel  pb={4} px={{base: 4, md:"10"}}>
                                            <Text>{chapter.details}</Text> 
                                            <Heading my="5" fontSize="20px">Topics</Heading>
                                            <Box>
                                                {
                                                chapter?.topics?.map((topic, idx) => 
                                                    <Box flex={1} key={idx} mb="5" p={{base:"5px", md:"15px"}} borderBottom="1px solid" borderColor="gray.200">
                                                        <HStack alignItems="flex-start" spacing={4}>
                                                            {/* <Box w="32px" h="32px" rounded="full" bg="brand.500" d="flex" alignItems="center" justifyContent="center"> */}
                                                                
                                                            {/* </Box> */}
                                                            <Box>
                                                                <Box as="span" display="inline-block" bg="brand.500" px={2} py={0.5} rounded="md">
                                                                    <Text textTransform="uppercase" color="white" fontSize={10} fontWeight="bold"> Lesson {idx + 1}</Text>
                                                                </Box>
                                                                <Text fontSize="18px" fontFamily="Poppins" fontWeight="400">{topic.title}</Text>                                                        
                                                            </Box>
                                                        </HStack>
                                                        
                                                        <HStack my="5" flexDir={{ base: 'column', md: 'row' }} alignItems="flex-start" flexWrap="wrap" spacing="0">
                                                        {
                                                            topic.contents.map((content, c) => 
                                                            <Box w={{base: '100%', lg:"50%"}} key={c} cursor="pointer" onClick={() => navigate('/account/subjects/topic/'+content?.id)}>
                                                                <Box  py="10px" px={{base: "10px", md:"20px"}}>
                                                                    <HStack alignItems="flex-start" flex={1} flexDir={{ base: 'column', lg: 'row' }} position="relative" spacing={{base: 0, lg:"6"}} bg={isWatched(content?.id) ? "brand.50" : "gray.100"} p="20px" rounded="md">
                                                                        {
                                                                            content?.type === 'VIDEO' &&
                                                                            <Box minW={{base: '100%', lg:"30%"}} rounded="lg" overflow="hidden">
                                                                                <Box className='player-wrapper'>  
                                                                                    <ReactPlayer className='react-player' width="100%" height='100%' url={'https://www.youtube.com/watch?v='+content?.data} light={true} />                                                            
                                                                                </Box>
                                                                            </Box>
                                                                        }
                                                                        <Box>
                                                                            
                                                                            {
                                                                                <Box>
                                                                                    <Text my={2} fontWeight="bold">{content.title}</Text>
                                                                                    <Text fontSize="14px">{content?.description}</Text>
                                                                                    
                                                                                </Box>
                                                                            }      
                                                                                                                    
                                                                        </Box>
                                                                        {
                                                                        isWatched(content?.id) &&
                                                                        <Box position="absolute" d="flex" right="10px" top="10px" bg="transparent" rounded="full" w="32px" h="32px" alignItems="center" justifyContent="center"  >
                                                                            <Icon name="check-circle" color="green.500" size="16px" />
                                                                        </Box>
                                                                        }
                                                                    </HStack>
                                                                </Box>
                                                            </Box>
                                                        )}
                                                        </HStack>
                                                        {
                                                            topic?.results &&
                                                            <Box pl={{base: 0, md: "1em"}}>
                                                                <Box rounded="lg" my="5" p="4" bg="accent.50" border="2px solid" borderColor="accent.500">
                                                                    <HStack alignItems="center" justifyContent="space-between">
                                                                        <Box textAlign="center">
                                                                            <Heading mb="2" size="sm">Total Questions</Heading>
                                                                            <Text>{topic?.results?.results[0]?.result?.total_questions}</Text>
                                                                        </Box>
                                                                        <Box textAlign="center">
                                                                            <Heading mb="2" size="sm">Correct Answers</Heading>
                                                                            <Text>{topic?.results?.results[0]?.result?.correct}</Text>
                                                                        </Box>
                                                                        <Box textAlign="center">
                                                                            <Heading mb="2" size="sm">Score</Heading>
                                                                            <Text>{parseFloat(topic?.results?.results[0]?.result?.percentage).toFixed(2)}%</Text>
                                                                        </Box>
                                                                    </HStack>
                                                                </Box>
                                                            </Box>
                                                        }
                                                        {
                                                            topic?.quiz && 
                                                            <HStack pl="1em" mb={{ base: "20px", md: "10px" }}>
                                                                <Button onClick={() => navigate('/account/subjects/quiz/'+topic?.quiz?.id, {state: topic?.title})} size="md">{topic?.results ? 'Re-Take Quiz' : "Topic Quiz"}</Button>
                                                                {
                                                                    topic?.results &&
                                                                    <Button onClick={() => setDetails(topic?.results?.results[0])} size="md" colorScheme="gray" >View Details...</Button>
                                                                }
                                                            </HStack>
                                                        }
                                                        
                                                    </Box>   
                                                )}

                                            </Box>
                                        </AccordionPanel>
                                    </AccordionItem>
                                )
                            }
                        </Accordion>
                        :
                        <Box my="5" bg="yellow.50" p="10" rounded="lg">
                            <Heading size="sm">Coming Soon</Heading>
                        </Box>
                    }

                    <Modal 
                        open={details !== null}
                        onClose={() => setDetails(null)}
                        title="Result Details"
                        size="3xl"
                        >
                        <Box bg="white" rounded="lg">
                        {
                            details?.details?.map((requ, b) =>                                         
                                <Box key={b} p="5" borderBottom="1px solid" borderColor="gray.200">
                                    <Heading size="sm">Question No. {parseInt(b) + 1}</Heading>
                                    <Box my="4" fontWeight="bold">{requ?.question}</Box>
                                    <Box>
                                    {
                                        requ?.options?.map((anu, n) => {
                                            if(requ?.correct_answer === anu?.id ){
                                                return(
                                                    <HStack p="2" rounded="md" key={n} my="2" bg='green.100'>
                                                        <Text fontWeight="bold">{parseInt(n+1)}:</Text>
                                                        <Text>{anu?.answer}</Text>
                                                    </HStack>
                                                )
                                            }else if(requ?.answer === anu?.id && !requ?.correct ){
                                                return(
                                                    <HStack p="2" rounded="md" key={n} my="2" bg='red.100'>
                                                        <Text fontWeight="bold">{parseInt(n+1)}:</Text>
                                                        <Text>{anu?.answer}</Text>
                                                    </HStack>
                                                )
                                            }else{
                                                return(
                                                    <HStack p="2" rounded="md" key={n} my="2" bg='transparent'>
                                                        <Text fontWeight="bold">{parseInt(n+1)}:</Text>
                                                        <Text>{anu?.answer}</Text>
                                                    </HStack>
                                                )
                                            }
                                            
                                        })
                                    }
                                    </Box>                                                
                                </Box>
                                                                            
                            )
                        }
                        </Box>
                    </Modal>
                </Box>
            }            
            
        </>
    )
}
import React, { useEffect, useState } from "react"
import { Box, Spinner, useBoolean, Heading, HStack } from "@chakra-ui/react"
import { useApp } from '../../../hooks'
import { useDispatch, useSelector } from "react-redux"
import { gradeAction } from "../../../redux/actions"
import { Helmet } from "react-helmet-async"
import { SubjectCard } from "./Components"

export default function MockExams () {
    
    const { setDashHeading, profile} = useApp()
    const dispatch = useDispatch()
    
    const [ loading, setLoading] = useBoolean(false)
    const { with_subjects} = useSelector(state => state?.grades)
    const [ mySubjects, setMySubjects ] = useState([]);

    useEffect(() => {
        setLoading.on()
        dispatch(gradeAction.with_subjects())
        .then((res) =>{
            setLoading.off()
        })
        .catch((err) =>{
            setLoading.off()
        })    
    }, [])

    useEffect(() => {
        if(profile?.grade_id && with_subjects){
            let subjects = with_subjects.find((x) => x?.id === profile?.grade_id)
            setMySubjects(subjects)
        }
    }, [profile, with_subjects])


    useEffect(() => {
        setDashHeading('Mock Exams')
      return () => {
        setDashHeading('Dashboard')
      };
    }, [])


    return(
        <>
            <Helmet>
                <title>Subjects - PCTB Online Academy</title>
                <meta name="description" content="PCTB Online Academy is FREE online learning platform for HSSC and SSC Student covering of Subjects, Assessment and other learning material to help our young genration for tapping in to the future." />
            </Helmet>
            {
                loading ?
                    <Box d="flex" h="100%" alignItems="center" justifyContent="center">
                        <Spinner />
                    </Box>
                :
                <Box px="2em" py="3">
                    <Heading fontFamily="Poppins" size="md" fontWeight="bold">Class {mySubjects?.title} {mySubjects?.label} - Subjects</Heading>
                    <HStack flexWrap="wrap" spacing="0" mx="-15px">
                    {
                        mySubjects?.subjects?.map((subject, s) => 
                        <SubjectCard grade_slug={mySubjects?.url_title} w={{ base: '100%', md: '50%', lg: '33.33%' }} subject={subject} key={s} />
                        )
                    }
                    </HStack>

                </Box>
            }            
            
        </>
    )
}
import { ParentConstants as Constants } from '../types'

const initialState = {
    children: [],   
    progress: null
    
};

export function parentReducer(state = initialState, action) {
    switch (action.type) {  
        
        case Constants.GET:
            return {
                ...state,
                children: action.payload.children,
            };

        case Constants.PROGRESS:
            return {
                ...state,
                progress: action.payload.progress,
            };

        case Constants.ADD:
            return {
                ...state,
                children: [...state?.children, action.payload.child],
            };

        
            
        default:
            return {
                ...state
            }
    }
}
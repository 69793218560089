import React, { useEffect } from "react";
import { Box, Heading, HStack, useBoolean, Text, Image, Button, UnorderedList, ListItem} from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'
import { Container } from "../../uikit";
import { useParams } from "react-router-dom";


export default function About() {
    const { slug } = useParams();
    
    return (
        <>
            <Helmet>
                <title> About - PCTB Online Academy</title>
                <meta name="description" content="PCTB Online Academy is FREE online learning platform for HSSC and SSC Student covering of Subjects, Assessment and other learning material to help our young genration for tapping in to the future." />
            </Helmet>
            <Box  pt="100px" bgBlendMode="overlay" bgImage="/assets/pakistan-banner.png" bgRepeat="no-repeat" bgSize="cover" bgPosition="center center">
                <Box py="5em" >
                    <Container>
                        <HStack flexDir={{ base: 'column', lg: 'row' }} alignItems="flex-end">
                            <Box flex="1">
                                <Text variant="heading-title">About Us</Text>
                                <Heading color="white">PCTB Online Academy is a joint initiative of {'\n'}
                                    Govt. of Punjab, School Education Department {'\n'}
                                    and Punjab Curriculum & Textbook Board</Heading>
                            </Box>
                            <Box w="40%" textAlign="right">
                            
                            </Box>
                        </HStack>
                    </Container>
                </Box>
            </Box>
            <Box py="4em">
                <Container>
                    <HStack flexDir={{ base: 'column', lg: 'row' }} alignItems="stretch" spacing={{base: 0, lg:"30px"}}>
                        <Box mb={{ base: 5, lg: 0 }} p="25px" flex="1" bgImage="/assets/box-bg.svg" bgSize="cover" bgRepeat="no-repeat" rounded="md">
                            <Box bg="white" px="20" py="25px" shadow="lg" textAlign="center" rounded="lg">
                                <Heading mb="15px" size="md">Mission</Heading>
                                <Text>Providing free online teaching through Video Lectures, Live Sessions, Interactive Quizzes and Mock Test etc. initially for Science Subjects of Matriculation and Intermediate Students.</Text>
                            </Box>
                        </Box>
                        <Box p="25px" flex="1" bgImage="/assets/box-bg.svg" bgSize="cover" bgRepeat="no-repeat" rounded="md">
                            <Box h="100%" bg="white" px="20" py="25px" shadow="lg" textAlign="center" rounded="lg">
                                <Heading mb="15px" size="md">Vision</Heading>
                                <Text>Making Quality Education Easier, Free and Convenient for everyone.</Text>
                            </Box>
                        </Box>
                    </HStack>
                </Container>
            </Box>
            <Box py={{base: "1em", lg:"4em"}}>
                <Container>
                    <Box position="relative">
                        <Box w={{base: '100%', lg: "90%"}} bg="brand.500" p="25px" rounded="lg">
                            <Box w={{base: "90%", lg: "60%"}}>
                            <Heading my="10px" color="white" size="md">Objectives</Heading>
                            <UnorderedList>
                                <ListItem color="white" mb="20px">
                                    Equal access of quality education to all students regardless of their geographic, demographic, and financial backgrounds.
                                </ListItem>
                                <ListItem color="white" mb="20px">
                                    Self-paced learning enabling students to watch video lectures, do practice/graded quiz/assignments and undertake mock board exam at their own convenient time & place.
                                </ListItem>
                                <ListItem color="white" mb="20px">
                                    Easy access to all students, just one click away to all educational material for SSC and HSSC. 
                                </ListItem>
                                <ListItem color="white" mb="20px">
                                    Eliminating the disparities between students based on gender, ethnic minorities, or financial capability.
                                </ListItem>
                                <ListItem color="white" mb="20px">
                                    Availability of comprehensive data bank of questions to practice for their final board exams. 
                                </ListItem>
                                <ListItem color="white" mb="20px">
                                    To use modern e-learning teaching and assessment methodologies encouraging students to trust the PCTB Online Academy thus discouraging private tuitions and academies.
                                </ListItem>
                            </UnorderedList>
                            </Box>
                        </Box>
                        <Box d={{ base: 'none', lg: 'block' }} position="absolute" right="-10%" bottom="0">
                            <Image w="85%" left="-20px" position="relative" src="/assets/home-user.png" />
                        </Box>
                    </Box>
                </Container>
            </Box>
        </>
    )
}
import { GradeConstants as Constants } from '../types'
import { gradeApi as api } from '../../api'

export const gradeAction = {
    get,
    with_subjects,
    with_subjects2,
    with_subjects_minimal,
    details,
    clear,
};

function get() {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._get()
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.GET, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function with_subjects() {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._with_subjects()
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.WITHSUBJECTS, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function with_subjects2() {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._with_subjects2()
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.WITHSUBJECTS2, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function with_subjects_minimal() {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._with_subjects_minimal()
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.WITHSUBJECTSMINIMAL, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function details(slug) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._details(slug)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.DETAILS, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function clear() {
    return dispatch => {   
        return new Promise((resolve, reject) => {     
            dispatch(success());
            resolve()
        })   
    }
    function success() { return { type: Constants.CLEAR } }
}
import { VClassConstants as Constants } from '../types'

const initialState = {
    classes: [],   
    virtual_class: null,
    quiz: null,
    total: 0,
    sessions: [] 
    
};

export function vClassReducer(state = initialState, action) {
    switch (action.type) {  
        
        case Constants.GET:
            return {
                ...state,
                classes: action?.payload?.data?.virtual_classes,
                total: action?.payload?.data?.count
            };

        case Constants.LIVE:
            return {
                ...state,
                sessions: action.payload.sessions,
            };

        case Constants.ADD:
            return {
                ...state,
                classes: [...state?.classes, action.payload.virtual_class],
            };

        case Constants.DETAILS:
            return {
                ...state,
                virtual_class: action.payload.virtual_class,
            };

        case Constants.QUIZ:
            return {
                ...state,
                quiz: action.payload.quiz,                
            };
            
        default:
            return {
                ...state
            }
    }
}
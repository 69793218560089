import React, { Fragment, useEffect, useState } from 'react'
import { Box, Heading, HStack, Image, Input, Text, useBoolean, Stack, Code, Button, useToast, ButtonGroup } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { vClassAction } from '../../../redux/actions'
import { useApp } from '../../../hooks'
import { useNavigate } from 'react-router-dom'
import { Pagination } from '../../../uikit'
import dayjs from 'dayjs';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const VirtualClasses = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const toast = useToast()
    const [ mount, setMount ] = useBoolean(false)
    const { setDashHeading, user, profile } = useApp()    
    const [ loading, setLoading ] = useBoolean(false)
    const [ q, setQ] = useState('')
    const { classes } = useSelector(state => state?.vclass)
    const [ joining, setJoining ] = useState(null)
    const [ totalRows, setTotalRows] = useState(0)
    const [ query, setQuery ] = useState({keywords: '', type: 'PUBLIC'})


    const fetchData = React.useCallback((filters, pageOffset=0) => {    
        setLoading.on()  
        dispatch(vClassAction.get(filters, pageOffset))
                .then((res) => {              
                    setTotalRows(res?.data?.count)
                    setQuery(filters)
                    setLoading.off()
                })
                .catch((err) => {
                    setLoading.off()
                    console.log("error", err)
                })
    }, [dispatch, setLoading])

    useEffect(() => {
        if(!mount){
            setMount.on()
            fetchData(query)               
        }
    }, [mount, setMount, dispatch, fetchData, query])


    const handlePagination = (page, pageSize) =>{ 
        let new_offset = pageSize * (page - 1);
        fetchData(query, new_offset)        
    }



    useEffect(() => {
        setDashHeading("Virtual Classes")
        return () => {
            setDashHeading("Dashboard")
          };
    }, [])

    const handleQuery = (name, value) => {
        setQuery({
            ...query,
            [name]: value
        })
        
    }

    useEffect(() => {                
        fetchData(query, 0)                
    }, [query])

    
    const checkJoined = (id) => {
        let clas = classes?.find((x) => x?.id === parseInt(id));        
        if(clas){
            let joined = clas?.students.find(y => y?.student_id === user?.id)            
            return joined ? true : false
        }        
        return false;
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if(q?.length > 2){
            setQuery({
                ...query,
                keywords: q
            })
        }
    }
    const joinClass = (id) => {
        setJoining(id)
        dispatch(vClassAction.join(id))
            .then((res) => {
                setJoining(null)
                toast({
                    title: 'Class Joined',
                    description: res?.message || 'Class joined successfully!',
                    status: 'success',
                    position: "top"
                })                
            })
            .catch((err) => setJoining(null))
    }
    
    return (
        <Box px="2em" py="5">     
            <HStack mb={3} justifyContent="space-between"> 
                <ButtonGroup isAttached variant="outline">
                    <Button onClick={()=> handleQuery('type', 'PUBLIC')} variant={query?.type === 'PUBLIC' ? "solid" : "outline"}>Public</Button>    
                    <Button onClick={()=> handleQuery('type', 'PRIVATE')} variant={query?.type === 'PRIVATE' ? "solid" : "outline"}>Private</Button>
                </ButtonGroup>

                <Box>
                    <form onSubmit={handleSubmit}>
                        <Input size="sm" w="260px" variant="outline" rounded="md" placeholder="Enter keywords" onChange={(e) => setQ( e.target.value)}  />
                    </form>
                </Box>
            </HStack>          
            {
                (!loading && classes?.length === 0) &&
                    <Box bg="yellow.50" p="5">                
                        <Heading size="md">No Virtual Class Available</Heading>
                    </Box>
            }                
            
            {
                (classes?.length > 0) &&                
                classes?.map((cls, c) =>
                <>
                    {
                        cls?.type === 'PUBLIC' &&
                        <Box key={c} bg="white" _hover={{ bg: 'gray.100' }}  rounded="lg" p={10} mb="3" cursor="pointer" {...(checkJoined(cls?.id)) && { onClick: () => navigate('/account/virtual-classes/'+cls?.id) }}>
                            <Stack direction={{ base: "column", md: "row" }} justifyContent="space-between" alignItems="flex-start">
                                <Box flex={1}>
                                    <Text fontSize="12px">{dayjs(cls?.created_at).format('DD-MMMM-YY')}</Text>
                                    <Heading mb="2" size="lg">{cls?.title}</Heading>
                                    <HStack alignItems="center">
                                        <Text>Class: {cls?.grade?.title}</Text>
                                        <Text>Subject: {cls?.subject}</Text>                                    
                                    </HStack>
                                        <HStack mt={3} onClick={(e) => e.stopPropagation()}>
                                        
                                            <Code fontFamily="body" colorScheme="blue">
                                                {process.env.REACT_APP_BASE_URL+'join-class/'+cls?.id}
                                            </Code>
                                        
                                        <CopyToClipboard text={process.env.REACT_APP_BASE_URL+'join-class/'+cls?.id}>
                                            <Button  size="xs" variant="outline">Copy</Button>
                                        </CopyToClipboard>
                                    </HStack>
                                </Box>
                                <Box>
                                    <HStack alignItems="center">
                                        <Box bg="#4CBC9A" rounded="lg" color="white" px="4" py="2">{cls?.students?.length} / {cls?.total_students} Students</Box>
                                        {
                                            !checkJoined(cls?.id) ?
                                            <Button isLoading={joining === cls?.id} onClick={() => joinClass(cls?.id)} variant="outline">Join Class</Button>
                                            :
                                            <Button isDisabled variant="outline">Joined</Button>
                                        }                                    
                                    </HStack>
                                </Box>
                            </Stack>
                        </Box>  
                    }

                    {
                        ( cls?.type === 'PRIVATE' && checkJoined(cls?.id) ) &&
                        <Box key={c} bg="white" _hover={{ bg: 'gray.100' }}  rounded="lg" p={10} mb="3" cursor="pointer" {...(checkJoined(cls?.id)) && { onClick: () => navigate('/account/virtual-classes/'+cls?.id) }}>
                            <HStack justifyContent="space-between" alignItems="flex-start">
                                <Box flex={1}>
                                    <Text fontSize="12px">{dayjs(cls?.created_at).format('DD-MMMM-YY')}</Text>
                                    <Heading mb="2" size="lg">{cls?.title}</Heading>
                                    <HStack alignItems="center">
                                        <Text>Class: {cls?.grade?.title}</Text>
                                        <Text>Subject: {cls?.subject}</Text>                                    
                                    </HStack>
                                </Box>
                                <Box>
                                    <HStack alignItems="center">
                                        <Box bg="#4CBC9A" rounded="lg" color="white" px="4" py="2">{cls?.students?.length} / {cls?.total_students} Students</Box>
                                        {
                                            !checkJoined(cls?.id) ?
                                            <Button isLoading={joining === cls?.id} onClick={() => joinClass(cls?.id)} variant="outline">Join Class</Button>
                                            :
                                            <Button isDisabled variant="outline">Joined</Button>
                                        }                                    
                                    </HStack>
                                </Box>
                            </HStack>
                        </Box>  
                    }
                </>                 
                      
                )
                
            }   

            <Box my="5">
                <Pagination pageSize={15} onChange={handlePagination}  total={totalRows} />
            </Box>         
        </Box>
    )
}

export default VirtualClasses;
import React from "react";
import {
    Popover as ChakraPopover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverArrow,
  } from '@chakra-ui/react'

  export const Popover = React.forwardRef(({trigger, position, type="click", gutter,  showArrow, header, children, ...rest}, ref) => {
      return(
            <ChakraPopover  trigger={type} arrowSize="10" gutter={gutter || 28} placement={position || 'bottom-end'} {...rest}>
                <PopoverTrigger>
                    {trigger}
                </PopoverTrigger>
                <PopoverContent  w="auto"  _focus={{ shadow: 'none' }} rounded="xl">
                    { showArrow && <PopoverArrow /> }
                    { header && <PopoverHeader>{header}</PopoverHeader>}
                    <PopoverBody>
                        {children}
                    </PopoverBody>
                </PopoverContent>
          </ChakraPopover>
      )
  })
import React from "react";
import {Box, Spinner} from '@chakra-ui/react'

export const Loader = (props) => {
    return(
        <Box 
            w="100%"
            d="flex" 
            position="fixed"
            left="0"
            top="0"
            bg="rgba(0,0,0,0.25)"
            minH={'100vh'} 
            zIndex="modal"
            alignItems="center" 
            justifyContent="center" 
        >
            <Spinner size="lg" />
        </Box>
    )
}

import { AuthConstants as Constants } from '../types'
const accessToken = process.env.REACT_APP_ACCESS_TOKEN
let token = localStorage.getItem(accessToken)

const initialState = {
    user: {},
    loggedIn: false,
    token: token,
    profile: null
};

export function authReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.LOGIN:
            return {
                ...state,
                loggedIn: true,
                token: action.payload.token,
                user: action.payload.user,
            };

        case Constants.AUTH:
            return {
                ...state,
                user: action.payload.user,
            };

        case Constants.PROFILE:
            return {
                ...state,
                profile: action.payload.profile,
            };

        case Constants.UPDATE_USER:
            return {
                ...state,
                profile: action.payload.profile,
                user: action.payload.user,
            };

            
        case Constants.REFRESH_TOKEN:
            return {
                ...state,
                token: action.payload?.token || null
            }

        case Constants.REFRESH_TOKEN_FAILED:
            return {
                ...state,
                user: null,
                token: null
            }   

        
       
        case Constants.LOGOUT:
            return{
                ...state,
                loggedIn: false,
                token: null,
                user: {},
            };
                   
        case Constants.FAILED:
            return{
                ...state
            }    
            
        case Constants.MESSAGES:
            return{
                ...state,
                messages: action?.payload?.messages,
                count: action?.payload?.count

            }

        case Constants.UPDATE_MESSAGE:
            return{
                ...state,
                messages: state?.messages?.filter(x => parseInt(x?.id) !== parseInt(action?.payload?.id))
            }

        case Constants.CLEAR:
            return{
                ...state,
                loggedIn: false,
                token: null,
                user: {},
            };
        
        default:
            return {
                ...state
            }
    }
}
import React, { useEffect} from "react"
import { Box, Text, HStack, Image, Heading, Avatar, Button, Spinner, useBoolean, VStack } from "@chakra-ui/react"
import { Helmet } from "react-helmet-async"
import { HomeSlider, StudentSlider, VideoSlider } from "./Components"
import { Container } from "../../uikit"
import { useDispatch, useSelector } from "react-redux"
import { gradeAction } from '../../redux/actions'
import { useApp } from "../../hooks"
import Marquee from 'react-double-marquee'
import { ClassCard } from "../Common"
import { Link } from "react-router-dom"

export default function Home(){

    
    const dispatch = useDispatch()
    const { user } = useApp()
    const [ loading, setLoading ] = useBoolean(false)
    const { with_subjects_minimal } = useSelector(state => state?.grades)

    useEffect(() => {
        setLoading.on()
        dispatch(gradeAction.with_subjects_minimal())
            .then((res) => setLoading.off())
            .catch((err) => setLoading.off())
    }, [])


    
    return(
        <>
            <Helmet>
                <title>Welcome to PCTB Online Academy</title>
                <meta name="description" content="PCTB Online Academy is FREE online learning platform for HSSC and SSC Student covering of Subjects, Assessment and other learning material to help our young genration for tapping in to the future." />
            </Helmet>
            <Box>
                <Box bg="brand.500">
                    <HomeSlider />
                </Box>
                <Box bg="gray.100" py="3">
                    <Container>
                        <HStack spacing="5">
                            {/* <Icon fontSize="28px" name="bullhorn-solid" color="brand.600" /> */}
                            <Image src="/assets/conversation.svg" maxH="28px" w="auto" alt="Updates" />
                            <Box position="relative" w="100%" overflow="hidden" whiteSpace="nowrap">
                                <Marquee direction="left">
                                    <Text as="span" mx="5">• 4000+ video lectures of 9th and 10th grades (English and Urdu Medium) are available on Web Portal and Mobile Apps  </Text>
                                    <Text as="span" mx="5">• Android and iOS Mobile App of PCTB Online Academy is now available on Google Play Store and Apple App Store </Text>
                                    <Text as="span" mx="5">• 3000+ video lectures of 11th and 12th grades.</Text>
                                </Marquee>
                            </Box>
                        </HStack>
                    </Container>
                </Box>

                <Box py="3em">
                    <Container>
                        <Box textAlign="center" mb="10">
                            <Text variant="heading-title">Say Goodbye to Evening Academies</Text>
                            <Heading my="3" variant='insaf-heading' size="lg">Instantly Access Video Lectures & Mock Examinations </Heading>
                            <Text variant="loud">Content available for science subjects of IX, X, XI and XII classes </Text>
                        </Box>
                        {
                            loading &&
                            <Box p="10" textAlign="center">
                                <Spinner />
                            </Box>
                        }
                        <HStack my="5" spacing="0" alignItems="flex-top" flexWrap="wrap" mx="-15px">
                            {[...with_subjects_minimal].reverse().map((grade, idx) => (
                                <ClassCard
                                    w={{ base: '50%', md: '50%', lg: '33.33%' }}
                                    
                                    key={idx}
                                    subtitle={grade?.label || "..."}
                                    heading={grade.title}                                    
                                    to={'/class/'+grade?.id} />
                            ))}

                        </HStack>
                    </Container>
                </Box>

                <Box>
                    <VideoSlider />
                </Box>

                <Box py="3em">
                    <Container>
                        <Box textAlign="center" mb="10">
                            <Text variant="heading-title">Salient Features Of PCTB Online Academy</Text>
                            <Heading variant='insaf-heading' my="3" size="lg">PCTB Online Academy - Learn, Practice & Discuss</Heading>
                            <Text variant="loud">A unique initiative of Govt. of Punjab, School Education Department and Punjab Curriculum & Textbook Board </Text>
                        </Box>
                        <HStack flexDir={{ base:'column', lg: 'row' }} alignItems="center" justifyContent="space-between">
                            <Box w={{base: '100%', lg: "48%"}}>
                                <Image w="100%" src="/assets/home-students.png" alt="" />
                            </Box>
                            <Box w={{base: '100%', lg: "48%"}}>
                                <HStack my="10" alignItems="center" spacing={5}>
                                    <Box bg="gray.100" rounded="lg" p="5">
                                        <Image src="/assets/mortar-icon.svg" alt="Mortar" />
                                    </Box>
                                    <Box>
                                        <Text fontSize={{base: '15px', md: '18px', lg: "20px"}} fontWeight="600" color="gray.700">Study at Your Own Pace</Text>
                                        <Text>You can now study at your own pace and preferred time</Text>
                                    </Box>
                                </HStack>
                                <HStack my="10" alignItems="center" spacing={5}>
                                    <Box bg="gray.100" rounded="lg" p="5">
                                        <Image src="/assets/trophy-icon.svg" alt="Mortar" />
                                    </Box>
                                    <Box>
                                        <Text fontSize={{base: '15px', md: '18px', lg: "20px"}} fontWeight="600" color="gray.700">Earn Achievement Badges</Text>
                                        <Text>Celebrate your success by earning badges as you progress and take Mock Examinations</Text>
                                    </Box>
                                </HStack>
                                <HStack my="10" alignItems="center" spacing={5}>
                                    <Box bg="gray.100" rounded="lg" p="5">
                                        <Image src="/assets/badge-icon.svg" alt="Mortar" />
                                    </Box>
                                    <Box>
                                        <Text fontSize={{base: '15px', md: '18px', lg: "20px"}} fontWeight="600" color="gray.700">Share Progress with Teachers & Parents</Text>
                                        <Text>Receive achievement badges as you progress and share them with your friends and family members on social media</Text>
                                    </Box>
                                </HStack>
                                <HStack my="10" alignItems="center" spacing={5}>
                                    <Box bg="gray.100" rounded="lg" p="5">
                                        <Image src="/assets/star-icon.svg" alt="Mortar" />
                                    </Box>
                                    <Box>
                                        <Text fontSize={{base: '15px', md: '18px', lg: "20px"}} fontWeight="600" color="gray.700">Get Guidance on Forum</Text>
                                        <Text>Interact with students and teachers from different cities on the forum and seek guidance from them</Text>
                                    </Box>
                                </HStack>
                            </Box>
                        </HStack>
                    </Container>
                </Box>

                {/* <Box py="3em" bg="gray.50">
                    <Container>
                        <Box textAlign="center" mb="5">
                            <Text variant="heading-title">Top Performers in Mock Examinations</Text>
                            <Heading variant="insaf-heading" my="3" size="lg">Star Performers of PCTB Online Academy</Heading>
                            <Text variant="loud">Get yourself featured here by scoring high marks in mock examinations</Text>
                        </Box>
                        <StudentSlider />
                    </Container>
                </Box> */}

                <Box py="3em" bg="gray.50">
                    <Container>
                        <Box textAlign="center" mb="10">
                            <Text variant="heading-title">Quality Education for All</Text>
                            <Heading variant="insaf-heading" my="3" size="lg">Say No to Tuition Culture by using PCTB Online Academy</Heading>
                        </Box>
                        <HStack flexDir={{ base: 'column', lg: 'row' }} alignItems="center" justifyContent="space-between">
                            <Box w={{base: '100%', lg: "48%"}}>
                                <Image w="100%" src="/assets/insaf-academy-stats.png" alt="" />
                            </Box>
                            <Box w={{base: '100%', lg: "48%"}}>
                                <Text my="5">We have done our best to provide students with free access to high-quality video lectures and mock examination to support and enhance learning whilst eliminating the need to rely on costly tuition alternatives and evening academies.</Text>
                                <HStack my="5" alignItems="flex-start">
                                    <Image boxSize="24px" src="/assets/tick-icon.svg" alt="" />
                                    <Text fontWeight="500" color="gray.800">7000+ Video Lectures</Text>
                                </HStack>
                                <HStack my="5" alignItems="flex-start">
                                    <Image boxSize="24px" src="/assets/tick-icon.svg" alt="" />
                                    <Text fontWeight="500" color="gray.800">Web portal and Mobile Apps (Android & iOS)</Text>
                                </HStack>
                                <HStack my="5" alignItems="flex-start">
                                    <Image boxSize="24px" src="/assets/tick-icon.svg" alt="" />
                                    <Text fontWeight="500" color="gray.800">9th (EM), 10th (EM), 9th (UM), 10th (UM), 11th & 12th Classes</Text>
                                </HStack>
                                <HStack my="5" alignItems="flex-start">
                                    <Image boxSize="24px" src="/assets/tick-icon.svg" alt="" />
                                    <Text fontWeight="500" color="gray.800">Physics, Chemistry, Maths, Biology & Computer Science subjects</Text>
                                </HStack>

                                <HStack my="5" alignItems="flex-start">
                                    <Image boxSize="24px" src="/assets/tick-icon.svg" alt="" />
                                    <Text fontWeight="500" color="gray.800">MCAT/ECAT complete course lecture videos and FLP mock examination</Text>
                                </HStack>

                                
                            </Box>
                        </HStack>

                    </Container>
                </Box>
                
                <Box py="100" bgImage="/assets/waves-bg.svg" bgSize="cover" bgRepeat="no-repeat" bgPosition="center center">
                    <Container textAlign="center">
                        <Box w="80%" mx="auto">
                            <Heading color="white">Sign up to Get Instant & 100% Free Access to Video Lectures & Mock Examinations </Heading>
                            <Button as={Link} to={ user ? "/account" : "/register"} size="lg" mt={10} colorScheme="accent">Start Learning Today</Button>
                        </Box>
                    </Container>
                </Box>

            </Box>
        </>
    )
}
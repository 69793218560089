import { PaperConstants as Constants } from '../types'

const initialState = {
    papers: [],       
    paper: null,
    subjects: [],
    subject: null,
    lecture: null
    
};

export function paperReducer(state = initialState, action) {
    switch (action.type) {  
        
        case Constants.GET:
            return {
                ...state,
                papers: action.payload.papers,
                subjects: action?.payload?.subjects || []
            };

        

        case Constants.DETAILS:
            return {
                ...state,
                paper: action.payload.paper,
            };

        case Constants.SUBJECT:
            return {
                ...state,
                subject: action.payload.subject,
            };

        case Constants.LECTURE:
            return {
                ...state,
                lecture: action.payload.lecture,
            };

        case Constants.QUIZ:
            return {
                ...state,
                quiz: action.payload.quiz,                
            };
            
        default:
            return {
                ...state
            }
    }
}
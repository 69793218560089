import React, { createContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { authAction } from '../redux/actions';
import Cookies from 'js-cookie'

const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const initialState = {
    user: null,   
    loading: true,    
};

const AppContext = createContext({
    ...initialState,
    init: () => Promise.resolve(),
    login: () => Promise.resolve(),     
    logout: () => Promise.resolve(),
});



export const AppProvider = (props) => {
    
    const auth = useSelector(state => state.auth);
    const [state, setState] = useState({...initialState})
    const [ dashHeading, setDashHeading ] = useState('')
    const [ mount, setMount ] = useState(false)
    const dispatch = useDispatch()


    // useEffect(() => {
    //     if(auth && prevAuth !== auth){
    //         setState({
    //             ...state,
    //             ...auth
    //         })    
    //     }
    // }, [auth, state])

    

    useEffect(() => {
        const loader =  async () => {     
            try{                            
                dispatch(authAction.fetch_user())                    
                    .then((res) => {                        
                        return dispatch(authAction.profile())
                    })
                    .then(() => {
                        setState({...state,  loading: false})
                    })            
                    .catch((err) => {
                        localStorage.removeItem(accessToken)
                        Cookies.remove('insaf-auth-token', { domain: process.env.REACT_APP_BASE_DOMAIN })
                        setState({...state, user: null, loading: false})             
                    })                                
            }
            catch(err){
                localStorage.removeItem(accessToken)
                Cookies.remove('insaf-auth-token', { domain: process.env.REACT_APP_BASE_DOMAIN })
                setState({...state, user: null, loading: false})
            }
        }
        if(!mount){
            setMount(true)
            // loader()
            setTimeout(function() { loader() }, 1000);
        }
    }, [mount, state, dispatch])

    const login = (form_data) => {   
        let res = {};
        return new Promise((resolve, reject) => {
            dispatch(authAction.clear())
            .then(() => {
                return dispatch(authAction.login(form_data))
            })
            .then((resp) => {
                res = {...res, ...resp}
                return dispatch(authAction.profile())
            })            
            .then((response) => {
                res = {...res, ...response}
                resolve(res) 
            })
            .catch((error) => reject(error) )
        })

    }

    

    const logout = () => {
        return new Promise((resolve, reject) => {
        dispatch(authAction.logout())
            .then(() => {
                setState({...state, user:null, profile: null, plans:[]})
                resolve()
            })
            .catch((e) => {
                reject()
            })
        })

    }    
    
    return (
        <AppContext.Provider
            value={{
                ...state,
                ...auth,
                dashHeading,
                setDashHeading,
                login,
                logout,                
            }}>
            {props.children}
            
        </AppContext.Provider>
    );
}

export  {AppContext}
import React, { useEffect, useState } from "react";
import { Box, Heading, HStack, useBoolean, Text, Button, Spinner, Image } from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'
import { Container } from "../../uikit"
import { useSelector, useDispatch } from "react-redux";
import { paperAction } from "../../redux/actions";
import { PaperCard } from "./PaperCard";
import { useApp } from "../../hooks";
import { Link, useNavigate } from "react-router-dom";
import SubjectItem from "./SubjectItem";
import { MdcatCalculator, EcatCalculator, NustCalculator } from "./Calculators";

export default function Papers() {
    
    const { user, profile } = useApp()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [ calc, setCalc ] = useState(null)
    const [ loading, setLoading ] = useBoolean(false)
    const { papers, subjects } = useSelector(state => state?.papers)

    useEffect(() => {
        setLoading.on()
        dispatch(paperAction.get('MDCAT'))
            .then((res) => setLoading.off())
            .catch((err) => setLoading.off())
    }, [])

    
    console.log("Profile", profile)

    return (
        <>
            <Helmet>
                <title>MDCAT - PCTB Online Academy</title>
                <meta name="description" content="PCTB Online Academy is FREE online learning platform for HSSC and SSC Student covering of Subjects, Assessment and other learning material to help our young genration for tapping in to the future." />
            </Helmet>
            <Box  pt="100px" bgBlendMode="overlay" bgImage="/assets/waves-bg.svg" bgRepeat="no-repeat" bgSize="cover" bgPosition="center center">
                <Box py="2em">
                    <Container>
                        <HStack flexDir={{ base: 'column', lg: 'row' }} alignItems={{base: 'flex-start', lg: "flex-end"}}>
                            <Box flex={1} mb="3">
                                <Text color="accent.500" fontWeight="bold" fontSize="20px">MDCAT</Text>
                                <Heading mb={4} color="white">100% Free Access to Full Length Papers (MDCAT)</Heading>                                
                                <Text w="80%" fontSize={{md: "16px", lg:"20px"}} color="rgba(255,255,255,0.75)">Access a comprehensive inventory of preparatory content for your MDCAT from your PC, laptop or cell phone</Text>
                                
                            </Box>
                            {
                                (user && profile) ? 
                                <Button as={Link} to={"/account/mdcat"} size="lg" colorScheme="accent">Start Learning</Button>
                                :
                                <Button as={Link} to="/register" size="lg" colorScheme="accent">Start Learning</Button>
                            }
                            
                        </HStack>
                    </Container>
                </Box>
            </Box>
            
            
            <Box py="2em">
                <Container>
                    <Box textAlign="center" mb="2">
                        <Text variant="heading-title">Say goodbye to MDCAT Preparing Academies</Text>
                        <Heading my="3" variant='insaf-heading' size="lg">Instantly Access to FLP Mock Examinations  </Heading>
                        <Text variant="loud">MDCAT is mandatory for students seeking admission to Medical & Dental Colleges</Text>
                    </Box>
                </Container>
            </Box>

            <Box py="1em" pb={3}>
                <Container>
                    <Box textAlign="center" p={5} bg="gray.100">
                        <Text variant="heading-title">Announcements</Text>                        
                        <Text>All Conducting bodies (Universities) will Follow PMC Question Bank. For Punjab, Uhs is conducting body. For Sindh, Dow University of Health Sciences will conduct the test.</Text>
                    </Box>
                </Container>
            </Box>

            <Box py="1em" pb="2em">
                <Container>
                    <HStack flexWrap="wrap" spacing="0" mx="-15px" mb={5}>
                    {
                        subjects?.map((subject, s) => 
                        <SubjectItem onClick={() => navigate('/account/mdcat/subject/'+subject?.id)}  data={subject} w={{ base: '100%', md: '50%', lg: '33.33%' }} key={s} />
                        )
                    }
                        <SubjectItem onClick={() => navigate('/account/mdcat')} type="FLP-MDCAT"  data={{ title: 'FLPs', image: 'mdcat.png', lecture_count: papers?.length }} w={{ base: '100%', md: '50%', lg: '33.33%' }} />
                    </HStack>
                </Container>
            </Box>

            <Box py="1em" pb="2em">
                <Container>
                    <Box textAlign="center" mb="2">
                        <Heading my="1" variant='insaf-heading' size="lg">Merit Calculator (ECAT - MDCAT-NUST)</Heading>
                        <Text variant="loud">You aggregate/merit:(Inter 40%, Matric 10%, Entry Test 50%)</Text>
                    </Box>
                </Container>    
                <Container>
                    <HStack flexWrap="wrap" spacing="0" mx="-15px" mb={5}>
                        <Box w={{ base: '100%', md: '50%', lg: '33.33%' }}  onClick={() => setCalc('MDCAT')}>
                            <Box p="15px">
                                <Box p="10px" bg="white" w="auto" shadow="custom" rounded="xl" cursor="pointer">
                                    <Image src="/assets/mdcat-calc.png" alt="MDCAT" mx="auto" rounded="lg" />
                                    <Box my="5">
                                        <Heading mb={5} textAlign="center" fontSize="20px">MDCAT AGGREGATE CALCULATOR</Heading>                                    
                                        <Button isFullWidth size="md" rounded="lg"  colorScheme="accent">Calculate</Button>                       
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        <Box w={{ base: '100%', md: '50%', lg: '33.33%' }}  onClick={() => setCalc('ECAT')}>
                            <Box p="15px">
                                <Box p="10px" bg="white" w="auto" shadow="custom" rounded="xl" cursor="pointer">
                                    <Image src="/assets/ecat-calc.png" alt="ECAT" mx="auto" rounded="lg" />
                                    <Box my="5">
                                        <Heading mb={5} textAlign="center" fontSize="20px">ECAT AGGREGATE CALCULATOR</Heading>                                    
                                        <Button isFullWidth size="md" rounded="lg"  colorScheme="accent">Calculate</Button>                       
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        <Box w={{ base: '100%', md: '50%', lg: '33.33%' }} onClick={() => setCalc('NUST')}>
                            <Box p="15px">
                                <Box p="10px" bg="white" w="auto" shadow="custom" rounded="xl" cursor="pointer">
                                    <Image src="/assets/nust-calc.png" alt="NUST" mx="auto" rounded="lg" />
                                    <Box my="5">
                                        <Heading mb={5} textAlign="center" fontSize="20px">NUST AGGREGATE CALCULATOR</Heading>                                    
                                        <Button isFullWidth size="md" rounded="lg"  colorScheme="accent">Calculate</Button>                       
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                    </HStack>
                </Container>

            </Box>
           
           <MdcatCalculator isOpen={calc === 'MDCAT'} onClose={() => setCalc(null)} />
           <EcatCalculator isOpen={calc === 'ECAT'} onClose={() => setCalc(null)} />
           <NustCalculator isOpen={calc === 'NUST'} onClose={() => setCalc(null)} />
            {/* <Box py="1em" pb="2em">
                <Container>                    
                        {
                            loading &&
                            <Box p="10" textAlign="center">
                                <Spinner />
                            </Box>
                        }
                        <HStack my="5" spacing="0" alignItems="flex-top" flexWrap="wrap" mx="-15px">
                            {papers?.map((paper, idx) => (
                                <PaperCard
                                    w={{ base: '50%', md: '50%', lg: '33.33%' }}
                                    key={idx}
                                    subtitle={paper?.label || "..."}
                                    heading={paper.title}                                    
                                    to={(user && profile && user?.group === 'STUDENT') ? '/account/mdcat/'+paper?.url_title : '/register'} />
                            ))}

                        </HStack>                                                             
                </Container>
            </Box> */}

            
        </>
    )
}
import { GradeConstants as Constants } from '../types'

const initialState = {
    grades: [],
    grade:null,    
    with_subjects: [],
    with_subjects2: [],
    with_subjects_minimal:[]
};

export function gradeReducer(state = initialState, action) {
    switch (action.type) {  
        
        case Constants.GET:
            return {
                ...state,
                grades: action.payload.grades,                
            };

        case Constants.WITHSUBJECTS:
            return {
                ...state,
                with_subjects: action.payload.data_array,                
            };

        case Constants.WITHSUBJECTS2:
            return {
                ...state,
                with_subjects2: action.payload.data_array,                
            };

        case Constants.WITHSUBJECTSMINIMAL:
            return {
                ...state,
                with_subjects_minimal: action.payload.data_array,                
            };

            
        
        case Constants.DETAILS:
            return {
                ...state,
                grade: action.payload.grade,                
            };
        
        

       
        case Constants.FAILED:
            return{
                ...state
            }        
        case Constants.CLEAR:
            return{
                ...state,
                grades: [],
                grade:null,
            };
        
        default:
            return {
                ...state
            }
    }
}
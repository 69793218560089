export const text = {
    baseStyle: {
        color: 'gray.600',
        fontSize: "16px"
    },
    variants:{
        caption: {
            fontWeight: "600"
        },
        small:{
            fontSize: "12px"
        },
        link:{
            d: 'block',
            my: 1,
            color: 'gray.500',
            fontSize: '16px',
            _hover:{
                color: 'brand.400'
            }
        },
        "heading-title":{
            d: 'block',
            my: 1,
            color: 'accent.500',
            fontSize:{
                base: "12px",
                md: '18px',
            },
            fontWeight: '700',            
        },
        "loud":{
            d: 'block',
            my: 1,            
            fontSize:{
                base: '12px',
                md: '16px',
                lg: '20px'
            },            
        },
        "footer-link":{
            d: 'block',
            my: 1.5,
            color: 'gray.500',
            fontSize: '14px',
            _hover:{
                color: 'brand.400'
            }
        }
    }
}
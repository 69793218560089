import { PaperConstants as Constants } from '../types'
import { paperApi as api } from '../../api'

export const paperAction = {
    get,        
    details,
    subject,
    lecture,
    comment,
    topic_comment,
    join,
    quiz,
    live,
    delete_topic_comment,
};

function get(type) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._get(type)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.GET, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}


function details(id) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._details(id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.DETAILS, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function subject(id, type) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._subject(id, type)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.SUBJECT, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function lecture(id) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._lecture(id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.LECTURE, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}


function join(id) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._join(id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.DETAILS, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}



function comment(form_data) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._comment(form_data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.DETAILS, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function topic_comment(form_data) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._topic_comment(form_data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.LECTURE, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}



function quiz(id) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._quiz(id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.QUIZ, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function live(grade_id) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._live(grade_id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.LIVE, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}


function delete_topic_comment(id) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._delete_topic_comment(id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.LECTURE, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}
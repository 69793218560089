import React, { useEffect, useState} from 'react'
import { Box, HStack, useBoolean, Heading, Text, Progress, IconButton, Accordion, AccordionItem, AccordionButton, AccordionPanel } from '@chakra-ui/react'
import { parentAction } from '../../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useApp } from '../../../hooks'
import { Modal, Drawer, Icon } from '../../../uikit'
import dayjs from 'dayjs'

export default function KidProgress(){

    const dispatch = useDispatch()
    const { child_id } = useParams()
    const [ loading, setLoading ] = useBoolean(false)
    const [ openSubject, setOpenSubject ] = useState(null)
    const {progress} = useSelector(state => state?.parent)
    const [ details, setDetails] = useState(null)
    const [ tab, setTab] = useState(0)
    const { setDashHeading } = useApp()

    useEffect(() => {
        if(child_id){
            setLoading.on()
            dispatch(parentAction.get_progress(child_id))
                .then((res) => {
                    setLoading.off()
                })
                .catch((err) => {
                    setLoading.off()
                })
        }
    }, [child_id])

    useEffect(() => {
        setDashHeading("Child Progress")
        return () => {
            setDashHeading("Dashboard")
          };
    }, [])

    const getmyProgress = (id, total_contents) => {
        let progress_total = progress.activities?.filter((x) => x?.subject_id === id)?.length || 0;        
        let p = parseInt(progress_total || 0)/parseInt(total_contents || 0) * 100
        // return isNaN(p) ? 0 : p;
        p = isNaN(p) ? 0 : p;
        return parseFloat(p).toFixed(2)
    }

    const getDone = (id) =>{
        return progress.activities?.filter((x) => x?.subject_id === id)?.length || 0;   
    }

    const subjectDetails = (itom) => {
        let my_sub = progress?.all_subjects?.find((x) => parseInt(x?.id) === parseInt(itom?.id));        
        console.log("My Sub", my_sub)
        setOpenSubject(my_sub)
    }

    const getChapterProgress = (id) => {
        let progress_total = progress.activities?.filter((x) => x?.chapter_id === id)?.length || 0;        
        let subject_totals = progress?.profile?.totals?.find((x) => x?.id === openSubject?.id )
        let total_contents = subject_totals?.total_contents;
        console.log("Progress Total", progress_total)
        console.log("Progress Total", total_contents)
        let p = parseInt(progress_total || 0)/parseInt(total_contents || 0) * 100
        p = isNaN(p) ? 0 : p;
        return {percentage: parseFloat(p).toFixed(2), ratio: progress_total + '/'+ total_contents}
    }

    const isFinished = (id) => {
        let progress_total = progress.activities?.find((x) => x?.content_id === id);        
        if(progress_total){
            return true
        }else{
            return false;
        }
    }

    return(
        <Box p={6}>
            <Heading size="lg">{progress?.first_name} {progress?.last_name} Progress</Heading>
            <HStack mt="5" alignItems="flex-start" spacing="7">
                <Box py="3" cursor="pointer" onClick={() => setTab(0)} borderBottom="2px solid" borderColor={tab === 0 ? 'accent.500' : 'transparent'}>
                    <Text fontWeight="700">Subject Progress</Text>
                </Box>
                <Box  py="3" cursor="pointer" onClick={() => setTab(1)}  borderBottom="2px solid" borderColor={tab === 1 ? 'accent.500' : 'transparent'}>
                    <Text fontWeight="700">Assessments</Text>
                </Box>                
            </HStack>

            <Box my={10}>
                {
                    tab === 0 &&
                    <Box bg="white" rounded="lg" py="3" px="3">
                        <table className='custom-table'>
                            <thead>
                                <th>Subject</th>
                                <th colSpan={2}>Progress</th>
                                <th>Topics Completed</th>
                            </thead>
                            <tbody>
                                {
                                    progress?.profile?.totals?.map((item, i) =>
                                    <tr key={i} onClick={() => subjectDetails(item)}>
                                        <td>{item?.title}</td>
                                        <td width="300">
                                            <HStack>
                                                    <Progress flex="1" size="sm" rounded="lg" colorScheme="accent"  value={getmyProgress(item?.id, item?.total_contents)} />
                                            </HStack>
                                        </td>
                                        <td>{getmyProgress(item?.id, item?.total_contents)}%</td>
                                        <td>{getDone(item?.id)} / {item?.total_contents}</td>
                                    </tr>                                    
                                    )
                                }
                            </tbody>
                        </table>
                    </Box>
                }

                {
                    tab === 1 &&
                    <Box bg="white" rounded="lg" py="3" px="3">
                        <table className='custom-table'>
                            <thead>
                                <th>Subject</th>
                                <th>Class</th>
                                <th>Attempted On</th>
                                <th>Total Questions</th>
                                <th>Passing Marks</th>
                                <th>Marks Obtained</th>
                            </thead>
                            <tbody>
                                {
                                    progress?.results?.map((result, r) =>
                                    result?.subject && 
                                    <tr key={r} onClick={() => setDetails(result)}>
                                        <td>{result?.subject?.subject?.title}</td>
                                        <td>{result?.subject?.subject?.grade?.title}</td>
                                        <td>{dayjs(result?.created_at).format('DD-MMM-YYYY hh:mm a')}</td>
                                        <td>{result?.result?.total_questions}</td>
                                        <td>{result?.quiz?.settings?.passing}%</td>
                                        <td>{result?.result?.percentage.toFixed(0)}%</td>
                                    </tr>                                    
                                    )
                                }
                            </tbody>
                        </table>
                    </Box>
                }
            </Box>

            <Modal 
                open={details !== null}
                onClose={() => setDetails(null)}
                title="Result Details"
                size="3xl"
                >
                <Box bg="white" rounded="lg">
                {
                    details?.details?.map((requ, b) =>                                         
                        <Box key={b} p="5" borderBottom="1px solid" borderColor="gray.200">
                            <Heading size="sm">Question No. {parseInt(b) + 1}</Heading>
                            <Box my="4" fontWeight="bold">{requ?.question}</Box>
                            <Box>
                            {
                                requ?.options?.map((anu, n) => {
                                    if(requ?.correct_answer === anu?.id ){
                                        return(
                                            <HStack p="2" rounded="md" key={n} my="2" bg='green.100'>
                                                <Text fontWeight="bold">{parseInt(n+1)}:</Text>
                                                <Text>{anu?.answer}</Text>
                                            </HStack>
                                        )
                                    }else if(requ?.answer === anu?.id && !requ?.correct ){
                                        return(
                                            <HStack p="2" rounded="md" key={n} my="2" bg='red.100'>
                                                <Text fontWeight="bold">{parseInt(n+1)}:</Text>
                                                <Text>{anu?.answer}</Text>
                                            </HStack>
                                        )
                                    }else{
                                        return(
                                            <HStack p="2" rounded="md" key={n} my="2" bg='transparent'>
                                                <Text fontWeight="bold">{parseInt(n+1)}:</Text>
                                                <Text>{anu?.answer}</Text>
                                            </HStack>
                                        )
                                    }
                                    
                                })
                            }
                            </Box>                                                
                        </Box>
                                                                    
                    )
                }
                </Box>
            </Modal>

            <Drawer 
                open={openSubject !== null}
                onClose={() => setOpenSubject(null)}
                title={`${openSubject?.title || 'Subject' }` + ' Progress'} 
                size="lg"
                bg="gray.100"
                >
                <Box bg="gray.100">
                    {
                        openSubject?.chapters?.map((chapter, c) =>
                        <Box>
                            <Accordion mb="10" allowMultiple>
                                <AccordionItem key={c} border="0" my="3" rounded="lg" shadow="custom" bg="white">
                                    <AccordionButton border="0" px="10" py="4" _hover={{ bg: 'transparent' }} _focus={{ shadow: 'none' }} borderBottom="1px solid" borderColor="gray.200">
                                        <HStack flex={1} alignItems="flex-start" justifyContent="space-between">
                                            <Box flex={1}>
                                                <Heading textAlign="left" mb="3" fontWeight="bold" size="md">Chapter #{c+1} {chapter?.title}</Heading>  
                                                <HStack w="200px">
                                                    <Progress w="200px" flex="1" size="sm" rounded="lg" colorScheme="accent"  value={getChapterProgress(chapter?.id)} />
                                                    <Text>{getChapterProgress(chapter?.id)?.percentage}%</Text>
                                                </HStack>                              
                                            </Box>
                                            <Text>{getChapterProgress(chapter?.id)?.ratio}</Text>
                                            
                                        </HStack>
                                    </AccordionButton>
                                    <AccordionPanel>
                                        <Box px={5}>
                                        {
                                            chapter?.topics?.map((topic, t) =>
                                            <Box key={t}>
                                                <HStack my="3" justifyContent="space-between">
                                                    <Box flex={1}>
                                                        <Heading size="sm"> { topic?.title }</Heading>
                                                    </Box>
                                                    <Box>

                                                    </Box>
                                                </HStack>
                                                {
                                                    topic?.contents?.map((content, co) =>
                                                    <HStack  my="1" justifyContent="space-between" alignItems="center" py={1}>
                                                        <Box>
                                                            {
                                                                isFinished(content?.id) ?
                                                                <Icon color="green.500"  name="check-circle" />
                                                                :
                                                                <Icon color="red.500"  name="times-circle" />
                                                            }
                                                        </Box>
                                                        <Box flex={1}>
                                                            <Text size="sm">&bull; { content?.title }</Text>
                                                        </Box>
                                                        
                                                    </HStack>
                                                    )
                                                }
                                            </Box>
                                            )
                                        }
                                        </Box>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        </Box>
                        )
                    }
                    {
                        (openSubject?.chapters?.length === 0) &&
                        <Box bg="yellow.100" p="5">
                            <Text>No Progress for this subject.</Text>
                        </Box>
                    }
                </Box>
            </Drawer>
        </Box>
    )
}
import React, { Suspense, lazy} from 'react'
import { BrowserRouter as Router, Route, Routes as Switch } from 'react-router-dom'
import { Splash } from '../uikit'
import { Fade } from '@chakra-ui/react'
import ScrollToTop from './ScrollToTop'
import { useApp } from '../hooks'
import MainLayout from '../layouts/MainLayout'
import AccountLayout from '../layouts/AccountLayout'
import DashboardLayout from '../layouts/DasbhoardLayout'
import ParentLayout from '../layouts/ParentLayout'
import ForgetPassword from '../pages/Auth/ForgetPassword'
import ResetPassword from '../pages/Auth/ResetPassword'
import { isEmpty } from '../helpers'
// import { useNavigate} from 'react-router-dom'

const RemoveAccount = lazy(() => import('./../pages/Auth/RemoveAccount'))

const Register = lazy(() => import('./../pages/Auth/Register'))
// const ForgetPassword = lazy(() => import('./../pages/Auth/ForgetPassword'))



const  Routes = () => {    
    
    const auth = useApp();    
    if(auth.loading)
    {
        return(
            <Fade in={true}>
                <Splash />
            </Fade>
        )
    }

    


    return (                
            <Fade in={true}>
                <Suspense fallback={<div />}>                    
                    <Router>
                        <ScrollToTop />
                        <Switch>                            

                            <Route path="/*" element={<MainLayout/>} />
                            <Route path="/account/*" element={<AccountLayout />} />
                            <Route path="/parent/*" element={<ParentLayout />} />
                            <Route path="/dashboard/*" element={<DashboardLayout />} />
                            {
                                isEmpty(auth?.user) &&
                                <>
                                <Route exact path="/register" element={<Register />} />
                                <Route exact path="/forget-password" element={<ForgetPassword />} />
                                <Route exact path="/reset-password/:hash" element={<ResetPassword/>} />
                                </>
                            }

                            <Route exact path="/remove-account" element={<RemoveAccount />} />
                            
                            
                        </Switch>
                    </Router>
                </Suspense>
            </Fade>                
    )
}

export default Routes;
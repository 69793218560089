import React, {useEffect, useState} from "react"
import { Box, Text, Heading, Image, HStack, Button } from '@chakra-ui/react'
import { Link, useNavigate } from "react-router-dom"

export const SubjectCard = ({subject, grade_slug, mockExam, ...rest}) => {

    const [ lectureCount, setLectureCount ] = useState(0)
    const navigate = useNavigate()
    useEffect(() => {
        var total_lectures = 0;
        if(subject?.chapters){
            subject?.chapters?.map((chapter) => 
                chapter?.topics?.map((topic) => {                    
                    return total_lectures += topic?.contents?.length
                })
            )
        }
        
        setLectureCount(total_lectures)

    }, [subject?.chapters])


    return(
        <Box {...rest}>
            <Box p="15px">                
                <Box p="10px" bg="white" w="auto" shadow="custom" rounded="xl" cursor="pointer" onClick={() => navigate('/account/mock-exams/'+subject?.id, {state: subject?.title})}>
                    <Image src={process.env.REACT_APP_STORAGE_URL + subject?.image?.url} alt={subject?.title} mx="auto" rounded="lg" />
                    <Box px="20px" py="5px" textAlign="center">
                        <Box my="5">
                            <Heading fontFamily="Poppins" fontSize="24px">{subject?.title}</Heading>                                            
                        </Box>
                        <HStack alignItems="center">
                            <Box flex={1}>
                                <Button isFullWidth as={Link} to={'/account/mock-exams/'+subject?.id} state={subject?.title} size="md" rounded="lg"  colorScheme="accent">Take Mock Exam</Button>
                            </Box>
                            <Box>
                                <Button onClick={(e) => e.stopPropagation()}  as={Link} to={'/account/mock-exams/results/'+subject?.id}  size="md" rounded="lg"  colorScheme="gray">Results...</Button>
                            </Box>
                        </HStack>
                    </Box>
                </Box>
                
            </Box>
        </Box>
    )
}
import React from "react"
import { Box, Heading } from '@chakra-ui/react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'



export const BarChart = ({height, data, title, ...rest}) => {

    const colors = ['#FEC64F', '#4CBC9A', '#2ECEFE', '#E95454', '#2E715C']
    let categories = [];
    let items = [];
    
    data?.totals?.map((item, i) =>{
        categories.push(item?.title)
        let progress_total = data?.progress?.filter((x) => x?.subject_id === item?.id)?.length || 0;
        

        let p = parseInt(progress_total || 0)/parseInt(item?.total_contents || 0) * 100
        items.push({
            name: item?.title,
            color: colors[i],
            y: parseInt(p)
        })
    })
    
    

    const options = {

        title: {
            text: ''
        },
    
        yAxis: {
            title: {
                text: ''
            }
        },
        chart:{
            height: height,
            type: 'column'
        },    
        legend: {
            enabled: false
        },
        // legend: {
        //     layout: 'horizontal',
        //     align: 'right',
        //     verticalAlign: 'top'
        // },
        credits: {
            enabled: false
        },
        // plotOptions: {
        //     series: {
        //         label: {
        //             connectorAllowed: false
        //         },
        //         pointStart: ''
        //     }
        // },
        
        series: [{
            data: items
        }],
        
        xAxis: {
            categories: categories
        },
        tooltip: {
            formatter: function() {
                // console.log(this.series)
                console.log(this);
                
                return '<b>' +this.key + '</b> = <b>' + this.y + '%</b>';
            }
        }
    }

    return(
        <Box position="relative">
            <Heading mx="2em" my="3" size="md">{title}</Heading>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                
            />
        </Box>
    )
}
import React from "react";
import { Box, Image, Heading } from '@chakra-ui/react'
import { Link } from "react-router-dom";

export const  PaperCard = ({ width, to, src, subtitle, heading, text, ...rest }) => {
    return (
        <Box  p="15px" height={"21%"} as={Link} to={to} {...rest}>
            <Box bg="gray.100" d="flex" alignItems="center" justifyContent="center" py={{base: "1.5em", lg:"3em"}}  bgImage="/assets/box-bg.svg" bgSize="cover" bgRepeat="no-repeat">                
                <Box bg="white" w="auto" shadow="custom" rounded="lg">
                    <Box p={{base: '5', lg:"10"}} px={{ base: '5', md: '20', lg:"20"  }} textAlign="center">
                        <Image src="/assets/class-icon.svg" alt="class" mx="auto" />
                        <Heading size="md" mb="2">{heading}</Heading>
                        <Heading fontSize="14px">{subtitle ||  "????"}</Heading>
                    </Box>
                </Box>
                
            </Box>
        </Box>
    )
}
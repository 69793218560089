import {handleResponse, requestTimeout} from '../helpers'
const apiUrl = process.env.REACT_APP_API_URL
const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME

export const subjectApi = {
    _get,
    _details,
    _topic,
    _quiz,
    _badges,
    _submit_quiz,
    _create_quiz,
    _results,
    _search,
    _comment,
};



async function _get(slug) {    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'subjects/'+slug, requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}



async function _details(id) {    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'subjects/details/'+id, requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _topic(id) {    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'subjects/topic/'+id, requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _quiz(id) {    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'subjects/quiz/'+id, requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _badges() {    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'badges', requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _submit_quiz(form_data) {    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        },
        body: JSON.stringify(form_data)
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'subjects/submit-quiz', requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _create_quiz(form_data) {    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        },
        body: JSON.stringify(form_data)
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'subjects/create-quiz', requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _results(id) {    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'subjects/results/'+id, requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _search(q) {    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'subjects/search/'+q, requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}


async function _comment(form_data) {    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        },
        body: JSON.stringify(form_data)
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'subjects/topic/comment', requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

import React, { Fragment, useEffect, useState } from 'react';
import { Badge, Box, Button, Heading, HStack, Image, Code, Text, useBoolean, useToast } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { virtualClassAction } from '../../../redux/actions';
import { useApp } from '../../../hooks';
import NewClass from './NewClass';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import {CopyToClipboard} from 'react-copy-to-clipboard';


const Classes = () => {
    
    const dispatch = useDispatch();    
    const navigate = useNavigate()
    const toast = useToast()
    const { setDashHeading } = useApp()
    const [ loading, setLoading ] = useBoolean(false)
    const [ isOpen, setOpen ] = useBoolean(false)
    const [ removing, setRemoving] = useState(null)
    const { classes  } = useSelector(state => state?.virtual)
    useEffect(() => {
        setDashHeading("Virtual Classes")
        return () => {
            setDashHeading("Dashboard")
          };
    }, [])

    useEffect(() => {
        setLoading.on()
        dispatch(virtualClassAction.get())
            .then((res) => {
                setLoading.off()                
            })
            .catch((err) => {
                setLoading.off()
            })
    }, [])
    

    const removeClass = async(id) => {
        let result = await window.confirm("Do you really want to delete this virtual class?", 'Delete Virtual Class');
        if(result){
            setRemoving(id)
            dispatch(virtualClassAction.remove(id))
                .then((res) => {
                    setRemoving(null)
                })
                .catch((err) =>{
                    setRemoving(null)
                })
        }
    }
    return (
        <Box px="2em" py="5">   
            <HStack  justifyContent="flex-end" mb="5">
                <Button onClick={setOpen.on}>Add Virtual Class</Button>
            </HStack>
            {
                (!loading && classes?.length === 0) &&
                    <Box bg="yellow.50" p="5">                
                        <Heading size="md">No Virtual Class Available</Heading>
                    </Box>
            }                
            
            {
                (classes?.length > 0) &&                
                classes?.map((cls, c) =>
                    <Box key={c} bg="white"  rounded="lg" p={10} mb="3">
                        <HStack justifyContent="space-between" alignItems="flex-start">
                            <Box flex={1}>
                                <Text fontSize="12px">{dayjs(cls?.created_at).format('DD-MMMM-YY')}</Text>
                                <Heading mb="2" size="lg">{cls?.title} <Badge colorScheme={cls?.type === 'PUBLIC' ? 'green' : 'blue'}>{cls?.type}</Badge></Heading>
                                <HStack alignItems="center">
                                    <Text>Class: {cls?.grade?.title}</Text>
                                    <Text>Subject: {cls?.subject}</Text>
                                </HStack>
                                <HStack mt={3}>
                                    
                                        <Code fontFamily="body" colorScheme="blue">
                                            {process.env.REACT_APP_BASE_URL+'join-class/'+cls?.id}
                                        </Code>
                                    
                                    <CopyToClipboard text={process.env.REACT_APP_BASE_URL+'join-class/'+cls?.id}>
                                        <Button size="xs" variant="outline">Copy</Button>
                                    </CopyToClipboard>
                                </HStack>

                                {
                                    cls?.students?.length === cls?.total_students &&
                                    <Badge mt={3} textTransform="none" colorScheme="red">Class is full</Badge>
                                }
                            </Box>
                            <Box d="flex" flexDir="column" alignItems="flex-end">
                                <Box bg="#4CBC9A" w="auto" rounded="lg" color="white" px="4" py="2">{cls?.students?.length}  / {cls?.total_students} Students</Box>
                                <HStack spacing="5" mt="5">                                    
                                    <Button onClick={() => navigate('/dashboard/virtual-classes/students/'+cls?.id)} variant="outline">Details</Button>                                    
                                    <Button isLoading={removing === cls?.id} onClick={() => removeClass(cls?.id)} colorScheme="red">Delete</Button>
                                </HStack>
                            </Box>
                        </HStack>
                    </Box>    
                )
                
            }
            <NewClass open={isOpen} onClose={setOpen.off} />
        </Box>
    )
}

export default Classes;
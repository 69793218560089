import React from "react";
import Slider from "react-slick";
import { Link } from 'react-router-dom'
import { Box, Text, Heading, HStack, Image, Button, Avatar, VStack} from '@chakra-ui/react'
import { Container, Icon } from '../../../uikit';
import './slick.css'

    

export const VideoSlider = () =>{

    
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: false,
        // nextArrow: <NextArrow />,
        // prevArrow: <PrevArrow />,
        autoplay: true,
        // fade: true,
        autoplaySpeed: 5000,
        adaptiveHeight: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: false
        // appendDots: (dots) => <div className="my-slider-dots">{dots}</div>
    };

    return(
        <>
            <Slider {...settings} className="video-slider">
                {/* <Box py="100" bgImg="/assets/minister-of-education.png" minH="400px" bgPosition="center center" bgSize="cover">
                    <Container mx="auto">
                        <HStack alignItems="center" justifyContent="space-between">                                                        
                            <Box w={{ base: '100%', md: '100%', lg: '45%' }} textAlign={{ base:'center', lg: 'left' }}>
                                <Text variant="heading-title">Message</Text>
                                <Heading my="3" size="md" color="white">PCTB Online Academy will level the playing  field for students with different economic, social and regional backgrounds as now every student will have free access to high-quality content  from anywhere.</Heading>                                
                                <Text mt={4} variant="loud" color="rgba(255,255,255,0.95)">Murad Raas</Text>
                                <Text fontSize="sm" color="rgba(255,255,255,0.95)">Minister, School Education Department</Text>
                                <Text fontSize="sm" color="rgba(255,255,255,0.95)">Govt. Of Punjab</Text>                                
                            </Box>
                            <Box d={{ base: 'none', lg: 'block' }} w="45%" textAlign="center">
                            </Box>
                        </HStack>
                    </Container>
                </Box> */}

                <Box py="100" bgImg="/assets/pakistan-banner.png" minH="400px" bgPosition="center center" bgSize="cover">
                    <Container mx="auto">
                        <HStack alignItems="center" justifyContent="space-between">
                            <Box w={{ base: '100%', md: '100%', lg: '45%' }} textAlign={{ base:'center', lg: 'left' }}>
                                <Text variant="heading-title">Our Mission</Text>
                                <Heading my="3" color="white">Providing equal access to education is our top priority</Heading>
                                <Text variant="loud" color="rgba(255,255,255,0.65)">PCTB Online Academy will prove to be a game changer as  millions of students will get free access to high quality content from their PCs, Laptops and Cell Phones.</Text>
                            </Box>
                            {/* <Box d={{ base: 'none', lg: 'block' }} w="45%" textAlign="center">
                                <Image mx="auto" transition="all 0.3s linear" cursor="pointer" _hover={{ opacity: 0.5 }} src="/assets/PlayButton.svg" alt="play" />
                            </Box> */}
                        </HStack>
                    </Container>
                </Box>
            
                
            </Slider>
        </>
    )
}
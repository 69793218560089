import React from 'react'
import {
    FormControl,
    FormLabel, Text,
    Select as ChakraInput,    
    FormErrorMessage,
  } from "@chakra-ui/react"
  
const Select = ({error, label, required, children, ...props}) => {
    return(
        <FormControl isInvalid={error}>
            {label && <FormLabel fontSize="12px" color="brand.600" fontWeight="600" textTransform="uppercase">{label} {required && <Text as={'span'} fontSize="xs" color="red">*</Text>}</FormLabel>}
            <ChakraInput {...props}>{children}</ChakraInput>

            <FormErrorMessage>{error}</FormErrorMessage>
        </FormControl>
    )
}
export {Select}
import React, { useEffect, useState } from "react"
import { Box, Spinner, useBoolean, Heading, Button, Stack, Image, Text, HStack, Progress } from "@chakra-ui/react"
import { useApp } from '../../../hooks'
import { useParams, useNavigate, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { paperAction } from "../../../redux/actions"
import { Helmet } from "react-helmet-async"
import Results from "./Results"
import useCountDown from 'react-countdown-hook';
import dayjs from "dayjs"
import { paperApi } from "../../../api"
import { MdcatCalculator, EcatCalculator, NustCalculator } from "./Calculators"
var duration = require('dayjs/plugin/duration')
dayjs.extend(duration)

const aplhabets = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]

export default function Details () {

    const loc = useLocation()    
    const { setDashHeading} = useApp()
    const dispatch = useDispatch()
    const { slug } = useParams()
    const navigate = useNavigate()
    const [ calc, setCalc ] = useState(null)
    const [ step, setStep ] = useState(1)
    const [ loading, setLoading] = useBoolean(false)
    const [ isOpen, setOpen] = useBoolean(false)
    const [ submitting, setSubmitting] = useBoolean(false)
    const [ myResult, setMyResult ] = useState(null)
    const [ userAnswers, setUserAnswwers] = useState([])
    const { paper } = useSelector(state => state?.papers)
    const [ qIndex, setqIndex ] = useState(0)
    const [timeLeft, { start, pause, resume, reset }] = useCountDown(0);
    
    const paper_type = loc?.pathname.includes('ecat') ? 'ECAT': 'MDCAT'


    useEffect(() => {
        setLoading.on()
        dispatch(paperAction.details(slug))
        .then((res) =>{
            setLoading.off()
        })
        .catch((err) =>{
            setLoading.off()
        })    
    }, [slug])

    
    const handleAnswerChange = (qid, aid) => {        

        let all_answers = [...userAnswers]
        let index = all_answers.findIndex(x => x?.qid === qid)
        if(index > -1){
            all_answers[index] = {qid: qid, aid: aid} 
        }else{
            all_answers.push({qid: qid, aid: aid})
        }
        setUserAnswwers(all_answers)
    }

    const startQuiz = () => {
        setStep(2);
        start(parseInt(paper?.duration) * 60 * 1000);

    }

    useEffect(() => {
        setDashHeading(paper_type)
      return () => {
        setDashHeading('Dashboard')
      };
    }, [])

    const totalQuestions = () => {    
        if(paper?.quiz){
            return  paper?.quiz.reduce((q, a) => parseInt(a.questions?.length + q), 0);     
        }else{
            return 0
        }
        
    }

    const getValue = (qid) => {
        let all_answers = [...userAnswers]
        let quest = all_answers.find(x => x?.qid === qid)
        if(quest){
            return quest?.aid
        }
        return null
    }

    const handleSubmit = React.useCallback((e = null) => {
        if(e){
            e.preventDefault()
        }
        setSubmitting.on()
        let quiz_details = [];

        paper?.quiz?.map((quiz, s) => {
            quiz?.questions?.map((qu) => {
                let my_answer = userAnswers?.find((x) => x?.qid === qu?.id);
                return quiz_details.push({
                            question: qu?.question,
                            options: qu?.answers,
                            answer: my_answer?.aid || null,
                            correct_answer: qu?.correct,
                            correct: my_answer?.aid === qu?.correct || false
                        })
            })
        })

        let form_data = {
            paper_id: paper?.id,
            details: quiz_details,            
        }
        paperApi._submit_quiz(form_data)
            .then((res) => {
                setMyResult(res?.result)
                setStep(3)
                reset()                
                setSubmitting.off()
            })
            .catch((err) => {
                console.log('Error', err)
                setSubmitting.off()
            })
    }, [paper?.id,paper?.quiz, setSubmitting, userAnswers, reset])

    useEffect(() => {        
        if(timeLeft <= 0 && step === 2){
            pause()
            handleSubmit()
        }
    }, [timeLeft, step, pause, handleSubmit])

    
    return(
        <>
            <Helmet>
                <title>{paper_type} - PCTB Online Academy</title>
                <meta name="description" content="PCTB Online Academy is FREE online learning platform for HSSC and SSC Student covering of Subjects, Assessment and other learning material to help our young genration for tapping in to the future." />
            </Helmet>
            {
                loading ?
                    <Box d="flex" h="100%" alignItems="center" justifyContent="center">
                        <Spinner />
                    </Box>
                :
                <Box px="2em" py="3">
                    <Heading fontFamily="Poppins" size="md" fontWeight="bold">{paper?.title} {paper?.label}</Heading>
                    {
                        step === 1 &&
                            <Box my="5">
                                <Box  bg="white" rounded="lg" shadow="md">
                                    <Stack direction={{ base: "column", md: "row" }} py="10" px="10" spacing={{base: "10px", md: 0}} mx="-15px" alignItems={{base: "normal", md:"center"}} justifyContent={{ base: "normal", md: "space-between"}}>
                                        <Box w={{base: "100%", md: "20%"}} px="15px" textAlign="center">
                                            <Heading size="xs">Duration</Heading>
                                            <Text>{paper?.duration} min</Text>
                                        </Box>
                                        <Box  w={{base: "100%", md: "20%"}} px="15px" textAlign="center">
                                            <Heading size="xs">Number of Questions</Heading>
                                            <Text>{totalQuestions()}</Text>
                                        </Box>

                                        <Box  w={{base: "100%", md: "20%"}} px="15px" textAlign="center">
                                            <Heading size="xs">Passing Marks</Heading>
                                            <Text>{paper?.passing || 50}%</Text>
                                        </Box>

                                        <Box  w={{base: "100%", md: "20%"}} my={{base: "10px", md: 0  }} px="15px" textAlign={{base: "center", md: "right"}}>
                                            <Heading size="sm"></Heading>
                                            {
                                                ( totalQuestions() > 0 ) &&
                                                <Button onClick={startQuiz} colorScheme="accent" size="sm" >Start Exam</Button>
                                            }
                                            
                                        </Box>

                                    </Stack>
                                    {( totalQuestions() === 0 ) && 
                                        <Box bg="red.100" px={5} py={3}>No questions found for this exam.</Box>
                                    }
                                    <Box borderTop="1px solid" py="5" px="10" borderColor="gray.200">
                                        <Heading my="8" fontSize="24px" color="accent.500" fontWeight="bold">Instructions</Heading>
                                        <div dangerouslySetInnerHTML={{ __html: paper?.description }} />                                

                                        <Heading my="8" fontSize="24px" color="accent.500" fontWeight="bold">STRUCTURE, WEIGHTAGE AND DIFFICULTY LEVELS</Heading>
                                        <div dangerouslySetInnerHTML={{ __html: paper?.details }} />                                
                                    </Box>
                                </Box>
                            </Box>  
                        }

                        {
                            step === 2 &&
                            <Box my="5">
                                
                                <Box bg="white" w={{base: "100%", md: "90%"}} mx="auto" rounded="lg" my={"5"}>
                                    <Box d="flex" p="5"  justifyContent={"space-between"} alignItems={"center"} roundedTop="lg" bg="brand.500">
                                        <Box w={{base: "50%", md:"40%"}}>                                            
                                            <Heading size="sm" fontWeight="800" color="white" fontFamily="Poppins">{paper?.label}</Heading>                                
                                            {/* <Progress  rounded="full" colorScheme='accent'  size='md' value={parseInt((parseInt(qIndex+1)/parseInt(totalQuestions()) * 100)) } /> */}
                                            {/* <HStack mt="2" justifyContent="space-between">
                                                <Heading size="sm" color="white">Quiz Progress</Heading>
                                                <Heading size="sm" color="white">{parseInt(qIndex + 1) }/ {totalQuestions()}</Heading>
                                            </HStack> */}
                                            {/* <Text color="white">
                                                Please answer the question and click next
                                            </Text> */}
                                        </Box>
                                        <HStack alignSelf="flex-end" alignItems="flex-start" justifyContent="flex-end">
                                            <Box bg="accent.500" px="3" py="2" rounded="md">
                                                <Text fontWeight="bold" fontSize="sm" color="white">{dayjs.duration(timeLeft).format('HH:mm:ss')}</Text>
                                            </Box>
                                        </HStack>                                            
                                    </Box>
                                    {
                                        paper?.quiz?.map((subject, s) => 
                                            <Box key={s}>
                                                <Box bg="accent.500" px={5} py={4}>
                                                    <Heading fontSize="22px" color="white">{subject?.title}</Heading>
                                                </Box>
                                            { subject?.questions?.map((question, q) => {
                                                
                                                    return(
                                                        <Box key={q} p="5" py={10} borderBottom="1px solid" borderColor="gray.400" bgImage="/assets/pctb-wartermark.png" bgRepeat="no-repeat" bgPosition="center center" bgSize="25%"> 
                                                            <Heading color="accent.500" size="sm">Question No. {parseInt(q) + 1}</Heading> 
                                                            <HStack  my="4" alignItems="flex-start">
                                                                <Box flex={1} fontWeight="bold">{question?.question}</Box>
                                                                <HStack alignItems="flex-start">
                                                                    {question?.answers?.map((answer, a) =>
                                                                        (getValue(question?.id) !== answer?.id) ? 
                                                                        <Box key={a} w="24px" cursor="pointer" onClick={() => handleAnswerChange(question?.id, answer?.id)}  h="24px" lineHeight="22px" textAlign="center" rounded="full" border="1px solid" borderColor="gray.400">
                                                                            <Text fontSize="12px" fontWeight="600" color="gray.700">{aplhabets[a]}</Text>
                                                                        </Box>
                                                                        :
                                                                        <Box key={a} w="24px" h="24px" lineHeight="22px" textAlign="center" rounded="full" bg="accent.500" border="1px solid" borderColor="accent.500">
                                                                            <Text fontSize="12px" fontWeight="600" color="white">{aplhabets[a]}</Text>
                                                                        </Box>
                                                                    )}
                                                                </HStack>
                                                            </HStack>
                                                            {/* <RadioGroup value={getValue(question?.id)} onChange={(e) => handleAnswerChange(question?.id, e)}> */}
                                                            {
                                                                question?.answers?.map((answer, a) => 
                                                                    <HStack key={a} my="4" alignItems="flex-start">                                                                        
                                                                        {/* <Radio value={answer?.id}>{answer?.answer}</Radio> */}                                                                        
                                                                        {
                                                                            getValue(question?.id) !== answer?.id ?
                                                                                // <Icon mt="1" name="circle" />
                                                                                <Box w="24px"  h="24px" lineHeight="22px" textAlign="center" rounded="full" border="1px solid" borderColor="gray.400">
                                                                                    <Text fontSize="12px" fontWeight="600" color="gray.700">{aplhabets[a]}</Text>
                                                                                </Box>
                                                                            :
                                                                                <Box w="24px" h="24px" lineHeight="22px" textAlign="center" rounded="full" bg="accent.500" border="1px solid" borderColor="accent.500">
                                                                                    <Text fontSize="12px"  fontWeight="600" color="white">{aplhabets[a]}</Text>
                                                                                </Box>
                                                                        }
                                                                       
                                                                        <Box>{answer?.answer}</Box>
                                                                    </HStack>
                                                                )
                                                            }
                                                            {/* </RadioGroup> */}

                                                            {/* <HStack mt="10" justifyContent="space-between">
                                                                <Button rounded="lg" onClick={() => setqIndex(qIndex-1)} isDisabled={qIndex === 0} variant="outline">Previous</Button>
                                                                {
                                                                    (qIndex < totalQuestions()) ?
                                                                        <Button rounded="lg" isDisabled={getValue(question?.id) === null} onClick={() => setqIndex(qIndex+1)}>Next</Button>
                                                                    :
                                                                    <Button rounded="lg" isDisabled={getValue(question?.id) === null} onClick={() => setStep(2)}>Review</Button>
                                                                }
                                                                
                                                            </HStack> */}
                                                        </Box>
                                                    )
                                                   
                                            })
                                            }
                                            </Box>
                                        )
                                    }

                                    
                                </Box>

                                <Box my={5} px={"4em"}>
                                    <Button isLoading={submitting} onClick={handleSubmit}>Submit Exam</Button>
                                </Box>
                            </Box>
                        }
                        {
                            step === 3 &&
                            <Box my="5">
                                <HStack justifyContent="space-between" alignItems="flex-start">
                                    <Box>
                                        <Heading size="lg" fontWeight="800" fontFamily="Poppins" mb="3">{paper?.label || 'Quiz'}</Heading>                                        
                                    </Box>                                    
                                </HStack>
                                <Box>
                                    <Box bg="white" mt="20" rounded="lg" shadow="lg" w={{base: "100%", md: "70%"}} mx="auto">
                                        <Box p={{base: 5, md:"20"}}>
                                            {
                                                (parseFloat(myResult?.result?.percentage) < parseFloat(paper?.passing)) ?
                                                <Image maxW="350px" h="auto" src="/assets/retake-quiz.png" alt="congrats" mx="auto" />
                                                :
                                                <Image maxW="350px" h="auto" src="/assets/congrats.png" alt="congrats" mx="auto" />
                                            }
                                            
                                            <Box my="10" textAlign="center">
                                                <Heading mb="2" fontWeight="800" color="accent.500" size="lg"> { (parseFloat(myResult?.result?.percentage) < parseFloat(paper?.passing)) ?  "You've Failed" :'Congratulations !'}</Heading>
                                                <Text>{ (parseFloat(myResult?.result?.percentage) < parseFloat(paper?.passing)) ? '"Everything is hard before it is easy",  -Goethe, Practice Harder & Try Again ': 'Amazing skills, you nailed it!'}</Text>
                                            </Box>

                                            <Box rounded="lg" my="10" p="10" w={{base: "100%", md:"80%"}} mx="auto" bg="accent.50" border="2px solid" borderColor="accent.500">
                                                <Stack direction={{ base: "column", md: "row" }} alignItems="center" justifyContent="space-between">
                                                    <Box textAlign="center">
                                                        <Heading mb="2" size="sm">Total Questions</Heading>
                                                        <Text>{myResult?.result?.total_questions}</Text>
                                                    </Box>
                                                    <Box textAlign="center">
                                                        <Heading mb="2" size="sm">Correct Answers</Heading>
                                                        <Text>{myResult?.result?.correct}</Text>
                                                    </Box>
                                                    <Box textAlign="center">
                                                        <Heading mb="2" size="sm">Score</Heading>
                                                        <Text>{parseFloat(myResult?.result?.percentage).toFixed(2)}%</Text>
                                                    </Box>
                                                </Stack>
                                            </Box>

                                            <HStack my="4" justifyContent="center" spacing="5">                                                
                                                <Button onClick={setOpen.on}   rounded="lg" variant="outline" colorScheme="accent">View Details</Button>
                                                <Button  onClick={() => navigate('/account/'+paper_type?.toLowerCase())} rounded="lg" colorScheme="accent">More Papers</Button>
                                                {
                                                    paper_type === 'MDCAT' &&
                                                    <Button  onClick={() => setCalc('MDCAT')} rounded="lg" colorScheme="accent">Aggregate Calculator</Button>
                                                }
                                                {
                                                    paper_type === 'ECAT' &&
                                                    <Button  onClick={() => setCalc('ECAT')} rounded="lg" colorScheme="accent">Aggregate Calculator</Button>
                                                }
                                            </HStack>
                                            
                                            


                                            <MdcatCalculator isOpen={calc === 'MDCAT'} onClose={() => setCalc(null)} />
                                            <EcatCalculator isOpen={calc === 'ECAT'} onClose={() => setCalc(null)} />
                                            <NustCalculator isOpen={calc === 'NUST'} onClose={() => setCalc(null)} />

                                        </Box>                            
                                    </Box>
                                </Box>
                            </Box>
                        }
                </Box>
            }            
            <Results result={myResult} open={isOpen} onClose={setOpen.off} />
        </>
    )
}
import React from "react"
import { Box, useToast } from "@chakra-ui/react"
import { useParams, useNavigate } from "react-router-dom"
import { useApp } from "../../hooks"
import { useDispatch } from "react-redux"
import { vClassAction } from "../../redux/actions"

export default function JoinClass(){

    let { class_id } = useParams() 
    let { user } = useApp()
    const dispatch = useDispatch()
    const toast = useToast()
    const navigate = useNavigate()

    if(!user){
        alert("You are not logged in. Please Login to continue");
        navigate('/')
    }
    else{
        if(user?.group !== 'STUDENT'){            
            alert("Your account appears to be non-student. Please login as student to continue");
            navigate('/')
        }else{
                
                dispatch(vClassAction.join(class_id))
                    .then((res) => {                        
                        toast({
                            title: 'Class Joined',
                            description: res?.message || 'Class joined successfully!',
                            status: 'success',
                            position: "top"
                        })      
                        navigate('/account/virtual-classes/'+class_id)          
                    })
                    .catch((err) => {
                        alert("Problem joining class")
                    })
            }

        
    }
    return(
        <Box>

        </Box>
    )
}
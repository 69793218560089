import React, { useEffect} from 'react';
import { Box, useBoolean, Spinner, Heading } from '@chakra-ui/react';
import { useApp } from '../../../hooks';
import { authAction } from '../../../redux/actions';
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from 'react-router-dom'
import { Item } from './Item'

const Notifications = () => {
     
    const { setDashHeading } = useApp()    
    const navigate = useNavigate()
    const [ mount, setMount ] = useBoolean(false)
    const [ loading, setLoading ] = useBoolean(false)
    const { messages } = useSelector(state => state?.auth)
    const dispatch = useDispatch()
    
    
    
    useEffect(() => {
        if(!mount){
            setMount.on()
            setLoading.on()
            dispatch(authAction.get_messages())
                .then(() => setLoading.off())
                .catch(() => setLoading.off())
        }
    }, [mount, setMount, setLoading, dispatch])
    
   

   
    useEffect(() => {
        setDashHeading("Notifications")
        return () => {
            setDashHeading("Dashboard")
          };
    }, [])
    
    const handleClick = async (e, notification) => {
        e.preventDefault()
        await dispatch(authAction.update_message(notification?.id))
        if(notification?.type === 'VCLASS'){
            // navigate('VirtualClasses')
            navigate('/account/virtual-classes')
        }

        if(notification?.type === 'TOPIC'){
            // navigate('VideoPlayer', { content: notification?.payload.details})
            navigate('/account/subjects/topic/'+notification?.link_id)
        }
    }

    console.log("Messages", messages)
   
    return (        
        <Box px="2em" py="5">   
            {
                loading ?
                    <Box d="flex" h="100%" alignItems="center" justifyContent="center">
                        <Spinner />
                    </Box>
                :
                <Box>
                    {
                        messages?.map((notification, n) => 
                        <Item key={n} data={notification} onClick={(e) => handleClick(e, notification)}  />
                        )
                    }                    
                </Box>
            }
            {
                (!loading && messages?.length === 0 ) &&
                <Box minH="500px" display="flex" p={5} alignItems="center" justifyContent="center" >
                    <Heading size="md">No New Notifications</Heading>
                </Box>
            }
        </Box>
    )
}

export default Notifications;
export const colors = {
    brand: {
        50: "#D7F6E3",
        100: "#D7F6E3",
        200: "#B1EECF",
        300: "#4F9982",
        400: "#4F9982",
        500: "#2E715C",
        600: "#164943",
        700: "#0F3D3C",
        800: "#092F31",
        900: "#052329",
    },
    accent:{
        50: "#FEF8F4",
        100: "#FEF0D6",
        200: "#FEDDAD",
        300: "#FCC484",
        400: "#F9AC65",
        500: "#F58634",
        600: "#D26526",
        700: "#B0491A",
        800: "#8E3010",
        900: "#751F09",
    },
    dark:{
        50: "#F0F2F6",
        100: "#F0F2F6",
        200: "#E1E5ED",
        300: "#BABEC9",
        400: "#868993",
        500: "#43454C",
        600: "#303441",
        700: "#212536",
        800: "#15182C",
        900: "#0C0F24",
    },
    flat:{            
        tomato: "#FB4630",
        pink: "#FE445F",
        purple: "#773DE3",
        indigo: "#B28FF0",
        navy: "#3C50BC",
        blue: "#3389E1",
        aqua: "#00C3ED",
        teal: "#009988",
        green: "#77C33E",
        lime: "#A8E172",
        yellow: "#FFCC34",
        orange: "#FFB718",
        gray: "#616C78"
    },
    muted:{            
        tomato: "rgba(251,70,48, 0.25)",
        pink: "rgba(254,68,95, 0.25)",
        purple: "rgba(119,61,227, 0.25)",
        indigo: "rgba(178,141,240, 0.25)",
        navy: "rgba(60,80,188, 0.25)",
        blue: "rgba(51,137,225, 0.25)",
        aqua: "rgba(0,195,237, 0.25)",
        teal: "rgba(0,189,153, 0.25)",
        green: "rgba(119,195,62, 0.25)",
        lime: "rgba(168,225,114, 0.25)",
        yellow: "rgba(255,204,52, 0.25)",
        orange: "rgba(255,183,24, 0.25)",
        gray: "rgba(97,108,120, 0.25)",
    }      
}
import React, {useState} from "react"
import { Box, Text, useBoolean, Button, Image, Heading, useToast } from "@chakra-ui/react"
import { Input } from "../../uikit";
import { Link } from "react-router-dom";
import { authApi } from "../../api";

export default function ForgetPassword(){
    const toast = useToast()
    const [error, setErrors] = useState(null)
    const [ state, setState ] = useState(null)
    const [ loading, setLoading ] = useBoolean(false)

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading.on()
        authApi._forget_password(state)
            .then((res) => {
                toast({
                    title: 'Email Sent!',
                    description: res?.message || 'Password reset link has been sent your email address. Please check your email to change your password.',
                    status: 'success',
                    variant: "solid",
                    position: 'top'
                })
                setState(null)
                setTimeout(function (){
                            window.location.href='/'  
                        }, 1500); 

                setLoading.off()
            })
            .catch((err) =>{
                setLoading.off()
                setErrors(err?.error)
                console.log("Error", err?.error)
            })
    }
    return(
        <Box position="relative" h="100vh" bg="brand.500">
            <Box position="absolute" left={0} top={0} w="100%" h="100%"  display="flex" alignItems="center" justifyContent="center" overflowY="auto">
                <Box w="100%" maxW="480px" mx="auto" bg="white" p={5} px={10} rounded="md">
                    <Image src="/assets/insaf-academy-emblem.svg" maxH="85px" mb="20px" mx="auto" />
                    <Box textAlign="center">
                        <Heading mt="5" size="md">Forget Password?</Heading>
                        <Text fontSize="sm" mb={5}>Please enter your email address below to reset your password.</Text>
                    </Box>

                    <form onSubmit={handleSubmit} id="forget-password-form">
                        <Box mb="4">
                            <Input
                                error={error && error?.email}
                                label="Email Address"
                                variant="filled"
                                value={state?.email || ''}
                                onChange={(e) => setState({...state, email: e.target.value})}
                            />
                        </Box>
                        
                        <Button type="submit" colorScheme="accent" w="100%" isLoading={loading} loadingText="Please wait...">Reset Password</Button>
                        <Box mt="5">
                            <Text variant="link" as={Link} to="/register">Don't have an account? Register Now</Text>
                        </Box>
                    </form>
                    
                </Box>
            </Box>
        </Box>
    )
}
import React, { useEffect, useState} from "react"
import { Box, Text, HStack, Heading, Button, Image, useBoolean, Menu, MenuItem, MenuButton, MenuList } from "@chakra-ui/react"
import { Helmet } from "react-helmet-async"
import { useApp } from "../../../hooks"
import { Link } from "react-router-dom"
import { BarChart } from "./Charts"
import { Icon } from "../../../uikit"
import { useDispatch, useSelector } from 'react-redux';
import { parentAction } from '../../../redux/actions';
import { useNavigate } from 'react-router-dom';
import NewChildren from "./NewChildren"

export default function Dashboard(){
    
    const dispatch = useDispatch();    
    const navigate = useNavigate()
    const [child, setChild] = useState(null)
    const [ loading, setLoading ] = useBoolean(false)
    const { children  } = useSelector(state => state?.parent)
    const {user, profile} = useApp()
    const [ isOpen, setOpen ] = useBoolean(false)

    useEffect(() => {
        setLoading.on()
        dispatch(parentAction.get())
            .then((res) => {
                setLoading.off()                
            })
            .catch((err) => {
                setLoading.off()
            })
    }, [])

    useEffect(() => {
        
        if(children?.length > 0){
            setChild(children[0])
        }
    }, [children])

    console.log("Child", children)

    const changeChild = (index) => {
        setChild(children[index])
    }
    
    return(
        <>
            <Helmet>
                <title>My Account - PCTB Online Academy</title>
                <meta name="description" content="PCTB Online Academy is FREE online learning platform for HSSC and SSC Student covering of Subjects, Assessment and other learning material to help our young genration for tapping in to the future." />
            </Helmet>
            <Box>
            <HStack px="2em" py="20px" spacing="7" justifyContent="space-between" alignItems="flex-start">
                <Box flex={3}>                    
                    <Box bg="accent.100" p="45px" rounded="xl" bgImage="/assets/dashboard-section-bg.png" bgSize="cover" >
                        <HStack position="relative" alignItems="flex-start" spacing="40px">
                            <Box w="70%">
                                <Heading mb="10px" fontWeight="800" size="lg" color="white">Welcome, {profile?.gender === 'MALE' ? 'Sir ' : 'Madam '} {user?.first_name}</Heading>
                                <Text fontSize="18px" color="rgba(255,255,255,0.75)">Add your children using their B-Form/CNIC # and keep track of their progress on PCTB Online Academy</Text>                                
                            </Box>                            

                            <Box position="absolute" right="2" top="2">                                
                            <Menu placement="bottom-end">                                
                                <MenuButton as={Box}>
                                    <HStack>
                                        <Text fontWeight="bold" fontSize='sm' color="white">
                                            {
                                                child ?
                                                child?.child?.first_name + " "+  child?.child?.last_name
                                                :
                                                'No Child Added'
                                            }
                                            
                                        </Text>
                                        <Icon name="angle-down-solid" color="white" size="12px" />
                                    </HStack>
                                </MenuButton>
                                <MenuList>
                                    {
                                        children?.map((child, c) =>
                                        <MenuItem key={c} onClick={() => changeChild(c)}>{child?.child?.first_name} {child?.child?.last_name}</MenuItem>
                                        )
                                    }
                                    
                                </MenuList>
                            </Menu>
                        </Box>
                        </HStack>

                        
                    </Box>
                                     

                    <Box my={7} flex="1" bg="white" rounded="xl" p="2" pt={4}>
                        <BarChart data={child?.profile} height="350px" title="Children Progress" />
                    </Box>
                </Box>
                <Box flex={1}>
                    

                    <Box  bg="white" mb="7" rounded="xl" p="4">
                        <Box textAlign="center" my="5">
                            <Heading mb="3" fontWeight="800" size="md">Add Children</Heading>
                            <Text>To see your children progress in the dashboard you must add</Text>
                        </Box>
                        <Box textAlign="center" mb="5">
                            <Button onClick={setOpen.on}>Add Children</Button>
                        </Box>
                    </Box>   

                    <Box  bg="white" mb="7" rounded="xl" p="6">
                        <HStack>
                            <Image boxSize="42px" src="/assets/vicon-class.png" />
                            <Heading size="md">My Children</Heading>
                        </HStack>
                            {
                                children?.map((child, c) =>
                                <Box key={c} bg="gray.100" rounded="lg" p="4" my="3" cursor="pointer" onClick={() => navigate('/parent/children/progress/'+child?.child?.id)}>
                                    <Text mb="2" fontWeight="bold">{child?.child?.first_name} {child?.child?.last_name}</Text>
                                    <Text mb="2" fontWeight="400">{child?.child?.profile?.grade?.title} {child?.child?.profile?.grade?.label}</Text>
                                    {/* <HStack justifyContent="space-between">
                                        <Text fontSize="14px">Total Students: {cls?.total_students}</Text>
                                    </HStack>
                                    <HStack justifyContent="space-between">
                                        <Text fontSize="14px">Class: {cls?.grade?.title}</Text>
                                        <Text fontSize="14px">Subject: {cls?.subject}</Text>
                                    </HStack> */}
                                </Box>
                                )
                            }
                        
                        
                        
                    </Box>                    
                </Box>
                
            </HStack>
        </Box>  
        <NewChildren open={isOpen} kids={children} onClose={setOpen.off} />      
        </>
        
    )
}
import React from "react";
import Slider from "react-slick";
import { Link } from 'react-router-dom'
import { Box, Text, Heading, HStack, Button} from '@chakra-ui/react'
import { Container } from '../../../uikit';
import { useApp } from '../../../hooks'
import { isEmpty } from "../../../helpers";
import './slick.css'

    

export const HomeSlider = () =>{

    const { user } = useApp()
    var settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        arrows: false,
        // nextArrow: <NextArrow />,
        // prevArrow: <PrevArrow />,
        autoplay: true,
        fade: true,
        autoplaySpeed: 5000,
        adaptiveHeight: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: false
        // appendDots: (dots) => <div className="my-slider-dots">{dots}</div>
    };

    
    return(
        <>
            <Slider {...settings} className="home-slider">
                <Box position="relative" py={100} d="flex" alignItems="center" bgImg="/assets/slide-01.png" bgPos="right bottom" minH="660px" bgSize="cover">                    
                    <Container  mt="7%" position="relative" zIndex="5">
                        <HStack alignItems="center" justifyContent="space-between" spacing="0">
                            <Box w={{base: '95%', md: '80%', lg: "60%" }} px={{ base: '2em', lg: '0' }}>
                                <Heading mb="20px" fontSize={{base: '30px', md: '46px', lg:"56px"}} color="white">100% Free Access to High-Quality Video Lectures </Heading>
                                <Text w="80%" fontSize={{md: "16px", lg:"20px"}} color="rgba(255,255,255,0.75)">Your complete preparation toolkit for classes IX to XI and MDCAT and ECAT entry test.</Text>

                                <Button as={Link} to={isEmpty(user) ? "/register" : "/account"} size="lg" mt={10} colorScheme="accent">Start Learning Today</Button>
                            </Box>
                            <Box w="35%">

                            </Box>
                        </HStack>
                    </Container>
                    {/* <Image zIndex="3" h={{base:"55%", md: '75%', lg: '85%'}} position="absolute" right={{base: "0rem", lg: '10rem'}} bottom="0" src="/assets/home-user.png" />                                      */}
                </Box>

                <Box position="relative" py={100} d="flex" alignItems="center" bgImg="/assets/slide-02.png" bgPos="right bottom" minH="660px" bgSize="cover">                    
                    <Container  mt="7%" position="relative" zIndex="5">
                        <HStack alignItems="center" justifyContent="space-between" spacing="0">
                            <Box w={{base: '95%', md: '80%', lg: "60%" }} px={{ base: '2em', lg: '0' }}>
                                <Heading mb="20px" fontSize={{base: '30px', md: '46px', lg:"56px"}} color="white">Interact with Students & Teachers on Forum </Heading>
                                <Text w="80%" fontSize={{md: "16px", lg:"20px"}} color="rgba(255,255,255,0.75)">Find opinion of teachers and students from across the Pakistan on different questions at our Forum</Text>

                                <Button as={Link} to={isEmpty(user) ? "/register" : "/account"} size="lg" mt={10} colorScheme="accent">Start Learning Today</Button>
                            </Box>
                            <Box w="35%">

                            </Box>
                        </HStack>
                    </Container>
                    {/* <Image zIndex="3" h={{base:"55%", md: '75%', lg: '85%'}} position="absolute" right={{base: "0rem", lg: '10rem'}} bottom="0" src="/assets/home-user.png" />                                      */}
                </Box>

                <Box position="relative" py={100} d="flex" alignItems="center" bgImg="/assets/slide-03.png" bgPos="right bottom" minH="660px" bgSize="cover">                    
                    <Container  mt="7%" position="relative" zIndex="5">
                        <HStack alignItems="center" justifyContent="space-between" spacing="0">
                            <Box w={{base: '95%', md: '80%', lg: "60%" }} px={{ base: '2em', lg: '0' }}>
                                <Heading mb="20px" fontSize={{base: '30px', md: '46px', lg:"56px"}} color="white">Take Mock Examination for Board Exams Practice </Heading>
                                <Text w="80%" fontSize={{md: "16px", lg:"20px"}} color="rgba(255,255,255,0.75)">Take our Practice/Mock Examination to test your knowledge and prepare for the board examinations.</Text>

                                <Button as={Link} to={isEmpty(user) ? "/register" : "/account"} size="lg" mt={10} colorScheme="accent">Start Learning Today</Button>
                            </Box>
                            <Box w="35%">

                            </Box>
                        </HStack>
                    </Container>
                    {/* <Image zIndex="3" h={{base:"55%", md: '75%', lg: '85%'}} position="absolute" right={{base: "0rem", lg: '10rem'}} bottom="0" src="/assets/home-user.png" />                                      */}
                </Box>

                
                
            </Slider>
        </>
    )
}
import React from "react"
import { Box, Text, Heading, HStack, IconButton,  Image, useBoolean, Button } from "@chakra-ui/react"
import { useDispatch } from "react-redux"
import { paperAction } from "../../redux/actions"
import { Icon } from "../../uikit"
import { useApp } from "../../hooks"
import { Link, useNavigate } from "react-router-dom"
import { isEmpty } from "../../helpers"

export default function SubjectItem({data, type, onClick, handleEdit, ...rest}){

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user, profile } = useApp()
    const [ duplicating, setDuplicating ] = useBoolean(false)
    const [ removing, setRemoving ] = useBoolean(false)

    const onDuplicate = () => {
        setDuplicating.on()
        dispatch(paperAction.duplicate(data?.id))
            .then((res) => setDuplicating.off())
            .catch((e) => setDuplicating.off())
    }

    
    console.log("User", user)
    console.log("Profile", profile)
    
//    
    return(
        <Box {...rest} >
            <Box p="15px">                
                <Box p="10px" bg="white" w="auto" shadow="custom" rounded="xl" cursor="pointer">                    
                    <Image src={process.env.REACT_APP_STORAGE_URL + data?.image} alt={data?.title} mx="auto" rounded="lg" />
                    <Box px="20px" py="5px" textAlign="center">
                        <Box my="5">
                            <Heading  fontSize="20px">{data?.title}</Heading>                                            
                            <HStack my={4} justifyContent="center">
                                {
                                    (type === "FLP-MDCAT" || type === 'FLP-ECAT') ?
                                    <>
                                        <Text fontSize="sm">Full Length Papers:</Text>
                                        <Box w="5px" h="5px" rounded="full" bg="accent.500" />
                                        <Text fontSize="sm">Total Exams: {data?.lecture_count}</Text>
                                    </>
                                    :
                                    <>
                                        <Text fontSize="sm">Total Topics: {data?.topics_count}</Text>
                                        <Box w="5px" h="5px" rounded="full" bg="accent.500" />
                                        <Text fontSize="sm">Total Lectures: {data?.lecture_count}</Text>
                                    </>
                                }                              
                                
                            </HStack>
                        </Box> 
                        {
                        (user  && profile) ?
                            <Button isFullWidth onClick={onClick}  size="md" rounded="lg"  colorScheme="accent">Start Learning</Button>                       
                            :
                            <Button  as={Link} isFullWidth to="/register" size="md" rounded="lg"  colorScheme="accent">Start Learning</Button>                       
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
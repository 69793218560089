import {handleResponse, requestTimeout} from '../helpers'
const apiUrl = process.env.REACT_APP_API_URL
const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME

export const virtualClassApi = {
    _get,
    _add,
    _details,
    _comment,
    _quiz,
    _quiz_results,
    _remove,
    _remove_student
};



async function _get() {    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'teacher/virtual-classes', requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _details(id) {    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'teacher/virtual-classes/'+id, requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _add(form_data) {    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        },
        body: JSON.stringify(form_data)
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'teacher/virtual-classes', requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _comment(form_data) {    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        },
        body: JSON.stringify(form_data)
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'teacher/virtual-classes/comment', requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _quiz(form_data) {    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        },
        body: JSON.stringify(form_data)
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'teacher/virtual-classes/quiz', requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _quiz_results(id) {    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'teacher/virtual-classes/results/'+id, requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _remove(id) {    
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'teacher/virtual-classes/'+id, requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}


async function _remove_student(sid, cid) {    
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'teacher/virtual-classes/students/'+cid+"/"+sid, requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

import React, { useEffect, useState } from "react"
import { Box, Spinner, useBoolean, Heading, HStack, Text, Button } from "@chakra-ui/react"
import { useApp } from '../../../hooks'
import { useDispatch, useSelector } from "react-redux"
import { gradeAction, subjectAction } from "../../../redux/actions"
import { Helmet } from "react-helmet-async"
import { Modal } from "../../../uikit"
import { useParams } from "react-router-dom"
import dayjs from "dayjs"

export default function Results () {
    
    const { setDashHeading, profile} = useApp()
    const dispatch = useDispatch()
    const { subject_id } = useParams()
    const [ details, setDetails ] = useState(null)
    const [ loading, setLoading] = useBoolean(false)
    const { results} = useSelector(state => state?.subjects)
    

    useEffect(() => {
        if(subject_id){
            setLoading.on()
            dispatch(subjectAction.results(subject_id))
            .then((res) =>{
                setLoading.off()
            })
            .catch((err) =>{
                setLoading.off()
            })   
        } 
    }, [subject_id])

    


    useEffect(() => {
        setDashHeading('Mock Exam Results')
      return () => {
        setDashHeading('Dashboard')
      };
    }, [])

    console.log("Results", results)

    return(
        <>
            <Helmet>
                <title>Subjects - PCTB Online Academy</title>
                <meta name="description" content="PCTB Online Academy is FREE online learning platform for HSSC and SSC Student covering of Subjects, Assessment and other learning material to help our young genration for tapping in to the future." />
            </Helmet>
            {
                loading ?
                    <Box d="flex" h="100%" alignItems="center" justifyContent="center">
                        <Spinner />
                    </Box>
                :
                <Box px="2em" py="3">
                    {
                        results &&
                        <>
                            <Heading size="md" fontWeight="bold" fontFamily="Poppins">{results[0]?.subject?.title} Mock Exam Results</Heading>
                            <table className="results-table">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Subject</th>
                                        <th>Total Questions</th>
                                        <th>Correct Answers</th>
                                        <th>Marks Obtained</th>
                                        <th></th>

                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    results?.map((quiz, q) =>
                                        quiz?.results?.map((result, r) =>
                                            <tr key={r}>
                                                <td>{dayjs(result?.created_at).format('DD MMM YYYY hh:mm a')}</td>
                                                <td>{quiz?.subject?.title}</td>
                                                <td>{result?.result?.total_questions}</td>
                                                <td>{result?.result?.correct}</td>
                                                <td>{result?.result?.percentage.toFixed(2)}%</td>
                                                <td><Button onClick={() => setDetails(result)} size="sm">View Details</Button></td>
                                            </tr>
                                        )
                                    )
                                }
                                </tbody>
                            </table>
                        </>
                    }
                    
                    

                </Box>
            }            
            
            <Modal 
                open={details !== null}
                onClose={() => setDetails(null)}
                title="Result Details"
                size="3xl"
                >
                <Box bg="white" rounded="lg">
                {
                    details?.details?.map((requ, b) =>                                         
                        <Box key={b} p="5" borderBottom="1px solid" borderColor="gray.200">
                            <Heading size="sm">Question No. {parseInt(b) + 1}</Heading>
                            <Box my="4" fontWeight="bold">                                
                                <div dangerouslySetInnerHTML={{__html: requ?.question}} />
                            </Box>
                            
                            <Box>
                            {
                                requ?.options?.map((anu, n) => {
                                    if(requ?.correct_answer === anu?.id ){
                                        return(
                                            <HStack p="2" rounded="md" key={n} my="2" bg='green.100'>
                                                <Text fontWeight="bold">{parseInt(n+1)}:</Text>                                                
                                                <div dangerouslySetInnerHTML={{__html: anu?.answer}} />
                                            </HStack>
                                        )
                                    }else if(requ?.answer === anu?.id && !requ?.correct ){
                                        return(
                                            <HStack p="2" rounded="md" key={n} my="2" bg='red.100'>
                                                <Text fontWeight="bold">{parseInt(n+1)}:</Text>
                                                <div dangerouslySetInnerHTML={{__html: anu?.answer}} />
                                            </HStack>
                                        )
                                    }else{
                                        return(
                                            <HStack p="2" rounded="md" key={n} my="2" bg='transparent'>
                                                <Text fontWeight="bold">{parseInt(n+1)}:</Text>
                                                <div dangerouslySetInnerHTML={{__html: anu?.answer}} />
                                            </HStack>
                                        )
                                    }
                                    
                                })
                            }
                            </Box>                                                
                        </Box>
                                                                    
                    )
                }
                </Box>
            </Modal>
        </>
    )
}
import React, {useState, useEffect} from "react"
import { Box, Text, useBoolean, Button, Image, Heading, useToast } from "@chakra-ui/react"
import { Input } from "../../uikit";
import { Link, useParams } from "react-router-dom";
import { authApi } from "../../api";

export default function ResetPassword(){
    const toast = useToast()
    const [ mount, setMount ] = useBoolean(false)
    const [ valid, setValid ] = useBoolean(false)
    const [ submitting, setSubmitting] = useBoolean(false)
    const [error, setErrors] = useState(null)
    const [ state, setState ] = useState(null)
    const [ loading, setLoading ] = useBoolean(false)
    const { hash } = useParams()

    useEffect(() => {
        if(hash && !mount){
            setMount.on()
            setLoading.on()
            authApi._check_reset({hash: hash})
                .then((res) => {
                    setLoading.off()
                    setValid.on()
                })
                .catch((error) => {
                    setLoading.off()
                    setValid.off()
                })
        }
    }, [hash, mount, setLoading, setMount, setValid])

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitting.on()
        authApi._reset_password({...state, hash: hash})
            .then((res) => {
                setSubmitting.off()
                toast({
                    title: 'Password Updated',
                    description: res?.message || 'Password updated successfully.',
                    status: 'success',
                    variant: "solid",
                    position: 'top'
                })
                setTimeout(function (){
                            window.location.href='/'  
                        }, 1500);                
            })
            .catch((err) => {
                setSubmitting.off()
                setErrors(err?.error)
            })
    }
    return(
        <Box position="relative" h="100vh" bg="brand.500">
            <Box position="absolute" left={0} top={0} w="100%" h="100%"  display="flex" alignItems="center" justifyContent="center" overflowY="auto">
                <Box w="100%" maxW="480px" mx="auto" bg="white" p={5} px={10} rounded="md">
                    <Image src="/assets/insaf-academy-emblem.svg" maxH="85px" mb="20px" mx="auto" />

                    <Box textAlign="center">
                        <Heading mt="5" size="md">Set New Password</Heading>
                        {
                            (!loading && !valid) ?
                            <Box my={5} bg="red.100" p={4} rounded="md">
                                <Text color="red.500">Invalid Reset Code</Text>
                            </Box>
                            :
                            <Text fontSize="sm" mb={5}>Please set your new password.</Text>
                        }
                        
                    </Box>
                    {
                    valid &&
                        <form onSubmit={handleSubmit} id="reset-password-form">                     
                            <Box my={5}>
                                <Input
                                    error={error?.password || error?.hash}
                                    label="Password"
                                    type="password"
                                    placeholder="Enter New Password"
                                    value={state?.password || ''}
                                    onChange={(e) => setState({...state, password: e.target.value})}
                                />                        
                            </Box>
                            <Box my={5}>
                                <Input
                                    label="Confirm Password"
                                    type="password"
                                    placeholder="Confirm New Password"
                                    value={state?.confirm_password || ''}
                                    onChange={(e) => setState({...state, confirm_password: e.target.value})}
                                />
                            </Box>

                            
                            <Button type="submit" colorScheme="accent" w="100%" isLoading={submitting} loadingText="Please wait...">Set Password</Button>
                            <Box mt="5">
                                <Text variant="link" as={Link} to="/register">Don't have an account? Register Now</Text>
                            </Box>
                        </form>
                    }
                    
                </Box>
            </Box>
        </Box>
    )
}
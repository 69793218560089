import React, { useEffect} from "react"
import { Box, Text, HStack, Heading, Button, Image, useBoolean } from "@chakra-ui/react"
import { Helmet } from "react-helmet-async"
import { useApp } from "../../../hooks"
import { Link } from "react-router-dom"
import NewClass from "../Classes/NewClass"
import { BarChart } from "./Charts"
import { useDispatch, useSelector } from 'react-redux';
import { virtualClassAction } from '../../../redux/actions';
import { useNavigate } from 'react-router-dom';

export default function Dashboard(){
    
    const dispatch = useDispatch();    
    const navigate = useNavigate()
    const [ loading, setLoading ] = useBoolean(false)
    const { classes  } = useSelector(state => state?.virtual)
    const {user, profile} = useApp()
    const [ isOpen, setOpen ] = useBoolean(false)

    useEffect(() => {
        setLoading.on()
        dispatch(virtualClassAction.get())
            .then((res) => {
                setLoading.off()                
            })
            .catch((err) => {
                setLoading.off()
            })
    }, [])


    const totalStudents = () => {
        let total = 0;
        classes?.map((cls) =>
            total += cls?.students?.length
        )
        return total;
    }

    const totalQuiz = () => {
        let total = 0;
        classes?.map((cls) =>
            total += cls?.quiz?.length
        )
        return total;
    }

    console.log("Classes", classes)
    return(
        <>
            <Helmet>
                <title>My Account - PCTB Online Academy</title>
                <meta name="description" content="PCTB Online Academy is FREE online learning platform for HSSC and SSC Student covering of Subjects, Assessment and other learning material to help our young genration for tapping in to the future." />
            </Helmet>
            <Box>
            <HStack px="2em" py="20px" spacing="7" justifyContent="space-between" alignItems="flex-start">
                <Box flex={3}>                    
                    <Box bg="accent.100" p="45px" rounded="xl" bgImage="/assets/dashboard-section-bg.png" bgSize="cover" >
                        <HStack position="relative" alignItems="flex-start" spacing="40px">
                            <Box w="70%">
                                <Heading mb="10px" fontWeight="800" size="lg" color="white">Welcome,  {user?.first_name}</Heading>
                                <Text fontSize="18px" color="rgba(255,255,255,0.75)">Create as many virtual classes you want and track progress of your students as well as create mock exams for them to assess their preparation for board exams</Text>                                
                            </Box>                            
                        </HStack>
                    </Box>
                    <HStack my="7" justifyContent="space-between" alignItems="stretch" spacing="8">
                        <Box textAlign="center" flex="1" bg="white" rounded="xl" p="4">                                                    
                            <Heading>{totalStudents()}</Heading>
                            <Text>Total Students</Text>
                        </Box>
                        <Box textAlign="center" flex="1" bg="white" rounded="xl" p="4">                                                    
                            <Heading>{totalQuiz()}</Heading>
                            <Text>Total Assessments</Text>
                        </Box>
                        <Box textAlign="center" flex="1" bg="white" rounded="xl" p="4">                                                    
                            <Heading>{classes?.length}</Heading>
                            <Text>Total Virtual Classes</Text>
                        </Box>
                    </HStack>                    

                    <Box my={7} flex="1" bg="white" rounded="xl" p="2" pt={4}>
                        <BarChart data={classes} height="350px" title="Classwise Enrollment Status" />
                    </Box>
                </Box>
                <Box flex={1}>
                    

                    <Box  bg="white" mb="7" rounded="xl" p="4">
                        <Box textAlign="center" my="5">
                            <Heading mb="3" fontWeight="800" size="md">Virtual Class</Heading>
                            <Text>Lets create your virtual class and start teaching!</Text>
                        </Box>
                        <Box textAlign="center" mb="5">
                            <Button onClick={setOpen.on}>Create Virtual Class</Button>
                        </Box>
                    </Box>   

                    <Box  bg="white" mb="7" rounded="xl" p="6">
                        <HStack>
                            <Image boxSize="42px" src="/assets/vicon-class.png" />
                            <Heading size="md">My Virtual Classes</Heading>
                        </HStack>
                        {
                            classes?.map((cls, c) =>
                            c < 5 &&
                            <Box key={c} bg="gray.100" rounded="lg" p="4" my="3" cursor="pointer" onClick={() => navigate('/dashboard/virtual-classes/students/'+cls?.id)}>
                                <Text mb="2" fontWeight="bold">{cls?.title}</Text>
                                <HStack justifyContent="space-between">
                                    <Text fontSize="14px">Registered Students:</Text>
                                    <Text fontSize="14px">{cls?.students?.length} / {cls?.total_students}</Text>
                                </HStack>
                                <HStack justifyContent="space-between">
                                    <Text fontSize="14px">Class: {cls?.grade?.title}</Text>
                                    <Text fontSize="14px">Subject: {cls?.subject}</Text>
                                </HStack>
                            </Box>
                            )
                        }
                        
                    </Box>                    
                </Box>
                
            </HStack>
        </Box>
        <NewClass open={isOpen} onClose={setOpen.off} />
        </>
        
    )
}
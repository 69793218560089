import React, { Fragment, useEffect } from 'react';
import { Box, HStack, Image, Text, Heading } from '@chakra-ui/react';
import { useApp } from '../../../hooks';
import { Icon } from '../../../uikit';


const Notifications = () => {
     
    const { setDashHeading } = useApp()

    useEffect(() => {
        setDashHeading("Notifications")
        return () => {
            setDashHeading("Dashboard")
          };
    }, [])

    
    return (
        <Box m="2em" px="2em" py="10" bg="white" rounded="lg" shadow="sm">
            <Heading  size="md">Today</Heading>
            <Box position="relative" py="2">
                <HStack alignItems="flex-start" my="2" spacing="10">
                    <Box>
                        <Text color="gray.400">10:10 AM</Text>
                    </Box>
                    <Box>
                        <HStack>
                            <Box bg="red.200" rounded="md" p="1"><Text fontSize="sm" color="white">KH</Text></Box>
                            <Text><strong>Muhammad Ahsan</strong> attach 2 files to <Text as="span" color="yellow.500">Chemistry</Text> Subject</Text>                        
                        </HStack>
                    </Box>
                    
                </HStack>

                <Box h="calc(100% - 40px)" bg="gray.300" w="1px" position="absolute" left="2%" top="40px" />
            </Box>

            <Box position="relative" py="2">
                <HStack alignItems="flex-start" my="2" spacing="10">
                    <Box>
                        <Text color="gray.400">09:30 AM</Text>
                    </Box>
                    <Box>
                        <HStack>
                            <Box bg="yellow.400" rounded="md" p="1"><Text fontSize="sm" color="white">AK</Text></Box>
                            <Text><strong>Ali Khan</strong> attach 2 files to <Text as="span" color="yellow.500">Chemistry</Text> Subject</Text>                        
                        </HStack>                        
                    </Box>
                    
                </HStack>

                <Box h="calc(100% - 40px)" bg="gray.300" w="1px" position="absolute" left="2%" top="40px" />
            </Box>

            <Box position="relative" py="2">
                <HStack alignItems="flex-start" my="2" spacing="10">
                    <Box>
                        <Text color="gray.400">09:10 AM</Text>
                    </Box>
                    <Box>
                        <HStack>
                            <Box bg="teal.400" rounded="md" p="1"><Text fontSize="sm" color="white">AK</Text></Box>
                            <Text><strong>Sohail Ahmad</strong> accepted your invitation to Biology subject</Text>                        
                        </HStack>                        
                    </Box>
                    
                </HStack>

                <Box h="calc(100% - 40px)" bg="gray.300" w="1px" position="absolute" left="2%" top="40px" />
            </Box>

            <Box position="relative" py="2">
                <HStack alignItems="flex-start" my="2" spacing="10">
                    <Box>
                        <Text color="gray.400">08:30 AM</Text>
                    </Box>
                    <Box>
                        <HStack>
                            <Box bg="green.400" rounded="md" p="1"><Text fontSize="sm" color="white">AK</Text></Box>
                            <Text><strong>Sohail Ahmad</strong> accepted your invitation to Biology subject</Text>                        
                        </HStack>                        
                    </Box>
                    
                </HStack>

                <Box h="calc(100% - 40px)" bg="gray.300" w="1px" position="absolute" left="2%" top="40px" />
            </Box>


            <Heading  my="10" size="md">Yesterday</Heading>
            <Box position="relative" py="2">
                <HStack alignItems="flex-start" my="2" spacing="10">
                    <Box>
                        <Text color="gray.400">10:10 AM</Text>
                    </Box>
                    <Box>
                        <HStack>
                            <Box bg="red.200" rounded="md" p="1"><Text fontSize="sm" color="white">KH</Text></Box>
                            <Text><strong>Muhammad Ahsan</strong> attach 2 files to <Text as="span" color="yellow.500">Chemistry</Text> Subject</Text>                        
                        </HStack>
                    </Box>
                    
                </HStack>

                <Box h="calc(100% - 40px)" bg="gray.300" w="1px" position="absolute" left="2%" top="40px" />
            </Box>

            <Box position="relative" py="2">
                <HStack alignItems="flex-start" my="2" spacing="10">
                    <Box>
                        <Text color="gray.400">09:30 AM</Text>
                    </Box>
                    <Box>
                        <HStack>
                            <Box bg="yellow.400" rounded="md" p="1"><Text fontSize="sm" color="white">AK</Text></Box>
                            <Text><strong>Ali Khan</strong> attach 2 files to <Text as="span" color="yellow.500">Chemistry</Text> Subject</Text>                        
                        </HStack>                        
                    </Box>
                    
                </HStack>

                <Box h="calc(100% - 40px)" bg="gray.300" w="1px" position="absolute" left="2%" top="40px" />
            </Box>

            <Box position="relative" py="2">
                <HStack alignItems="flex-start" my="2" spacing="10">
                    <Box>
                        <Text color="gray.400">09:10 AM</Text>
                    </Box>
                    <Box>
                        <HStack>
                            <Box bg="teal.400" rounded="md" p="1"><Text fontSize="sm" color="white">AK</Text></Box>
                            <Text><strong>Sohail Ahmad</strong> accepted your invitation to Biology subject</Text>                        
                        </HStack>                        
                    </Box>
                    
                </HStack>

                <Box h="calc(100% - 40px)" bg="gray.300" w="1px" position="absolute" left="2%" top="40px" />
            </Box>

            <Box position="relative" py="2">
                <HStack alignItems="flex-start" my="2" spacing="10">
                    <Box>
                        <Text color="gray.400">08:30 AM</Text>
                    </Box>
                    <Box>
                        <HStack>
                            <Box bg="green.400" rounded="md" p="1"><Text fontSize="sm" color="white">AK</Text></Box>
                            <Text><strong>Sohail Ahmad</strong> accepted your invitation to Biology subject</Text>                        
                        </HStack>                        
                    </Box>
                    
                </HStack>

                <Box h="calc(100% - 40px)" bg="gray.300" w="1px" position="absolute" left="2%" top="40px" />
            </Box>


        </Box>
    )
}

export default Notifications;
import React, {useEffect} from 'react'
import { useBoolean } from '@chakra-ui/react'
import {Route, Routes as Switch } from 'react-router-dom'
import { Box, HStack } from '@chakra-ui/react'
import { Header, Sidebar } from './Components'
import { useApp } from '../../hooks'
import Dashboard from '../../pages/Account/Dashboard'
import Subjects from '../../pages/Account/Subjects'
import SubjectDetails from '../../pages/Account/SubjectDetails'
import Topic from '../../pages/Account/Topic'
import Notifications from '../../pages/Account/Notifications'
import Quiz from '../../pages/Account/Quiz'
import MockExams from '../../pages/Account/MockExams'
import Papers from '../../pages/Account/Papers'
import Ecat from '../../pages/Account/Papers/Ecat'
import PaperDetails from '../../pages/Account/Papers/Details'
import Results from '../../pages/Account/MockExams/Results'
import NewExam from '../../pages/Account/MockExams/NewExam'
import VirtualClasses from '../../pages/Account/VirtualClasses'
import ClassDetails from '../../pages/Account/VirtualClasses/ClassDetails'
import Assessment from '../../pages/Account/VirtualClasses/Assessment'
import Badges from '../../pages/Account/Badges'
import Settings from '../../pages/Account/Settings'
import Search from '../../pages/Account/Search'
import LiveSessions from '../../pages/Account/LiveSessions'
import CATExam from '../../pages/Account/Papers/MockExams'
import CATResults from '../../pages/Account/Papers/CatResults'
import CatSubjectDetails from '../../pages/Account/Papers/SubjectDetails'
import Lecture from '../../pages/Account/Papers/Lecture'
import CatQuiz from '../../pages/Account/Papers/CatQuiz'
import CatTopicQuiz from '../../pages/Account/Papers/CatTopicQuiz'

export default function AccountLayout() {
    
    
    const {profile, user} = useApp()
    
    if(!profile || window.is_empty(user)){
        window.location.href="/"
    }

    if(user?.group === 'TEACHER'){
        window.location.href="/dashboard"
    }
    else if(user?.group === 'PARENT'){
        window.location.href="/parent"
    }
    else{        
        return (
            <>
                <HStack bg="white" h="100vh" overflowY="hidden" spacing="0" alignItems="flex-start">
                    <Sidebar />
                    <Box flex="1" h="100%" overflowY="auto" bg="#E8E6E6" pt="80px">                     
                        <Header />
                        <Switch>
                            <Route exact path="/" element={<Dashboard />} />
                            <Route exact path="/subjects" element={<Subjects />} />
                            <Route exact path="/subjects/:subject_id" element={<SubjectDetails />} />
                            <Route exact path="/subjects/topic/:topic_id" element={<Topic />} />
                            <Route exact path="/notifications" element={<Notifications />} />
                            <Route exact path="/subjects/quiz/:quiz_id" element={<Quiz />} />
                            <Route exact path="/mock-exams" element={<MockExams />} />
                            <Route exact path="/mdcat" element={<Papers />} />
                            <Route exact path="/ecat" element={<Ecat />} />
                            <Route exact path="/mdcat/subject/:subject_id" element={<CatSubjectDetails type="MDCAT" />} />
                            <Route exact path="/ecat/subject/:subject_id" element={<CatSubjectDetails type="ECAT" />} />
                            <Route exact path="/mdcat/lecture/:topic_id" element={<Lecture type="MDCAT" />} />
                            <Route exact path="/ecat/lecture/:topic_id" element={<Lecture type="ECAT" />} />
                            <Route exact path="/mdcat/:slug" element={<PaperDetails />} />
                            <Route exact path="/ecat/:slug" element={<PaperDetails />} />
                            <Route exact path="/mdcat/mock-exam" element={<CATExam />} />
                            <Route exact path="/ecat/mock-exam" element={<CATExam />} />
                            <Route exact path="/mdcat/results" element={<CATResults />} />
                            <Route exact path="/ecat/results" element={<CATResults />} />
                            <Route exact path="/mock-exams/quiz/:quiz_id" element={<Quiz />} />
                            <Route exact path="/mock-exams/results/:subject_id" element={<Results />} />
                            <Route exact path="/mock-exams/:subject_id" element={<NewExam />} />
                            <Route exact path="/virtual-classes" element={<VirtualClasses />} />
                            <Route exact path="/live-sessions" element={<LiveSessions />} />
                            <Route exact path="/virtual-classes/:class_id" element={<ClassDetails />} />
                            <Route exact path="/virtual-classes/assessment/:quiz_id" element={<Assessment />} />
                            <Route exact path="/badges" element={<Badges />} />
                            <Route exact path="/settings" element={<Settings />} />
                            <Route exact path="/search/:query" element={<Search />} />
                            <Route exact path="/cat-mock-exams/:quiz_id" element={<CatQuiz />} />

                            <Route exact path="/mdcat-topic-quiz/:quiz_id" element={<CatTopicQuiz />} />
                            <Route exact path="/ecat-topic-quiz/:quiz_id" element={<CatTopicQuiz />} />

                            
                        </Switch>
                    </Box>
                </HStack>
            </>

        )
    }
}


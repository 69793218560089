import React, {useEffect, useState} from "react"
import { Box, Text, Heading, Image, HStack, Button } from '@chakra-ui/react'
import { Link } from "react-router-dom"

export const SubjectCard = ({subject, grade_slug, mockExam, ...rest}) => {


  

    const getTotalLectures = () => {
        let total = 0;
        subject?.chapters?.map( x => {
            x?.topics?.map(y => {
                y?.contents?.map( z => {
                    if(z?.type === "VIDEO"){
                        total++;
                    }
                    return null;
                })
                return null;
            })
            return null;
        })
        return total;
    }

    



    return(
        <Box {...rest}>
            <Box p="15px">                
                <Box p="10px" bg="white" w="auto" shadow="custom" rounded="xl">                    
                    <Image src={process.env.REACT_APP_STORAGE_URL + subject?.image?.url} alt={subject?.title} mx="auto" rounded="lg" />
                    <Box px="20px" py="5px" textAlign="center">
                        <Box my="5">
                            <Heading fontFamily="Poppins" fontSize="24px">{subject?.title}</Heading>                                                                        
                            <HStack my={4} justifyContent="center">
                                <Text fontSize="sm">Total Chapters: {subject?.chapters?.length || 0}</Text>
                                <Box w="5px" h="5px" rounded="full" bg="accent.500" />
                                <Text fontSize="sm">Total Lectures: {getTotalLectures()}</Text>
                            </HStack>
                        </Box>                        
                        <Button isFullWidth as={Link} to={'/subject/'+subject?.id} size="md" rounded="lg"  colorScheme="accent">Start Learning</Button>
                        
                        
                    </Box>
                </Box>
                
            </Box>
        </Box>
    )
}
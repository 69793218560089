import React, { useEffect, useState} from 'react'
import { Box, Heading, useBoolean, HStack, Spinner, Text, Stack, Avatar, Button, useToast, toast} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { useApp } from '../../../hooks'
import { useDispatch, useSelector } from 'react-redux'
import { Input } from '../../../uikit'
import { vClassAction } from '../../../redux/actions'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'

var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)


export default function ClassDetails({id, ...rest}){

    const dispatch = useDispatch()
    const { class_id } = useParams()
    const navigate = useNavigate()
    const toast = useToast()
    const { setDashHeading, user } = useApp()
    const [ submitting, setSubmitting ] = useState(null)
    const [state, setState ] = useState({})
    const [ loading, setLoading] = useBoolean(false)
    const [ replyForm, setReplyForm] = useState(null)
    const [ reply, setReply] = useState('')
    const { virtual_class } = useSelector(state => state?.vclass)

    const handleInputChange = (name, value) => {
        setState({
            ...state,
            [name]: value
        })
    }

    useEffect(() => {
        if(class_id){
            setLoading.on()
            dispatch(vClassAction.details(class_id))
                .then((res) => {
                    setLoading.off()
                })
                .catch((err) => {
                    setLoading.off()
                })
        }
    }, [class_id])


    useEffect(() => {
        setDashHeading("Virtual Class")
        return () => {
            setDashHeading("Dashboard")
          };
    }, [])


    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitting('q')
        dispatch(vClassAction.comment({virtual_class_id: class_id, parent_id: '', comments: state?.comments}))
            .then((res) => {
                setSubmitting(null)
                toast({
                    title: 'Comment submitted',
                    description: res?.message,
                    status: "success",
                    position: "top"
                    
                })
                setState(null)

            })
            .catch((err) => setSubmitting(null))

    }

    const handleReply = (id) => {    
        setSubmitting(id)
        dispatch(vClassAction.comment({virtual_class_id: class_id, parent_id: id, comments: reply}))
            .then((res) => {
                setSubmitting(null)
                toast({
                    title: 'Comment submitted',
                    description: res?.message,
                    status: "success",
                    position: "top"
                    
                })
                setState(null)
                setReply(null)
                setReplyForm(null)

            })
            .catch((err) => setSubmitting(null))

    }
    
    

    if(loading){
        return(
            <Box d="flex" h="100%" alignItems="center" justifyContent="center">
                <Spinner />
            </Box>
        )
    }


    return(        
        <Box>
            {
                virtual_class ?
                <Stack direction={{ base: "column", md: "row" }} px="2em" spacing={7} py="20px" justifyContent="space-between" alignItems={{base: "normal", md: "flex-start"}}>                
                    <Box flex={2}>  
                        <Heading mb="5" size="lg">{virtual_class?.title}</Heading>   
                        <Text>{virtual_class?.description}</Text>                

                        <Box my="8" w="100%">
                            <HStack justifyContent="flex-start">
                                <Heading alignSelf="flex-start" mb="5" py="2" borderBottom="2px solid" borderColor="brand.400" size="sm">Discussion Board</Heading>
                            </HStack>

                            <Stack direction={{ base: "column", md: "row" }} my={5}  spacing="4">
                                <Avatar display={{ base: "none", md: "block" }} name='Kent Dodds' visibility="hidden" src='https://bit.ly/kent-c-dodds' />
                                <Box flex={1} w="100%"  p="5" bg='white' rounded="lg" my={5}>
                                    <Input 
                                        w="100%"
                                        placeholder="Enter your question..."
                                        variant="filled"
                                        textarea 
                                        value={state?.comments || ''}
                                        onChange={(e) => handleInputChange('comments', e.target.value)}
                                    />
                                    <Button isLoading={submitting === 'q'} onClick={handleSubmit} mt={3} size="sm">Ask a Question</Button>
                                </Box>
                            </Stack>
                            {
                                virtual_class?.comments?.map((comment, c) =>
                                <HStack key={c} alignItems="flex-start" spacing="4" mb="3">
                                    <Avatar size="md" name={comment?.user?.first_name + " " + comment?.user?.last_name} src={comment?.user?.avatar ? process.env.REACT_APP_STORAGE_URL+comment?.user.avatar.url :  "/assets/avatar.svg"} />
                                    <Box flex={1} p="5" bg="gray.50" rounded="lg">
                                        <Text fontSize="12px" color="gray.600">{dayjs(comment?.created_at).fromNow()}</Text>
                                        <HStack mb="3" alignItems="center">
                                            <Text  fontSize="16px" fontWeight="bold">{comment?.user?.first_name + " " + comment?.user?.last_name}</Text>
                                            {comment?.user_id === virtual_class?.teacher_id && 
                                                <Box bg="green.500" color="white" fontSize="12px" px="3" py={1} rounded="lg" > 
                                                    Teacher
                                                </Box>
                                            }
                                        </HStack>
                                        <Text fontSize="13px">{comment?.comments}</Text>
                                        <Button onClick={() => setReplyForm(comment?.id)} mt={3} size="sm" variant="link">Reply</Button>
                                        <Box>
                                            {
                                                replyForm === comment?.id &&
                                                <Box bg="white" rounded="lg" p="4" border="1px solid" borderColor="gray.200">
                                                    <Input 
                                                            placeholder="Enter your reply..."
                                                            variant="filled"
                                                            textarea 
                                                            value={reply || ''}
                                                            onChange={(e) => setReply(e.target.value)}
                                                        />
                                                        <Button isLoading={submitting === comment?.id} onClick={() => handleReply(comment?.id)} mt={3} size="sm">Submit</Button>
                                                </Box>
                                            }
                                        </Box>
                                        {
                                            comment?.replies?.length > 0 &&                                        
                                            <Box my={2}  rounded="lg" p={3}>
                                                {
                                                    comment?.replies?.map((rep, r) =>
                                                    <Box p={5} bg="gray.100" my={2} key={r} rounded="lg">
                                                        <Text fontSize="12px" color="gray.600">{dayjs(rep?.created_at).fromNow()}</Text>
                                                        <HStack mb="3" alignItems="center">
                                                            <Text fontWeight="700">{rep?.user?.first_name} {rep?.user?.last_name}</Text>
                                                            {rep?.user_id === virtual_class?.teacher_id && 
                                                                <Box bg="green.500" color="white" fontSize="10px" px="3" py={1} rounded="lg" > 
                                                                    Teacher
                                                                </Box>
                                                            }
                                                        </HStack>
                                                        
                                                        <Text fontSize="13px">{rep?.comments}</Text>
                                                    </Box>
                                                    )
                                                }
                                            </Box>
                                        }
                                    </Box>
                                </HStack>
                                )
                            }
                            
                        </Box>



                    </Box>
                    <Box flex={1} w="100%">                    
                        <Box bg="white" rounded="lg" p={6}>
                            <Heading size="md">Assessments</Heading>
                            {
                                virtual_class?.quiz?.map((quiz, q) =>
                                <Box my={3} key={q}>                                    
                                    <HStack px="3" cursor="pointer"  rounded="lg" border="1px solid" bg="gray.100" borderColor="gray.100" p="5" justifyContent="space-between">
                                        <Box flex="1">
                                            <Text fontWeight="700">{quiz?.title}</Text>
                                            {
                                                quiz?.result ?
                                                <HStack>
                                                    <Text fontSize="12px">Correct: {quiz?.result?.result?.correct}</Text>
                                                    <Text fontSize="12px">Obtained: {quiz?.result?.result?.percentage}%</Text>
                                                    <Text fontSize="12px">Level: {quiz?.settings?.level}</Text>
                                                </HStack>
                                                :
                                                <>
                                                    <HStack>
                                                        <Text fontSize="12px">Duration: {quiz?.settings?.duration} min</Text>
                                                        <Text fontSize="12px">Passing: {quiz?.settings?.passing} %</Text>
                                                        <Text fontSize="12px">Level: {quiz?.settings?.level}</Text>
                                                    </HStack>
                                                    <Button mt={4} size="sm" onClick={() => navigate('/account/virtual-classes/assessment/'+quiz?.id)}>Take Exam</Button>
                                                </>
                                            }
                                            
                                        </Box>
                                    </HStack>
                                </Box>
                                )
                            }
                        </Box>
                    </Box>
                </Stack>
                :

                <Box p={6}>
                    <Box bg="yellow.100">No class Founed</Box>
                </Box>
            }
            
        </Box>
    )
}
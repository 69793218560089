import React, { useState} from "react";
import { Box, Heading, HStack, Text, Stack, Button, useToast} from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'
import { Container, Icon, Input } from "../../uikit";
import { useParams } from "react-router-dom";


export default function Contact() {
    
    const [state, setState] = useState(null)
    const toast = useToast()

    const handleSubmit = (e) => {
        e.preventDefault()
        if(!state?.first_name || state?.first_name === ''){
            toast({
                title: "Validation Error",
                description: "First name is required",
                status: "error",
                position: "top",
                isClosable: true
            })
        }
        else if(!state?.last_name || state?.last_name === ''){
            toast({
                title: "Validation Error",
                description: "Last name is required",
                status: "error",
                position: "top",
                isClosable: true
            })
        }
        else if(!state?.email || state?.email === ''){
            toast({
                title: "Validation Error",
                description: "Email is required",
                status: "error",
                position: "top",
                isClosable: true
            })
        }
        else if(!state?.message || state?.message === ''){
            toast({
                title: "Validation Error",
                description: "Message is required",
                status: "error",
                position: "top",
                isClosable: true
            })
        }else{
            setState(null)
            toast({
                title: "Success",
                description: "Thanks for contacting us. We will get back to you soon.",
                status: "success",
                position: "top",
                isClosable: true
            })

            

        }
    }
    return (
        <>
            <Helmet>
                <title> Get In Touch - PCTB Online Academy</title>
                <meta name="description" content="PCTB Online Academy is FREE online learning platform for HSSC and SSC Student covering of Subjects, Assessment and other learning material to help our young genration for tapping in to the future." />
            </Helmet>
            <Box  pt="100px" bgBlendMode="overlay" bgImage="/assets/pakistan-banner.png" bgRepeat="no-repeat" bgSize="cover" bgPosition="center center">
                <Box py="5em" >
                    <Container>
                        <HStack flexDir={{ base: 'column', lg: 'row' }} alignItems="flex-end">
                            <Box flex="1">
                                <Text variant="heading-title">Looking For Help?</Text>
                                <Heading color="white">Get In Touch</Heading>
                            </Box>
                            <Box w="40%" textAlign="right">
                            
                            </Box>
                        </HStack>
                    </Container>
                </Box>
            </Box>
            <Box py="4em">
                <Container>                    
                    <Stack flexDir={{ base: 'column', lg: 'row' }} alignItems="flex-start" spacing={10}>
                        <Box flex={1}>
                            <HStack my={10} alignItems="flex-start" spacing={4}>
                                <Icon fontSize={32} name="map-marked-alt-solid" color="brand.500" />
                                <Box>
                                    <Text fontWeight="bold">Address</Text>
                                    <Text>Punjab Curriculum & Textbook Board (PCTB)</Text>
                                    <Text>Block E2 Block E 2 Gulberg III</Text>
                                    <Text>Lahore, Punjab</Text>
                                    <Text>Pakistan</Text>
                                </Box>
                            </HStack>

                            <HStack my={10} alignItems="flex-start" spacing={4}>
                                <Icon fontSize={32} name="phone-solid" color="brand.500" />
                                <Box>
                                    <Text fontWeight="bold">Phone</Text>
                                    <Text>(042) 99230672</Text>                                    
                                </Box>
                            </HStack>

                            <HStack my={10} alignItems="flex-start" spacing={4}>
                                <Icon fontSize={32} name="envelope-solid" color="brand.500" />
                                <Box>
                                    <Text fontWeight="bold">Email</Text>
                                    <Text>info@pctb.punjab.gov.pk</Text>                                    
                                </Box>
                            </HStack>

                            


                            
                        </Box>
                        <Box flex={1} w="100%">
                            <form onSubmit={handleSubmit} id="contact-form">
                                <HStack mb={10} alignItems={"flex-start"} spacing={7}>
                                    <Input 
                                        variant="filled"
                                        label="First Name"
                                        value={state?.first_name || ""}
                                        onChange={(e) => setState({...state, first_name: e.target.value})}
                                    />
                                    <Input 
                                        variant="filled"
                                        label="Last Name"
                                        value={state?.last_name || ""}
                                        onChange={(e) => setState({...state, last_name: e.target.value})}
                                    />
                                    
                                </HStack>

                                <HStack mb={10} alignItems={"flex-start"} spacing={7}>
                                    <Input 
                                        variant="filled"
                                        label="Email Address"
                                        value={state?.email || ""}
                                        onChange={(e) => setState({...state, email: e.target.value})}
                                    />
                                    <Input 
                                        variant="filled"
                                        label="Phone Number"
                                        value={state?.phone || ""}
                                        onChange={(e) => setState({...state, phone: e.target.value})}
                                    />
                                    
                                </HStack>
                                <Box mb={10}>
                                    <Input
                                        variant="filled"
                                        label="Question/Message"
                                        value={state?.message}
                                        textarea
                                        onChange={(e) => setState({...state, message: e.target.value})}
                                    />
                                </Box>
                                <Button type="submit">Contact</Button>
                            </form>
                        </Box>
                    </Stack>

                </Container>
            </Box>
            
        </>
    )
}
import React from "react"
import { Box, Heading, HStack, Text } from "@chakra-ui/react"
import { Modal } from "../../../uikit"

const aplhabets = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]

export default function Results ({result, open, onClose, ...rest}) {
    
    return(
        <>                  
            <Modal 
                open={open}
                onClose={onClose}
                title="Result Details"
                size="3xl"
                >
                <Box bg="white" rounded="lg">
                {
                    result?.details?.map((requ, b) =>                                         
                        <Box key={b} p="5" borderBottom="1px solid" borderColor="gray.200">
                            <Heading size="sm">Question No. {parseInt(b) + 1}</Heading>
                            <Box my="4" fontWeight="bold">{requ?.question}</Box>
                            <Box>
                            {
                                (requ?.answer === null) &&
                                    <Box bg="red.50" px={3} py={2} border="1px solid" borderColor="red.500">
                                        <Text color="red.600" fontSize="14px">No Attempt</Text>                                
                                    </Box>
                            }
                            {
                                requ?.options?.map((anu, n) => {                                    
                                    if(requ?.correct_answer === anu?.id && requ?.correct ){
                                        return(
                                            <HStack p="2" rounded="md" key={n} my="2" bg='green.100'>
                                                <Text fontWeight="bold">{aplhabets[n]}:</Text>
                                                <Text>{anu?.answer}</Text>
                                            </HStack>
                                        )
                                    }else if(requ?.answer === anu?.id && !requ?.correct ){
                                        return(
                                            <HStack p="2" rounded="md" key={n} my="2" bg='red.100'>
                                                <Text fontWeight="bold">{aplhabets[n]}:</Text>
                                                <Text>{anu?.answer}</Text>
                                            </HStack>
                                        )
                                    }else if(requ?.answer !== anu?.id && requ?.correct_answer === anu?.id ){
                                        return(
                                            <HStack p="2" rounded="md" key={n} my="2" bg='green.100'>
                                                <Text fontWeight="bold">{aplhabets[n]}:</Text>
                                                <Text>{anu?.answer}</Text>
                                            </HStack>
                                        )
                                    }else{
                                        return(
                                            <HStack p="2" rounded="md" key={n} my="2" bg='yellow.50'>
                                                <Text fontWeight="bold">{aplhabets[n]}:</Text>
                                                <Text>{anu?.answer}</Text>
                                            </HStack>
                                        )
                                    }
                                    
                                })
                            }                             
                            </Box>   
                                                                         
                        </Box>
                                                                    
                    )
                }
                </Box>
            </Modal>
        </>
    )
}
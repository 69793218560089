export const button = {
        baseStyle: {
            backgroundColor: 'brand',
            rounded: 'md',
            _focus: { 
                boxShadow: 'none' 
            }
        },
        variants:{
            action: {
                py: 5,
                px: 8,
                rounded: '4px',
                fontSize: "12px",
                bg: 'brand.500',
                color: 'white',
                _hover:{
                    bg: 'brand.700'
                }
            },
            link:{
                _hover:{
                    textDecoration: 'none'
                }
            }

        },
        defaultProps: {
            colorScheme: 'accent'
        },
}
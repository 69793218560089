export const states = [
    {
        "name": "Islamabad Capital Territory",
        "isoCode": "IS",
        "countryCode": "PK",
        "latitude": "33.72049970",
        "longitude": "73.04052770"
    },
    {
        "name": "Gilgit-Baltistan",
        "isoCode": "GB",
        "countryCode": "PK",
        "latitude": "35.80256670",
        "longitude": "74.98318080"
    },
    {
        "name": "Khyber Pakhtunkhwa",
        "isoCode": "KP",
        "countryCode": "PK",
        "latitude": "34.95262050",
        "longitude": "72.33111300"
    },
    {
        "name": "Azad Kashmir",
        "isoCode": "JK",
        "countryCode": "PK",
        "latitude": "33.92590550",
        "longitude": "73.78103340"
    },
    {
        "name": "Federally Administered Tribal Areas",
        "isoCode": "TA",
        "countryCode": "PK",
        "latitude": "32.66747600",
        "longitude": "69.85974060"
    },
    {
        "name": "Balochistan",
        "isoCode": "BA",
        "countryCode": "PK",
        "latitude": "28.49073320",
        "longitude": "65.09577920"
    },
    {
        "name": "Sindh",
        "isoCode": "SD",
        "countryCode": "PK",
        "latitude": "25.89430180",
        "longitude": "68.52471490"
    },
    {
        "name": "Punjab",
        "isoCode": "PB",
        "countryCode": "PK",
        "latitude": "31.14713050",
        "longitude": "75.34121790"
    },
]
import { SubjectConstants as Constants } from '../types'

const initialState = {
    subject: null,   
    subjects: [],
    topic: null,
    quiz: null,
    badges: [],
    results: null
};

export function subjectReducer(state = initialState, action) {
    switch (action.type) {  
        
        case Constants.GET:
            return {
                ...state,
                subjects: action.payload.subjects,                
            };

        case Constants.BADGES:
            return {
                ...state,
                badges: action.payload.badges,
            };

        
        case Constants.DETAILS:
            return {
                ...state,
                subject: action.payload.subject,                
            };

        case Constants.TOPIC:
            return {
                ...state,
                topic: action.payload.topic,                
            };

        case Constants.QUIZ:
            return {
                ...state,
                quiz: action.payload.quiz,                
            };
        

        case Constants.RESULTS:
            return({
                ...state,
                results: action.payload.results
            })
    
       
        case Constants.FAILED:
            return{
                ...state
            }        
        case Constants.CLEAR:
            return{
                ...state,
                subjects: [],
                subject:null,
                topic: null,
                quiz: null,
                results: null
            };
        
        default:
            return {
                ...state
            }
    }
}
import React, { useEffect } from "react";
import { Link, useMatch } from "react-router-dom";
import { Image, HStack, Box, Button, Heading, Text, useBoolean, Avatar, IconButton, VStack} from "@chakra-ui/react";
import { Container, Icon, Drawer, Popover } from "../../../uikit";
import {Login} from ".";
import { authAction, gradeAction } from "../../../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import { useApp } from "../../../hooks";
import { isEmpty } from "../../../helpers";
import Cookies from 'js-cookie'

export const Header = ({ setHeight, classes }) => {

    let headerRef = React.createRef()
    const dispatch = useDispatch()
    const [mount, setMount] = useBoolean()
    const [ menu, setMenu] = useBoolean(false)
    const [showLogin, setShowLogin] = useBoolean(false)
    const auth = useApp()
    const { user } = auth

    const match = useMatch('/');



    const [ loading, setLoading ] = useBoolean(false)
    const { with_subjects_minimal } = useSelector(state => state?.grades)

    useEffect(() => {
        setLoading.on()
        dispatch(gradeAction.with_subjects_minimal())
            .then((res) => setLoading.off())
            .catch((err) => setLoading.off())
    }, [])


    useEffect(() => {
        if (!mount && headerRef?.clientHeight) {
            setHeight(headerRef.clientHeight)
            setMount.on()
        }
    }, [setMount, mount, setHeight, headerRef?.clientHeight])

    
    
    const handleLogout = () => {       
        localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN);
        Cookies.remove('insaf-auth-token', { domain: process.env.REACT_APP_BASE_DOMAIN })
        dispatch(authAction.clear())
        window.location.href="/"
    }

    

    // {...!match && {borderBottom: '1px solid', borderColor: 'whiteAlpha.500'}}
    return (
        <Container zIndex="99" ref={header => headerRef = header} fluid px="2em" py="3" position="absolute" left="0" top="0"  borderBottom='1px solid' borderColor='whiteAlpha.500' backdropFilter="blur(5px)">
            <Container mx="auto">
                <HStack alignItems="center" justifyContent="space-between">                
                    <Box as={Link} to="/">
                        <Image src="/assets/pctb-academy-logo.svg" w="auto" h="35px" alt="PCTB Online Academy" />
                    </Box>
                    
                    <Box>                    
                        <HStack alignItems="center" spacing={6}>
                            <HStack d={{ base: 'none', md: 'none', lg: 'flex' }} spacing={5}>
                                <Button  as={Link}  fontWeight="normal" to="/" color="rgba(255,255,255,0.65)" _hover={{ color: "white" }}  variant="link">Home</Button>
                                <Box>
                                    <Popover                                    
                                        showArrow
                                        trigger={<Button  fontWeight="normal" variant="link" color="rgba(255,255,255,0.65)" _hover={{ color: "white" }}  rightIcon={<Icon name="angle-down-solid" color="currentcolor" />}>Classes &amp; Subjects</Button>}
                                        >
                                        <Box p="2">
                                            <Heading mb="5" mt="3" size='md'>Browse Classes &amp; Subjects</Heading>
                                            <HStack w="600px" flexWrap="wrap" spacing="0" alignItems="flex-start">
                                                {[...with_subjects_minimal].reverse().map((grade, idx) => (<Box w="33.3%" mb="5" key={idx}>
                                                    <Box mb="3">
                                                        <Box fontWeight="bold" color="brand.400" >{grade?.title} {grade?.label}</Box>
                                                    </Box>
                                                    {grade?.subjects?.map((subject, idx) => (<Text key={idx} variant="link" as={"a"} href={`/subject/${subject.id}`}>{subject.title}</Text>))}
                                                </Box>))}
                                            </HStack>
                                        </Box>
                                    </Popover>
                                </Box>
                                <Button  as={Link} fontWeight="normal" to="/mdcat" color="rgba(255,255,255,0.65)" _hover={{ color: "white" }}  variant="link">MDCAT</Button>
                                <Button  as={Link} fontWeight="normal" to="/ecat" color="rgba(255,255,255,0.65)" _hover={{ color: "white" }}  variant="link">ECAT</Button>
                                <Button  as={Link} fontWeight="normal" to="/mock-exam" color="rgba(255,255,255,0.65)" _hover={{ color: "white" }}  variant="link">Mock Exam</Button>
                                <Button  as={Link} fontWeight="normal" to="/about" color="rgba(255,255,255,0.65)" _hover={{ color: "white" }}  variant="link">About</Button>
                                <Button  as={Link} fontWeight="normal" to="/contact" color="rgba(255,255,255,0.65)" _hover={{ color: "white" }}  variant="link">Contact</Button>
                                {/* <Button  as={Link} fontWeight="normal" to="/" color="rgba(255,255,255,0.65)" _hover={{ color: "white" }}  variant="link">
                                    <Icon name="search-solid" color="currentcolor" />
                                </Button> */}
                            </HStack>
                            <HStack>
                                {
                                    isEmpty(user) ?
                                    <Button onClick={setShowLogin.on} size="sm" colorScheme="accent">Sign In</Button>
                                    :
                                    <Box>
                                        <Popover
                                            showArrow
                                            gutter={9}
                                            trigger={<Avatar size='sm' name={user?.fullName} src={user?.avatar ? process.env.REACT_APP_STORAGE_URL+user.avatar?.url :  "/assets/avatar.svg"} />}
                                        >
                                            <Box w="200px" px="2">
                                                <Box my="2" _hover={{ color: 'brand.400' }} d="block" fontSize="sm" as={Link} to="/account">My Profile</Box>
                                                <Box my="2" _hover={{ color: 'brand.400' }} d="block" fontSize="sm" as={Link} to="#" onClick={handleLogout}>Logout</Box>
                                            </Box>
                                        </Popover>
                                    </Box>
                                }
                                <IconButton onClick={setMenu.on} d={{ base: 'flex', lg: 'none' }} icon={<Icon fontSize="22px" name="bars-solid" color="white"  />} variant="ghost" colorScheme="white" />
                            </HStack>
                            
                        </HStack>
                    </Box>
                    
                    {/* <HStack alignItems="center" spacing="4">
                        {
                            isEmpty(user) ?
                                <>
                                    <Button onClick={setShowLogin.on} variant="link">Login</Button>
                                    <Button as={Link} to="/register" variant="action_accent" size="sm">Start Learning Today</Button>
                                </>
                                :
                                <>
                                    <IconButton size="sm" rounded="full" icon={<Icon fontSize="18px" color="currentcolor" name="bell" />} variant="ghost" />
                                    <Box>
                                        <Popover
                                            showArrow
                                            gutter={9}
                                            trigger={<Avatar size='sm' name='Dan Abrahmov' src='https://bit.ly/dan-abramov' />}
                                        >
                                            <Box w="200px" px="2">
                                                <Box my="2" _hover={{ color: 'brand.400' }} d="block" fontSize="sm" as={Link} to="/account">My Profile</Box>
                                                <Box my="2" _hover={{ color: 'brand.400' }} d="block" fontSize="sm" as={Link} to="#" onClick={handleLogout}>Logout</Box>
                                            </Box>
                                        </Popover>
                                    </Box>
                                </>
                        }

                    </HStack> */}
                </HStack>
                <Login open={showLogin} onClose={setShowLogin.off} />
            </Container>
            <Drawer
                open={menu}
                size="xs"                
                onClose={setMenu.off}
                >
                <VStack px="20px" alignItems="stretch"  py="50px" >
                    <Box onClick={setMenu.off} as={Link} borderBottom="1px solid" borderColor="gray.200" py="1" to="/" color="brand.500" _hover={{ color: "brand.800" }}>Home</Box>
                    {[...with_subjects_minimal].reverse().map((grade, idx) => 
                        <Box onClick={setMenu.off} key={idx} borderBottom="1px solid" borderColor="gray.200" as={Link} py="1" to={"/class/"+grade?.id} color="brand.500" _hover={{ color: "brand.800" }}>
                            Class {grade?.title} {grade?.label}
                        </Box>
                    )}
                    <Box onClick={setMenu.off} as={Link} borderBottom="1px solid" borderColor="gray.200" py="1" to="/mdcat" color="brand.500" _hover={{ color: "brand.800" }}>MDCAT</Box>
                    <Box onClick={setMenu.off} as={Link} borderBottom="1px solid" borderColor="gray.200" py="1" to="/ecat" color="brand.500" _hover={{ color: "brand.800" }}>ECAT</Box>
                    <Box onClick={setMenu.off} as={Link} borderBottom="1px solid" borderColor="gray.200" py="1" to="/mock-exam" color="brand.500" _hover={{ color: "brand.800" }}>Mock Exams</Box>
                    <Box onClick={setMenu.off} as={Link}  py="1" to="/about" color="brand.500" _hover={{ color: "brand.800" }}>About</Box>
                </VStack> 
            </Drawer>
        </Container>
    )
}
import React, { useState } from 'react'
import { Box, Button, useToast,
    HStack, useBoolean, Text,
} from '@chakra-ui/react'
import { Modal } from '../../../uikit'
import { useDispatch, useSelector } from 'react-redux'
import { gradeAction, parentAction } from '../../../redux/actions'
import { Input, Select } from '../../../uikit'
import { parentApi } from '../../../api'
import MaskInput from 'react-maskinput/lib'

export default function NewChildren({open, kids, onClose, ...rest}){

    const dispatch = useDispatch()
    const toast = useToast()
    const [ step, setStep ] = useState(0)
    const [state, setState ] = useState(null)
    const [ errors, setErrors ] = useState(null)
    const [ searching, setSearching ] = useBoolean(false)
    const [ subjects, setSubjects ] = useState([])
    const [ adding, setAdding  ] = useState(null)
    const [ isSearched, setIsSearched ] = useBoolean(false)
    const [ submitting, setSubmitting ] = useBoolean(false)
    const { with_subjects } = useSelector(state => state?.grades)
    const [students, setStudents] = useState([])
    const handleInputChange = (name, value) => {
        setState({
            ...state,
            [name]: value
        })
    }

    const addChild = (id) => {
        setAdding(id)
        dispatch(parentAction.add(id))
            .then((res) => {
                setAdding(null)
                toast({
                    title: 'New Child',
                    description: res?.message || 'Child added successfully',
                    position: 'top'
                })
                setState(null)
                onClose()
            })
            .catch((err) => {
                setErrors(err?.error)
                setAdding(null)
            })

    }


    const handleSearch = (e) => {
        e.preventDefault()
        setSearching.on()
        setIsSearched.on()
        parentApi._search(state?.q)
            .then((res) => {
                console.log("Res", res)
                setStudents(res?.students || [])
                setStep(1)
                setSearching.off()                
            })
            .catch((err) => {
                setStudents([])
                console.log("Error", err)
                setSearching.off()                
            })
    }

    const isAdded = (id) => {
        let index = kids?.findIndex((x) => x?.student_id === id )
        console.log("index", index)
        return (index > -1)
    }
    
    
    return(
        <Modal
            open={open}
            onClose={onClose}
            title="Add Child"
            >                
            <Box p={6}>
                <form onSubmit={handleSearch}>
                    
                    <Box my={3}>
                        <HStack justifyContent="space-between" alignItems="flex-start">
                            <Box flex="1">                                
                                <Input
                                    as={MaskInput}
                                    mask="00000-0000000-0" 
                                    // alwaysShowMask
                                    error={errors?.q}
                                    // showMask
                                    maskChar="_"
                                    label="Please enter correct CNIC/B-Form number of your children"
                                    variant="filled"
                                    value={state?.q || ''}
                                    onChange={(e) => handleInputChange('q', e.target.value)}
                                />
                            </Box>
                            <Box pt="6" >
                                <Button isLoading={searching} type="submit">Search</Button>
                            </Box>
                        </HStack>
                    </Box>                                       
                </form>
                {/* 34343-4343434-3 */}
                <Box mt={10}>
                    {
                        students?.length > 0 ?
                    
                            <table className="custom-table">
                                <tbody>
                                    {
                                        students?.map((student, s) => 
                                        <tr key={s}>
                                            <td>{student?.first_name} {student?.last_name} s/o {student?.profile?.father_name}</td>
                                            <td>{student?.profile?.grade?.title} {student?.profile?.grade?.label}</td>
                                            <td>
                                                {
                                                    isAdded(student?.id) ?
                                                    <Button  isDisabled variant="outline" size="sm">Added</Button>
                                                    :
                                                    <Button  onClick={() => addChild(student?.id)} isLoading={adding === student?.id} variant="outline" size="sm">Add</Button>
                                                }
                                                
                                            </td>
                                        </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                            :
                            <>
                                {
                                    isSearched &&
                                    <Box p="5" bg="yellow.100">
                                        <Text>No Students found</Text>
                                    </Box>
                                }
                            
                            </>
                    }
                    
                </Box>
            </Box>
        </Modal>
    )
}
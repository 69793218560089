import React from 'react'
import {
    FormControl,
    FormLabel,    
    Text,
    FormErrorMessage,
    NumberInput as ChakraNumberInput,
    NumberInputField
  } from "@chakra-ui/react"
  
const NumberInput = React.forwardRef( ({error, onChange,  placeholder, value, required=false,  label, ...props}, ref) => {
    // const format = (val) => {
    //     if(val){
    //         val = val + ""
    //         return val.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1,`)
    //     }
    // }
    // const parse = (val) => val.replace(/^,|,$/g, ``)

    return(
        <FormControl isInvalid={error}>
            {label && <FormLabel fontSize="12px" color="brand.600" fontWeight="600" textTransform="uppercase">{label} {required && <Text as={'span'} fontSize="xs" color="red">*</Text>}</FormLabel>}
            <ChakraNumberInput 
                pattern="[0-9 _,]*"
                // onChange={(valueString) => onChange(parse(valueString))}
                // value={format(value)}
                onChange = {(val) => onChange(val)}
                value={value}
                focusBorderColor='brand.700' {...props} ref={ref}>
                <NumberInputField placeholder={placeholder || 0}  />                
            </ChakraNumberInput>
            <FormErrorMessage>{error}</FormErrorMessage>
        </FormControl>
    )
})
export {NumberInput}
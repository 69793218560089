import React, { useState, useEffect} from 'react'
import { Box, useBoolean, Spinner, Heading, HStack, AspectRatio, Text} from '@chakra-ui/react'
import { useParams, Link } from 'react-router-dom'
import { useApp, usePrevious } from '../../../hooks'
import { Helmet } from 'react-helmet-async'
import { subjectApi } from '../../../api'
import ReactPlayer from 'react-player'


export default function Search(){

    const { query } = useParams()
    const prevQuery = usePrevious(query)
    const [ searching, setSearching ] = useBoolean(false)
    const { setDashHeading, profile } = useApp()
    const [ results, setResults ] = useState([])

    useEffect(() => {
        setDashHeading('Search')
      return () => {
        setDashHeading('Dashboard')
      };
    }, [])

    useEffect(() => {
        if(query && query!== prevQuery){
            setSearching.on()
            subjectApi._search(query)
                .then((res) => {
                    console.log("Res", res)
                    setResults(res?.results)
                    setSearching.off()
                })
                .catch((err) => {
                    console.log("Err", err)
                    setSearching.off()
                })
        }
    }, [query, prevQuery])


    
    return(
        <>
            <Helmet>
                <title>Search Lectures PCTB Online Academy</title>
                <meta name="description" content="PCTB Online Academy is FREE online learning platform for HSSC and SSC Student covering of Subjects, Assessment and other learning material to help our young genration for tapping in to the future." />
            </Helmet>
            {
                searching ?
                    <Box d="flex" h="100%" alignItems="center" justifyContent="center">
                        <Spinner />
                    </Box>
                :
                <>
                    <Box px="2em" py="5">
                        <Heading size="sm">{results?.length} Results Found</Heading>
                        <HStack spacing={0} alignItems="flex-start" flexWrap="wrap" mx="-15px">
                        {
                            results?.map((result, r) =>
                            <Box p="15px" key={r} w="25%">
                                <Box as={Link} to={"/account/subjects/topic/" + result?.id}>
                                    <Box bg="white" p="3" rounded="lg" shadow="md" _hover={{ shadow: 'xl' }}>
                                        <AspectRatio   rounded="lg" overflow="hidden" bg="white" ratio={16 / 9}>
                                            <div className='player-wrapper'>
                                                <ReactPlayer
                                                    className='react-player'
                                                    url={'https://www.youtube.com/watch?v=' + result?.data}
                                                    width='100%'
                                                    light={true}
                                                    height='100%'
                                                    autoPlay={false}
                                                />
                                            </div>
                                        </AspectRatio>
                                        <Box p={2}>
                                            <Text  noOfLines={1}>{result?.title}</Text>
                                            <HStack justifyContent="flex-start">
                                                <Box py={1} px={3} rounded="full" my={1} bg="accent.100">
                                                    <Text color="accent.700" fontSize="13px" noOfLines={1}>{result?.topic?.title}</Text>
                                                </Box>
                                            </HStack>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            )
                        }                       
                        </HStack>
                    </Box>
                </>
            }
            
        </>
    )
}
import React from "react";
import { Box, Image, HStack, Text } from '@chakra-ui/react'
import { Icon } from "../../../uikit";
import { Link, NavLink } from "react-router-dom";
import { useMatch } from "react-router-dom";

export const Sidebar = () => {

    const match = useMatch('/account');

    
    return(
        <Box px={{ base: "5px", md: "20px" }} w={{ base: "60px", md: "300px"}} position="relative" zIndex="99"  bg="brand.500" py="20px"  h="100%" overflowY="auto" borderRight="0px solid" borderColor="gray.100">
            <Box h="0px" />
            <Box as={Link} to="/account">
                <Box display={{ base: "none", md: "block" }}>
                    <Image mx="20px" src="/assets/pctb-academy-logo-alt.svg" h="45px" w="auto" />
                </Box>

                <Box display={{ base: "block", md: "none" }}>
                    <Image mx="5px" src="/assets/insaf-academy-emblem.svg" h="38px" w="auto" />
                </Box>

            </Box>
            <Box h="30px" />
            
            <HStack rounded="xl" my="15px" className={ match ? "account-nav active" : "account-nav"} as={Link} to="/account" spacing="10px" alignItems="center" mx={{ base: "2px", md: "15px"}} px={{ base: "5px", md: "15px"}} py={{base: "5px", md:"15px"}} color="whiteAlpha.800">
                {/* <Icon  fontSize="24px" name="home-solid" color="currentcolor" /> */}
                <Image src="/assets/icon-dashboard.svg" alt="Dashboard" w="auto" h="32px" />
                <Box display={{ base: "none", md: "block" }}>
                    <Text color="currentcolor" fontWeight="bold" fontSize="18px">Dashboard</Text>
                </Box>
            </HStack>                    
            <HStack rounded="xl" my="15px" className="account-nav" as={NavLink} to="/account/subjects" spacing="10px" alignItems="center" mx={{ base: "2px", md: "15px"}} px={{ base: "5px", md: "15px"}} py={{base: "5px", md:"15px"}} color="whiteAlpha.800">
                {/* <Icon  fontSize="24px" name="book-open-solid" color="currentcolor" /> */}
                <Image src="/assets/icon-subjects.svg" alt="Subjects" w="auto" h="32px" />
                <Box display={{ base: "none", md: "block" }}>
                    <Text color="currentcolor" fontSize="18px" fontWeight="600">Subjects</Text>
                </Box>
            </HStack>            
            <HStack rounded="xl" my="15px" className="account-nav" as={NavLink} to="/account/mdcat" spacing="10px" alignItems="center" mx={{ base: "2px", md: "15px"}} px={{ base: "5px", md: "15px"}} py={{base: "5px", md:"15px"}} color="whiteAlpha.800">            
                <Image src="/assets/icon-mortar.svg" alt="MDCAT" w="auto" h="32px" />
                <Box display={{ base: "none", md: "block" }}>
                    <Text color="currentcolor" fontSize="18px" fontWeight="600">MDCAT</Text>
                </Box>
            </HStack>

            <HStack rounded="xl" my="15px" className="account-nav" as={NavLink} to="/account/ecat" spacing="10px" alignItems="center" mx={{ base: "2px", md: "15px"}} px={{ base: "5px", md: "15px"}} py={{base: "5px", md:"15px"}} color="whiteAlpha.800">            
                <Image src="/assets/icon-mortar.svg" alt="MDCAT" w="auto" h="32px" />
                <Box display={{ base: "none", md: "block" }}>
                    <Text color="currentcolor" fontSize="18px" fontWeight="600">ECAT</Text>
                </Box>
            </HStack>

            <HStack rounded="xl" my="15px" className="account-nav" as={NavLink} to="/account/mock-exams" spacing="10px" alignItems="center" mx={{ base: "2px", md: "15px"}} px={{ base: "5px", md: "15px"}} py={{base: "5px", md:"15px"}} color="whiteAlpha.800">
                {/* <Icon  fontSize="24px" name="graduation-cap-solid" color="currentcolor" /> */}
                <Image src="/assets/icon-mortar.svg" alt="Mock Exams" w="auto" h="32px" />
                <Box display={{ base: "none", md: "block" }}>
                    <Text color="currentcolor" fontSize="18px" fontWeight="600">Mock Exams</Text>
                </Box>
            </HStack>
            <HStack rounded="xl" my="15px" className="account-nav" as={NavLink} to="/account/virtual-classes" spacing="10px" alignItems="center" mx={{ base: "2px", md: "15px"}} px={{ base: "5px", md: "15px"}} py={{base: "5px", md:"15px"}} color="whiteAlpha.800">
                {/* <Icon  fontSize="24px" name="video-solid" color="currentcolor" /> */}
                <Image src="/assets/icon-video.png" alt="Virtual Classes" w="auto" h="32px" />
                <Box display={{ base: "none", md: "block" }}>
                    <Text color="currentcolor" fontSize="18px" fontWeight="600">Virtual Classes</Text>
                </Box>
            </HStack>

            <HStack rounded="xl" my="15px" className="account-nav" as={NavLink} to="/account/live-sessions" spacing="10px" alignItems="center" mx={{ base: "2px", md: "15px"}} px={{ base: "5px", md: "15px"}} py={{base: "5px", md:"15px"}} color="whiteAlpha.800">
                {/* <Icon  fontSize="24px" name="video-solid" color="currentcolor" /> */}
                <Image src="/assets/icon-video.png" alt="Virtual Classes" w="auto" h="32px" />
                <Box display={{ base: "none", md: "block" }}>
                    <Text color="currentcolor" fontSize="18px" fontWeight="600">Live Sessions</Text>
                </Box>
            </HStack>

            {/* <HStack rounded="md" my="10px" className="account-nav" as={NavLink} to="/account/videos" spacing="10px" alignItems="center" mx="15px" px="15px" py="10px" color="whiteAlpha.800">
                <Icon  fontSize="24px" name="play-solid" color="currentcolor" />
                <Text color="currentcolor" fontSize="15px" fontWeight="600">Videos</Text>
            </HStack> */}
            <HStack rounded="xl" my="15px" className="account-nav" as={NavLink} to="/account/badges" spacing="10px" alignItems="center" mx={{ base: "2px", md: "15px"}} px={{ base: "5px", md: "15px"}} py={{base: "5px", md:"15px"}} color="whiteAlpha.800">
                {/* <Icon  fontSize="24px" name="star" color="currentcolor" /> */}
                <Image src="/assets/icon-badges.svg" alt="Badges" w="auto" h="32px" />
                <Box display={{ base: "none", md: "block" }}>
                    <Text color="currentcolor" fontSize="18px" fontWeight="600">Badges Earned</Text>
                </Box>
            </HStack>
            <HStack rounded="xl" my="15px" className="account-nav" as={NavLink} to="/account/notifications" spacing="10px" alignItems="center" mx={{ base: "2px", md: "15px"}} px={{ base: "5px", md: "15px"}} py={{base: "5px", md:"15px"}} color="whiteAlpha.800">
                {/* <Icon  fontSize="24px" name="bell" color="currentcolor" /> */}
                <Image src="/assets/icon-notifications.svg" alt="Dashboard" w="auto" h="32px" />
                <Box display={{ base: "none", md: "block" }}>
                    <Text color="currentcolor" fontSize="18px" fontWeight="600">Notifications</Text>
                </Box>
            </HStack>
            <HStack rounded="xl" my="15px" className="account-nav" as={NavLink} to="/account/settings" spacing="10px" alignItems="center" mx={{ base: "2px", md: "15px"}} px={{ base: "5px", md: "15px"}} py={{base: "5px", md:"15px"}} color="whiteAlpha.800">
                {/* <Icon  fontSize="24px" name="cog-solid" color="currentcolor" /> */}
                <Image src="/assets/icon-settings.svg" alt="Settings" w="auto" h="32px" />
                <Box display={{ base: "none", md: "block" }}>
                    <Text color="currentcolor" fontSize="18px" fontWeight="600">Settings</Text>
                </Box>
            </HStack>                    
        </Box>
    )
}
import React, { useEffect, useState } from 'react'
import { Box, useBoolean, Menu, MenuButton, Heading, Spinner, MenuList, MenuItem, HStack } from '@chakra-ui/react'
import { gradeAction } from '../../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useApp } from '../../../hooks'
import { Icon } from '../../../uikit'
import { SubjectCard } from './Components/SubjectCard'

export default function BrowseSubjects(){

    const dispatch = useDispatch()
    const { setDashHeading, profile } = useApp()
    const [ loading, setLoading ] = useBoolean(false)
    const { with_subjects} = useSelector(state => state?.grades)
    const [ currentGrade, setCurrentGrade ] = useState(null)
    const [ mySubjects, setMySubjects ] = useState([]);

    useEffect(() => {
        setLoading.on()
        dispatch(gradeAction.with_subjects())
        .then((res) =>{
            setLoading.off()
        })
        .catch((err) =>{
            setLoading.off()
        })    
    }, [])

    useEffect(() => {
        if(profile?.grade_id && with_subjects){
            let subjects = with_subjects.find((x) => x?.id === profile?.grade_id)
            setMySubjects(subjects)
            setCurrentGrade(subjects)
        }
    }, [profile, with_subjects])


    useEffect(() => {
        setDashHeading('Subjects')
      return () => {
        setDashHeading('Dashboard')
      };
    }, [])

    const selectGrade = (data) => {
        setCurrentGrade(data)
        setMySubjects(data)
    }

    return(
        <Box p={8}>
            <HStack alignItems="center" justifyContent="space-between">
                <Box>
                    <Menu variant="classes"  placement="bottom-start">
                        <MenuButton>                        
                            <Heading cursor="pointer" size="md">
                                {
                                    currentGrade ?
                                        <>
                                            {`${'Class: '+  currentGrade?.title || ''}` }
                                            {`${currentGrade.label ?  ' - '+currentGrade?.label : ''}` }
                                        </>
                                    :
                                    `${'Select Class...'}`
                                }
                                <Icon ml={4} name="angle-down-solid" color="currentcolor" />
                            </Heading> 
                        </MenuButton>
                        <MenuList rounded="none" w="auto" minW="240px" _focus={{ shadow: 'xl', outline: 'none' }} left="0" shadow="xl" px="3" maxH="260px" overflowY="auto">
                            {
                                with_subjects?.map((grade, g) =>
                                <MenuItem key={g} onClick={() => selectGrade(grade)}>{grade?.title} {grade?.label}</MenuItem>
                                )
                            }                            
                        </MenuList>
                    </Menu>
                </Box>             
            </HStack>

            {
                loading ?
                    <Box d="flex" h="100%" alignItems="center" justifyContent="center">
                        <Spinner />
                    </Box>
                :
                <Box py="3">                    
                    <HStack flexWrap="wrap" spacing="0" mx="-15px">
                    {
                        mySubjects?.subjects?.map((subject, s) => 
                        <SubjectCard grade_slug={mySubjects?.url_title} w={{ base: '100%', md: '50%', lg: '33.33%' }} subject={subject} key={s} />
                        )
                    }
                    </HStack>

                </Box>
            }   
        </Box>
    )
}
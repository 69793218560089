import {handleResponse, requestTimeout} from '../helpers'
const apiUrl = process.env.REACT_APP_API_URL
const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME

export const vClassApi = {
    _get,
    _add,
    _details,
    _comment,
    _join,
    _quiz,
    _submit_quiz,
    _live
};



async function _get(filters, offset) {    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        },
        body: JSON.stringify(filters)
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'paginate/virtual-classes/'+offset, requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _details(id) {    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'virtual-classes/'+id, requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _add(form_data) {    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        },
        body: JSON.stringify(form_data)
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'virtual-classes', requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _comment(form_data) {    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        },
        body: JSON.stringify(form_data)
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'virtual-classes/comment', requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}


async function _join(id) {    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'virtual-classes/join/'+id, requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _quiz(id) {    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'virtual-classes/quiz/'+id, requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _submit_quiz(form_data) {    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        },
        body: JSON.stringify(form_data)
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'virtual-classes/submit-quiz', requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _live(id) {    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }        
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'live-sessions/'+id, requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}
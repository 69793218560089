import React, { useEffect, useState} from 'react'
import { Box, Heading, useBoolean, HStack, Spinner, Text, Progress, Avatar, Button, useToast, toast, Image} from '@chakra-ui/react'
import { useParams, useNavigate } from 'react-router-dom'
import { useApp } from '../../../hooks'
import { useDispatch, useSelector } from 'react-redux'
import { Input } from '../../../uikit'
import { virtualClassAction } from '../../../redux/actions'
import dayjs from 'dayjs'
import NewQuiz from './NewQuiz'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)


export default function ClassStudents({id, ...rest}){

    const dispatch = useDispatch()
    const { class_id } = useParams()
    const toast = useToast()
    const navigate = useNavigate()
    const { setDashHeading } = useApp()
    const [ tab, setTab] = useState(0)
    const [ isOpen, setOpen] = useBoolean(false)
    const [ submitting, setSubmitting ] = useState(null)
    const [ removing, setRemoving ] = useState(null)
    const [state, setState ] = useState({})    
    const [ loading, setLoading] = useBoolean(false)
    const [ replyForm, setReplyForm] = useState(null)
    const [ reply, setReply] = useState('')
    const { virtual_class } = useSelector(state => state?.virtual)

    const handleInputChange = (name, value) => {
        setState({
            ...state,
            [name]: value
        })
    }


    useEffect(() => {
        if(class_id){
            setLoading.on()
            dispatch(virtualClassAction.details(class_id))
                .then((res) => {
                    setLoading.off()
                })
                .catch((err) => {
                    setLoading.off()
                })
        }
    }, [class_id])


    useEffect(() => {
        setDashHeading("Virtual Class")
        return () => {
            setDashHeading("Dashboard")
          };
    }, [])


    
    if(loading){
        return(
            <Box d="flex" h="100%" alignItems="center" justifyContent="center">
                <Spinner />
            </Box>
        )
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitting('q')
        dispatch(virtualClassAction.comment({virtual_class_id: class_id, parent_id: '', comments: state?.comments}))
            .then((res) => {
                setSubmitting(null)
                toast({
                    title: 'Comment submitted',
                    description: res?.message,
                    status: "success",
                    position: "top"
                    
                })
                setState(null)

            })
            .catch((err) => setSubmitting(null))

    }

    const handleReply = (id) => {    
        setSubmitting(id)
        dispatch(virtualClassAction.comment({virtual_class_id: class_id, parent_id: id, comments: reply}))
            .then((res) => {
                setSubmitting(null)
                toast({
                    title: 'Comment submitted',
                    description: res?.message,
                    status: "success",
                    position: "top"
                    
                })
                setState(null)
                setReply(null)
                setReplyForm(null)

            })
            .catch((err) => setSubmitting(null))

    }

    const handleRemove = async (student_id, class_id) => {
        let result = await window.confirm("Do you really want to remove this student?", 'Remove Student');
        if(result){
            setRemoving(student_id)
            dispatch(virtualClassAction.remove_student(student_id, class_id))
                .then((res) => {
                    setRemoving(null)
                })
                .catch((err) =>{
                    setRemoving(null)
                })
        }
    }

    console.log("Virtual Class", virtual_class)

    const getProgress = () => {        
        let val =  (virtual_class?.students?.length / parseInt(virtual_class?.total_students)) * 100
        return val;
    }

    const getProgress2 = (student) =>{
        let subject = student?.totals.find(x => x?.title === virtual_class?.subject)
        let p = 0;
        if(subject){
            let progress_total = student?.user?.activities?.filter((x) => x?.subject_id === subject?.id)?.length || 0;            
            p = parseInt(progress_total || 0)/parseInt(subject?.total_contents || 0) * 100
        }
        p = isNaN(p) ? 0 : p;
        return parseFloat(p).toFixed(2)        
    }

    return(        
        <Box>
            {
                virtual_class ?
                <Box p="6">
                    <HStack justifyContent="space-between" alignItems="stretch" spacing="5">
                        <Box flex={1} bg="white" rounded="lg" p="6">
                            <HStack spacing="7">
                                <Image boxSize="64px" src="/assets/vicon-class.png" alt="Class" title="" />
                                <Box>
                                    <Text fontWeight="700" fontSize="18px">{virtual_class?.title}</Text>
                                    <HStack alignItems="center">
                                        <Text>Class: {virtual_class?.grade?.title}</Text>
                                        <Text>Subject: {virtual_class?.subject}</Text>
                                    </HStack>
                                </Box>
                            </HStack>
                        </Box>

                        <Box flex={1} bg="white" rounded="lg" p="6" cursor="pointer" onClick={setOpen.on}>
                            <HStack spacing="7">
                                <Image boxSize="64px" src="/assets/vicon-quiz.png" alt="Assessment" title="" />
                                <Box>
                                    <Text fontWeight="700" fontSize="18px">Create Assessment</Text>
                                    <HStack alignItems="center">
                                        <Text>Click here to create assessment</Text>                                        
                                    </HStack>
                                </Box>
                            </HStack>
                        </Box>

                        <Box flex={1} bg="white" rounded="lg" p="6">
                            <HStack spacing="7" justifyContent="stretch">                                
                                <Box flex="1">
                                    <Text fontWeight="700" fontSize="18px">Students Joined</Text>
                                    <Progress mt="2" rounded="lg" colorScheme="accent"  value={getProgress()} />
                                    <HStack mt="2" alignItems="center" justifyContent="space-between">
                                        <Text>Total Students in class</Text>                                        
                                        <Text>{virtual_class?.students?.length} / {virtual_class?.total_students}</Text>
                                    </HStack>
                                </Box>
                            </HStack>
                        </Box>
                    </HStack>

                    <HStack mt="5" alignItems="flex-start" spacing="7">
                        <Box py="3" cursor="pointer" onClick={() => setTab(0)} borderBottom="2px solid" borderColor={tab === 0 ? 'accent.500' : 'transparent'}>
                            <Text fontWeight="700">Students</Text>
                        </Box>
                        <Box  py="3" cursor="pointer" onClick={() => setTab(1)}  borderBottom="2px solid" borderColor={tab === 1 ? 'accent.500' : 'transparent'}>
                            <Text fontWeight="700">Assessments</Text>
                        </Box>
                        <Box  py="3" cursor="pointer" onClick={() => setTab(2)}  borderBottom="2px solid" borderColor={tab === 2 ? 'accent.500' : 'transparent'}>
                            <Text fontWeight="700">Discussion</Text>
                        </Box>
                    </HStack>
                    {
                        tab === 0 &&                    
                        <Box mt="10" p="5" bg="white" rounded="lg">                        
                            <Heading px="5" py="5" size="md">Students</Heading>
                            <table className="custom-table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Father Name</th>
                                        <th>{virtual_class?.subject } Progress</th>
                                        <th></th>                                                              
                                    </tr>                                
                                </thead>
                                <tbody>
                                    {
                                        virtual_class?.students?.map((student, s) =>
                                        <tr key={s} onClick={() => navigate('/dashboard/virtual-classes/student-progress/'+student?.user?.id)}>
                                            <td>{student?.user?.first_name} {student?.user?.last_name}</td>
                                            <td>{student?.user?.email} </td>
                                            <td>{student?.user?.profile?.father_name}</td>                                            
                                            <td>
                                                <HStack>
                                                    <Progress flex="1" size="sm" rounded="lg" colorScheme="accent"  value={getProgress2(student)} />
                                                    <Text fontSize="12px">{getProgress2(student)}%</Text>
                                                </HStack>
                                            </td>
                                            <td><Button isLoading={removing === student?.student_id} onClick={() => handleRemove(student?.student_id, student?.virtual_class_id)} size="xs" colorScheme="red">Remove</Button></td>
                                        </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </Box>
                    }
                    {
                        tab === 1 &&                    
                        <Box mt="10" p="5" bg="white" rounded="lg">                        
                            <Heading px="5" py="5" size="md">Assessments</Heading>
                            <table className="custom-table">
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Questions</th>
                                        <th>Duration</th>     
                                        <th>Level</th>                 
                                        <th>Passing%</th>                                        
                                    </tr>                                
                                </thead>
                                <tbody>
                                    {
                                        virtual_class?.quiz?.map((quiz, q) =>
                                        <tr key={q} onClick={() => navigate('/dashboard/virtual-classes/results/'+quiz?.id)}>
                                            <td>{quiz?.title}</td>
                                            <td>{quiz?.questions?.length} </td>
                                            <td>{quiz?.settings?.duration}</td>
                                            <td>{quiz?.settings?.level}</td>
                                            <td>{quiz?.settings?.passing}%</td>
                                        </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </Box>
                    }
                    {
                        tab === 2 &&
                        <Box my="8">                            
                            <HStack my={5} alignItems="flex-start" spacing="4">
                                <Avatar name='Kent Dodds' visibility="hidden" src='https://bit.ly/kent-c-dodds' />
                                <Box flex={1}  p="5" bg='white' rounded="lg" my={5}>
                                    <Input 
                                        placeholder="Enter your question..."
                                        variant="filled"
                                        textarea 
                                        value={state?.comments || ''}
                                        onChange={(e) => handleInputChange('comments', e.target.value)}
                                    />
                                    <Button isLoading={submitting === 'q'} onClick={handleSubmit} mt={3} size="sm">Ask a Question</Button>
                                </Box>
                            </HStack>
                            {
                                virtual_class?.comments?.map((comment, c) =>
                                <HStack key={c} alignItems="flex-start" spacing="4" mb="3">
                                    <Avatar size="md" name={comment?.user?.first_name + " " + comment?.user?.last_name} src={comment?.user?.avatar ? process.env.REACT_APP_STORAGE_URL+comment?.user.avatar.url :  "/assets/avatar.svg"} />
                                    <Box flex={1} p="5" bg="gray.50" rounded="lg">
                                        <Text fontSize="12px" color="gray.600">{dayjs(comment?.created_at).fromNow()}</Text>
                                        <HStack mb="3" alignItems="center">
                                            <Text  fontSize="16px" fontWeight="bold">{comment?.user?.first_name + " " + comment?.user?.last_name}</Text>
                                            {comment?.user_id === virtual_class?.teacher_id && 
                                                <Box bg="green.500" color="white" fontSize="12px" px="3" py={1} rounded="lg" > 
                                                    Teacher
                                                </Box>
                                            }
                                        </HStack>
                                        <Text fontSize="13px">{comment?.comments}</Text>
                                        <Button onClick={() => setReplyForm(comment?.id)} mt={3} size="sm" variant="link">Reply</Button>
                                        <Box>
                                            {
                                                replyForm === comment?.id &&
                                                <Box bg="white" rounded="lg" p="4" border="1px solid" borderColor="gray.200">
                                                    <Input 
                                                            placeholder="Enter your reply..."
                                                            variant="filled"
                                                            textarea 
                                                            value={reply || ''}
                                                            onChange={(e) => setReply(e.target.value)}
                                                        />
                                                        <Button isLoading={submitting === comment?.id} onClick={() => handleReply(comment?.id)} mt={3} size="sm">Submit</Button>
                                                </Box>
                                            }
                                        </Box>
                                        {
                                            comment?.replies?.length > 0 &&                                        
                                            <Box my={2}  rounded="lg" p={3}>
                                                {
                                                    comment?.replies?.map((rep, r) =>
                                                    <Box p={5} bg="gray.100" my={2} key={r} rounded="lg">
                                                        <Text fontSize="12px" color="gray.600">{dayjs(rep?.created_at).fromNow()}</Text>
                                                        <HStack mb="3" alignItems="center">
                                                            <Text fontWeight="700">{rep?.user?.first_name} {rep?.user?.last_name}</Text>
                                                            {rep?.user_id === virtual_class?.teacher_id && 
                                                                <Box bg="green.500" color="white" fontSize="10px" px="3" py={1} rounded="lg" > 
                                                                    Teacher
                                                                </Box>
                                                            }
                                                        </HStack>
                                                        
                                                        <Text fontSize="13px">{rep?.comments}</Text>
                                                    </Box>
                                                    )
                                                }
                                            </Box>
                                        }
                                    </Box>
                                </HStack>
                                )
                            }
                            
                        </Box>
                    }
                </Box>
                :

                <Box p={6}>
                    <Box bg="yellow.100">No class Founed</Box>
                </Box>
            }
            <NewQuiz open={isOpen} classId={virtual_class?.id} subject={virtual_class?.subject} onClose={setOpen.off} />
        </Box>
    )
}
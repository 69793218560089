import {handleResponse, requestTimeout} from '../helpers'
const apiUrl = process.env.REACT_APP_API_URL
const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME

export const parentApi = {
    _get,
    _add,
    _search ,
    _remove,
    _get_progress
};



async function _get() {    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'parent/get', requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}



async function _add(id) {    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'parent/add-child/'+id, requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _search(q) {    
    const requestOptions = {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'parent/search/'+q, requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _remove(id) {    
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'parent/child/'+id, requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}


async function _get_progress(id) {    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'parent/get-progress/'+id, requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}
import { SubjectConstants as Constants, GradeConstants } from '../types'

import { subjectApi as api } from '../../api'

export const subjectAction = {
    get,
    add,    
    details,
    topic,
    quiz,
    clear,
    badges,
    results,
    comment,
};

function get(slug) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._get(slug)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.GET, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function add(form_data) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._add(form_data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        dispatch(update_grade(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.ADD, payload } }
    function update_grade(payload) { return { type: GradeConstants.DETAILS, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}


function details(id) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._details(id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.DETAILS, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function topic(id) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._topic(id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.TOPIC, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function quiz(id) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._quiz(id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.QUIZ, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function clear() {
    return dispatch => {   
        return new Promise((resolve, reject) => {     
            dispatch(success());
            resolve()
        })   
    }
    function success() { return { type: Constants.CLEAR } }
}

function badges() {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._badges()
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.BADGES, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function results(id) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._results(id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.RESULTS, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}


function comment(form_data) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._comment(form_data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.TOPIC, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

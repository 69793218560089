import React from "react";
import { Box } from "@chakra-ui/react";

export const Container = React.forwardRef( ({fluid, children, ...rest}, ref) => {
    return(
        <Box ref={ref} w="100%" px="15px" maxW={fluid ? '100%' : "80em"} mx="auto" {...rest}>
            {children}
        </Box>
    )
})

import React from "react"
import { Box, HStack, Text, Avatar } from '@chakra-ui/react'
import dayjs from 'dayjs';

var relativeTime = require('dayjs/plugin/relativeTime')
var utc = require('dayjs/plugin/utc')
dayjs.extend(relativeTime)
dayjs.extend(utc)


export const Item = ({data, onClick, ...rest}) => {


    
    return(
        <Box my={4} bg="white" transition="all 0.3s linear" rounded="md" px={4} py={3} onClick={onClick} _hover={{ opacity: 0.65 }} cursor="pointer">
            <HStack spacing={5} alignItems="flex-start">
                <Avatar name={data?.sender?.first_name+' '+data?.sender?.last_name} source={{uri: process.env.REACT_APP_STORAGE_URL+data?.sender?.avatar?.url}} />
                <Box flex={1}>
                    <Text fontSize="11px" color="gray.500">{dayjs.utc(data?.created_at).fromNow()} - {dayjs.utc(data?.created_at).format('DD MMM YYYY')}</Text>
                    <Text fontSize="15px" fontWeight="600" my={0}>{data?.payload?.title}</Text>
                    <Text  fontSize="11px" color="gray.500">By: {data?.sender?.first_name } {data?.sender?.last_name }</Text>
                    <Text variant="small" mt={2}>{data?.payload?.body}</Text>            
                </Box>
            </HStack>
        </Box>
    )
}
import {handleResponse, requestTimeout} from '../helpers'
const apiUrl = process.env.REACT_APP_API_URL
const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME

export const paperApi = {
    _get,
    _details,
    _subject,
    _lecture,
    _comment,
    _topic_comment,
    _join,
    _quiz,    
    _submit_quiz,
    _submit_exam,
    _start_quiz,
    _get_results,
    _start_topic_quiz,
    _submit_topic_quiz
};



async function _get(type) {    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'papers/'+type, requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _subject(subject_id, type) {    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'papers/subject/'+type+'/'+subject_id, requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _lecture(id) {    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'papers/lecture/'+id, requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _details(id) {    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'papers/details/'+id, requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _add(form_data) {    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        },
        body: JSON.stringify(form_data)
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'virtual-classes', requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _comment(form_data) {    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        },
        body: JSON.stringify(form_data)
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'virtual-classes/comment', requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _topic_comment(form_data) {    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        },
        body: JSON.stringify(form_data)
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'cat-lecture/comment', requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}


async function _join(id) {    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'virtual-classes/join/'+id, requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _quiz(data) {    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        },
        body: JSON.stringify(data)
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'cat-quiz', requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _start_quiz(id) {    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }        
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'cat-quiz/'+id, requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _start_topic_quiz(id) {    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }        
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'cat-topic-quiz/'+id, requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}


async function _submit_quiz(form_data) {    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        },
        body: JSON.stringify(form_data)
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'papers/submit-quiz', requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _submit_topic_quiz(form_data) {    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        },
        body: JSON.stringify(form_data)
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'cat-topic-quiz-submit', requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _submit_exam(form_data) {    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        },
        body: JSON.stringify(form_data)
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'cat-quiz-submit', requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}


async function _get_results(type) {    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }        
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'cat-quiz-results/'+type, requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}
import React from "react";
import { Box, Heading, HStack, Text, Image, UnorderedList, ListItem, List} from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'
import { Container } from "../../uikit";
import { useParams } from "react-router-dom";


export default function Privacy() {
    
    
    return (
        <>
            <Helmet>
                <title> Privacy Policy - PCTB Online Academy</title>
                <meta name="description" content="PCTB Online Academy is FREE online learning platform for HSSC and SSC Student covering of Subjects, Assessment and other learning material to help our young genration for tapping in to the future." />
            </Helmet>
            <Box  pt="100px" bgBlendMode="overlay" bgImage="/assets/pakistan-banner.png" bgRepeat="no-repeat" bgSize="cover" bgPosition="center center">
                <Box py="5em" >
                    <Container>
                        <HStack flexDir={{ base: 'column', lg: 'row' }} alignItems="flex-end">
                            <Box flex="1">
                                <Heading color="white">Privacy Policy</Heading>
                            </Box>
                            <Box w="40%" textAlign="right">
                            
                            </Box>
                        </HStack>
                    </Container>
                </Box>
            </Box>
            <Box py="4em">
                <Container>                    
                    <Text fontSize="sm" as="p" mb={5}>At PCTB Online Academy, accessible from https://insafacademy.pctb.pk, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by PCTB Online Academy and how we use it.</Text>
                    <Text fontSize="sm" as="p" mb={5}>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</Text>
                    <Text fontSize="sm" as="p" mb={5}>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in PCTB Online Academy. This policy is not applicable to any information collected offline or via channels other than this website. Our Privacy Policy was created with the help of the Free Privacy Policy Generator.</Text>

                    <Heading size="md" my={10}>Consent</Heading>
                    <Text fontSize="sm" as="p" mb={5}>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</Text>                    

                    <Heading size="md" my={10}>Information We Collect</Heading>
                    <Text fontSize="sm" as="p" mb={5}>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</Text>
                    <Text fontSize="sm" as="p" mb={5}>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</Text>
                    <Text fontSize="sm" as="p" mb={5}>When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</Text>

                    <Heading size="md" my={10}>How we use your information</Heading>
                    <Text fontSize="sm" as="p" mb={5}>We use the information we collect in various ways, including to:</Text>                    
                    <UnorderedList mb={5} fontSize="sm">
                        <ListItem>Provide, operate, and maintain our website</ListItem>
                        <ListItem>Improve, personalize, and expand our website</ListItem>
                        <ListItem>Understand and analyze how you use our website</ListItem>
                        <ListItem>Develop new products, services, features, and functionality</ListItem>
                        <ListItem>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</ListItem>
                        <ListItem>Send you emails</ListItem>
                        <ListItem>Find and prevent fraud</ListItem>
                    </UnorderedList>

                    <Heading size="md" my={10}>Log Files</Heading>
                    <Text fontSize="sm" as="p" mb={5}>PCTB Online Academy follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</Text>

                    <Heading size="md" my={10}>Cookies and Web Beacons</Heading>
                    <Text fontSize="sm" as="p" mb={5}>Like any other website, PCTB Online Academy uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</Text>

                    <Heading size="md" my={10}>Advertising Partners Privacy Policies</Heading>
                    <Text fontSize="sm" as="p" mb={5}>You may consult this list to find the Privacy Policy for each of the advertising partners of PCTB Online Academy.</Text>
                    <Text fontSize="sm" as="p" mb={5}>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on PCTB Online Academy, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</Text>
                    <Text fontSize="sm" as="p" mb={5}>Note that PCTB Online Academy has no access to or control over these cookies that are used by third-party advertisers.</Text>


                    <Heading size="md" my={10}>Third Party Privacy Policies</Heading>
                    <Text fontSize="sm" as="p" mb={5}>PCTB Online Academy's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.</Text>
                    <Text fontSize="sm" as="p" mb={5}>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.</Text>

                    <Heading size="md" my={10}>CCPA Privacy Rights (Do Not Sell My Personal Information)</Heading>
                    <Text fontSize="sm" as="p" mb={5}>Under the CCPA, among other rights, California consumers have the right to:</Text>
                    <Text fontSize="sm" as="p" mb={5}>Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</Text>
                    <Text fontSize="sm" as="p" mb={5}>Request that a business delete any personal data about the consumer that a business has collected</Text>
                    <Text fontSize="sm" as="p" mb={5}>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</Text>
                    <Text fontSize="sm" as="p" mb={5}>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</Text>

                    <Heading size="md" my={10}>GDPR Data Protection Rights</Heading>
                    <Text fontSize="sm" as="p" mb={5}>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</Text>

                    <Text fontSize="sm" as="p" mb={5}>The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.</Text>
                    <Text fontSize="sm" as="p" mb={5}>The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</Text>
                    <Text fontSize="sm" as="p" mb={5}>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</Text>
                    <Text fontSize="sm" as="p" mb={5}>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</Text>
                    <Text fontSize="sm" as="p" mb={5}>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</Text>
                    <Text fontSize="sm" as="p" mb={5}>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</Text>
                    <Text fontSize="sm" as="p" mb={5}>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</Text>

                    <Heading size="md" my={10}>Children's Information</Heading>
                    <Text fontSize="sm" as="p" mb={5}>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</Text>
                    <Text fontSize="sm" as="p" mb={5}>PCTB Online Academy does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</Text>

                    <a name="delete-account" />
                    <Heading size="md" my={10}>Delete Account</Heading>
                    <Text fontSize="sm" as="p" mb={5}>How to Delete Your Account and Data We respect your right to privacy and allow you to delete your account and all associated data from our servers. If you wish to remove your data, please follow these steps:</Text>
                    <Text fontSize="sm" as="p" mb={5} fontWeight="bold">Login to the App:</Text>
                    <ul>
                        <li>
                            <Text fontSize="sm" mb={3}>Open the app on your mobile device.</Text>
                        </li>
                        <li>
                            <Text fontSize="sm" mb={3}>Enter your login credentials (username/email and password) and sign in to your account.</Text>
                        </li>
                        <li>
                            <Text fontSize="sm" mb={3}>Go to Profile/Account Tab:</Text>
                        </li>
                    </ul>
                    <Text fontSize="sm" as="p" mb={5}>Once logged in, navigate to the Profile or Account tab. This is usually accessible from the main menu or bottom navigation bar. Select “Delete Account” Option:</Text>
                    <Text fontSize="sm" as="p" mb={5}>In the Profile or Account settings, look for the “Delete Account” option. Tap on “Delete Account”. Confirm Deletion:</Text>
                    <Text fontSize="sm" as="p" mb={5}>A confirmation prompt will appear asking if you are sure you want to delete your account.</Text>
                    <Text fontSize="sm" as="p" mb={5}>Confirm your choice by selecting “Yes” or “Confirm”.</Text>

                    <Text fontSize="sm" as="p" fontWeight="bold" mb={5}>Account Deletion:</Text>
                    <Text fontSize="sm" as="p" mb={5}>After confirming, your account and all associated data will be permanently deleted from our servers. This action is irreversible. If you encounter any issues or need assistance, please contact our support team at info@pctb.punjab.gov.pk.</Text>



                </Container>
            </Box>
            
        </>
    )
}
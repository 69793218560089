import React, {useEffect, useState } from 'react';
import { Box, Heading, HStack, Image, Stack, Text, useBoolean, Spinner } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { subjectAction } from '../../../redux/actions';
import { useApp } from '../../../hooks';
import dayjs from 'dayjs';

const Badges = () => {
    const dispatch = useDispatch();    
    const { setDashHeading } = useApp()    
    const [ loading, setLoading ] = useBoolean(false)
    const { badges} = useSelector(state => state.subjects)


    useEffect(() => {
        setLoading.on()
        dispatch(subjectAction.badges())
            .then((res) => setLoading.off())
            .catch((err) => setLoading.off())
    }, [])
    useEffect(() => {
        setDashHeading("Badges")
        return () => {
            setDashHeading("Dashboard")
          };
    }, [])


    let gold_badges = badges?.badges?.filter((x) => x?.badge === 'GOLD')?.length;
    let silver_badges = badges?.badges?.filter((x) => x?.badge === 'SILVER')?.length;
    let bronze_badges = badges?.badges?.filter((x) => x?.badge === 'BRONZE')?.length;
    
    return (
        <Box px="2em" py="5">   
            {
                loading ?
                    <Box d="flex" h="100%" alignItems="center" justifyContent="center">
                        <Spinner />
                    </Box>
                :
                <Box>
                    <Stack direction={{ base: "column", md: "row" }} spacing={"10"} py={"7"}>
                        <Box bg="white" flex="1" borderRadius={"10px"} px={'2'}>
                            <HStack>
                                <Box w={'100px'} h={'100px'}>
                                    <Image
                                        src='/assets/goldmedal.svg'
                                        alt='goldmedal'
                                        objectFit={"cover"}
                                        w={"100%"}
                                        h={"100%"}
                                    />

                                </Box>
                                <Box ml={"5"}>
                                    <Text color={"rgba(55, 69, 87, 1)"} fontWeight={"900"} fontSize={"18"}>
                                        {gold_badges}
                                    </Text>
                                    <Text color={"rgba(55, 69, 87, 1)"}> Gold Badges Earned</Text>
                                </Box>
                            </HStack>
                        </Box>
                        <Box bg="white" flex="1" borderRadius={"10px"} px={'2'}>
                            <HStack>
                                <Box w={'100px'} h={'100px'}>
                                    <Image
                                        src='/assets/silvermedal.svg'
                                        alt='silvermedal'
                                        objectFit={"cover"}
                                        w={"100%"}
                                        h={"100%"}
                                    />

                                </Box>
                                <Box ml={"5"}>
                                    <Text color={"rgba(55, 69, 87, 1)"} fontWeight={"900"} fontSize={"18"}>
                                        {silver_badges}
                                    </Text>
                                    <Text color={"rgba(55, 69, 87, 1)"}> Silver Badges Earned</Text>
                                </Box>
                            </HStack>

                        </Box>
                        <Box bg="white" flex="1" borderRadius={"10px"} px={'2'}>
                            <HStack>
                                <Box w={'100px'} h={'100px'}>
                                    <Image
                                        src='/assets/bronzemedal.svg'
                                        alt='bronzemedal'
                                        objectFit={"cover"}
                                        w={"100%"}
                                        h={"100%"}
                                    />
                                </Box>
                                <Box ml={"5"}>
                                    <Text color={"rgba(55, 69, 87, 1)"} fontWeight={"900"} fontSize={"18"}>
                                        {bronze_badges}
                                    </Text>
                                    <Text color={"rgba(55, 69, 87, 1)"}> Bronze Badges Earned</Text>
                                </Box>
                            </HStack>
                        </Box>
                    </Stack>

                    <Box bg={"#fff"} borderRadius={"10px"} p={'5'} mb={"5"}>
                        <Text fontSize={"18px"} fontWeight={'700'} color={"rgba(55, 69, 87, 1)"}>Recently Earned Badges</Text>
                        <HStack spacing="1%" py={"7"} flexWrap="wrap">
                            {
                                badges?.recent?.map((badge, b) =>
                            <Box
                                d={"flex"}
                                flexDir={"column"}
                                alignItems={"center"}
                                bg={"rgba(160, 152, 174, 0.1)"}
                                w={"18%"}
                                height={"300px"}
                                pt={"10"}
                                borderRadius={"10"}
                                >
                                <Box w={'150px'} h={'150px'} mb={"5"}>
                                    {
                                        badge?.badge === 'GOLD' &&
                                        <Image
                                            src='/assets/goldmedal.svg'
                                            alt='goldmedal'
                                            objectFit={"cover"}
                                            w={"100%"}
                                            h={"100%"}
                                        />
                                    }
                                    {
                                        badge?.badge === 'SILVER' &&
                                        <Image
                                            src='/assets/silvermedal.svg'
                                            alt='silvermedal'
                                            objectFit={"cover"}
                                            w={"100%"}
                                            h={"100%"}
                                        />
                                    }

                                    {
                                        badge?.badge === 'BRONZE' &&
                                        <Image
                                            src='/assets/bronzemedal.svg'
                                            alt='bronzemedal'
                                            objectFit={"cover"}
                                            w={"100%"}
                                            h={"100%"}
                                        />
                                    }
                                    
                                    <Text
                                        textAlign={"center"}
                                        mb={"3"}
                                        color={'#374557'}
                                        fontSize={"18px"}
                                        fontWeight={"700"}
                                    >{badge?.badge}
                                    </Text>
                                    <Text fontSize={"12px"} color={"rgba(160, 152, 174, 1)"} textAlign={"center"}>Earned On</Text>
                                    <Text fontSize={"12px"} color={"rgba(160, 152, 174, 1)"} textAlign={"center"}>{dayjs(badge?.created_at).format('DD-MM-YYYY')}</Text>
                                </Box>
                            </Box>
                            )
                        }
                        </HStack>
                        {
                            (badges?.recent?.length === 0) &&
                            <Box my="3" px="4" py="3" bg="yellow.50" rounded="lg" borderWidth="1px" borderColor="yellow.300">
                                <Text color="yellow.800">No Badges Found</Text>
                            </Box>
                        }
                        <Box py={"7"}>
                            <Text
                                color={"rgba(151, 151, 151, 1)"}
                                fontSize={"18px"}
                                fontWeight={"700"}
                            >How Badges are Earned?</Text>
                        </Box>
                        <Stack direction={{ base: "column", md: "row" }} spacing={"5"} mb={'5'}>
                            <Box
                                w={{base: "100%", md:"50%"}}
                                d={"flex"}
                                flexDir={"column"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                bg={"rgba(160, 152, 174, 0.1)"}
                                height={"200px"}
                                borderRadius={"10"}
                            >
                                <Text
                                    color={"#000"}
                                    fontSize={"18px"}
                                    fontWeight={"700"}
                                >Mock Examinations</Text>
                                <Box mt={"5"} textAlign={"center"}>
                                    <Text color={"rgba(151, 151, 151, 1)"}>
                                        90% {"&"} above marks = Gold
                                    </Text>
                                    <Text color={"rgba(151, 151, 151, 1)"}>
                                        Above 80% but less than 90% = Silver
                                    </Text>
                                    <Text color={"rgba(151, 151, 151, 1)"}>
                                        Above 70% but less than 80% = Bronze
                                    </Text>
                                </Box>

                            </Box>
                            <Box
                                w={{base: "100%", md:"50%"}}
                                d={"flex"}
                                flexDir={"column"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                bg={"rgba(160, 152, 174, 0.1)"}
                                height={"200px"}
                                borderRadius={"10"}
                            >
                                <Text
                                    color={"#000"}
                                    fontSize={"18px"}
                                    fontWeight={"700"}
                                >Content Completion</Text>
                                <Box mt={"5"} textAlign={"center"}>
                                    <Text color={"rgba(151, 151, 151, 1)"}>
                                        Topic completion (all videos in it) = Bronze
                                    </Text>
                                    <Text color={"rgba(151, 151, 151, 1)"}>
                                        Chapter completion (all topics and videos in it) = Silver
                                    </Text>
                                    <Text color={"rgba(151, 151, 151, 1)"}>
                                        Subject completion (All chapters, topics and videos in it) = Gold
                                    </Text>
                                </Box>
                            </Box>
                        </Stack>

                        
                    </Box>
                </Box>
            }
        </Box>
    )
}

export default Badges;
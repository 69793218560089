import React, { useState, useEffect } from "react"
import { Box, HStack, Button, Text } from "@chakra-ui/react"
import { Modal, ToggleGroup, NumberInput, Select } from "../../../uikit"

export const NustCalculator = ({isOpen, onClose, ...rest}) => {

    const [ state, setState ] = useState({ type: 'MATRIC', matric_marks: 0, matric_total: 1100, fsc_marks: 0, fsc_total: 1100, test_marks: 0, test_total: 200})

    const [ total, setTotal ] = useState(null)


    const calculate = () => {
        let { fsc_marks, fsc_total, matric_marks, matric_total, test_marks, test_total } = state

        if(state?.type === 'FSC'){
            let fsc = ((parseInt(fsc_marks) / parseInt(fsc_total)) * 100)
            fsc = (fsc / 100) * 70;
            let test = ((parseInt(test_marks) / parseInt(test_total)) * 100)
            test = (test / 100) * 30;

            setTotal({ fsc, test})
        }

        if(state?.type === 'MATRIC'){
            let matric = ((parseInt(matric_marks) / parseInt(matric_total)) * 100)
            matric = (matric / 100) * 10;
            let fsc = ((parseInt(fsc_marks) / parseInt(fsc_total)) * 100)            
            fsc = (fsc / 100) * 15;

            let test = ((parseInt(test_marks) / parseInt(test_total)) * 100)
            test = (test / 100) * 75;
            setTotal({ fsc, test, matric})
        }

    }

    useEffect(() => {
        setTotal(null)
    }, [state?.type])


    
    return(
        <Modal
            open={isOpen}
            onClose={onClose}
            size="xl"
            title="NUST Aggregate Calculator"
            >
            <Box flex={1} p={8}>
                
                {/* <ToggleGroup 
                    value={state?.type}
                    onChange={(val) => setState({...state, type: val})}
                    options={[
                        {label: 'Without Matric', value: 'FSC'},
                        {label: 'With Matric', value: 'MATRIC'}
                    ]}
                /> */}

                {
                    state?.type === 'MATRIC' &&                
                    <HStack my={5} alignItems="flex-start" justifyContent="space-between" spacing={7}>
                        <NumberInput
                            label="Matric Marks"
                            value={state?.matric_marks}
                            onChange={(val) => setState({...state, matric_marks: val})}
                        />
                        <Select
                            label="Out of"
                            placeholder="Select..."
                            value={state?.matric_total}
                            onChange={(e) => setState({...state, matric_total: e.target.value})}
                            >
                                <option value={1100}>1100</option>
                                <option value={1050}>1050</option>
                                <option value={1000}>1000</option>
                                <option value={950}>950</option>
                                <option value={900}>900</option>
                                <option value={850}>850</option>
                                <option value={800}>800</option>
                        </Select>

                    </HStack>
                }


                <HStack my={5} alignItems="flex-start" justifyContent="space-between" spacing={7}>
                    <NumberInput
                        label="FSC Marks"
                        value={state?.fsc_marks}
                        onChange={(val) => setState({...state, fsc_marks: val})}
                    />
                    <Select
                        label="Out of"
                        placeholder="Select..."
                        value={state?.fsc_total}
                        onChange={(e) => setState({...state, fsc_total: e.target.value})}
                        >
                            <option value={1100}>1100</option>
                            <option value={1050}>1050</option>
                            <option value={1000}>1000</option>
                            <option value={950}>950</option>
                            <option value={900}>900</option>
                            <option value={850}>850</option>
                            <option value={800}>800</option>
                    </Select>

                </HStack>

                <HStack my={5} alignItems="flex-start" justifyContent="space-between" spacing={7}>
                    <NumberInput
                        label="Test Marks"
                        value={state?.test_marks}
                        onChange={(val) => setState({...state, test_marks: val})}
                    />
                    <NumberInput
                        label="Out of"
                        value={state?.test_total}
                        onChange={(val) => setState({...state, test_total: val})}
                    />

                </HStack>

                {
                    total &&
                    <Box bg="gray.100" rounded="md" my={5} px={8} py={5}>
                        
                            
                        <HStack alignItems="center" justifyContent="space-between">
                            <Text>Aggregate</Text>
                            {
                                state?.type === 'FSC' ?
                                <Text fontweight="bold">{(total?.fsc + total?.test).toFixed(4)}%</Text>
                                :
                                <Text>{(total?.fsc + total?.test + total?.matric).toFixed(4)}%</Text>
                            }
                            
                        </HStack>
                            
                        
                    </Box>
                }
                <Button onClick={calculate}>Calculate</Button>
            </Box>

        </Modal>
    )
}
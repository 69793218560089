import { useContext, useRef, useEffect } from 'react';
import {AlertContext, AppContext} from '../services'

const useApp = () => useContext(AppContext);
const useAlert =() => useContext(AlertContext)

const  usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

export {useApp, useAlert, usePrevious}
// export * from './onLoadImages'
export * from './useLocalStorage'

import React, { Fragment, useEffect, useState } from 'react';
import { Box, Heading, HStack, Image, Text, useBoolean } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { useApp } from '../../../hooks';


const Reports = () => {
    const dispatch = useDispatch();    
    const { setDashHeading } = useApp()

    useEffect(() => {
        setDashHeading("Reports")
        return () => {
            setDashHeading("Dashboard")
          };
    }, [])

    
    return (
        <Box px="2em" py="5">   
            <Box bg="yellow.50" p="5">
                <Heading size="md">No Reports found</Heading>
            </Box>
        </Box>
    )
}

export default Reports;
import {handleResponse, requestTimeout} from '../helpers'
import Cookies from 'js-cookie'
const apiUrl = process.env.REACT_APP_API_URL
const tokenName = process.env.REACT_APP_REFRESH_TOKEN
const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME

export const authApi = {
    _login,
    _profile,
    _signup,
    _logout,
    _fetch_user,
    _update_user,
    _refresh,
    _resend_request,
    _forget_password,
    _check_reset,
    _reset_password,   
    _change_password, 
    _remove_account,
    _set_activity,
    _avatar,
    _step_one,
    _step_two,
    _step_three,
    _get_messages,
    _update_messages,
    _get_download_link

};


async function _logout(){
    const app_token = localStorage.getItem(accessToken)
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + app_token
        }        
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'logout', requestOptions))
                .then(handleResponse)
                .then( () => {
                    localStorage.removeItem(tokenName);    
                    localStorage.removeItem(accessToken)
                    return true;
                });    
}

async function _login(form_data) {

    let auth_data = {
        ...form_data,
        grant_type: "password",
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret:process.env.REACT_APP_CLIENT_SECRET,        
    }
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(auth_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'oauth/token', requestOptions))
                .then(handleResponse)
                .then((response) => {
                    if (response.access_token) {                
                        localStorage.setItem(accessToken, response?.access_token)
                        Cookies.set('insaf-auth-token', response?.refresh_token, { domain: process.env.REACT_APP_BASE_DOMAIN, expires: 15 })
                    }
                    return {user: response.user, token: response.access_token};
                });
}

async function _profile() {    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'profile', requestOptions))
                .then(handleResponse)
                .then((response) => {
                    return response
                });
}


async function _signup(form_data) {
    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(form_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'signup', requestOptions))
                .then(handleResponse)
                .then((response) => {
                    if (response.access_token) {                
                        localStorage.setItem(accessToken, response?.access_token)
                        Cookies.set('insaf-auth-token', response?.refresh_token, { domain: process.env.REACT_APP_BASE_DOMAIN, expires: 15 })
                    }
                    return {user: response.user, token: response.access_token};
                });
}


async function _forget_password(form_data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(form_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'forget-password', requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}

async function _reset_password(form_data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(form_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'reset-password', requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}

async function _check_reset(form_data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(form_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'check-reset', requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}


async function _fetch_user() {
    const token = Cookies.get('insaf-auth-token')    
    await _refresh(token);

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem(accessToken)
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'admin/me', requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _update_user(data) {
    const token = localStorage.getItem(accessToken)
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ token
        },
        body: JSON.stringify(data)
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'user-update', requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _change_password(data) {
    const token = localStorage.getItem(accessToken)
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ token
        },
        body: JSON.stringify(data)
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'auth/change-password', requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}


async function _refresh(refresh_token){

    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body:JSON.stringify({
                "grant_type": "refresh_token",
                "client_id": process.env.REACT_APP_CLIENT_ID,
                "client_secret":process.env.REACT_APP_CLIENT_SECRET,                                 
                "refresh_token": refresh_token
        })
    };
    if(refresh_token){
        return requestTimeout(apiMaxTime,fetch(apiUrl + 'oauth/token', requestOptions))
            .then(handleResponse)
            .then((data) => {     
                localStorage.setItem(accessToken, data?.access_token)
                Cookies.set('insaf-auth-token', data?.refresh_token, { domain: process.env.REACT_APP_BASE_DOMAIN, expires: 15 })
                return data;
            });
    }
    return Promise.reject({error: 'Unauthorized Access'})
}

async function _resend_request(url, config){
    return requestTimeout(apiMaxTime, fetch(url, config))
            .then((data) => {  
                return data;
            });
}


async function _step_one(form_data) {
    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(form_data)

    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'auth/step-one', requestOptions))
                .then(handleResponse)
                .then((response) => {
                    return response
                });
}

async function _set_activity(topic_id) {    
    const app_token = localStorage.getItem(accessToken)
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + app_token
        }        
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'profile/set-activity/'+topic_id, requestOptions))
                .then(handleResponse)
                .then((response) => {
                    return response
                });
}
async function _avatar(form_data) {    
    const app_token = localStorage.getItem(accessToken)
    const requestOptions = {
        method: 'POST',
        headers: {
            // 'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + app_token
        },
        body: form_data       
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'profile/avatar', requestOptions))
                .then(handleResponse)
                .then((response) => {
                    return response
                });
}

async function _step_two(form_data) {
    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(form_data)

    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'auth/step-two', requestOptions))
                .then(handleResponse)
                .then((response) => {
                    return response
                });
}

async function _step_three(form_data) {
    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(form_data)

    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'auth/step-three', requestOptions))
                .then(handleResponse)
                .then((response) => {
                    return response
                });
}

async function _remove_account() {
    const token = localStorage.getItem(accessToken)
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ token
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'auth/delete-account', requestOptions))
                .then(handleResponse)
                .then((data) => {             
                    return data;
                });
}

async function _get_messages() {    
    const token = localStorage.getItem(accessToken)
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        }

    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'get-messages', requestOptions))
                .then(handleResponse)
                .then((response) => {
                    return response
                });
}

async function _update_messages(id) {    
    const token = localStorage.getItem(accessToken)
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        }

    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'update-message/'+id, requestOptions))
                .then(handleResponse)
                .then((response) => {
                    return response
                });
}

async function _get_download_link(video_id) {        

    // const vimeoApiUrl = `https://api.vimeo.com/videos/`;
    const token = localStorage.getItem(accessToken)
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    console.log("Vid URI", apiUrl+video_id)
    return requestTimeout(apiMaxTime,fetch(apiUrl+'get-video-link/' + video_id, requestOptions))
                .then(handleResponse)
                .then((data) => {      
                    return data;
                });
}
import React, { useState } from "react";
import { Box, Heading, Button, Text, useBoolean, Image, useToast } from "@chakra-ui/react";
import { Modal, Input } from "../../../uikit";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useApp } from "../../../hooks";


export const  Login = ({ open, onClose, ...rest }) => {

    const [state, setState] = useState({});
    const navigate = useNavigate();
    const auth = useApp();    
    const [error, setError] = useState({})
    const [loading, setLoading] = useBoolean();
    const toast = useToast();

    const handleChange = (name, value) => {
        setState({ ...state, [name]: value })
    }
    const handleLogin = (e) => {
        e.preventDefault()
        setLoading.on()
        auth.login(state)
            .then((res) => {
                setLoading.off()
                setState({})
                onClose();
                
                if(res?.user?.group === 'STUDENT'){
                    navigate('/account');
                }
                if(res?.user?.group === 'TEACHER'){
                    navigate('/dashboard');
                }

                if(res?.user?.group === 'PARENT'){
                    navigate('/parent');
                }
                
                toast({
                    title: "",
                    description: "login successful",
                    status: "success",
                    position: "top",
                    duration: 9000,
                    isClosable: true,
                })
            })
            .catch((err) => {
                setLoading.off()
                setError(err.error);
                setState({})
                console.log("Error", err)
                toast({
                    title: "Authentication Error!",
                    description: err?.message || err?.error,
                    status: "error",
                    position: "top",
                    duration: 9000,
                    isClosable: true,
                })
            })
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            size="md"
            >
            <Box px="12" py="10">
                <Image src="/assets/insaf-academy-emblem.svg" maxH="85px" mb="20px" mx="auto" />
                {/* <Heading my="5" size="md">Welcome Back</Heading> */}
                <form onSubmit={handleLogin} id="login-form">
                    <Box mb="4">
                        <Input
                            error={error && error?.email?.message}
                            label="Email"
                            variant="filled"
                            value={state?.username || ''}
                            onChange={(e) => handleChange('username', e.target.value)}
                        />
                    </Box>

                    <Box mb="4">
                        <Input
                            error={error && error?.password?.message}
                            type="password"
                            label="Password"
                            variant="filled"
                            value={state?.password || ''}
                            onChange={(e) => handleChange('password', e.target.value)}
                        />
                    </Box>
                    <Button type="submit" colorScheme="accent" w="100%" isLoading={loading} loadingText="Please wait...">Login</Button>
                    <Box mt="5">
                        <Text variant="link" as={Link} to="/forget-password">Forget Password?</Text>
                        <Text variant="link" as={Link} to="/register">Don't have an account? Register Now</Text>
                    </Box>
                </form>
            </Box>
        </Modal>
    )
}
import React, {useState, useEffect} from "react"
import { Box, Heading, HStack, Text, Button, Menu, MenuItem, MenuList, MenuButton } from '@chakra-ui/react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Icon } from "../../../../uikit"
import { usePrevious } from "../../../../hooks"


export const DonutChart = ({height, data, title, ...rest}) => {


    const [ current, setCurrent ] = useState(0)
    
    
    let categories = [];
    let items = [];

    
    data?.totals?.map((item, i) =>{
        return categories.push({id: item?.id, title: item?.title})
        // let progress_total = data?.progress?.filter((x) => x?.subject_id === item?.id)?.length || 0;
        // let p = parseInt(progress_total || 0)/parseInt(item?.total_contents || 0) * 100
        // items.push({
        //     name: item?.title,
        //     color: colors[i],
        //     y: parseFloat(p)
        // })
    })

    const [ category, setCategory ] = useState(categories[0]?.id)
    const prevCategory = usePrevious(category)
    const options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,            
            type: 'pie'
        },
        title: {
            enabled: false,
            text: '',
            
        },        
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        // plotOptions: {
        //     pie: {
        //         allowPointSelect: true,
        //         cursor: 'pointer',
        //         dataLabels: {
        //             enabled: false
        //         },
        //         showInLegend: false
        //     }
        // },
        credits: {
            enabled: false
        },
        legend: {
            layout: 'horizontal',
            align: 'left',
            verticalAlign: 'bottom'
        },
        plotOptions: {
            pie: {
                borderWidth: 0,
                // shadow: false,
                // center: ['50%', '50%'],
                dataLabels: {
                    enabled: false
                },
            }
        },
        series: [{
            // name: 'Brands',
            // colorByPoint: true,
            data: [{
                name: 'Remaining',
                y: parseInt(100 - (current)),
                // sliced: true,
                // selected: true,
                color: "#2E715C"
            }, {
                name: 'Completed',
                y: parseInt(current),
                color: "#FEC64F"
            }]
        }]
    }

    useEffect(() => {
      if(categories && prevCategory !== category){
            let sub_totals = data?.totals?.find((x) => x?.id === category)
            let completed = data?.progress?.filter((y) => y?.subject_id === category );
            // console.log("Subject Progress", sub_totals?.total_contents)
            // console.log("Subject Progress", completed?.length)
            let total = parseFloat((completed?.length / sub_totals?.total_contents || 0) * 100).toFixed(2)
            // console.log("Total", total)
            setCurrent(total)
      }
    }, [categories, category, prevCategory])

   

    console.log("Current", current)
    return(
        <Box position="relative">
            <Heading mx="1em" my="3" size="md">{title}</Heading>            

            <HStack px="5" spacing={5}>
                <Box>
                    <Menu>
                        <MenuButton as={Button} fontWeight="normal" colorScheme="gray" variant="link" _hover={{textDecoration: 'none' }} rightIcon={<Icon name="angle-down-solid" />}>
                            {categories?.find(x => x?.id === category)?.title || 'Select...'}
                        </MenuButton>
                        <MenuList>
                            {
                                categories?.map((cat, c) =>
                                <MenuItem key={c} onClick={() => setCategory(cat?.id)}>{cat?.title}</MenuItem>
                                )
                            }
                            
                        </MenuList>
                    </Menu>
                </Box>
                <Box>
                    <Menu>
                        <MenuButton as={Button} fontWeight="normal" colorScheme="gray" variant="link" _hover={{textDecoration: 'none' }} rightIcon={<Icon name="angle-down-solid" />}>
                            Video
                        </MenuButton>
                        <MenuList>
                            {/* <MenuItem>Quiz</MenuItem> */}
                            <MenuItem>Video</MenuItem>
                        </MenuList>
                    </Menu>
                </Box>
            </HStack>
            
            <Box position="relative">
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}                
                />
                <Box position="absolute" rounded="full" w="130px" h="130px" bg="white" left="50%" top="50%" transform="translate(-50%, -50%)" />
                <Box position="absolute" rounded="full" w="70px" h="70px" bg="#f2f2f2" left="50%" top="50%" transform="translate(-50%, -50%)" />
            </Box>
            <Box px="3" top="-3" position="relative">
                <HStack mb="2">
                    <Box w="18px" h="18px" rounded="md" bg="#2E715C" />
                    <Text>Remaining</Text>
                </HStack>
                <HStack>
                    <Box w="18px" h="18px" rounded="md" bg="#FEC64F" />
                    <Text>Completed</Text>
                </HStack>
            </Box>
        </Box>
    )
}
import React, { useEffect } from "react";
import { Box, Heading, HStack, useBoolean, Text, Button, Spinner, Image } from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'
import { Container } from "../../uikit"
import { useApp } from '../../hooks'
import { useSelector, useDispatch } from "react-redux";
import { gradeAction } from "../../redux/actions";
import { ClassCard } from "../Common";
import { isEmpty } from "../../helpers";
import { Link } from "react-router-dom";

export default function MockExam() {
    
    const dispatch = useDispatch()
    const { user } = useApp()
    const [ loading, setLoading ] = useBoolean(false)
    const { with_subjects_minimal } = useSelector(state => state?.grades)

    useEffect(() => {
        setLoading.on()
        dispatch(gradeAction.with_subjects_minimal())
            .then((res) => setLoading.off())
            .catch((err) => setLoading.off())
    }, [])

    
    

    return (
        <>
            <Helmet>
                <title>Mock Exams - PCTB Online Academy</title>
                <meta name="description" content="PCTB Online Academy is FREE online learning platform for HSSC and SSC Student covering of Subjects, Assessment and other learning material to help our young genration for tapping in to the future." />
            </Helmet>
            <Box  pt="100px" bgBlendMode="overlay" bgImage="/assets/mock-exam-header.png" bgRepeat="no-repeat" bgSize="cover" bgPosition="center center">
                <Box py="5em">
                    <Container>
                        <HStack alignItems="flex-end">
                            <Box flex="1">
                                <Text variant="heading-title">Mock Examination</Text>
                                <Heading color="white">Achieve High Marks in Board Exams {'\n'}by taking Mock Examinations </Heading>
                                <Text variant="loud" color="whiteAlpha.700">Databank of 1000+ MCQs available of each subject for your practice</Text>                                
                                <Button as={Link} to={isEmpty(user) ? "/register" : "/account"} size="lg" mt={10} colorScheme="accent">Take Mock Exam</Button>
                            </Box>
                            <Box w="40%" textAlign="right">
                            
                            </Box>
                        </HStack>
                    </Container>
                </Box>
            </Box>
            <Box py="4em" pb="2em">
                <Container>
                    <Heading mb="20px" size="md">About Mock Examinations</Heading>
                    <Text>
                        Mock Examinations are meant to prepare you for the board examinations. We have built huge databank of MCQs for each subject and the system will randomly choose different questions each time you attempt Mock Examination. Mock Examinations are currently available for Class IX (English Medium) and Class X (English Medium), For Class XI (Urdu Medium), Class X (Urdu Medium), Class XI and Class XII Mock Examinations will be available in May 2022.
                    </Text>
                        {
                            loading &&
                            <Box p="10" textAlign="center">
                                <Spinner />
                            </Box>
                        }
                        <HStack my="5" spacing="0" alignItems="flex-top" flexWrap="wrap" mx="-15px">
                            {[...with_subjects_minimal].reverse().map((grade, idx) => (
                                <ClassCard
                                    w={{ base: '50%', md: '50%', lg: '33.33%' }}
                                    key={idx}
                                    subtitle={grade?.label || "..."}
                                    heading={grade.title}                                    
                                    to={'/register'} />
                            ))}

                        </HStack>                                                             
                </Container>
            </Box>

            <Box py="4em" pt="0">
                <Container>
                    <HStack flexDir={{ base: 'column', lg: 'row'  }} alignItems="center" justifyContent="space-between" spacing="10">
                        <Box flex="1.5">
                            <Image mb={{ base: 4, lg: 0 }} src="/assets/home-learning.png" alt="Home Learning" />
                        </Box>
                        <Box flex="2">
                            <Heading mb="40px" size="xl">“Get yourself Featured on Home Page by Earning Achievement Badges”</Heading>
                            <Text>Achieve high score in Mock Examinations to earn Achievement Badges and get yourself featured on home page of PCTB Online Academy. Attempt more & a greater number of Mock Examinations to not only prepare yourself for your board exams but also to get yourself featured on the home page.  </Text>
                            <Button as={Link} to="/register" size="lg" mt={10} colorScheme="accent">Take Mockup Exam</Button>
                        </Box>
                    </HStack>                                                          
                </Container>
            </Box>
        </>
    )
}
import { extendTheme } from "@chakra-ui/react"
import { colors, button, text, input, textarea, select } from "./styles"
import { custom } from "./styles/custom"

export const theme = extendTheme({
    styles:{
        global: custom
    },
    fonts: {
        body: "Inter,Helvetica Neue,Arial,sans-serif",
        heading: "Raleway,Helvetica Neue,Arial,sans-serif",
        mono: "Times New Roman, monospace",
    },
    colors: colors,
    shadows:{
        "custom": "0px 0px 10px 0px rgba(50, 50, 50, 0.15)",
        "topper": "0px 4px 20px rgba(245, 134, 52, 0.3)",
        "sidebar": "0px 0px 20px 0px rgba(50, 50, 50, 0.15)"
    },
    components:{
        Button: button,
        Heading:{
            baseStyle: {
                color: 'gray.700'
            },
            variants:{
                "page":{
                   fontSize: '22px',
                   color: 'gray.600',
                   fontWeight: '600'
                },
                'dashboard':{
                    fontFamily: 'Poppins',
                    fontSize: '36px',
                    fontWeight: 'bold'
                },
                "insaf-heading":{
                    fontSize:{
                        base: "20px",
                        md: '36px',
                        lg: '40px'
                    }
                }
            }
        },
        FormLabel:{
            baseStyle: {
                color: 'gray.500'
            }
        },
        Text: text,
        Input: input,
        Textarea: textarea,
        Select: select,
        Switch: {
            baseStyle: {
                backgroundColor: 'brand',
                rounded: 'md',
                _focus: { 
                    boxShadow: 'none' 
                }
            },
        },
        Menu:{
            parts: ['menu', 'item', 'list'],      
            baseStyle:{
                shadow: 'xl'
            },  
            sizes: {
                sm: { 
                    list:{
                        shadow: '3xl',
                        rounded: 'none',
                        minW: '150px',
                        py: 0,
                    },                   
                    item: {
                        fontSize: '0.85rem',
                        px: 3,
                        py: 2,
                    },                    
                },
            },
            variants:{
                classes:{
                    list:{
                        py: 2,
                        border:0,
                        minW: '240px',
                        rounded: 'md'
                    },
                    item: {
                        bg: 'transparent',
                        fontSize: '1rem',
                        fontWeight: '600',
                        color: 'gray.400',
                        rounded: 'md',
                        px: 4,
                        py: 1,
                        _hover:{
                            bg: 'gray.100',
                            color: 'brand.700'
                        }
                    }, 
                },
                topics:{
                    list:{
                        py: 2,
                        border:0,
                        minW: '240px',
                        rounded: 'md'
                    },
                    item: {
                        bg: 'transparent',
                        fontSize: '0.85rem',
                        color: 'gray.500',
                        rounded: 'md',
                        px: 4,
                        py: 1,
                        _hover:{
                            bg: 'gray.100',
                            color: 'gray.700'
                        }
                    }, 
                }
            }
        },
        Tooltip:{
            baseStyle:{
                fontWeight: '400',
                fontSize: '12px',                
                shadow: 'none',
            }
        },
        Radio:{
            parts: ['control', 'label'],
            sizes:{
                sm: {
                    control: { width: 3, height: 3, _focus: {shadow: 'none'} },
                    label: { fontSize: "13px" },
                  }
            },
            defaultProps:{
                colorScheme: "brand",
            }
        },
        Checkbox:{
            parts: ['control', 'label', 'icon'],
            variants:{
                lead: {
                    control: { width: 3.5, height: 3.5, _focus: {shadow: 'none'} },
                    label: { fontSize: "13px" },
                    icon: {fontSize: '6px'}
                  }
            },
            defaultProps:{
                colorScheme: "brand",
            }
        }   
    }
})
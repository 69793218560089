import React from "react";
import { Link } from "react-router-dom";
import { Box, Image, HStack, Text, Heading, IconButton } from "@chakra-ui/react";
import { Container, Icon } from "../../../uikit";

export const Footer = () => {
    return(
        <Box>
            <Box bg="#BCCCC9" py="20px">
                <Container>
                    <HStack flexDir={{ base: 'column', lg: 'row' }} justifyContent="space-between" alignItems="center">
                        <Heading mb={{ base: '5', lg: 0 }} textAlign={{ base: 'center', lg: 'left' }} size="md">Download Mobile App and Start Learning</Heading>
                        <HStack alignItems="center">
                            <a rel="noreferrer" href="https://apps.apple.com/be/app/insafacademy/id1634574274" target={"_blank"}><Image maxH="40px" w="auto" src="/assets/btn-app-store.png" alt="AppStore" /></a>
                            <a rel="noreferrer" href="https://play.google.com/store/apps/details?id=app.pctb.insafacademy" target={"_blank"}><Image maxH="40px" w="auto" src="/assets/btn-play-store.png" alt="AppStore" /></a>
                        </HStack>

                    </HStack>
                </Container>
            </Box>
            <Box  bg="#EDEDED" borderTop="1px solid" borderColor="gray.200">
                <Container>
                    <HStack flexDir={{ base: 'column', lg: 'row' }} borderBottom="1px solid" borderColor="gray.300" alignItems="center" justifyContent="space-between" py="1.5em">
                        <Image  mb={{ base: '5', lg: 0 }} maxH={{base: '45px', lg:"60px"}} src="/assets/pctb-academy-logo-footer.svg" alt="PCTB Online Academy" />
                        <HStack mb="4"  spacing="3" alignItems="flex-start" justifyContent="flex-end">
                            <IconButton bg="white" color="brand.500" _hover={{ bg:"brand.500", color: "white" }} icon={<Icon name="facebook-f" color="currentcolor"/>} rounded="full" colorScheme="brand" />
                            <IconButton bg="white" color="brand.500" _hover={{ bg:"brand.500", color: "white" }} icon={<Icon name="twitter" color="currentcolor"/>} rounded="full" colorScheme="brand" />
                            <IconButton bg="white"  color="brand.500" _hover={{ bg:"brand.500", color: "white" }} icon={<Icon name="linkedin-in" color="currentcolor"/>} rounded="full" colorScheme="brand" />                                
                        </HStack>
                    </HStack>
                    <HStack flexDir={{ base: 'column', lg: 'row' }} alignItems={{base: 'center', lg:"flex-start"}} py="3em">
                        <Box mb={{ base: '30px' }} w={{base: '100%', lg: "25%"}}  mr={{lg:"3%"}} pr={{lg:"40px"}} borderRight="1px solid" borderColor="gray.200">
                            <HStack spacing="5" justifyContent={{base: 'center', lg: "flex-start"}} mb="30px">
                                <Image h={{base: '45px', lg: "80px"}} w="auto" src="/assets/pctb-logo.svg" alt="pctb" />
                                <Image h={{base: '45px', lg: "80px"}} w="auto" src="/assets/gop-logo.svg" alt="gop" />
                                <Image h={{base: '45px', lg: "80px"}} w="auto" src="/assets/school-education-logo.png" alt="gop" />
                            </HStack>
                            <Box>
                                <Text fontSize="sm" textAlign={{base: 'center', lg:"left"}}>A joint project of <strong>Punjab Curriculum Textbook Board</strong> &amp; <strong>Govt. of Punjab</strong></Text>
                            </Box>
                        </Box>
                        <HStack  w={{base: '100%', lg: "50%"}} alignItems="flex-start">
                            <Box flex={1}>
                                <Heading color="brand.500" size="sm" mb="5" fontWeight="600">PCTB Online Academy</Heading>
                                <Text as={Link} variant="footer-link" to="/about">About</Text>
                                <Text as={Link} variant="footer-link" to="/subject/12">Subjects</Text>
                                {/* <Text as={Link} variant="footer-link" to="/">Latest News</Text> */}
                                {/* <Text as={Link} variant="footer-link" to="/">How it works</Text> */}
                                <Text as={Link} variant="footer-link" to="/mock-exam">Mock Exams</Text>
                            </Box>
                            <Box flex={1}>
                                <Heading color="brand.500" size="sm" mb="5" fontWeight="600">Import Links</Heading>                                
                                <Text as={'a'} variant="footer-link" href="https://pctb.punjab.gov.pk/" target="_blank">Punjab Curriculum and Textbook Board</Text>
                                <Text as={'a'} variant="footer-link" href="https://schools.punjab.gov.pk/">Punjab School Education Department</Text>
                            </Box>
                        </HStack>
                        <Box d={{ base: 'none', lg: 'block' }} w={{base: '100%', lg: "25%"}}>
                            <Heading color="brand.500" size="sm" mb="5" fontWeight="600">Other Links</Heading>                            
                            <Text as={Link} variant="footer-link" to="/privacy-policy">Privacy Policy</Text>
                            <Text as={Link} variant="footer-link" to="/terms-and-conditions">Terms & Conditions</Text>
                            <Text as={Link} variant="footer-link" to="/contact">Contact</Text>
                        </Box>                    
                    </HStack>
                    <Box textAlign="center" pb="4">                        
                                                
                        <Text color="gray.400">&copy; 2022 PCTB Online Academy. All rights reserved.</Text>
                        <Text color="gray.400">Powered by <Text as="a" href="https://naqeebz.com" target="_blank" fontWeight="bold">Naqeebz</Text></Text>
                    </Box> 
                </Container>
            </Box>
        </Box>
    )
}
import React, { useEffect, useState } from "react"
import { Box, Spinner, useBoolean, Heading, HStack, Text,
    Accordion, AccordionItem, AccordionButton,
    AccordionPanel, AccordionIcon, Button,
} from "@chakra-ui/react"
import { useApp, usePrevious } from '../../../hooks'
import { useDispatch, useSelector } from "react-redux"
import { useParams, useNavigate } from "react-router-dom"
import { paperAction } from "../../../redux/actions"
import { Helmet } from "react-helmet-async"
import ReactPlayer from 'react-player'
import { Icon, Modal } from '../../../uikit'
import { Link } from "react-router-dom"

export default function SubjectDetails ({type}) {
    
    
    const { subject_id } = useParams()
    
    const  navigate  = useNavigate()
    const prev_subject = usePrevious(subject_id)
    const [ details, setDetails ] = useState(null)
    const { setDashHeading, profile, user} = useApp()
    const dispatch = useDispatch()
    const [count,setCount]=useState(0);
    const [ loading, setLoading] = useBoolean(false)
    const { subject } = useSelector(state => state?.papers)
    console.log(localStorage.getItem('lastitem'))

    useEffect(() => {
      
        if(subject_id && subject_id !== prev_subject){
            setLoading.on()
            dispatch(paperAction.subject(subject_id, type))
            .then((res) =>{
                setLoading.off()
            })
            .catch((err) =>{
                setLoading.off()
            })  
        }  
    }, [subject_id])
    
    


    useEffect(() => {
      
        setDashHeading(type)
      return () => {
        setDashHeading('Dashboard')
      };
    }, [])


   
    
    return(
        <>
            <Helmet>
                <title>Subjects - PCTB Online Academy</title>
                <meta name="description" content="PCTB Online Academy is FREE online learning platform for HSSC and SSC Student covering of Subjects, Assessment and other learning material to help our young genration for tapping in to the future." />
            </Helmet>
            {
                loading ?
                    <Box d="flex" h="100%" alignItems="center" justifyContent="center">
                        <Spinner />
                    </Box>
                :
                <Box px={{base: "1em", md:"2em"}} py="3">
                    <Heading fontFamily="Poppins" size="md">{type}  {`${subject?.title}`}</Heading>

                    <Button mt="5" colorScheme="black" leftIcon={<Icon name="long-arrow-alt-left-solid" color="currentcolor" />} onClick={() => navigate('/account/'+type.toLowerCase())} variant="link">Back</Button>
                    {
                        subject?.topics?.length > 0 ?
                        <Accordion mb="10" defaultIndex={[Number(localStorage.getItem('lastitem'))]} allowMultiple>
                        {                        
                                subject?.topics?.map((topic, index) =>
                                  
                                    <AccordionItem key={index} border="0" my="8" rounded="lg" shadow="custom" onClick={console.log(index)} bg="white" >
                                        <AccordionButton flexDirection={{ base: "column", md: "row" }} border="0" px={{base: "4", md: "10"}} py="4" _hover={{ bg: 'transparent' }} _focus={{ shadow: 'none' }} borderBottom="1px solid" borderColor="gray.200" alignItems={{ base: "normal", md: "space-between" }}>
                                            <Box flex='1' textAlign="left" pb="5px">
                                                <Text fontSize="15px" mt="4" fontWeight="500" color="accent.500">{topic?.label}</Text>
                                                <Heading fontFamily="Poppins" fontSize={{base: '18px', md: '24px', lg: "30px"}}>{topic.title} </Heading>
                                            </Box>
                                            <HStack spacing="5">
                                                {/* <Button isDisabled colorScheme="accent">Chapter Quiz</Button> */}
                                                
                                                
                                                <Text display={{ base: "none", md: "block" }} fontSize="12px" color="accent.500" onClick ={()=>localStorage.setItem('lastitem',index)} >Click To Expand</Text>
                                                <AccordionIcon color="accent.500" />
                                            </HStack>
                                        </AccordionButton>
                                        <AccordionPanel  pb={4} px={{base: 4, md:"10"}}>
                                            <Text>{topic.details}</Text> 
                                            <Heading my="5" fontSize="20px">Lectures</Heading>
                                            <Box>
                                                
                                                        
                                                        <HStack my="5" flexDir={{ base: 'column', md: 'row' }} alignItems="flex-start" flexWrap="wrap" spacing="0">
                                                        {
                                                            topic.contents.map((content, c) => 
                                                            <Box w={{base: '100%', lg:"50%"}} key={c} cursor="pointer" onClick={() => navigate('/account/'+type.toLowerCase()+'/lecture/'+content?.id)}>
                                                                <Box  py="10px" px={{base: "10px", md:"20px"}}>
                                                                    <HStack alignItems="flex-start" flex={1} flexDir={{ base: 'column', lg: 'row' }} position="relative" spacing={{base: 0, lg:"6"}} bg={"gray.100"} p="20px" rounded="md">
                                                                        {
                                                                            content?.type === 'VIDEO' &&
                                                                            <Box minW={{base: '100%', lg:"30%"}} rounded="lg" overflow="hidden">
                                                                                <Box className='player-wrapper'>  
                                                                                    <ReactPlayer className='react-player' width="100%" height='100%' url={'https://www.youtube.com/watch?v='+content?.data} light={true} />                                                            
                                                                                </Box>
                                                                            </Box>
                                                                        }
                                                                        <Box>
                                                                            
                                                                            {
                                                                                <Box>
                                                                                    <Text my={2} fontWeight="bold">{content.title}</Text>
                                                                                    <Text fontSize="14px">{content?.description}</Text>
                                                                                    
                                                                                </Box>
                                                                            }      
                                                                                                                    
                                                                        </Box>
                                                                       
                                                                    </HStack>
                                                                </Box>
                                                            </Box>
                                                        )}
                                                        </HStack>
                                                        
                                                        {
                                                            topic?.quiz && 
                                                            <>
                                                             { !topic?.quiz?.result ?
                                                                <Button onClick={() => navigate('/account/'+type.toLowerCase()+'-topic-quiz/'+topic?.quiz?.id)}>Take Quiz</Button>
                                                                :
                                                                <HStack alignItems="center" justifyContent="space-between" mx={5} rounded="md" border="1px solid" borderColor="accent.400" flex={1} p={5} bg="accent.100">
                                                                    <Box textAlign="center">
                                                                        <Text fontWeight="bold" color="accent.600">Total Questions</Text>
                                                                        <Text>{topic?.quiz?.result?.result?.total_questions}</Text>
                                                                    </Box>
                                                                    <Box textAlign="center">
                                                                        <Text fontWeight="bold" color="accent.600">Correct</Text>
                                                                        <Text>{topic?.quiz?.result?.result?.correct}</Text>
                                                                    </Box>
                                                                    <Box textAlign="center">
                                                                        <Text fontWeight="bold" color="accent.600">Percentge</Text>
                                                                        <Text>{topic?.quiz?.result?.result?.percentage?.toFixed(2)}%</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Button onClick={() => navigate('/account/'+type.toLowerCase()+'-topic-quiz/'+topic?.quiz?.id)}>ReTake Quiz</Button>
                                                                    </Box>

                                                                    
                                                                </HStack>
                                                                }
                                                            </>
                                                        }
                                                

                                            </Box>
                                        </AccordionPanel>
                                    </AccordionItem>
                                )
                            }
                        </Accordion>
                        :
                        <Box my="5" bg="yellow.50" p="10" rounded="lg">
                            <Heading size="sm">Coming Soon</Heading>
                        </Box>
                    }

                    <Modal 
                        open={details !== null}
                        onClose={() => setDetails(null)}
                        title="Result Details"
                        size="3xl"
                        >
                        <Box bg="white" rounded="lg">
                        {
                            details?.details?.map((requ, b) =>                                         
                                <Box key={b} p="5" borderBottom="1px solid" borderColor="gray.200">
                                    <Heading size="sm">Question No. {parseInt(b) + 1}</Heading>
                                    <Box my="4" fontWeight="bold">{requ?.question}</Box>
                                    <Box>
                                    {
                                        requ?.options?.map((anu, n) => {
                                            if(requ?.correct_answer === anu?.id ){
                                                return(
                                                    <HStack p="2" rounded="md" key={n} my="2" bg='green.100'>
                                                        <Text fontWeight="bold">{parseInt(n+1)}:</Text>
                                                        <Text>{anu?.answer}</Text>
                                                    </HStack>
                                                )
                                            }else if(requ?.answer === anu?.id && !requ?.correct ){
                                                return(
                                                    <HStack p="2" rounded="md" key={n} my="2" bg='red.100'>
                                                        <Text fontWeight="bold">{parseInt(n+1)}:</Text>
                                                        <Text>{anu?.answer}</Text>
                                                    </HStack>
                                                )
                                            }else{
                                                return(
                                                    <HStack p="2" rounded="md" key={n} my="2" bg='transparent'>
                                                        <Text fontWeight="bold">{parseInt(n+1)}:</Text>
                                                        <Text>{anu?.answer}</Text>
                                                    </HStack>
                                                )
                                            }
                                            
                                        })
                                    }
                                    </Box>                                                
                                </Box>
                                                                            
                            )
                        }
                        </Box>
                    </Modal>
                </Box>
            }            
            
        </>
    )
}
import React, { Fragment, useEffect, useState } from 'react';
import { Box, HStack, Image, Text, Heading, useBoolean, Button } from '@chakra-ui/react';
import { useApp } from '../../../hooks';
import { Icon } from '../../../uikit';
import { useDispatch, useSelector } from 'react-redux';
import { parentAction } from '../../../redux/actions';
import { useNavigate } from 'react-router-dom';


const Notifications = () => {
     
    const { setDashHeading } = useApp()

    const dispatch = useDispatch();    
    const navigate = useNavigate()    
    const [ removing, setRemoving ] = useState(null)
    const [ loading, setLoading ] = useBoolean(false)
    const { children  } = useSelector(state => state?.parent)
    const {user, profile} = useApp()    


    useEffect(() => {
        setLoading.on()
        dispatch(parentAction.get())
            .then((res) => {
                setLoading.off()                
            })
            .catch((err) => {
                setLoading.off()
            })
    }, [])

    useEffect(() => {
        setDashHeading("Children")
        return () => {
            setDashHeading("Dashboard")
          };
    }, [])


    const handleRemove = async (id) => {
        let result = await window.confirm("Do you really want to remove this child?", 'Remove Child');
        if(result){
            setRemoving(id)
            dispatch(parentAction.remove(id))
                .then((res) => {
                    setRemoving(null)
                })
                .catch((err) =>{
                    setRemoving(null)
                })
        }
    }

    console.log("children", children)
    
    return (
        <Box m="2em" px="1em" py="5" bg="white" rounded="lg" shadow="sm">
            <Box mt="2" p="5" bg="white" rounded="lg">                        
            <table className="custom-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>CNIC/B-Form</th>
                        <th>Father Name</th>                        
                        <th></th>                                                              
                    </tr>                                
                </thead>
                <tbody>
                    {
                        children?.map((student, s) =>
                        <tr key={s} onClick={() => navigate('/parent/children/progress/'+student?.student_id)}>
                            <td>{student?.child?.first_name} {student?.child?.last_name}</td>
                            <td>{student?.child?.cnic} </td>
                            <td>{student?.child?.profile?.father_name}</td>                                                                        
                            <td onClick={(e) => e.stopPropagation()}><Button isLoading={removing === student?.id} onClick={() => handleRemove(student?.id)} size="xs" colorScheme="red">Remove</Button></td>
                        </tr>
                        )
                    }
                </tbody>
            </table>
        </Box>

        </Box>
    )
}

export default Notifications;
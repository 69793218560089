import React, { useState, useEffect } from 'react'
import { Box, Slider, Text, Heading, Button, useToast,
    SliderTrack, SliderFilledTrack, SliderThumb, HStack, useBoolean 
} from '@chakra-ui/react'
import { Modal } from '../../../uikit'
import { useDispatch, useSelector } from 'react-redux'
import { gradeAction, virtualClassAction } from '../../../redux/actions'
import { Input } from '../../../uikit'

export default function NewQuiz({open, classId, subject, onClose, ...rest}){

    const dispatch = useDispatch()
    const toast = useToast()
    const [state, setState] = useState({level: 'MEDIUM', duration: 10, total: 25, passing: 50})
    const [ errors, setErrors ] = useState(null)
    const [ subjects, setSubjects ] = useState([])
    const [ submitting, setSubmitting ] = useBoolean(false)
    const { with_subjects } = useSelector(state => state?.grades)
    const handleInputChange = (name, value) => {
        setState({
            ...state,
            [name]: value
        })
    }

    useEffect(() => {
        dispatch(gradeAction.with_subjects())
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitting.on()
        dispatch(virtualClassAction.quiz({...state, virtual_class_id: classId, subject: subject}))
            .then((res) => {
                setSubmitting.off()
                toast({
                    title: 'Virtual Class',
                    description: res?.message || 'Assessment created successfully',
                    position: 'top'
                })
                setState({total_students: 10})
                onClose()
            })
            .catch((err) => {
                setErrors(err?.error)
                setSubmitting.off()
            })

    }

    useEffect(() => {
        if(state?.grade_id){
            let grade = with_subjects?.find((x) => x?.id === parseInt(state?.grade_id))
            setSubjects(grade?.subjects || [])
        }
    }, [state?.grade_id])
    
    return(
        <Modal
            open={open}
            onClose={onClose}            
            >                
            <Box p={6}>
                <form onSubmit={handleSubmit}>
                    <Heading textAlign="center" size="md" my="5">Create Assessment</Heading>
                    <Box my={3}>
                        
                        <Box my="3">
                            <Input 
                                error={errors?.title}
                                variant="filled"
                                label="Title"
                                value={state?.title || ""}
                                onChange={(e) => handleInputChange('title', e.target.value)}
                            />
                        </Box>

                        <Box my="3">
                            <Input 
                                textarea
                                error={errors?.description}
                                variant="filled"
                                label="Description"
                                value={state?.description || ""}
                                onChange={(e) => handleInputChange('description', e.target.value)}
                            />
                        </Box>

                        <Box my="3">
                            <Text fontWeight="bold" fontSize="12px">Difficulty Level</Text>
                            <HStack mt="3" justifyContent="space-between">
                                <Box cursor="pointer" py="3" flex="1" rounded="lg" bg={state?.level === 'EASY' ? 'accent.500' : 'white'} color={state?.level === 'EASY' ? 'white' : 'accent.500'}  textAlign="center" border="1px solid" borderColor="accent.500"  onClick={() => handleInputChange('level', 'EASY')}>Easy</Box>
                                <Box cursor="pointer"  py="3" flex="1" rounded="lg" bg={state?.level === 'MEDIUM' ? 'accent.500' : 'white'} color={state?.level === 'MEDIUM' ? 'white' : 'accent.500'}  textAlign="center" border="1px solid" borderColor="accent.500"   onClick={() => handleInputChange('level', 'MEDIUM')}>Medium</Box>
                                <Box  cursor="pointer"  py="3" flex="1" rounded="lg" bg={state?.level === 'HARD' ? 'accent.500' : 'white'} color={state?.level === 'HARD' ? 'white' : 'accent.500'}  textAlign="center" border="1px solid" borderColor="accent.500"   onClick={() => handleInputChange('level', 'HARD')}>Hard</Box>
                            </HStack>
                        </Box>
                        <Box my="3">
                            <Heading size="sm">Duration</Heading>                
                            <Box p={5} borderWidth="1px" borderColor="gray.200" rounded="md">
                                <Text fontSize="24px" fontWeight="bold" mb="4" textAlign="center">{state?.duration} min</Text>
                                <Slider aria-label='slider-ex-1' value={state?.duration || 10} onChange={(val) => handleInputChange('duration', val)} max={45} min={15} colorScheme="accent" size="md">
                                    <SliderTrack h="10px" rounded="full">
                                        <SliderFilledTrack />
                                    </SliderTrack>
                                    <SliderThumb bg="brand.500" w="24px" h="24px" />
                                </Slider>                        
                            </Box>  
                        </Box>

                        <Box my="3">
                            <Heading size="sm">Number of Questions.</Heading>                
                            <Box p={5} borderWidth="1px" borderColor="gray.200" rounded="md">
                                <Text fontSize="24px" fontWeight="bold" mb="4" textAlign="center">{state?.total}</Text>
                                <Slider aria-label='slider-ex-1' value={state?.total || 10} onChange={(val) => handleInputChange('total', val)} max={50} min={15} colorScheme="accent" size="md">
                                    <SliderTrack h="10px" rounded="full">
                                        <SliderFilledTrack />
                                    </SliderTrack>
                                    <SliderThumb bg="brand.500" w="24px" h="24px" />
                                </Slider>                        
                            </Box>  
                        </Box>

                        <Box my="3">
                            <Heading size="sm">Passing %</Heading>                
                            <Box p={5} borderWidth="1px" borderColor="gray.200" rounded="md">
                                <Text fontSize="24px" fontWeight="bold" mb="4" textAlign="center">{state?.passing}%</Text>
                                <Slider aria-label='slider-ex-1' value={state?.passing || 50} onChange={(val) => handleInputChange('passing', val)} max={100} min={50} colorScheme="accent" size="md">
                                    <SliderTrack h="10px" rounded="full">
                                        <SliderFilledTrack />
                                    </SliderTrack>
                                    <SliderThumb bg="brand.500" w="24px" h="24px" />
                                </Slider>                        
                            </Box>  
                        </Box>

                    </Box>  
                  

                    <Box mt={10}>
                        <Button isFullWidth type="submit">Create Assessment</Button>
                    </Box>
                </form>

            </Box>
        </Modal>
    )
}
import React, { useEffect, useState } from "react"
import { Box, Spinner, useBoolean, Heading, HStack, Button, Text,
    Slider, Stack,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
 } from "@chakra-ui/react"
import { useApp } from '../../../hooks'
import { useDispatch, useSelector } from "react-redux"
import { subjectApi } from "../../../api"
import { Helmet } from "react-helmet-async"
import { SubjectCard } from "./Components"
import { useLocation, useNavigate, useParams } from "react-router-dom"

export default function NewExam () {
    
    const { setDashHeading, profile} = useApp()    
    const { subject_id } = useParams()
    const navigate = useNavigate()
    const [submitting, setSubmitting ] = useBoolean(false)
    const location = useLocation()
    const [state, setState] = useState({level: 'MEDIUM', duration: 10, total: 25})
    

    const [ loading, setLoading] = useBoolean(false)
    
    const handleInputChange = (name, value) => {
        setState({
            ...state,
            [name]: value
        })
    }


    useEffect(() => {
        setDashHeading('Mock Exam')
      return () => {
        setDashHeading('Dashboard')
      };
    }, [])

    const createQuiz = () => {
        setSubmitting.on()
        subjectApi._create_quiz({...state, subject_id: subject_id})
            .then((res) => {
                setSubmitting.off()
                navigate('/account/mock-exams/quiz/'+res?.quiz?.id, {state: 'Assessment'})
            })
            .catch((err) => {
                setSubmitting.off()
            })
    }


    return(
        <>
            <Helmet>
                <title>Mock Exam - PCTB Online Academy</title>
                <meta name="description" content="PCTB Online Academy is FREE online learning platform for HSSC and SSC Student covering of Subjects, Assessment and other learning material to help our young genration for tapping in to the future." />
            </Helmet>
            {
                loading ?
                    <Box d="flex" h="100%" alignItems="center" justifyContent="center">
                        <Spinner />
                    </Box>
                :
                <Box px="2em" py="3">
                    <Heading size="lg" fontWeight="800">{`${location?.state}`}</Heading>
                    <Box my="4" rounded="lg" p="10" bg="white">
                        <Heading fontWeight="800" size="md" textAlign="center">Choose Difficulty Level of Mock Exam</Heading>
                        <Stack direction={{ base: "column", md: "row" }} my="10" justifyContent="space-between" spacing={{base: "5", md: "5"}}>
                            <Box py={{ base: "10", md: '5' }} h="180px" onClick={() => handleInputChange('level', 'EASY')} flex="1" as={Button} bg={state?.level === 'EASY' ? "green.50" : "gray.100"} rounded="lg" border="2px solid" borderColor={state?.level === 'EASY' ? "green.500" : "transparent"} _hover={{ bg: 'green.50', borderColor: 'green.500' }}>
                                <Text fontSize="32px" fontWeight="800" color="green.500"> Easy</Text>                            
                            </Box>
                            <Box  py={{ base: "10", md: '5' }} h="180px" onClick={() => handleInputChange('level', 'MEDIUM')}  flex="1" as={Button} bg={state?.level === 'MEDIUM' ? "orange.50" : "gray.100"} rounded="lg" border="2px solid" borderColor={state?.level === 'MEDIUM' ? "orange.500" : "transparent"} _hover={{ bg: 'orange.50', borderColor: 'orange.500' }}>
                                <Text fontSize="32px" fontWeight="800" color="orange.500"> Medium</Text>                            
                            </Box>
                            <Box  py={{ base: "10", md: '5' }} h="180px"  onClick={() => handleInputChange('level', 'HARD')} flex="1" as={Button} bg={state?.level === 'HARD' ? "red.50" : "gray.100"} rounded="lg" border="2px solid transparent" borderColor={state?.level ==='HARD' ? "red.500" : "transparent"} _hover={{ bg: 'red.50', borderColor: 'red.500' }}>
                                <Text fontSize="32px" fontWeight="800" color="red.500"> Hard</Text>                            
                            </Box>
                        </Stack>
                    </Box>

                    <Stack direction={{ base: "column", md: "row" }} justifyContent="space-between" spacing={{base: "7", md: "7"}} mt="25px"  alignItems="flex-start">
                        <Box flex="1" rounded="lg" p="10" bg="white">
                            <Heading fontWeight="800" size="md" textAlign="center">Set Time for Mock Exam</Heading>
                            <Heading my="10" textAlign="center" fontWeight="800" fontSize="54px">{state?.duration}:00</Heading>
                            <Box  maxW="400px" mx="auto">
                                <Slider aria-label='slider-ex-1' value={state?.duration || 10} onChange={(val) => handleInputChange('duration', val)} max={45} min={10} colorScheme="accent" size="lg">
                                    <SliderTrack h="10px" rounded="full">
                                        <SliderFilledTrack />
                                    </SliderTrack>
                                    <SliderThumb bg="brand.500" w="24px" h="24px" />
                                </Slider>    
                                <Text mt="5" textAlign="center">
                                    Set time to attempt mock Exam {'\n'}
                                    i.e. between 10 - 45 minutes
                                </Text>                
                            </Box>    
                        </Box>
                        <Box flex="1" rounded="lg" p="10" bg="white">
                            <Heading fontWeight="800" size="md" textAlign="center">Choose # of Questions for Mock Exam</Heading>
                            <Heading my="10" textAlign="center" fontWeight="800" fontSize="54px">{state?.total}</Heading>
                            <Box  maxW="400px" mx="auto">
                                <Slider aria-label='slider-ex-1' value={state?.total || 25} onChange={(val) => handleInputChange('total', val)} max={100} min={25} colorScheme="accent" size="lg">
                                    <SliderTrack h="10px" rounded="full">
                                        <SliderFilledTrack />
                                    </SliderTrack>
                                    <SliderThumb bg="brand.500" w="24px" h="24px" />
                                </Slider>    
                                <Text mt="5" textAlign="center">
                                Choose between 25-100 questions to attempt {'\n'} Mock Exam within selected time frame
                                </Text>                
                            </Box>  
                        </Box>
                    </Stack>

                    <Box rounded="lg" my="10" p="10" bg="white">
                        <Stack direction={{ base: "column", md: "row" }} alignItems="center" justifyContent="space-between">
                            <Box textAlign="center">
                                <Heading mb="2" size="sm">Duration</Heading>
                                <Text>{state?.duration}:00 min</Text>
                            </Box>
                            <Box textAlign="center">
                                <Heading mb="2" size="sm">Number of Questions</Heading>
                                <Text>{state?.total}</Text>
                            </Box>
                            <Box textAlign="center">
                                <Heading mb="2" size="sm">Passing Marks</Heading>
                                <Text>70%</Text>
                            </Box>
                            <Box textAlign="center">
                                <Button rounded="lg" onClick={createQuiz} isLoading={submitting} colorScheme="accent">Create Mock Exam</Button>
                            </Box>
                        </Stack>
                    </Box>
                </Box>
            }            
            
        </>
    )
}
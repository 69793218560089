import React, { useEffect, useState } from "react"
import { Box, Spinner, useBoolean, Heading, HStack, Text,
    Accordion, AccordionItem, AccordionButton,
    AccordionPanel, AccordionIcon, Button,
} from "@chakra-ui/react"
import { useApp, usePrevious } from '../../../hooks'
import { useDispatch, useSelector } from "react-redux"
import { useParams, useNavigate } from "react-router-dom"
import { subjectAction } from "../../../redux/actions"
import { Helmet } from "react-helmet-async"
import ReactPlayer from 'react-player'
import { Icon, Modal } from '../../../uikit'
import { Link } from "react-router-dom"

export default function SubjectDetails () {
    
    const { subject_id } = useParams()
    const  navigate  = useNavigate()
    const prev_subject = usePrevious(subject_id)
    const [ details, setDetails ] = useState(null)
    const { setDashHeading, profile, user} = useApp()
    const dispatch = useDispatch()
    
    const [ loading, setLoading] = useBoolean(false)
    const { subject } = useSelector(state => state?.subjects)
    

    useEffect(() => {
        if(subject_id && subject_id !== prev_subject){
            setLoading.on()
            dispatch(subjectAction.details(subject_id))
            .then((res) =>{
                setLoading.off()
            })
            .catch((err) =>{
                setLoading.off()
            })  
        }  
    }, [subject_id])

    


    useEffect(() => {
        setDashHeading('Subjects')
      return () => {
        setDashHeading('Dashboard')
      };
    }, [])


    

    return(
        <>
            <Helmet>
                <title>Subjects - PCTB Online Academy</title>
                <meta name="description" content="PCTB Online Academy is FREE online learning platform for HSSC and SSC Student covering of Subjects, Assessment and other learning material to help our young genration for tapping in to the future." />
            </Helmet>
            {
                loading ?
                    <Box d="flex" h="100%" alignItems="center" justifyContent="center">
                        <Spinner />
                    </Box>
                :
                <Box px="2em" py="3">
                    <Heading fontFamily="Poppins" size="md">{`${subject?.grade?.title}`} {`${subject?.grade?.label || ''}`} {`${subject?.title}`}</Heading>

                    <Button mt="5" colorScheme="black" leftIcon={<Icon name="long-arrow-alt-left-solid" color="currentcolor" />} onClick={() => navigate('/dashboard/subjects')} variant="link">Back</Button>
                    {
                        subject?.chapters?.length > 0 ?
                        <Accordion mb="10" allowMultiple>
                        {                        
                                subject?.chapters?.map((chapter, index) =>
                                    <AccordionItem key={index} border="0" my="8" rounded="lg" shadow="custom" bg="white">
                                        <AccordionButton border="0" px="10" py="4" _hover={{ bg: 'transparent' }} _focus={{ shadow: 'none' }} borderBottom="1px solid" borderColor="gray.200">
                                            <Box flex='1' textAlign="left" pb="5px">
                                                <Text fontSize="15px" mt="4" fontWeight="500" color="accent.500">{chapter?.label}</Text>
                                                <Heading fontFamily="Poppins" fontSize={{base: '18px', md: '24px', lg: "30px"}}>{chapter.title} </Heading>
                                            </Box>
                                            <HStack spacing="5">                                                
                                                <Text fontSize="12px" color="accent.500">Click To Expand</Text>
                                                <AccordionIcon color="accent.500" />
                                            </HStack>
                                        </AccordionButton>
                                        <AccordionPanel pb={4} px="10">
                                            <Text>{chapter.details}</Text> 
                                            <Heading my="5" fontSize="20px">Topics</Heading>
                                            <Box>
                                                {
                                                chapter?.topics?.map((topic, idx) => 
                                                    <Box flex={1} key={idx} mb="5" p="15px" borderBottom="1px solid" borderColor="gray.200">
                                                        <HStack alignItems="flex-start" spacing={4}>
                                                            <Box w="32px" h="32px" rounded="full" bg="brand.500" d="flex" alignItems="center" justifyContent="center">
                                                                <Text color="white" fontSize="sm" fontWeight="bold">{idx + 1}</Text>
                                                            </Box>
                                                            <Box>
                                                                <Text fontSize="18px" fontFamily="Poppins" fontWeight="400">{topic.title}</Text>                                                        
                                                            </Box>
                                                        </HStack>
                                                        
                                                        <HStack my="5" flexDir={{ base: 'column', md: 'row' }} alignItems="flex-start" flexWrap="wrap" spacing="0">
                                                        {
                                                            topic.contents.map((content, c) => 
                                                            <Box w={{base: '100%', lg:"50%"}} key={c} cursor="pointer" onClick={() => navigate('/dashboard/subjects/topic/'+content?.id)}>
                                                                <Box  py="10px" px="20px">
                                                                    <HStack flex={1} flexDir={{ base: 'column', lg: 'row' }} position="relative" spacing={{base: 0, lg:"6"}} bg={"gray.100"} p="20px" rounded="md">
                                                                        {
                                                                            content?.type === 'VIDEO' &&
                                                                            <Box minW={{base: '100%', lg:"30%"}} rounded="lg" overflow="hidden">
                                                                                <Box className='player-wrapper'>  
                                                                                    <ReactPlayer className='react-player' width="100%" height='100%' url={'https://www.youtube.com/watch?v='+content?.data} light={true} />                                                            
                                                                                </Box>
                                                                            </Box>
                                                                        }
                                                                        <Box>
                                                                            
                                                                            {
                                                                                <Box>
                                                                                    <Text>{content.title}</Text>
                                                                                    {/* <Text>
                                                                                        {content.description}
                                                                                    </Text> */}
                                                                                    
                                                                                </Box>
                                                                            }      
                                                                                                                    
                                                                        </Box>                                                                        
                                                                    </HStack>
                                                                </Box>
                                                            </Box>
                                                        )}
                                                        </HStack>
                                                                                                           
                                                        
                                                    </Box>   
                                                )}

                                            </Box>
                                        </AccordionPanel>
                                    </AccordionItem>
                                )
                            }
                        </Accordion>
                        :
                        <Box my="5" bg="yellow.50" p="10" rounded="lg">
                            <Heading size="sm">Coming Soon</Heading>
                        </Box>
                    }

                   
                </Box>
            }            
            
        </>
    )
}